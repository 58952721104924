
.nav > li > a:focus,
.nav > li > a:hover {
    background: transparent;
}

.nav-tabs {
    margin-bottom: 10px;

    > li {
        margin-right: 5px;

        > a {
            border-bottom-color: transparent !important;
            padding: 7px 15px;

            .small {
                opacity:.8;
                margin-left: 4px;
            }
        }
    }
}

#navbar {
    input {
        margin: 0 5px 0 2px;
    }

    .navbar-header {
        display: flex;
    }

    .flex-parent--center {
        display: flex;
        justify-content: center;
    }

    .browser--ie & {
        .navbar-default {
            .navbar-brand {
                overflow: visible;
                padding: 1px;

                .heading {
                    max-width: 100px;
                }
            }
        }
    }

    .navbar-default {
        background: $primary-color;
        border: none;
        min-height: 50px;
        position: absolute;

        .navbar-nav {
            > li {
                > a {
                    height: 50px;
                    padding: 0 1.3em;

                    i {
                        line-height: 50px;
                    }
                }
            }
        }

        .navbar-plugin-button {
            position: relative;
            top: 1px;
        }

        .navbar-toggle {
            border-color: transparent;
            border-radius: 0;
            border-width: 0;
            fill: $white;
            float: left;
            height: 50px;
            line-height: 48px;
            margin: 0;
            padding: 0 10px;
            width: 50px;
            z-index: 5;

            &.navbar-right__icon {
                border-radius:50px;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 32px;
                line-height: 0;
                margin: 9px 10px 0 0;
                padding: 0;
                width: 32px;
            }

            &.menu-toggle {
                margin-right: 16px;
            }

            .icon-bar {
                background: $white;
                width: 21px;
            }

            .icon__search {
                svg {
                    left: -1px;
                    position: relative;
                    width: 17px;
                }
            }

            &.icon__mute {
                width: 30px;
                padding: 0;
                opacity: .6;
                font-size: 15px;
            }

            .icon--white {
                color: $white;
            }
        }

        .navbar-brand {
            flex: 1;
            font-size: 16px;
            height: 50px;
            line-height: 50px;
            overflow: hidden;
            padding: 0;
            display: flex;

            .dropdown, .MenuWrapper {
                max-width: calc(100% - 40px);
            }

            .heading {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                width: auto;
            }

            .header-dropdown__icon {
                margin-left: 6px;
            }

            .dropdown-toggle, .MenuWrapper > a {
                align-items: center;
                color: inherit;
                display: flex;
                overflow: hidden;
                text-decoration: none;
            }

            .description {
                color: inherit;
                display: inline-block;
                margin-right: 1em;
            }
        }
    }

    .sidebar-name {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        span {
            margin-left: 2px;
            white-space: nowrap;
        }
    }

    .channel-create-btn {
        margin-right: 15px;
    }

    .more-channel-table {
        border: 1px solid #dbdbdc;
        width: 100%;

        td {
            padding: 7px;
        }

        button {
            width: 110px;
        }
    }
}

.badge-notify {
    background: $red;
    left: 4px;
    position: absolute;
    top: 3px;
    z-index: 100;
}

.chat_section__badge {
    &.chat_section__badge {
        &--left {
            position: absolute;
            text-align: center;
            background: #f70101;
            color: #fff;
            border: 1px solid #fff;
            border-radius: 18px;
            width: 28px;
            top: 20px;
            left: 20px;
            font-size: 10pt;
            line-height: 10pt;
            padding: 5px;

        }

        &--right {
            min-width: max-content;
            position: absolute;
            text-align: center;
            color: #fff;
            border: 1px solid #fff;
            border-radius: 18px;
            width: 28px;
            top: 3px;
            right: 10px;
            font-size: 10pt;
            line-height: 10pt;
            padding: 5px;
        }

        &--purple {
            background: $purple;
        }
        &--red {
            background: $red;
        }
        &--green {
            background: $green;
        }
        &--orange {
            background: $orange;
        }
        &--ocean {
            background: $ocean;
        }
        &--lgray {
            background: $lgray;
        }

        &--shadow {
            box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
        }
    }
}
