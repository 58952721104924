@charset 'UTF-8';

.react-grid-row {
    &.react-grid-row_hot {
        background: #fff2f7;
    }

    &.react-grid-cell-wrapper {
        padding: 8px 0px !important;
    }
}

.promos-content {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    background: white;
    height: 100%;
    margin-left: 0px;
    padding-top: 0px;
    position: relative;

    .react-grid-cell {
        vertical-align: top;
    }

}

.order-heading-wrapper {
    display: flex;
    justify-content: space-between;
    padding: 20px 0px 20px 35px;
    white-space: nowrap;
}

.order-switcher {
    background: #fbfbfb;
    border-bottom: 1px solid #E2E2E2;
    padding: 20px;
    height: 68px;
}



.order-content {

}

.order-content__actions {
    display: flex;
    flex-direction: column;
}

.order-content__order-bold {
    font-weight: bold;
}

.order-content__order-regular {

}

.order-content__buttons {
    display: flex;
}

.order-content__message {
    margin-right: 1em;

    &.message {

    }

    &.message {

        &--link {
            color:gray;
            font-weight: bold;
        }

        &--bold {
            font-weight: bold;
        }

        &--wrap {
            font-weight: bold;
            white-space: normal;
        }
    }
}

.order-content__message-link {
    display: flex;
}

.order-content_row {
    color: #808080;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}



//todo: Вынести в отдельный scss

.order-tabs-item {
    display: flex;
    justify-content: space-around;

}

.order-tabs-item__title {
    font-weight: 8pt !important;
    color:gray;
}

.order-tabs-item__counter {
    color:black;
    text-align: right;
    font-weight: bold;
    margin-left: 1em;
    widows: 10px;
    height: 10px;

    &_red {
        background:$red;
    }
    &_green {
        background:$green;
    }
    &_orange {
        background:$orange;
    }
    &_gray {
        background: $gray;
    }
}
