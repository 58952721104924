@charset 'UTF-8';

@mixin opacity($opacity) {
    opacity: $opacity;
}
@mixin clearfix {
    overflow: hidden;

}
$default-has-layout-approach: zoom !default;
@mixin has-layout($approach: $default-has-layout-approach) {

}
@mixin alpha-property($property, $color, $opacity) {
    #{$property}: rgba($color, $opacity);
}

@mixin input-placeholder {
    &:-ms-input-placeholder { @content; }
    &:-moz-placeholder { @content; }
    &::-moz-placeholder { @content; }
    &::-webkit-input-placeholder { @content; }
}

// Make an element completely transparent.
@mixin transparent { opacity: 0; }
@mixin nowrap { white-space: nowrap; }
// Make an element completely opaque.
@mixin opaque { opacity: 1; }
$default-border-radius: 5px !default;
@mixin border-radius($radius: $default-border-radius, $vertical-radius: false) {

    @if $vertical-radius {
        // Webkit doesn't understand the official shorthand syntax for specifying
        // a vertical radius unless so in case there's several we only take the first.
        border-radius: $radius
    }
    @else {
        border-radius: $radius
    }
}
// Dependencies
@import '~bootstrap/dist/css/bootstrap.css';
@import '~jasny-bootstrap/dist/css/jasny-bootstrap.css';
@import '~perfect-scrollbar/dist/css/perfect-scrollbar.css';
@import '~font-awesome/css/font-awesome.css';
@import '~bootstrap-colorpicker/dist/css/bootstrap-colorpicker.css';

// styles.scss
@import 'utils/module';
@import 'base/module';
@import 'routes/module';
@import 'components/module';
@import 'layout/module';
@import 'responsive/module';
@import 'widgets/module';
