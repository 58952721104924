.layout__heading {}
.layout__heading-actions {
    display: flex;
    padding: 20px 0px 20px 20px;
    background: #fff;
    border-bottom: 1px solid #E2E2E2;
    height: 76px;
    flex: none;
    justify-content: space-between;
}

.layout__client_header {
    display: flex;
    padding: 5px 0px 10px 20px;
    height: 76px;
    border-bottom: 1px solid #dcdcdc;
    h2 {
        vertical-align: baseline;
    }
}

.layout__heading-buttons {
    display: flex;
    align-items: center;
    align-items: baseline;
    justify-content: center;
    margin-right: 10px;
}

.layout__content {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    background: #fff;
    height: 100%;
    margin-left: 420px;
    padding-top: 0px;
    position: relative;
}


.layout__list {
    position: relative;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 100%;
}
