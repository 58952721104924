@charset 'UTF-8';

.heading {
    -webkit-flex: 0 0 65px;
    flex: 0 0 65px;
    border-bottom: 1px solid #E2E2E2;
    font-size: 14px;
    position: relative;
    width: 100%;
    z-index: 9;

    .heading-wrapper {
        padding: 10px 0px 10px 20px;
        align-items: center;
        justify-content: space-between;
        display: flex;

        &__header {

        }

        &__buttons {
            margin-right: 20px;
        }

        &__levels-wrapper {
            flex-grow: 1;
            display: flex;
            justify-content: flex-end;
        }

        &.heading_profile {
            max-width: 95%;
            justify-content: space-between;
        }
    }

    h1 {
        margin: 0;
        font-family: 'Roboto', sans-serif;
        font-weight: bold;
        font-style: normal;
        font-size: 36px;
        line-height: 44px;
    }
}

.heading__unlock {
    display: flex;
    align-items: center;
    justify-content: start;
}

.main-content {

    display: flex;
    background: white;
    height: 100%;
    margin-left: 100px;
    padding-top: 0px;
    position: relative;
    flex-direction: column;
}

.client-tab-wrapper {
    background: #fbfbfb;
    border-bottom: 1px solid #E2E2E2;
    padding: 20px;
    overflow-x: auto;
}


.catalog-heading {
    padding: 20px;
    background: #F5F5F5;
    border-bottom: 1px solid #E2E2E2;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #454545;
}

.push-actions {
    display: flex;
    height: 40px;
    align-items: center;
    width: 80%;
    justify-content: flex-end;
}

.push-actions__form {
    width: 400px;
}
.push-sended-success {
    width: 400px;
    font-size: 16px;
    color: #2e7d32;
}
.catalog--tree {
    display: flex;
    flex-direction: column;
    border-right: $border-gray;
    height: 100%;
    left: 0;
    position: fixed;
    width: 420px;
    z-index: 1;

.search-bar__container {
    padding: 0 10px;
}
    body.announcement-bar--fixed & {
        height: calc(100% - #{$announcement-bar-height});
    }

    &.sidebar--padded {
        padding-top: 44px;
    }

    .catalog--tree__icons {
        border-bottom: 1px solid transparent;
    }

    .sidebar-item {
        border-bottom: 1px solid gainsboro !important;
        .icon {
            display: inline-block;
            margin: 0 7px 0 1px;
            position: relative;
            text-align: left;
            vertical-align: top;
            width: 15px;
        }

        .icon__lock {
            top: 2px;
        }

        .icon__globe {
            top: 1px;
        }

        .icon__archive {
            top: 2px;
        }

        .icon__draft {
            top: 2px;
        }

        .icon__bot {
            top: 1px;
            left: -1px;

            svg {
                width: 16px;
                height: 14px;
            }
        }
    }

    .sidebar-item__name {
        display: flex;
        flex: 1;
        padding-right: 10px;
        overflow: hidden;
        vertical-align: top;

        > span {
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100%;
            font-weight: bold;
        }
    }

    .sidebar-item__last {
        .message {
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }
    .sidebar__divider {
        color: $white;
        font-size: .9em;
        height: 20px;
        line-height: 20px;
        margin: 5px 0 6px;
        position: relative;
        z-index: 0;

        &:before {
            background: $light-gray;
            content: '';
            height: 1px;
            position: absolute;
            top: 10px;
            width: 100%;
        }

        .sidebar__divider__text {
            background: $primary-color;
            float: right;
            padding: 0 10px;
            position: relative;
            z-index: 1;
        }
    }

    .sidebar__switcher {
        flex: 0 0 45px;
        bottom: 0;
        display: block;
        padding-bottom: 0;
        width: 100%;

        button {
            background: transparent;
            display: block;
            font-weight: 600;
            height: 45px;
            line-height: 40px;
            padding: 0;
            text-decoration: none;
            width: 100%;

            > span {
                display: inline-block;
                position: relative;
                vertical-align: middle;
                z-index: 5;
            }

            &:hover {
                span {
                    opacity: 1;
                }
            }
        }

        .switch__shortcut {
            margin-left: 4px;
        }
    }
.sidebar--search {
    -webkit-flex: 0 0 65px;
    flex: 0 0 65px;
    border-bottom: 1px solid #cccccc;
    font-size: 14px;
    position: relative;
    width: 100%;
    z-index: 9;
    padding-top: 14px;
}
    .search__form {

        margin: 0;
        padding: 0em 10px;
    }
    .nav {
        margin: 0;
    }
    .nav-pills {
        > li {
            .badge {
                margin: 0 10px 0 0;
            }
        }
    }

    .catalog--tree__container {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }

    .catalog--tree__list {
        position: relative;
        /* flex: 1 1 auto; */
        /* display: flex; */
        /* flex-direction: column; */
        overflow: hidden;
        /* background: #f0f8ff; */

        .client-list {
            list-style: none;
            padding-left: 0px;


            div {
                padding: 5px 5px 5px 10px;
            }

            .counter {
                line-height: 0;
                padding: 0.8rem 0.2rem;
                width: 20px;
                border-radius: 5px;
                font-size: smaller;
                background: #409ef8;
                color: white;
                display: flex;
                max-height: 1.2em;
                justify-content: center;
            }
        }
    }

    .catalog--tree__extra {
        padding: 5px 0px 5px 20px;
        background: #F5F5F5;
        border-top: 1px solid #dcd7d7;
        border-bottom: 1px solid #dcd7d7;

        &:hover {
            cursor: pointer;
            background-color: #e9e9e9;
        }
    }

    .nav-pills__container {
        -webkit-overflow-scrolling: touch;
        overflow: auto;
        position: relative;
        width: 100%;
        padding-bottom: 10px;
    }

    .nav-pills__unread-indicator {
        border-radius:(50px);
        font-size: 13.5px;
        cursor: pointer;
        left: 15px;
        margin: 0 auto;
        padding: 4px 0 3px;
        position: absolute;
        text-align: center;
        width: 190px;
        z-index: 1;

        .icon {
            margin-left: 5px;
        }
    }

    .nav-pills__unread-indicator-top {
        top: 15px;

        .icon {
            svg {
                 transform: rotate(180deg);
            }
        }
    }

    .nav-pills__unread-indicator-bottom {
        bottom: 15px;
    }

    .nav {
        &.nav-stacked {
            > li + li {
                margin: 0;
            }
        }

        .public_direct_name {
            flex: 1;
            cursor: pointer;
        }

        li {
            > h4 {
                align-items: end;
                display: flex;
                font-size: 1em;
                font-weight: 600;
                margin: 1.5em 0 .7em;
                padding: 0 12px 0 15px;
                text-transform: uppercase;

                span {
                    flex: 1;
                }
            }

            .divider {
                & + .divider {
                    display: none;
                }
            }

            > .nav-more {
                border-radius: 0;
                display: block;
                line-height: 1.5;
                outline: none;
                overflow: hidden;
                padding: 5px 0 5px 15px;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            .sidebar-item {
                ////@include single-transition(none);
                align-items: center;
                border-radius: 0;

                line-height: 1.5;
                outline: none;
                overflow: hidden;
                padding: 5px 5px 5px 15px;
                text-decoration: none;
                text-align: left;
                text-overflow: ellipsis;
                white-space: nowrap;
                width: 100%;

                .status {
                    flex: 0 0 auto;
                }

                .icon {
                    &.icon__archive,
                    &.icon__globe,
                    &.icon__draft,
                    &.icon__lock,
                    &.icon__bot {
                        opacity: 0.5;
                    }
                }

                &.has-close {
                    &:hover {
                        .btn-close {
                            opacity:.8;
                            display: block;
                        }
                    }

                    .btn-close {
                        opacity: 0;
                        display: none;
                        font-family: 'Open Sans', sans-serif;
                        font-size: 21px;
                        font-weight: 600;
                        position: relative;
                        width: 24px;
                        height: 20px;
                        line-height: 20px;
                        margin-right: 7px;
                        text-align: center;

                        &:hover {
                            opacity: 1;
                        }
                    }
                }

                &.unread-title {
                    font-weight: 600;
                }

                &.muted {
                    opacity: 0.5;
                }

            }

            &.active {
                .muted {
                    opacity: 1;
                }

                button,a {
                    &:before {

                    }
                }

                .icon {
                    &.icon__archive,
                    &.icon__globe,
                    &.icon__draft,
                    &.icon__lock,
                    &.icon__bot {
                        opacity: 0.7;;
                    }
                }

                button,a,
                button:hover,a:hover,
                button:focus,a:focus {
                    @include alpha-property(background-color, $black, .1);
                    border-radius: 0;
                    font-weight: 400;

                    &.unread-title {
                        font-weight: 600;
                    }
                }
            }
        }
    }

    .modal-title {
        line-height: 2em;
    }

    .add-channel-btn {
        float: right;
        font-size: 1.9em;
        font-weight: 700;
        line-height: 18px;
        margin: -2px 0 0 0;
        outline: none;
        padding: 0 5px;
        text-decoration: none;

        &:hover {
            color: #666666;
        }
    }

    .status-wrapper {
        height: 36px;
        width: 36px;

        .status {
            bottom: -4px;
            height: 18px;
            right: -5px;
            width: 18px;

            &.status-edit {
                .fa {
                    top: 4px;
                }
            }

            .fa {
                position: relative;
                top: 2px;
            }

            .icon__container {
                &:after {
                    border-radius: 20px;
                    content: '';
                    height: 10px;
                    left: 4px;
                    position: absolute;
                    top: 4px;
                    width: 10px;
                }
            }

            svg {
                height: 13px;
                left: 3px;
                max-height: initial;
                position: relative;
                top: 3px;
                width: 13px;
                z-index: 1;
            }
        }
    }
}


    .catalog--tree {
        left: 100px;
    }

    .app__content {
        margin-left: 305px;
    }

.channel-loading-gif {
    height: 15px;
    margin-top: 2px;
    width: 15px;
}

.join-channel-loading-gif {
    height: 25px;
    margin-left: 10px;
    margin-top: 5px;
    width: 25px;
}

/* context menu shown when right-clicking on a channel in the LHS when using the desktop app */
.react-contextmenu--visible {
    background: #f0f0f0;
    border: 1px solid #C6C6C6;
    border-radius: 5px;
    color: black;
    cursor: pointer;
    padding: 2px 0;
    z-index: 100;
}

.react-contextmenu-item {
    margin: 1px 0;
    padding: 0 22px;

    &:hover {
        background: #489DFE;
        color: $white;
    }

    &:focus,
    span {
        outline: none;
    }
}

.tree-ul {
    list-style: none;
    background-color: #fff;
    margin: 0px;
    padding: 0px;

    .tree-node {
        list-style: none;

        margin: 0px;
        padding: 0px;


        position: relative;

        background: #FFFFFF;

        box-sizing: border-box;


        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        /* identical to box height */


        color: #3995EA;


    }
    .tree-subtree {
        border-left: 1px solid #E2E2E2;
        padding: 0;
        margin-left: 20px;
        margin-top: 0;
    }
    .tree-container {
        cursor: pointer;
        position: relative;
        padding: 0px 5px;
        display: block;
        border-top: 1px solid #E2E2E2;
        border-bottom: 1px solid #E2E2E2;
        margin-top: -1px;
        padding: 20px;
    }
    .tree-toggle {
        position: relative;
        display: inline-block;
        vertical-align: top;
        margin-left: -5px;
        height: 24px;
        width: 24px;

    }
    .tree-base {
        display: inline-block;
        vertical-align: top;

    }
}

.catalog-list {
    .catalog-item {

        border-bottom: 1px solid #E2E2E2;;
    }
    .catalog-item-wrapper {
        padding: 20px;
        position: relative;
    }

}

.row-item-wrapper {
    padding: 20px 0;
    padding: 0;
    position: relative;
    min-height: 160px;
    min-height: 120px;

    .img {
        position: absolute;
        width: 120px;
        height: 120px;
        display: block;
        top:0;
    }
}


.item-content {
    position: relative;
    margin-left: 140px;
    white-space: normal;
    .item-content-wrapper {

        margin: 0
    }
    .item-name {

        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 15px;
    }
    .item-desc {
        font-size: 12px;
        line-height: 22px;
        color: #454545;
        margin-bottom: 15px;
    }
    .item-action {

    }
    .item-price{
        position: absolute;
        right: 0;
        top: 0
    }
}
.row {
    margin: 0 !important;
}

.catalog__content {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    background: white;
    height: 100%;
    margin-left: 420px;
    padding-top: 0px;
    position: relative;

}

.moderation__content {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    background: white;
    height: 100%;
    margin-left: 420px;
    padding-top: 0px;
    position: relative;

    .tabs {
        flex-grow: 1;
    }

    .buttons{
        width: 33rem;
        justify-content: space-around;
    }

    .tool-wrapper {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 100%;
    }

}

.promos__content {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    background: white;
    height: 100%;
    margin-left: 0px;
    padding-top: 0px;
    position: relative;
}
.button {
    display: inline-block;
    margin-bottom: 0;
    font-weight: normal;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;

    background-image: none;
    border: 1px solid transparent;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    border-radius: 4px;

    user-select: none;
    &.link-style {
        background: none;
        padding: 0;
        margin-right: 15px;

    }
    &.outline-style{
        background: #FFFFFF;
        border: 2px solid #63AEF4;
        box-sizing: border-box;
        border-radius: 30px;
        color: #63AEF4;
        text-transform: uppercase;

        font-weight: bold;
        font-size: 13px;
        line-height: 16px;
        text-align: center;
        letter-spacing: 0.06em;

    }
    &.success {
        color:green;
    }
    &.danger {
        color: #DF5454;
    }
    &.warning {
        color: #F38D2E;
    }
}

.product-image {
    &.normal {
        border-radius: 4px;
    }
    &.rounded {
        border-radius: 8px;
    }
}

.price {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    color: #000000;
}

.no-active-label {
    padding: 0.4em 1em;
    border-radius: 4px;
    background: orange;
    color: white;
    font-size: initial;
}

label {

    font-weight: normal !important;
}
.main-content__wrapper {
    overflow: auto;
    height: 100%;
}
.legend {
    margin-bottom: 20px;
}
.w80 {
    width: 80px;
}
.w20 {
    width: 80px;
    display: inline-block;
    margin-right: 20px !important;
}

.switcher {
    margin-bottom: 10px;
}

.catalog-list {
    position: relative;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 100%;
}

input[type="file"] {
    display: block;
    width: 20px;
    height: 20px;
    border: 1px solid
}



.react-grid-header-fixed-container {
    //position: absolute;
}
.input-w80 {
    max-width: 80%
}
.input-w80px {
    max-width: 80px
}

.input-w20 {
    width: 20%
}

.input-w10 {
    width: 10%
}

.input-w30 {
    width: 30%
}

.input-w40 {
    width: 40%
}

.row-item-thumbnail {
    flex: none;
    height: 100%;
    width: 100%;
}

.product-preview {
    position: fixed;
    height: 100%;
    right: 0;
    top: 120px;
    width: 350px;
}
