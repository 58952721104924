@charset 'UTF-8';

html,
body {
    height: 100%;

    &.sticky {
        height: auto;
        overflow: auto;
    }
}

body {
    background: $bg--gray;
    position: relative;
    width: 100%;

    &.announcement-bar--fixed {
        padding-top: $announcement-bar-height;
    }
}

.sticky {
    background: $white;

    > .channel-view {
        height: 100vh;
        padding: 0 15px;
        overflow: auto;
    }

    .inner-wrap {
        width: 100%;

        > .row {
            &.content {
                min-height: calc(100vh - 89px);

                @media (max-width: 768px) {
                    min-height: calc(100vh - 187px);
                }
            }
        }
    }
}

.help-page {
    overflow: hidden;
    padding: 3em 0;
}

.inner-wrap {
    height: 100%;

    &.sticky {
        overflow: auto;
    }

    > .row {
        &.main {
            height: 100%;
            position: relative;
        }
    }
}
@mixin legacy-pie-clearfix {
    &:after {
        content    : "\0020";
        display    : block;
        height     : 0;
        clear      : both;
        overflow   : hidden;
        visibility : hidden;
    }
    @include has-layout;
}

.container-fluid {
    @include legacy-pie-clearfix;
    height: 100%;
    position: relative;
    padding: 0 !important;
    -webkit-overflow-scrolling: touch;
}

.channel-view {
    @include clearfix;
    height: 100%;
    position: relative;

    &--mobile {
        width: auto;
    }
}

img {
    max-width: 100%;
    &.rounded {
       border-radius : 100%;
    }
}
