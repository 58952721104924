@charset 'UTF-8';

.mention {
    border-radius:3px;
    background: $primary-color;
    color: $white;
    padding-bottom: 2px;
    position: relative;
    z-index: 10;
}

.badge {
    background-color: #777;
    border-radius: 10px;
    color: #fff;
    display: inline-block;
    font-size: 12px;
    font-weight: bold;
    line-height: 1;
    min-width: 10px;
    padding: 3px 7px;
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
}

.mentions__name {
    @include clearfix;
    cursor: pointer;
    font-size: 13px;
    line-height: 20px;
    margin: 0;
    padding: 6px 10px;
    position: relative;
    white-space: nowrap;
    width: 100%;
    z-index: 101;
    display: flex;

    &.no-flex {
        display: block;
    }

    .admin-setting-user--align {
        margin-right: 5px;
    }

    .icon {
        opacity: 0.5;
        display: inline-block;
        margin: 0 7px 0 0;
        position: relative;
        text-align: center;
        top: 2px;
        vertical-align: top;
        width: 16px;

        &.icon__globe {
            svg {
                height: 14px;
                width: 14px;
            }
        }
    }

    .status {
        font-size: 15px;
        text-align: center;
        vertical-align: bottom;
        width: 20px;
    }

    .fa-user {
        position: relative;
        top: -1px;
    }

    .mention--align {
        @include clearfix;
        text-overflow: ellipsis;
        margin-right: 5px;
    }
}

.mention__image {
    border-radius:(20px);
    display: block;
    font-size: 15px;
    height: 16px;
    line-height: 16px;
    margin-right: 7px;
    margin-top: 3px;
    text-align: center;
    width: 16px;

    .mention--align {
        display: inline-block;
        max-width: 80%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

.mention__fullname {
    opacity: 0.5;
}

.mention--highlight {
    background-color: $yellow;
}

.mention__purpose {
    opacity: 0.5;
    line-height: normal;
    margin-left: 5px;
    white-space: normal;
}

.mention__channelname {
    opacity: 0.5;
}
