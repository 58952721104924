@charset 'UTF-8';

.post-right__container {
    display:flex;
    flex-direction:(column);
    height: 100%;

    .post-right-root-message {
        padding: 1em 1em 0;
    }

    .post-right-comments-container {
        position: relative;
    }


    .help_format_text {
        bottom: -65px;
        right: auto;
    }

    .post {
        &.post--root {
            border-bottom: 1px solid #ddd;
            padding-bottom: 1em;
            padding-left: 1em;

            .post__body {
                background: transparent !important;
            }
        }

        .post__header {
            .col__reply {
                text-align: right;
            }
        }

        .post__body {
            width: 100%;
        }

        &.post--compact {
            .post__body {
                padding-left: 0;
            }

            &.same--root {
                &.post--comment {
                    padding-left: 1em;

                    .post__header {
                        margin-left: 0;
                        float: none;
                        height: auto;

                        .post__time {
                            opacity: .6;
                        }
                    }

                    .flag-icon__container {
                        left: auto;
                        position: relative;
                        top: 2px;
                    }
                }
            }

            &.same--user {
                .post__header {
                    .col__name {
                        display: inline-block;
                    }
                }
            }
        }

        .post__img {
            padding: 2px 8px 0 0;
        }
    }

    hr {
        border: none;
    }

    .date-separator {
        hr {
            border-top: 1px solid #eee;
        }
    }

    .post-create__container {
        width: 100%;

        .btn {
            margin-bottom: 10px;

            &.disabled {
                background: grey !important;
                border-color: transparent !important;
            }
        }

        .custom-textarea {
            max-height: calc(50vh - 80px);
            min-height: 100px;
        }

        .msg-typing {
            opacity: 0.7;;
            display: block;
            float: left;
            font-size: 13px;
            height: 20px;
            line-height: 20px;
            margin-top: 3px;
            max-width: 230px;
            min-width: 1px;
        }

        .post-right-comments-upload-in-progress {
            opacity: 0.7;;
            margin-right: 10px;
            padding: 6px 0;
        }

    }
}

.post-right-header {
    border-bottom: $border-gray;
    color: #999;
    font-size: 1em;
    font-weight: 400;
    height: 39px;
    padding: 10px 10px 0 15px;
    text-transform: uppercase;
}

.post-right-root-container {
    border-bottom: $border-gray;
    padding: 5px 10px;

    ul {
        margin-bottom: 2px;
        padding-left: 0;
    }
}

.post-right-channel__name {
    font-weight: 600;
    margin: 0 0 15px;
}

.post-right-root-container li {
    display: inline;
    list-style-type: none;
    padding-right: 3px;
}

.post-right-root-time {
    color: #a8adb7;
}

.post-right-create-comment-container {
    bottom: 0;
    left: 0;
    margin-bottom: 18px;
    padding: 5px;
    position: absolute;
    width: 100%;
}

.post-right__scroll {
    -webkit-overflow-scrolling: touch;
    flex:(1 1 auto);
    padding-top: 10px;
    position: relative;

    .file-preview__container {
        margin-top: 5px;
    }
}

.post-right-comment-time {
    color: #a8adb7;
}
