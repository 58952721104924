
.channel-header {
   flex:(0 0 65px);
    border-bottom: 1px solid #cccccc;
    font-size: 14px;
    position: relative;
    width: 100%;
    z-index: 9;

    .move--left & {
        .flex-parent {
            padding-right: 0;
        }
    }

    .channel-header__actions {
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-wrap: wrap;
        button:first-of-type {
            margin-right: 5px;
        }
    }

    .channel-header__favorites {
        opacity: 0.5;
        float: left;
        height: 20px;
        margin: 0 5px 0 0;
        padding-top: 2px;
        position: relative;
        z-index: 1;

        &.inactive {
            color: inherit;
        }

        &.active {
            opacity: 1;
        }

        i {
            font-size: 16px;
            position: relative;
            top: -1px;
        }
    }

    .channel-header__mute {
        opacity: 0.5;
        flex: 0;
        float: left;
        height: 20px;
        margin: 0 7px;
        position: relative;
        z-index: 1;

        &.inactive {
            color: inherit;
        }

        &.active {
            opacity: 1;
        }
    }

    .flex-parent {
        display: flex;
        padding: 0 16px;
    }

    .flex-child {
        &:first-child {
            flex: 1;
            min-width: 0;
            padding: 0 8px 0 0;
        }

        .markdown__paragraph-inline {
            overflow: hidden;
            text-overflow: ellipsis;
            width: calc(100% - 50px);
        }
    }

    .channel-header_plugin-dropdown {
        min-width: 200px;
        max-width: 300px;
        padding: 0;

        .fa {
            text-align: center;
            width: 20px;
            margin-left: -5px;
        }

        svg {
            text-align: center;
            width: 20px;
            margin-left: -5px;
            margin-right: 5px;
        }

        a {
            border-left: 3px solid transparent;
            padding: 10px 16px;

            &:hover {
                .fa {
                    opacity: 1;
                }
                svg {
                    opacity: 1;
                }
            }
        }
    }

    .channel-header__icon {
        border-radius:50%;
        border: 1px solid;
        cursor: pointer;
        height: 30px;
        line-height: 30px;
        margin: 16px 0 0 8px;
        min-width: 30px;
        position: relative;
        text-align: center;
        width: 30px;
        z-index: 5;
        color: #B1B1B3;

        &.wide {
           border-radius:(37px);
            line-height: 28px;
            padding: 0;
            white-space: nowrap;
            width: auto;

            > div {
                padding: 0 8px;
            }
        }

        &.text {
            font-weight: 600;
            padding: 0 14px;

            button {
                opacity:(.4);
            }

            &:hover {
                button {
                    opacity: 1;
                }
            }
        }

        .icon__text {
            font-weight: 600;
            margin-right: 4px;
            position: relative;
        }

        a {
            display: block;
        }

        .fa {
            font-size: 15px;
        }

        & + div {
            display: none;
        }

        .icon {
            position: relative;
            align-items: center;
            justify-content: center;
        }

        .icon__ellipsis {
            padding-top: 5px;
        }

        .icon__members {
            top: 2px;
        }

        .icon__mentions {
            top: 1px;
        }

        .icon__flag {
            top: 1px;
        }

        .icon__search {
            top: 1px;
        }
    }

    .move--left & {
        .icon--hidden {
            display: none;
        }
    }

    &.alt {
        margin: 0;

        th {
            font-weight: normal;
            vertical-align: top;

            &.header-list__right {
                padding-right: 4px;
                white-space: nowrap;
            }
        }

        td {
            border: none;
        }
    }

    .heading {
        font-size: 14px;
        font-weight: 600;
        margin: 0 8px 0 0;
        flex: 1 1 0;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .Badge {
        margin-left: 0;
    }

    .caret {
        margin-top: 3px;
    }

    .more {
        color: #81848b;
        display: inline-block;
        font-size: 13px;
        text-transform: capitalize;
        vertical-align: top;
    }

    .disabled {
        color: #999999;
    }
}

.row {
    &.header {
        position: relative;

        .channel__wrap & {
            display: none;
        }
    }
}

.header-dropdown__icon {
    color: inherit;
    font-size: 19px;
    margin-left: 1px;
    position: relative;
    top: 0;
}

.channel-header__top {
    display: flex;
}

.channel-header__info {
    margin-top: 13px;
    white-space: nowrap;

    .dropdown-toggle {
        display: flex;
        overflow: hidden;
        text-align: left;
        text-overflow: ellipsis;
    }

    .header-dropdown__icon {
        flex: 0 0 auto;
        color: $dark-gray;
    }

    .channel-header__title {
        display: flex;

        .channel-header__favorites {
            flex: 0 0 auto;
        }

        .channel-header-archived-icon {
            opacity: .5;
        }

        h2 {
            display: flex;
            flex: 0 1 auto;
            min-width: 0;
        }

        > a {
            display: inline-block;
            text-decoration: none;
            width: calc(100% - 65px);
        }

        h2 {
            font-size: inherit;
            line-height: inherit;
            margin: 0;
        }

        .MenuWrapper {
            width: 100%;
        }

        .modal {
            white-space: normal;
        }
    }

    .channel-header__description {
        font-size: 12px;
        margin-bottom: 3px;
        overflow: hidden;
        position: relative;
        text-overflow: ellipsis;
        width: calc(100% - 18px);
        height: 20px;


        blockquote {
            font-size: 13px;
            padding: 1px 0 0 19px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: inline;

            &:before {
                font-size: 15px;
                left: 2px;
                top: 0;
            }
        }

        .status {
            margin-right: 3px;
        }

        .markdown__heading {
            font-size: 1em;
            margin: 0;
        }

        code {
            margin: 1px 0 0;
            padding: 2px 5px 0;
        }

        &.light {
            opacity:.8;
            color: inherit;

            button {
                &:hover
                &:focus {
                    text-decoration: underline;
                }
            }
        }

        .markdown-inline-img {
            max-height: 45px;
        }
    }

    .header-status__text {
        margin-right: 3px;

        &:after {
            content: '\2022';
            margin: 0 2px 0 5px;
            position: relative;
            top: 1px;
        }
    }

    .status {
        width: 18px;

        svg {
            max-height: 20px;
            width: 16px;
        }
    }
}

.channel-header__popover,
.navbar__popover {
    p {
        white-space: pre-wrap;
    }
}

.channel-intro {
    margin: 0 auto 10px;
    padding: 0 15px;

    &.channel-intro--centered {
        max-width: 1020px;
    }

    .intro-links {
        display: inline-block;
        margin: 0 1.5em 10px 0;

        .fa {
            margin-right: 5px;
        }
    }

    .channel-intro-profile {
        margin-top: 5px;

        .user-popover {
            max-width: calc(100% - 100px);
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .channel-intro-img {
        .status-wrapper {
            height: 50px;
            margin: 0 10px 10px 0;
        }

        img {
            border-radius:(100px);
        }
    }

    .channel-intro__title {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 15px;
    }

    .channel-intro__content {
        border-radius:3px;
        background: $bg--gray;
        padding: 10px 15px;
    }
}

// Team Header in Sidebar
.sidebar--left,
.nav-left,
.sidebar--menu,
.admin-sidebar {

    .divider {
        border-top: 1px solid transparent;

        & + .divider {
            display: none;
        }
    }

    .team__header {
        @include legacy-pie-clearfix;
        display: flex;
       flex-direction:(row-reverse);
        align-items: center;
        flex: 0 0 65px;
        position: relative;
        max-height: 65px;
        padding: 10px;

        &:before {
            ////@include single-transition(all, .05s, linear);
            background: none;
            content: '';
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
        }

        &:hover {
            &:before {
                @include alpha-property(background, $black, .1);
            }

            .user__name {
                color: $white;
            }

            .sidebar-header-dropdown {
                .sidebar-header-dropdown__toggle {
                    opacity: 1;
                }
            }

            .navbar-right {
                .dropdown-toggle {
                    opacity: 1;
                }
            }

            .header__info {
                .sidebar-header-dropdown__icon {
                    opacity: 1;
                }
            }
        }

        a {
            text-decoration: none;
            flex-grow:(1);
        }

        a.out_of_office {
            background-color: transparent !important;
            cursor: default;
        }

        .dropdown {
            padding: 3px 0 0 16px;
        }

        .dropdown-menu {
            margin: 4px 0 0 10px;
            min-width: 220px;
        }

        .sidebar-header-dropdown,
        .admin-navbar-dropdown {
            font-size: .85em;
            height: 100%;
            left: 0;
            margin-right: -22px;
            position: absolute;
            right: 22px;
            top: 0;

            li {
                width: 100%;
            }

            .sidebar-header-dropdown__toggle,
            .admin-navbar-dropdown__toggle {
                opacity:.8;
                background: none;
                color: $white;
                display: block;
                font-size: 1em;
                height: 100%;
                left: 0;
                line-height: 1.8;
                padding: 10px;
                width: 100%;
            }

            .dropdown-menu {
                margin-left: 10px;
                margin-top: 4px;
                min-width: 199px;

                a,
                button {
                    overflow: hidden;
                    padding: 3px 20px;
                    text-overflow: ellipsis;
                }
            }

            .sidebar-header-dropdown__icon,
            .admin-navbar-dropdown__icon {
                border-radius: 36px;
                fill: $white;
                float: right;
                height: 36px;
                line-height: 36px;
                position: relative;
                right: -5px;
                text-align: center;
                top: 0;
                width: 36px;
            }
        }

        .user__picture {
            border-radius:36px;
            float: left;
            height: 36px;
            width: 36px;
        }

        .header__info {
            @include clearfix;
            flex-grow:(1);
            color: $white;
            padding: 2px 16px 0 8px;
            position: relative;
            width: 100%;

            .sidebar-header-dropdown__icon {
                opacity:.8;
                border-radius: 36px;
                fill: $white;
                height: 36px;
                line-height: 36px;
                text-align: center;
                width: 36px;

                position: absolute;
                right: -5px;
                top: 4px;
            }
        }

        .team__name,
        .user__name {
            display: block;
            font-size: 14px;
            font-weight: 600;
            max-width: 120px;
            overflow: hidden;
            text-decoration: none;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .team__name {
            line-height: 22px;
            margin: 0;
        }

        .user__name {
            //@include single-transition(all, .1s, linear);
            @include alpha-property(color, $white, .8);
            font-size: 14px;
            font-weight: 400;
            top: -3px;
            position: relative;
        }
    }

    .search__clear {
        display: none;
    }
}

#navbar {
    .navbar-default {
        .navbar-toggle {
            &.menu-toggle {
                padding: 0 1em;
            }
        }
    }
}

.channel-header__navbar {
    font-size: 16px;

    .heading {
        margin-right: 3px;
    }

    .header-dropdown__icon {
        top: 1px;
    }
}

.app__body {

    .channel-header__links {
        display: inline-block;
        font-family: 'Open Sans', sans-serif;
        font-size: 22px;
        height: 30px;
        line-height: 26px;
        margin-right: 1px;
        text-align: center;
        width: 30px;

        th {
            &:last-child {
                div {
                    margin-right: 10px;
                }
            }
        }

        .channel__wrap.move--left & {
            position: absolute;
            right: -400px;
            top: 14px;
        }

        .icon__flag {
            svg {
                height: 19px;
                position: relative;
                top: 2px;
                width: 19px;
            }
        }

        > a {
            opacity: 0.6;
            color: inherit;
            text-decoration: none;

            &:hover {
                opacity: 0.7;
            }

            &:focus {
                color: inherit;
            }

            &.active {
                color: $primary-color;
                opacity: 1;

                .icon {
                    fill: $primary-color;
                }
            }
        }
    }

}

.channel-header-archived-icon {
    margin-right: 5px;
    position: relative;
    top: 2px;
}

.channel-heading {
    color: #333;

    &:hover {
        text-decoration: none;
        color: #333;
    }
    h2 {
        margin: 0;
        font-family: Inter;
        font-style: normal;
        font-weight: bold;
        font-size: 28px;
        line-height: 60px;
    }
}
