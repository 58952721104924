.Menu {
    z-index: 10000;

    &.dropdown-menu {
        border-radius:4px;
        display: block;
        max-height: 80vh;
        max-width: 270px;
        overflow-x: hidden;
        overflow-y: auto;
        padding: 8px 0;

        li {
            a {
                color: inherit;
            }
        }
    }

    &.Menu-enter {
        &.dropdown-menu {
            opacity: 0;

            @media screen and (min-width: 641px) {
                max-height: 0;
                overflow: hidden;
                overflow-y: hidden;
            }
        }
    }

    &.Menu-enter-active {
        &.dropdown-menu {
            opacity: 1;
            @media screen and (min-width: 641px) {
                max-height: 80vh;
                transition: max-height 1000ms ease-in, opacity 250ms linear;
                overflow-y: hidden;
            }
            @media screen and (max-width: 640px) {
                transition: opacity 1000ms linear;
            }
        }
    }

    &.Menu-enter-done {
        &.dropdown-menu {
            opacity: 1;
            @media screen and (min-width: 641px) {
                max-height: 80vh;
                overflow-y: auto;
            }
        }
    }

    &.Menu-exit {
        &.dropdown-menu {
            opacity: 0;
            @media screen and (min-width: 641px) {
                max-height: 0;
                transition: max-height 1000ms ease-in 0ms, opacity 250ms linear 750ms;
                overflow-y: hidden;
            }
            @media screen and (max-width: 640px) {
                transition: opacity 1000ms linear;
            }
        }
    }
}
