@charset 'UTF-8';

.docs__page {
    line-height: 1.7;
    padding-bottom: 20px;

    > div {
        margin: 0 auto;
        max-width: 100%;
        padding: 0 15px;
        width: 1170px;
    }

    h1.markdown__heading {
        border-bottom: 1px solid #ddd;
        margin: 1em 0 1em;
        padding-bottom: 1rem;
    }
}
