.react-grid-page-buttons {
    background-color: #2e7d32;
    border: none;
    border-radius: 3px;
    color: #fff;
    cursor: pointer;
    font-family: 'Roboto', sans-serif;
    opacity: 1;
    &:focus {
        outline: none;
    }
    &:active {
        background-color: #52bf57;
        opacity: 1;
    }
    &:hover {
        opacity: 0.6;
    }
    &:disabled {
        background-color: #fff;
        color: #161616;
        cursor: initial;
    }
}

.react-grid-cell {
    border-bottom: 1px solid #f7f7f7;
    box-sizing: border-box;
    line-height: 20px;
    padding: 0 20px;
    position: relative;
    text-overflow: ellipsis;
    vertical-align: top;

    &.react-grid-edit {
        overflow: visible;
    }
    .react-grid-inactive {
        pointer-events: none;
    }
}

.react-grid-container {

    position: relative;
}

.react-grid-table {
    border-collapse: collapse;
    border-radius: 4px;
    font-size: 13px;
    position: relative;
    table-layout: fixed;
    text-align: left;
    white-space: nowrap;
    width: 100%;
    &.react-grid-header-fixed {
        box-shadow: none;
        z-index: 9;
        &.react-grid-header-stuck {
            margin-left: auto;
            margin-right: auto;
            position: fixed;
            top: 0;
        }
        &.react-grid-header-stuck-bottom {
            position: absolute;
        }
    }
    &.react-grid-header-hidden thead {
        border: none;
        height: 0;
        * {
            border: none;
            height: 0;
        }
        th * {
            border: none;
            display: none;
            height: 0;
        }
    }
}

.react-grid-table-container {
    background-color: #fff;
    overflow: hidden;
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;
    will-change: transform;
}

.react-grid-cell-tree-arrow {
    &.react-grid-tree-node-depth-0 {
        cursor: pointer;
        padding: 10px 5px 10px 0;
        padding-left: -16px;
        &::before {
            font-family: FontAwesome;
            font-size: 12px;
            font-style: normal;
            font-weight: normal;
            content: '\F078';
            font-size: 8px;
            left: -5px;
            position: relative;
            top: -1px;
        }
        &.react-grid-node-unexpanded {
            cursor: pointer;
            &::before {
                font-family: FontAwesome;
                font-size: 12px;
                font-style: normal;
                font-weight: normal;
                content: '\F054';
                font-size: 8px;
                left: -8px;
                margin-right: 2px;
                position: relative;
                top: -1px;
            }
        }
        &:not(.react-grid-expand) {
            visibility: hidden;
        }
    }
    &.react-grid-tree-node-depth-1 {
        cursor: pointer;
        padding: 10px 5px 10px 0;
        padding-left: 0px;
        &::before {
            font-family: FontAwesome;
            font-size: 12px;
            font-style: normal;
            font-weight: normal;
            content: '\F078';
            font-size: 8px;
            left: -5px;
            position: relative;
            top: -1px;
        }
        &.react-grid-node-unexpanded {
            cursor: pointer;
            &::before {
                font-family: FontAwesome;
                font-size: 12px;
                font-style: normal;
                font-weight: normal;
                content: '\F054';
                font-size: 8px;
                left: -8px;
                margin-right: 2px;
                position: relative;
                top: -1px;
            }
        }
        &:not(.react-grid-expand) {
            visibility: hidden;
        }
    }
    &.react-grid-tree-node-depth-2 {
        cursor: pointer;
        padding: 10px 5px 10px 0;
        padding-left: 16px;
        &::before {
            font-family: FontAwesome;
            font-size: 12px;
            font-style: normal;
            font-weight: normal;
            content: '\F078';
            font-size: 8px;
            left: -5px;
            position: relative;
            top: -1px;
        }
        &.react-grid-node-unexpanded {
            cursor: pointer;
            &::before {
                font-family: FontAwesome;
                font-size: 12px;
                font-style: normal;
                font-weight: normal;
                content: '\F054';
                font-size: 8px;
                left: -8px;
                margin-right: 2px;
                position: relative;
                top: -1px;
            }
        }
        &:not(.react-grid-expand) {
            visibility: hidden;
        }
    }
    &.react-grid-tree-node-depth-3 {
        cursor: pointer;
        padding: 10px 5px 10px 0;
        padding-left: 32px;
        &::before {
            font-family: FontAwesome;
            font-size: 12px;
            font-style: normal;
            font-weight: normal;
            content: '\F078';
            font-size: 8px;
            left: -5px;
            position: relative;
            top: -1px;
        }
        &.react-grid-node-unexpanded {
            cursor: pointer;
            &::before {
                font-family: FontAwesome;
                font-size: 12px;
                font-style: normal;
                font-weight: normal;
                content: '\F054';
                font-size: 8px;
                left: -8px;
                margin-right: 2px;
                position: relative;
                top: -1px;
            }
        }
        &:not(.react-grid-expand) {
            visibility: hidden;
        }
    }
    &.react-grid-tree-node-depth-4 {
        cursor: pointer;
        padding: 10px 5px 10px 0;
        padding-left: 48px;
        &::before {
            font-family: FontAwesome;
            font-size: 12px;
            font-style: normal;
            font-weight: normal;
            content: '\F078';
            font-size: 8px;
            left: -5px;
            position: relative;
            top: -1px;
        }
        &.react-grid-node-unexpanded {
            cursor: pointer;
            &::before {
                font-family: FontAwesome;
                font-size: 12px;
                font-style: normal;
                font-weight: normal;
                content: '\F054';
                font-size: 8px;
                left: -8px;
                margin-right: 2px;
                position: relative;
                top: -1px;
            }
        }
        &:not(.react-grid-expand) {
            visibility: hidden;
        }
    }
    &.react-grid-tree-node-depth-5 {
        cursor: pointer;
        padding: 10px 5px 10px 0;
        padding-left: 64px;
        &::before {
            font-family: FontAwesome;
            font-size: 12px;
            font-style: normal;
            font-weight: normal;
            content: '\F078';
            font-size: 8px;
            left: -5px;
            position: relative;
            top: -1px;
        }
        &.react-grid-node-unexpanded {
            cursor: pointer;
            &::before {
                font-family: FontAwesome;
                font-size: 12px;
                font-style: normal;
                font-weight: normal;
                content: '\F054';
                font-size: 8px;
                left: -8px;
                margin-right: 2px;
                position: relative;
                top: -1px;
            }
        }
        &:not(.react-grid-expand) {
            visibility: hidden;
        }
    }
    &.react-grid-tree-node-depth-6 {
        cursor: pointer;
        padding: 10px 5px 10px 0;
        padding-left: 80px;
        &::before {
            font-family: FontAwesome;
            font-size: 12px;
            font-style: normal;
            font-weight: normal;
            content: '\F078';
            font-size: 8px;
            left: -5px;
            position: relative;
            top: -1px;
        }
        &.react-grid-node-unexpanded {
            cursor: pointer;
            &::before {
                font-family: FontAwesome;
                font-size: 12px;
                font-style: normal;
                font-weight: normal;
                content: '\F054';
                font-size: 8px;
                left: -8px;
                margin-right: 2px;
                position: relative;
                top: -1px;
            }
        }
        &:not(.react-grid-expand) {
            visibility: hidden;
        }
    }
    &.react-grid-tree-node-depth-7 {
        cursor: pointer;
        padding: 10px 5px 10px 0;
        padding-left: 96px;
        &::before {
            font-family: FontAwesome;
            font-size: 12px;
            font-style: normal;
            font-weight: normal;
            content: '\F078';
            font-size: 8px;
            left: -5px;
            position: relative;
            top: -1px;
        }
        &.react-grid-node-unexpanded {
            cursor: pointer;
            &::before {
                font-family: FontAwesome;
                font-size: 12px;
                font-style: normal;
                font-weight: normal;
                content: '\F054';
                font-size: 8px;
                left: -8px;
                margin-right: 2px;
                position: relative;
                top: -1px;
            }
        }
        &:not(.react-grid-expand) {
            visibility: hidden;
        }
    }
    &.react-grid-tree-node-depth-8 {
        cursor: pointer;
        padding: 10px 5px 10px 0;
        padding-left: 112px;
        &::before {
            font-family: FontAwesome;
            font-size: 12px;
            font-style: normal;
            font-weight: normal;
            content: '\F078';
            font-size: 8px;
            left: -5px;
            position: relative;
            top: -1px;
        }
        &.react-grid-node-unexpanded {
            cursor: pointer;
            &::before {
                font-family: FontAwesome;
                font-size: 12px;
                font-style: normal;
                font-weight: normal;
                content: '\F054';
                font-size: 8px;
                left: -8px;
                margin-right: 2px;
                position: relative;
                top: -1px;
            }
        }
        &:not(.react-grid-expand) {
            visibility: hidden;
        }
    }
    &.react-grid-tree-node-depth-9 {
        cursor: pointer;
        padding: 10px 5px 10px 0;
        padding-left: 128px;
        &::before {
            font-family: FontAwesome;
            font-size: 12px;
            font-style: normal;
            font-weight: normal;
            content: '\F078';
            font-size: 8px;
            left: -5px;
            position: relative;
            top: -1px;
        }
        &.react-grid-node-unexpanded {
            cursor: pointer;
            &::before {
                font-family: FontAwesome;
                font-size: 12px;
                font-style: normal;
                font-weight: normal;
                content: '\F054';
                font-size: 8px;
                left: -8px;
                margin-right: 2px;
                position: relative;
                top: -1px;
            }
        }
        &:not(.react-grid-expand) {
            visibility: hidden;
        }
    }
    &.react-grid-tree-node-depth-10 {
        cursor: pointer;
        padding: 10px 5px 10px 0;
        padding-left: 144px;
        &::before {
            font-family: FontAwesome;
            font-size: 12px;
            font-style: normal;
            font-weight: normal;
            content: '\F078';
            font-size: 8px;
            left: -5px;
            position: relative;
            top: -1px;
        }
        &.react-grid-node-unexpanded {
            cursor: pointer;
            &::before {
                font-family: FontAwesome;
                font-size: 12px;
                font-style: normal;
                font-weight: normal;
                content: '\F054';
                font-size: 8px;
                left: -8px;
                margin-right: 2px;
                position: relative;
                top: -1px;
            }
        }
        &:not(.react-grid-expand) {
            visibility: hidden;
        }
    }
}

.react-grid-row-is-dragging {
    .react-grid-tree-node-depth-0 .react-grid-row-drag-handle, .react-grid-tree-node-depth-1 .react-grid-row-drag-handle, .react-grid-tree-node-depth-2 .react-grid-row-drag-handle, .react-grid-tree-node-depth-3 .react-grid-row-drag-handle, .react-grid-tree-node-depth-4 .react-grid-row-drag-handle, .react-grid-tree-node-depth-5 .react-grid-row-drag-handle, .react-grid-tree-node-depth-6 .react-grid-row-drag-handle, .react-grid-tree-node-depth-7 .react-grid-row-drag-handle, .react-grid-tree-node-depth-8 .react-grid-row-drag-handle, .react-grid-tree-node-depth-9 .react-grid-row-drag-handle, .react-grid-tree-node-depth-10 .react-grid-row-drag-handle {
        color: #fff;
    }
}

.react-grid-header-fixed-container.react-grid-hidden {
    opacity: 0;
}

.react-grid-header {
    background: #F5F5F5;
    border-bottom: 1px solid #E2E2E2;
    padding: 0 20px;
    font-family: 'Roboto', sans-serif;
    height: 32px;
    font-size: 14px;
    &.react-grid-header-hidden {
        visibility: hidden;
        th {
            border: none;
            height: 0;
            padding-bottom: 0;
            padding-top: 0;
            &.react-grid-checkbox-container {
                border: none;
                height: 0;
                margin: 0;
            }
        }
    }
    th {
        position: relative;
        text-overflow: ellipsis;
        padding: 0 20px;
        font-weight: normal;

        &.react-grid-checkbox-container {
            border-bottom: 1px solid #e9e9e9;
            &.react-grid-hidden .react-grid-checkbox {
                visibility: hidden;
            }
        }
        .react-grid-column {
            display: inline-block;
            height: 24px;
            overflow: hidden;
            position: relative;
            text-overflow: ellipsis;
            top: 6px;
            width: 80%;
        }
        &.react-grid-sort-handle-visible .react-grid-sort-handle {
            visibility: visible;
            display: inline-block;
        }
        .react-grid-sort-handle {
            float: left;
            transition-duration: 150ms;
            transition-property: opacity;
            transition-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);
            display: none;
            padding-right: 10px;
            &.react-grid-desc {
                cursor: pointer;
                &::before {
                    font-family: FontAwesome;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: normal;
                    content: '\F0D8';
                    top: -1px;
                }
            }
            &.react-grid-asc {
                cursor: pointer;
                &::before {
                    font-family: FontAwesome;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: normal;
                    content: '\F0D7';
                    cursor: pointer;
                    position: relative;
                    right: 1px;
                }
            }
        }
        .react-grid-drag-handle::after {
            border-right: 1px solid #f7f7f7;
            content: ' ';
            cursor: ew-resize;
            height: 100%;
            position: absolute;
            right: 0;
            top: 0;
            transition-duration: 150ms;
            transition-property: border;
            transition-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);
            width: 10px;
        }
        &:hover .react-grid-drag-handle::after {
            border-color: #e9e9e9;
        }
        &:nth-last-child(1), &:nth-last-child(2) {
            overflow: initial;
        }
        &:nth-last-child(1) .react-grid-drag-handle::after, &:nth-last-child(2) .react-grid-drag-handle::after {
            border: none;
            cursor: auto;
        }
    }
}

.react-grid-action-menu-container {
    background-color: #fff;
    border-radius: 3px;
    box-shadow: 2px 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    list-style-position: outside;
    list-style-type: none;
    min-height: 30px;
    overflow: auto;
    overflow-x: hidden;
    padding: 0;
    position: absolute;
    right: 6px;
    top: 10px;
    z-index: 11;
}

.react-grid-top .react-grid-action-menu-container {
    bottom: 3px;
    top: initial;
}

.react-grid-action-menu-item {
    border-radius: 3px;
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    line-height: 1.2;
    padding: 6px 30px 6px 6px;
    &:hover {
        background-color: #f7f7f7;
    }
}

.react-grid-row {
    background-color: #fff;
    box-sizing: border-box;
    cursor: pointer;
    text-overflow: ellipsis;
    transition-duration: 150ms;
    transition-property: background-color;
    transition-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);
    &:hover {
        background-color: #f5f5f5;
    }
    &.react-grid-empty-row {
        height: 80px;
        text-align: center;
    }
    &.react-grid-row-is-dragging {
        background-color: #28e;
        color: #28e;
    }
    &.react-grid-active {
        background-color: #e9e9e9;
    }
    &.react-grid-edit {
        background-color: #b2b2b2;
        box-shadow: 2px 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
        color: #fff;
        .react-grid-action-menu-container {
            color: initial;
            &:hover {
                background-color: #b2b2b2;
            }
            td {
                border-bottom: 2px solid transparent;
                &:focus {
                    border-bottom: 2px solid #2e7d32;
                    outline: none;
                }
            }
        }
    }
}

.react-grid-row-drag-handle {
    cursor: move;
    cursor: pointer;
    margin-right: 20px;
    &::before {
        font-family: FontAwesome;
        font-size: 12px;
        font-style: normal;
        font-weight: normal;
        content: '\F07D';
    }
}

.react-grid-cell-handle-container {
    display: inline-block;
    height: 100%;
    margin-left: -10px;
    padding-left: 10px;
    font-size: 16px;
}

.react-grid-row-is-dragging .react-grid-cell-handle-container {
    background-color: #fff;
}

.react-grid-inline-editor {
    left: 0;
    margin: 0 auto;
    transition-duration: 150ms;
    transition-property: -webkit-transform opacity;
    transition-property: transform opacity;
    transition-property: transform opacity, -webkit-transform opacity;
    transition-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);
    opacity: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    text-align: center;
    -webkit-transform: translateX(0px) translateY(0px);
    transform: translateX(0px) translateY(0px);
    .react-grid-button-container {
        background-color: #b2b2b2;
        border-radius: 3px;
        box-shadow: 2px 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
        display: inline-block;
        margin-top: 5px;
        padding: 5px;
        pointer-events: all;
        .react-grid-save-button {
            background-color: #2e7d32;
            border: none;
            border-radius: 3px;
            color: #fff;
            cursor: pointer;
            font-family: 'Roboto', sans-serif;
            opacity: 1;
            &:focus {
                outline: none;
            }
            &:active {
                background-color: #52bf57;
                opacity: 1;
            }
            &:hover {
                opacity: 0.6;
            }
            &:disabled {
                background-color: #fff;
                color: #161616;
                cursor: initial;
            }
        }
        .react-grid-cancel-button {
            background-color: #f7f7f7;
            border: none;
            border-radius: 3px;
            color: #161616;
            cursor: pointer;
            font-family: 'Roboto', sans-serif;
            opacity: 1;
            &:focus {
                outline: none;
            }
            &:active {
                background-color: #f9f9f9;
                opacity: 1;
            }
            &:hover {
                opacity: 0.6;
            }
            &:disabled {
                background-color: #fff;
                color: #161616;
                cursor: initial;
            }
        }
    }
}

.react-grid-shown {
    display: block;
    opacity: 1;
    -webkit-transform: translateY(-7px);
    transform: translateY(-7px);
}

.react-grid-hidden {
    top: -10000px;
}

.react-grid-error-container {
    background-color: #f7f7f7;
    bottom: 0;
    box-shadow: 2px 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    color: #e9e9e9;
    height: 100px;
    left: 0;
    margin: auto;
    opacity: 0;
    -webkit-perspective: 1300px;
    perspective: 1300px;
    position: absolute;
    right: 0;
    top: -10000px;
    -webkit-transform: rotateX(-70deg);
    transform: rotateX(-70deg);
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    transition-duration: 0.2s;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    transition-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);
    width: 50%;
    z-index: 10;
    &.react-grid-shown {
        opacity: 1;
        top: 0;
        -webkit-transform: rotateX(0deg);
        transform: rotateX(0deg);
        .react-grid-error-message {
            color: #3f3f3f;
            font-family: 'Roboto', sans-serif;
            height: 20px;
            left: 0;
            margin-top: -20px;
            margin-left: 20px;
            position: absolute;
            right: 0;
            top: 50%;
        }
        button {
            bottom: 10px;
            background-color: #2e7d32;
            border: none;
            border-radius: 3px;
            color: #fff;
            cursor: pointer;
            font-family: 'Roboto', sans-serif;
            opacity: 1;
            position: absolute;
            right: 10px;
            &:focus {
                outline: none;
            }
            &:active {
                background-color: #52bf57;
                opacity: 1;
            }
            &:hover {
                opacity: 0.6;
            }
            &:disabled {
                background-color: #fff;
                color: #161616;
                cursor: initial;
            }
        }
    }
}

th.react-grid-action-container,th.react-grid-updown-container {
    border-bottom: 1px solid #e9e9e9;
}

.react-grid-action-container {
    border-bottom: 1px solid #f7f7f7;
    cursor: pointer;
    font-family: 'Roboto', sans-serif;
    height: 20px;
    position: relative;
    width: 40px;

    text-overflow: ellipsis;
    padding: 0 20px;
    font-weight: normal;

    .react-grid-no-actions {
        display: none;
    }
    .react-grid-action-icon {
        cursor: pointer;

        padding-left: 0;
        position: relative;
        width: 10px;
        height: 20px;
        display: block;
        &::before {
            font-family: FontAwesome;
            font-size: 12px;
            font-style: normal;
            font-weight: normal;
            content: '\F142';
            border-radius: 3px;
            cursor: pointer;
            position: absolute;
            left: 0;
            text-align: center;
            top: 5px;
            width: 6px;
            right: 0;
        }
    }
    &.react-grid-action-menu-selected span::before {
        background-color: #e9e9e9;
    }
}

.react-grid-updown-container {

    border-bottom: 1px solid #f7f7f7;
    cursor: pointer;
    font-family: 'Roboto', sans-serif;
    height: 20px;
    position: relative;
    width: 50px;
    .react-grid-cell-wrapper {
        display: flex;
    }
    .react-grid-no-updown {
        display: none;
    }
    .updown-up {
        cursor: pointer;

        position: relative;
        width: 20px;
        height: 30px;
        flex: 1 auto;
        display: inline-block;
        &::before {
            font-family: FontAwesome;
            font-size: 21px;
            font-style: normal;
            font-weight: normal;

            color: #cfcfcf;
            content: '\F0D8';
            cursor: pointer;
            position: absolute;

            text-align: center;
            top: 0px;
            width: 20px;
        }

    }
    .updown-down {
        cursor: pointer;

        position: relative;
        width: 20px;
        height: 30px;
        flex: 1 auto;
        display: inline-block;

        &::before {
            font-family: FontAwesome;
            font-size: 21px;
            font-style: normal;
            font-weight: normal;
            color: #cfcfcf;
            content: '\F0D7';
            cursor: pointer;
            position: absolute;

            text-align: center;
            top: 0px;
            width: 20px;
        }

    }

    .updown-up:hover,.updown-down:hover{
        &::before {
            color: #000
        }
    }
}

.react-grid-loading-bar {

    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    opacity: 0.25;
    display: none;
    &.react-grid-active {
        display: block;
    }
    &::before {
        -webkit-animation: loading 2s linear infinite;
        animation: loading 2s linear infinite;
        background-color: #2e7d32;
        content: '';
        display: block;
        height: 4px;
        left: -200px;
        position: absolute;
        width: 200px;
    }
    &-text {
        width: 56px;
        height: 13.4px;
        background: radial-gradient(circle closest-side,#409ef8 90%,#0000) 0 0/33% 100% space;
        clip-path: inset(0 100% 0 0);
        animation: dots-e3xtdg 1.5s steps(4) infinite;
        position: absolute;
        top: 50%;
        left: 50%;
    }


}
@keyframes dots-e3xtdg {
    to {
        clip-path: inset(0 -34% 0 0);
    }
}
@-webkit-keyframes loading {
    from {
        left: 0px;
        width: 30%;
    }

    50% {
        width: 30%;
    }

    70% {
        width: 44%;
    }

    80% {
        left: 55%;
    }

    95% {
        left: 66%;
    }

    to {
        left: 72%;
    }
}


@keyframes loading {
    from {
        left: 0px;
        width: 30%;
    }

    50% {
        width: 30%;
    }

    70% {
        width: 44%;
    }

    80% {
        left: 55%;
    }

    95% {
        left: 66%;
    }

    to {
        left: 72%;
    }
}


.react-grid-pager-toolbar {
    background-color: #f7f7f7;
    border-radius: 0 0 3px 3px;
    border-top: 1px solid #e9e9e9;
    font-family: 'Roboto', sans-serif;
    font-size: 13px;
    height: 24px;
    text-indent: 10px;
    &.react-grid-is-stuck {
        bottom: 0;
        position: fixed;
    }
    &.react-grid-is-stuck-bottom {
        position: absolute;
        width: 100%;
    }
    span {
        display: block;
        float: right;
        position: relative;
        right: 5px;
        top: 2px;
        &:first-child {
            float: left;
            font-family: 'Roboto', sans-serif;
            font-size: 14px;
        }
    }
    button {
        margin: 0 10px;
    }
}

.react-grid-checkbox-container {
    border-bottom: 1px solid #f7f7f7;
    font-family: 'Roboto', sans-serif;
    font-size: 13px;
    height: 20px;
    overflow: hidden;
    padding: 0 10px !important;
    position: relative;
    text-overflow: ellipsis;
    width: 35px !important;
}

.react-grid-bulkaction-container {
    background-color: #b2b2b2;
    border-radius: 3px 3px 0 0;
    height: 28px;
    transition-duration: 150ms;
    transition-property: -webkit-transform opacity top;
    transition-property: transform opacity top;
    transition-property: transform opacity top, -webkit-transform opacity top;
    transition-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);
    opacity: 0;
    position: absolute;
    text-align: left;
    top: -37px;
    -webkit-transform: translateX(0px) translateY(-37px);
    transform: translateX(0px) translateY(-37px);
    width: 100%;
    z-index: 12;
    .react-grid-bulkaction-description {
        display: inline-block;
        font-family: 'Roboto', sans-serif;
        font-size: 13px;
        min-width: 70px;
        overflow: hidden;
        padding-left: 15px;
        position: relative;
        text-overflow: ellipsis;
        top: 3px;
        .react-grid-checkbox-container {
            border-bottom: 1px solid transparent;
        }
        button {
            background-color: #2e7d32;
            border: none;
            border-radius: 3px;
            color: #fff;
            cursor: pointer;
            font-family: 'Roboto', sans-serif;
            opacity: 1;
            margin: 5px 8px;
            position: relative;
            top: -1px;
            &:focus {
                outline: none;
            }
            &:active {
                background-color: #52bf57;
                opacity: 1;
            }
            &:hover {
                opacity: 0.6;
            }
            &:disabled {
                background-color: #fff;
                color: #161616;
                cursor: initial;
            }
        }
        &.react-grid-removed {
            top: -100px;
        }
        &.react-grid-shown {
            opacity: 1;
            top: 0;
            -webkit-transform: translateY(0px);
            transform: translateY(0px);
        }
    }
}

.react-grid-filter-container {
    background-color: #f7f7f7;
    border-radius: 3px 3px 0 0;
    padding: 5px 0;
    position: relative;
    text-align: center;
    width: 100%;
    .react-grid-filter-input {
        border: 1px solid #e9e9e9;
        border-radius: 3px;
        color: #b2b2b2;
        display: inline-block;
        font-family: 'Roboto', sans-serif;
        font-size: 14px;
        padding: 0;
        text-indent: 5px;
        transition-duration: 150ms;
        transition-property: color;
        transition-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);
        width: 99%;
        &:focus {
            color: #8e8e8e;
            outline: none;
        }
    }
    &:hover .react-grid-filter-input {
        color: #8e8e8e;
    }
    .react-grid-filter-button-container {
        display: inline-block;
        position: absolute;
        right: 5px;
        > i {
            padding: 0 3px 0 0;
            &::before {
                color: #b2b2b2;
                transition-duration: 150ms;
                transition-property: color;
                transition-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);
            }
            &:hover::before {
                color: #8e8e8e;
            }
            &.react-grid-active::before {
                color: #2e7d32;
            }
        }
        .react-grid-filter-search-button {
            cursor: pointer;
            &::before {
                font-family: FontAwesome;
                font-size: 12px;
                font-style: normal;
                font-weight: normal;
                content: '\F002';
            }
        }
        .react-grid-filter-clear-button {
            cursor: pointer;
            &::before {
                font-family: FontAwesome;
                font-size: 12px;
                font-style: normal;
                font-weight: normal;
                content: '\F00D';
            }
        }
        .react-grid-filter-menu-button {
            cursor: pointer;
            &::before {
                font-family: FontAwesome;
                font-size: 12px;
                font-style: normal;
                font-weight: normal;
                content: '\F0b0';
            }
        }
    }
}

.react-grid-advanced-filter-menu-container {
    background-color: #f7f7f7;
    border-radius: 3px;
    box-shadow: 10px 15px 30px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    font-family: 'Roboto', sans-serif;
    position: absolute;
    right: 10px;
    top: 24px;
    width: 400px;
    z-index: 13;
    .react-grid-advanced-filter-menu-title {
        display: inline-block;
        padding: 5px 10px;
    }
    .react-grid-advanced-filter-menu-field-container {
        display: block;
        font-size: 13px;
        margin: 0 auto;
        padding: 5px 0;
        text-align: left;
        width: 90%;
        .react-grid-advanced-filter-menu-field-label {
            display: inline-block;
            padding: 5px 0;
        }
        .react-grid-advanced-filter-menu-field-input {
            border: 1px solid #e9e9e9;
            border-radius: 3px;
            color: #b2b2b2;
            display: inline-block;
            font-family: 'Roboto', sans-serif;
            font-size: 14px;
            padding: 0;
            text-indent: 5px;
            transition-duration: 150ms;
            transition-property: color;
            transition-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);
            width: 100%;
            &:focus {
                color: #8e8e8e;
                outline: none;
            }
        }
    }
    .react-grid-advanced-filter-menu-button-container {
        margin: 0 auto;
        padding: 10px;
        text-align: left;
        width: 90%;
        .react-grid-advanced-filter-menu-button {
            background-color: #2e7d32;
            border: none;
            border-radius: 3px;
            color: #fff;
            cursor: pointer;
            font-family: 'Roboto', sans-serif;
            opacity: 1;
            margin-right: 5px;
            &:focus {
                outline: none;
            }
            &:active {
                background-color: #52bf57;
                opacity: 1;
            }
            &:hover {
                opacity: 0.6;
            }
            &:disabled {
                background-color: #fff;
                color: #161616;
                cursor: initial;
            }
            &.react-grid-secondary {
                background-color: #fff;
                border: none;
                border-radius: 3px;
                color: #fff;
                cursor: pointer;
                font-family: 'Roboto', sans-serif;
                opacity: 1;
                color: #161616;
                &:focus {
                    outline: none;
                }
                &:active {
                    background-color: #fff;
                    opacity: 1;
                }
                &:hover {
                    opacity: 0.6;
                }
                &:disabled {
                    background-color: #fff;
                    color: #161616;
                    cursor: initial;
                }
            }
        }
    }
}
.react-grid-cell.react-grid-tree-nested {
    overflow: visible;

    .react-grid-cell-wrapper {
        display: flex;
    }
}

.react-grid-cell.react-grid-tree-noactive{
    color: #cfcfcf;
}

.additional-class {

    &.right {
        text-align: right;
    }

    &.center {
        text-align: center;
    }

    &.left {
        text-align: left;
    }

    &.w-100 {
        width: 100%;
    }
}

.react-grid-cell.text-center {
    text-align: center;
}
.react-grid-cell.text-left {
    text-align: left;
}
.react-grid-cell.text-rightr {
    text-align: right;
}


.react-grid-header {
    th {
        //font-weight:bold;
    }
}

.status-container {
    display: flex;
    justify-content: flex-start;
    span {
        white-space:normal !important;
        word-wrap: break-word;
    }

}

.amount-container {
    display: flex;
    justify-content: flex-end;
    font-weight: bold;
    font-size: large;

    div {
        display: flex;
        white-space:normal !important;
        word-wrap: break-word;
    }

}

.actions-container {
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.expired-container {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.wrapped-container {
    white-space: normal;
    word-wrap: break-word;
}

.email-container {
    white-space: normal;
    word-wrap: break-word;
}

.price-container {
    display: flex;
    justify-content: flex-end;
}
