.product-form-content {
    padding: 20px 0px 20px 35px;
    margin-right: 350px;
    font-size: 13px;
    width: 850px;
}

.product-form-content__extra-row {
    background: red;
}

.product-form {
    margin-right: 350px;
    padding: 0px;
}

.offices-picker__list {
    &--scrollable {
        list-style: none;
        max-height: 350px;
        overflow: auto;
        padding-left: 0px;
    }
}

.offices-picker__item {
    &--scrollable {
        margin-left: 0px;
    }
}