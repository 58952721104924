.MenuWrapper {
    position: relative;
    .Menu {
        display: block;
    }
    *:first-child {
        user-select: none;
        cursor: pointer;
    }
}
