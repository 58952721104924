@charset 'UTF-8';

.status-wrapper {
    display: inline-block;
    height: 32px;
    position: relative;
    align-self: center;

    .status {
        border-radius: 100px;
        bottom: -4px;
        height: 15px;
        line-height: 0;
        margin: 0;
        position: absolute;
        right: -3px;
        top: auto;
        width: 15px;

        .modal & {
            bottom: -6px;
        }

        &.status-edit {
            text-align: center;

            i {
                opacity: 1;
                font-size: 10px;
                position: relative;
                top: 4px;
            }
        }

        svg {
            max-height: 11px;
            position: relative;
            top: 2px;
        }
    }
}

.status-selector:hover > .status {
    display: none;
}
.status-selector > .status {
    display: inherit;
}
.status-selector > .status-edit {
    display: none;
}
.status-selector:hover > .status-edit {
    display: inherit;
}

.status {
    display: inline-block;
    margin: 0 7px 0 1px;
    position: relative;
    top: 2px;
    width: 15px;

    &.status--group {
        border-radius: 2px;
        font-size: 10px;
        font-weight: 600;
        height: 15px;
        left: 1px;
        line-height: 14px;
        margin: 0 9px 0 -1px;
        text-align: center;
        width: 15px;

        .mentions__name & {
            height: 18px;
            left: 0;
            line-height: 18px;
            margin-left: 0;
            margin-right: 6px;
            text-align: center;
            width: 17px;
        }
    }

    .dnd--icon {
        fill: rgb(247, 67, 67);
    }

    svg {
        max-height: 14px;
        backface-visibility: hidden;
    }

    i,
    path,
    ellipse {
        opacity: 0.5;

        &.online--icon,
        &.away--icon,
        &.dnd--icon {
            opacity: 1;
        }
    }

    .fa-lock {
        margin-left: 1px;
    }

    .fa-globe {
        position: relative;
        top: -1px;
    }
}
