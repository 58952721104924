@charset 'UTF-8';

.tooltip {
    word-wrap: break-word;
    max-width: 220px;

    .tooltip-inner {
        max-width: 100%;
        font-size: 13px;
        font-weight: 500;
        padding: 5px 10px 6px;
        word-break: break-word;
    }

    &.text-nowrap {
        .tooltip-inner {
            white-space: nowrap;
        }
    }
}
