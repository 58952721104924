@charset 'UTF-8';

@media screen and (max-width: 1024px) {

    .react-grid-cell {
        font-size: 8pt;
    }

    .order-content {

    }

    .order-content__actions {
        display: flex;
        flex-direction: column;
    }

    .order-content__order-bold {
        font-weight: bold;
    }

    .order-content__order-regular {

    }

    .order-content__buttons {
        display: flex;
    }

    .order-content__message-link {
        display: flex;
    }

    .order-content_row {
        color: #808080;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .order-content__mesage {
        margin-right: 1em;
        word-break: break-all;
        text-overflow: ellipsis;
        overflow: hidden;
    }



    .sidebar-item__name {
        display: none !important;
    }

    .post-message__header-wrapper {

    }

    .custom-textarea {
        background: transparent;
        border: 1px solid #cccccc;
        height: 100%;
        line-height: 20px;
        min-height: 36px;
        overflow-x: hidden;
        resize: none;
        white-space: pre-wrap;
        word-wrap: break-word;

        &:focus {
            border-color: #cccccc;
            box-shadow: none;
        }

        &.bad-connection {
            background: #ffffac !important;
            color: #d04444 !important;
        }
    }

    .textarea-wrapper {
        min-height: 37px;
        position: relative;

        .textbox-preview-area {
            white-space: normal;
            box-shadow: none;
            left: 0;
            position: absolute;
            top: 0;
            z-index: 2;

            p {
                margin: 0;
            }

            ul + p,
            ol + p {
                margin-top: .6em;
            }

            p + ul,
            p + ol {
                margin-top: .6em;
            }
        }

        .help__text {
            bottom: -23px;
            font-size: 13px;
            position: absolute;
            right: 0;
            text-align: right;
            z-index: 3;

            .modal & {
                bottom: initial;
                margin-top: -20px;
                position: relative;
                top: 30px;
            }

            a {
                margin-left: 10px;
            }
        }

        .textbox-preview-link {
            margin-right: 8px;
        }
    }

    .help__format-text {
        opacity: 0;
        //@include single-transition(all, .5s, ease, .5s);
        display: inline-block;
        font-size: .85em;
        vertical-align: bottom;
        white-space: nowrap;

        .modal & {
            white-space: normal;
        }

        b,
        i,
        span {
            margin: 0 2px;
            position: relative;
            top: -1px;
        }

        b {
            opacity:(.9);
        }

        code {
            background: transparent;
            padding: 0;
        }

        .textbox-preview-link {
            cursor: pointer;
            font-size: 13px;
            margin-left: 15px;
        }
    }

    .date-separator,
    .new-separator {
        height: 2em;
        margin: 0;
        position: relative;
        text-align: center;
        z-index: 0;

        &:before,
        &:after {
            content: '';
            display: none;
            height: 1em;
            left: 0;
            position: absolute;
            width: 100%;
        }

        &:before {
            bottom: 0;
        }

        &:after {
            top: 0;
        }

        &.hovered--after {
            &:before {
                background: #f5f5f5;
                display: block;
            }
        }

        &.hovered--before {
            &:after {
                background: #f5f5f5;
                display: block;
            }
        }

        .separator__hr {
            border-color: #cccccc;
            margin: 0;
            position: relative;
            top: 1em;
            z-index: 5;
        }

        .separator__text {
            border-radius:50px;
            background: #ffffff;
            color: #555555;
            display: inline-block;
            font-size: 13px;
            font-weight: 700;
            line-height: 2em;
            padding: 0 1em;
            position: relative;
            z-index: 5;
        }
    }

    .new-separator {
        .separator__hr {
            border-color: #ffaf53;
        }

        .separator__text {
            color: #ff8800;
            font-weight: normal;
        }
    }

    .file-overlay {
        color: $white;
        font-size: em(20px);
        font-weight: 600;
        height: 100%;
        left: 0;
        pointer-events: none;
        position: absolute;
        text-align: center;
        top: 0;
        width: 100%;
        z-index: 11;

        .overlay__indent {
            @include clearfix;
            @include alpha-property(background-color, $black, .6);
            height: 100%;
            position: relative;
        }

        &.center-file-overlay {
        }

        &.right-file-overlay {
            font-size: em(18px);

            .overlay__circle {
                height: 300px;
                margin: -150px 0 0 -150px;
                width: 300px;
            }

            .overlay__files {
                margin: 60px auto 15px;
                width: 150px;
            }
        }

        .overlay__circle {
            border-radius:500px;
            @include alpha-property(background, $black, .7);
            height: 370px;
            left: 50%;
            margin: -185px 0 0 -185px;
            pointer-events: none;
            position: absolute;
            top: 50%;
            width: 370px;
        }

        .overlay__files {
            display: block;
            margin: 75px auto 20px;
        }

        .overlay__logo {
            opacity: 0.3;
            bottom: 30px;
            left: 50%;
            margin-left: -50px;
            position: absolute;
        }

        .fa {
            display: inline-block;
            font-size: 1.1em;
            margin-right: 8px;
        }
    }

    #post-list {
         flex:(1 1 auto);
        height: 100%;
        overflow-y: hidden;
        position: relative;

        .inactive {
            display: none;
        }

        .post-list__loading {
            opacity: 0.5;
            font-size: .9em;
            font-style: italic;
            padding: 1em 0;
            text-align: center;

            i {
                margin-right: 2px;
            }

            &.post-list__loading-search {
                cursor: pointer;

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        .post-list-holder-by-time {
            height: 100%;
            width: 100%;
            position: absolute;
            &.active {
                display: inline;
            }
        }

        .more-messages-text {
            border: none;
            display: block;
            font-size: 13px;
            margin: 5px 0 10px;
            outline: none;
            text-align: center;
            width: 100%;
        }

        .new-messages__button {
            opacity: 0;
            transform: translateX(-50%);
            bottom: 0;
            font-size: 13.5px;
            left: 50%;
            margin: 5px auto;
            position: absolute;
            text-align: center;
            visibility: hidden;
            z-index: 3;

            .fa {
                font-size: 1.2em;
                font-weight: bold;
                margin-right: .5rem;
                position: relative;
                top: 1px;
            }

            .icon {
                margin-left: 5px;
            }

            div {
                border-radius:50px;
                cursor: pointer;
                display: inline-block;
                padding: 0 20px;
                height: 28px;
                display: flex;
                align-items: center;
            }

            &.visible {
                opacity: 1;
                visibility: visible;
            }

            &.disabled {
                display: none;
            }
        }
    }

    .post-list__timestamp {
        opacity: 0;
        //@include single-transition(all, .6s, ease);
        transform: translateY(-45px);
        display: none;
        left: 0;
        position: absolute;
        text-align: center;
        top: 8px;
        width: 100%;
        z-index: 50;

        &.scrolling {
            transform: translateY(0);
            opacity:(.9);
        }

        &.rhs {
            top: 98px;
        }

        > div {
            border-radius:3px;
            @include font-smoothing(initial);
            background: $primary-color;
            border: 1px solid;
            color: $white;
            display: inline-block;
            font-size: 12px;
            line-height: 25px;
            padding: 0 8px;
            text-align: center;
        }
    }

    .post-list__arrows {
        opacity: 0;
        //@include single-transition(all, .6s);
        background-repeat: no-repeat;
        bottom: 0;
        display: none;
        fill: #444444;
        height: 40px;
        left: 9px;
        position: absolute;
        text-align: center;
        width: 40px;
        z-index: 50;

        svg {
            color: inherit;
            height: 28px;
            width: 28px;
        }

        &.scrolling {
            opacity: 1;
            display: block;
        }
    }

    .post-create-message {
        padding: 10px;
    }

    .post-create__container {
         flex:(0 0 auto);
        width: 100%;
        z-index: 10;
        padding: 20px 0;
        background-color: #efefef;
        label {
            font-weight: normal;
        }

        form {
            margin: 0 auto;
            padding: 0 15px 0;
            width: 100%;
        }

        .center {
            max-width: 1028px;
        }

        .custom-textarea {
            bottom: 0;
            max-height: calc(50vh - 40px);
            overflow: hidden;
            padding: 12px 0 12px 15px;
            resize: none;
            background: #fff;

            &.textbox-preview-area {
                overflow-y: auto;
            }

            @include input-placeholder {
                color: inherit;
            }
        }


        .scroll {
            .post-body__actions {
                right: 10px;
            }

            .custom-textarea {
                -ms-overflow-style: auto;
                -webkit-overflow-scrolling: touch;
                overflow: auto;

                    padding-right: 50px;

            }
        }

        .post-body__actions {
            display: flex;
            height: 100%;
            position: absolute;
            right: 0;
            top: 0;

            > span {
                border-left: 1px solid transparent;
                width: 42px;

                &:first-child {
                    border: none;
                }

                > span,
                div {
                    display: block;
                    height: 49px;
                    line-height: 49px;
                    text-align: center;
                    width: 100%;
                }
            }

            &:hover,
            &:active {
                box-shadow: none;
            }

            &.btn-file__disabled {
                opacity: 0.1;

                &:hover,
                &:active {
                    opacity: 0.1;
                }
            }

            .icon--attachment {
                opacity:(.4);
                //@include single-transition(all, .15s);
                cursor: pointer;
                overflow: hidden;
                position: relative;
                vertical-align: top;

                &:hover {
                    opacity: 0.6;
                }

                input {
                    cursor: pointer;
                    direction: ltr;
                    filter: alpha(opacity=0);
                    font-size: 23px;
                    height: 100%;
                    margin: 0;
                    opacity: 0;
                    position: absolute;
                    right: 0;
                    top: 0;
                    width: 100%;
                }

                svg {
                    position: relative;
                    top: 2px;
                }
            }
        }

        .post-create-body {
            padding: 0 0 2px;
            position: relative;

            .post-body__cell {
                position: relative;
                vertical-align: top;
            }

            .send-button {
                //@include single-transition(all, .15s);
                border-left: 1px solid transparent;
                cursor: pointer;
                display: none;
                font-size: 17px;
                height: 100%;
                line-height: 49px;
                padding-right: 4px;
                text-align: center;
                vertical-align: bottom;
                width: 45px;

                &:active {
                    opacity:(.75);
                }

                &.disabled {
                    i {
                        color: inherit;
                    }
                }

                .android &,
                .ios & {
                    display: block;
                }
            }



            textarea {
                box-shadow: none;
            }
        }

        .post-create-footer {
            @include clearfix;
            font-size: 13px;
            padding: 3px 0 0;
            position: relative;

            .post-error {
                opacity:(.55);
                display: inline-block;
                font-size: .85em;
                font-weight: normal;
                margin-bottom: 0;
                position: absolute;
                top: 4px;
            }
        }

        .msg-typing {
            opacity: 0.7;;
            display: block;
            font-size: .95em;
            height: 20px;
            margin-bottom: 5px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .channel-archived__message {
            padding: 25px;
            border-top-width: 1px;
            border-top-style: solid;
            text-align: center;
        }

        .channel-archived__close-btn {
            margin: 10px;
        }
    }

    .post-list__table {
        @include clearfix;
        height: 100%;
        width: 100%;

        .post-list__content {
            height: 100%;
            overflow: hidden;

            .dropdown-menu {
                &.bottom {
                    bottom: 19px;
                    top: auto;
                }
            }
        }
        .loading-screen {
            position: relative;
            height: 40px;
            padding: 0px;
            .loading__content {
                height: 40px;
            }
        }
    }
    .post-item {
        margin-bottom: 12px;
    }
    .post {
        @include legacy-pie-clearfix;
        //max-width: 100%;
        padding:  0 2.5em 0 2.5em;
        position: relative;
        word-wrap: break-word;


        .browser--ie & {
            .post__header {
                .col__reply {
                    .comment-icon__container {
                        flex: 0 1 auto;
                        align-items: center;
                    }

                    > .open, > div {
                        flex: 0 1 30px;
                        align-items: center;

                        &:first-child {
                            flex: 0 1 25px;
                        }
                    }
                }
            }
        }

        &.post--system {
            .post__header {
                .col__reply {
                    min-width: 0;
                }
            }
        }

        &:hover {
            .dropdown,
            .comment-icon__container,
            .reacticon,
            .reacticon__container,
            .flag-icon__container,
            .post__reply,
            .post__dropdown,
            .post__remove {
                visibility: visible;
            }

            .permalink-icon {
                visibility: visible;
            }

        }

        &.post--hovered {
            .dropdown,
            .comment-icon__container,
            .post__reply,
            .post__remove,
            .post__dropdown,
            .reacticon__container,
            .permalink-icon {
                visibility: visible;
            }

            .post__header {
                .col__reply {
                    z-index: 7;
                }
            }

            .post__body {
                background: transparent !important;
            }
        }

        &.post--highlight {
            background-color: beige;
        }

        &.post--hide-controls {
            .post__header {
                .col__reply {
                    display: none;
                }
            }
        }

        &.post--thread {
            &.same--user {
                padding: 0 .5em 0 1em;

                .post__header {
                    margin-bottom: 0;

                    .post__header--info {
                        position: absolute;
                        left: -5px;

                        .post__time {
                            font-size: 0.7em;
                            display: inline-block;
                            left: -14px;
                            line-height: 20px;
                            position: absolute;
                            text-align: right;
                            text-rendering: auto;
                            top: 4px;
                            width: 51px;

                        }

                        .flag-icon__container {
                            left: 32px;
                            margin-left: 7px;
                            position: absolute;
                            top: 6px;
                        }
                    }
                }
            }
        }

        &.post--compact {
            &.post--thread {
                .post__header {
                    padding-top: 3px;
                }
            }

            &.post--system {
                .status {
                    visibility: hidden;
                }

                > div {
                    margin-bottom: 0;
                }
            }

            &.same--root {
                &.same--user {
                    .post__header {
                        height: 0;
                    }
                }
            }

            .post-message {
                overflow: inherit;
            }



            .post-message--collapsed + .post-image__columns {
                &:before {
                    content: '';
                    height: 100%;
                    left: 0;
                    position: absolute;
                    top: 0;
                    width: 100%;
                }
            }

            .post__img {
                padding-top: 0;
            }

            .attachment {
                clear: both;
                padding-top: 1px;

                &.attachment--pretext {
                    clear: none;
                    padding: 0;
                }

                .attachment__body__wrap {
                    .btn-close {
                        left: -2px;
                    }
                }
            }

            &.post--comment {
                .attachment {
                    .attachment__body__wrap {
                        .btn-close {
                            left: -13px;
                        }
                    }
                }
            }

            .auto-responder {
                background: alpha-color($white, .7);
            }

            .status-wrapper {
                cursor: auto;
                height: 14px;
                pointer-events: none;

                .status {
                    bottom: auto;
                    height: 13px;
                    left: -2px;
                    position: relative;
                    right: auto;
                    top: auto;
                    width: 13px;
                }

                svg {
                    top: 1px;
                }

                &:after {
                    bottom: 0;
                }
            }

            blockquote {
                display: flex;
                padding: 3px 0px 3px 20px;
                flex-direction: column;

                &:before {
                    font-size: 15px;
                    left: 0;
                    top: 0;
                }
            }

            .search-item-snippet {
                blockquote {
                    margin-top: 0;
                }
            }

            .markdown__heading {
                clear: both;
                font-size: 1em;
                margin: 0;
            }

            .post__header {
                display: block;
                height: 22px;
                margin-bottom: 0;
                padding: 0;
                width: auto;
            }

            .post__body {
                background: transparent !important;
                line-height: 1.5;
                padding: 2px 0 0;

                .post--edited {
                    position: relative;
                    top: -1px;
                }

                .img-div {
                    max-height: 150px;
                    max-width: 150px;
                }

                p {
                    line-height: inherit;
                }

                ol,
                ul {
                    clear: both;
                    padding-left: 20px;
                }

                div {
                    margin-bottom: 0;
                }

                .post-image__column {
                    margin: 3px 10px 3px 0;
                    padding: 0;
                }
            }

            .post-image__column {
                border-radius:2px;
                font-size: .9em;
                height: 26px;
                line-height: 25px;
                max-width: 250px;
                min-width: 150px;
                padding: 0 7px;

                .post-image__thumbnail {
                    display: none;
                }

                .post-image__details {
                    background: transparent;
                    border: none;
                    padding: 0;
                    width: 100%;

                    span {
                        display: none;
                    }

                    svg {
                        position: relative;
                        top: 2px;
                    }

                    .icon {
                        display: block;
                        float: left;
                        margin-right: 5px;
                        opacity: .6;
                    }
                }

                .post-image__download {
                    opacity: 1;
                    padding: 0;
                    position: relative;
                    width: auto;
                }

                .post-image__name {
                    @include clearfix;
                    display: block;
                    margin: 0;
                    padding: 0 5px;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    width: 100%;

                    i {
                        font-size: .9em;
                        margin-right: 5px;
                        opacity: .5;
                    }
                }

                a {
                    &:hover {
                        text-decoration: none;
                    }
                }
            }

            .post__img {
                padding-top: 1px;
                width: 16px;

                img {
                    display: none;
                }
            }
        }

        .post__img:hover {
            cursor: pointer;
        }

        .post--fail {
            position: relative;
        }

        .post-edited__indicator {
            opacity: 0.6;
            font-size: .87em;
        }


        p {
            font-size: 13.5px;
            line-height: 1.6em;
            margin: 0;
            white-space: pre-wrap;
            width: 100%;
            word-break: break-word;
        }

        .post__header--info {
            padding: 0;
        }

        &.post--root {
            .comment-icon__container {
                visibility: visible;
            }
        }

        &.post--comment {
            .post__body {
                border-left: 4px solid $gray;
                padding-left: 7px;

                &.mention-comment {
                    border-left: 4px solid $yellow;
                    border-color: $yellow;
                }
            }

            .attachment {
                .attachment__body__wrap {
                    .btn-close {
                        left: -11px;
                    }
                }
            }
        }

        &.same--root {
            &.same--user {
                padding: 0 .5em 0 1.5em;

                &:hover,
                &.post--hovered {
                    .post__time {
                        opacity: 0.5;
                    }
                }

                .flag-icon__container {
                    left: 32px;
                    margin-left: 7px;
                    position: absolute;
                    top: 6px;
                }

                .post__img {
                    .status-wrapper {
                        display: none;
                    }

                    img {
                        display: none;
                    }
                }

                .post__header {
                    height: 0;
                    margin: 0;

                    .col__name {
                        display: none;
                    }

                    .col__reply {
                        top: -1px;
                    }
                }
            }

            &.post--comment {
                .post__link {
                    display: none;
                }

                &.same--user {
                    .post__img {
                        img {
                            display: none;
                        }
                    }
                }
            }
        }

        &.other--root {
            .comment-icon__container {
                &.icon--show {
                    visibility: visible;
                }
            }

            &.post--comment {

                .popover {
                    margin-top: 84px;

                    > .arrow {
                        top: 21px !important;
                    }
                }

                .post__header {
                    .col__reply {
                        top: -4px;
                    }
                }
            }
        }

        .post__content {
            font-size: 8pt;
            padding: 2px;

            > div {
                //display: table-cell;
                //vertical-align: top;
            }
        }

        .post__tail {
            content: "";
            position: absolute;
            top: 0;
            width: 12px;
            height: 19px;

            background-position: 50% 50%;
            background-repeat: no-repeat;
            background-size: contain;
        }
        .post__content {
            @include alpha-property(background-color, #63AEF4, .1);
            box-shadow: 0 1px 0.5px rgba(0,0,0,.13);
            border-radius: 7.5px;
            border-top-right-radius: 0;
            float: left;
        }

        .post__tail {
            left: -12px;
            background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAmCAMAAADp2asXAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAACQUExURUxpccPDw9ra2m9vbwAAAAAAADExMf///wAAABoaGk9PT7q6uqurqwsLCycnJz4+PtDQ0JycnIyMjPf3915eXvz8/E9PT/39/RMTE4CAgAAAAJqamv////////r6+u/v7yUlJeXl5f///5ycnOXl5XNzc/Hx8f///xUVFf///+zs7P///+bm5gAAAM7Ozv///2fVensAAAAvdFJOUwCow1cBCCnqAhNAnY0WIDW2f2/hSeo99g1lBYT87vDXG8/6d8oL4sgM5szrkgl660OiZwAAAHRJREFUKM/ty7cSggAABNFVUQFzwizmjPz/39k4YuFWtm55bw7eHR6ny63+alnswT3/rIDzUSC7CrAziPYCJCsB+gbVkgDtVIDh+DsE9OTBpCtAbSBAZSEQNgWIygJ0RgJMDWYNAdYbAeKtAHODlkHIv997AkLqIVOXVU84AAAAAElFTkSuQmCC)
        }

        .post__text {
            width: 100%;

        }

        &.current--user {
            .post__content {
                background-color: #dcf8c6;
                float: right;
            }
            .post__tail {
                left: auto;
                right: -12px;
                background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAmCAMAAADp2asXAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAADAUExURUxpcXmHa4maet/4yA0aDRMTE8fhsgAAAAAAAMDXrCsxJeX/z1xzXIiYetPsvGBsVUdPPuH8zOH8zNDrvMvmtrrOpwAAAAAAABUVFRoaGtnyxLTMozQ+MMfftFBeSR8nH5aoh6q/mW9+ZN/4yMjhtRwlHAAAAIOWd+r/06C1kkNLOwsLC9z4xur/0+n/0t76x9v4xeL9y+b/z+j/0d/7yeH8yuX/zeD8ytz5xt76yOP/zeH+y+b/zuD8yd35xuf/0MY9jkkAAAAsdFJOUwBvd/ATDZIBAsMp/At/11c9yPbizHoICQwT4bY1ykkgjahl6s8bBYT6nUAWOLbtFAAAAIhJREFUKM/tzbUWwlAURNFBE9zdg0NecLf//yvKUJyUdDnl7HXXletXqmXl9wPbQ9JCcC+VJsOj2mDwovzj3osjHGNFEVxNRAj7UR1hlx+I4FbuC8HkZBE8OwnRxamdFsEmUxCCGdoI51RLBK9xVwTvjyMEbzlDMJMp7lqseNc8YNc6CGyF/a0vcmwhZbCG+kEAAAAASUVORK5CYII=)
            }
        }

        .center {
            max-width: 1000px;
        }

        &:hover {
            .post__header {
                padding: 0 55px 0 0;
            }
        }

        .post__header {
            display: flex;
            margin-bottom: 2px;
            padding: 0 20px 0 0;
            white-space: nowrap;
            width: 100%;

            > div {
                display: inline-block;
                vertical-align: top;
            }

            .col__name {
                font-weight: 600;
                margin-right: 7px;
                overflow: hidden;
                text-overflow: ellipsis;

                .user-popover {
                    @include clearfix;
                    text-overflow: ellipsis;
                    vertical-align: top;
                    white-space: nowrap;
                }

                .colon {
                    display: none;
                    font-weight: 900;
                    margin-left: 2px;
                }
            }

            .col__reply {
                border-radius:4px;
                border: 1px solid transparent;
                display: flex;
                justify-content: flex-end;
                padding: 4px;
                position: absolute;
                right: 0;
                top: -8px;
                white-space: normal;
                z-index: 6;

                > div,
                > button {
                    border-radius:4px;
                    display: flex;
                    flex: 1;
                    height: 24px;
                    top: 0;
                    width: 24px;
                }

                .comment-icon__container {
                    flex: 0;
                    justify-content: flex-end;
                    padding: 0 4px 0 0;
                    width: auto;
                    align-items: center;
                }

                .dropdown {
                    > div {
                        display: flex;
                        flex: 1;
                        justify-content: center;
                        align-items: center;
                    }
                }

                .reacticon__container,
                .icon {
                    display: flex;
                    flex: 1;
                    margin: 0;
                    top: 0;
                    align-items: center;
                }

                .reacticon__container {
                    width: 28px;
                }


                > .open, > div {
                    display: flex;
                    flex: 1;
                    justify-content: center;
                }

                span {
                    svg {
                        flex: 1;
                        height: 16px;
                        top: 0;
                        width: 16px;
                    }
                }

                svg {
                    flex: 1;
                    height: 16px;
                    top: 0;
                    width: 16px;
                }

                &.dot_small {
                    min-width: auto !important;
                }
            }

            .col__remove {
                position: absolute;
                right: 10px;
            }

            .permalink-popover {
                min-width: 0;

                .popover-content {
                    padding: 5px;
                }

                .form-control,
                .btn {
                    font-size: 13px;
                    height: 30px;
                    line-height: 30px;
                    padding: 0 8px;
                }
            }
        }

        .post__img {
            padding-right: 10px;
            text-align: right;
            width: 53px;

            .icon {
                svg {
                    height: 32px;
                    width: 32px;
                }
            }

            path {
                fill: inherit;
            }

            img {
                border-radius:50px;
                height: 32px;
                max-width: none;
                vertical-align: inherit;
                width: 32px;
            }
        }

        .post__embed-container {
            //@include single-transition(max-height, .5, ease);
            display: block;
            padding-top: 5px;
            max-height: 1000px;
            overflow: hidden;

            &[hidden] {
                max-height: 0;
            }
        }

        .dropdown {
            display: inline-block;
            top: -1px;
            vertical-align: middle;
            visibility: hidden;

            &:last-child {
                margin-right: 0;
            }

            .dropdown-menu {
                left: auto;
                min-width: 130px;
                padding: 2px 0;
                right: 0;
                top: 20px;

                li {
                    display: block;
                }

                a {
                    padding: 5px 15px;
                }
            }
        }

        .post__dropdown {
            display: flex;
            justify-content: center;
            text-decoration: none;
            vertical-align: middle;
            width: 100%;
            visibility: hidden;

            &:after {
                content: '...';
                font-family: 'Open Sans', sans-serif;
                position: relative;
                top: -1px;
                width: 100%;
            }
        }

        .post__remove {
            opacity: 0.5;
            color: inherit;
            display: inline-block;
            float: right;
            font-family: 'Open Sans', sans-serif;
            font-size: 20px;
            font-weight: 600;
            height: 20px;
            line-height: 20px;
            position: relative;
            right: -10px;
            text-decoration: none;
            vertical-align: top;
            visibility: hidden;
            width: 20px;

            &:hover {
                opacity:.8;
            }
        }


        .post__body {
            @include legacy-pie-clearfix;
            padding: 0;
            width: 100%;
            word-wrap: break-word;

            &.post--ephemeral {
                padding-right: 20px !important;
            }

            div {
            }

            p + p {
                margin: 0.5em 0 0;
            }

            ul,
            ol {
                font-size: 13.5px;

                p {
                    margin-bottom: 0;
                }

                li {
                    ul,
                    ol {
                        font-size: 1em;
                        margin: 0;
                        padding: 0 0 0 20px;
                    }
                }

                li.list-item--task-list ul,
                li.list-item--task-list ol {
                    margin-left: 20px;
                }

                li.list-item--task-list ul {
                    padding-left: 0;
                }

                li:not(.list-item--task-list) li.list-item--task-list,
                li:not(.list-item--task-list) li.list-item--task-list ~ li {
                    margin-left: -20px;
                }

                li input[type='checkbox']:disabled {
                    cursor: default;
                    vertical-align: top;
                }
            }

            ul li.list-item--task-list,
            ul li.list-item--task-list ~ li {
                list-style-type: none;
            }

            ul li.list-item--task-list ~ li:not(.list-item--task-list) {
                text-indent: 3px;

                &:before {
                    content: '\2022';
                    margin-right: 8px;
                }
            }

            ul + p,
            ol + p {
                margin-top: .6em;
            }

            p + ul,
            p + ol {
                margin-top: .6em;
            }

            .pending-post-actions {
                background: alpha-color($black, .7);
                color: $white;
                font-size: .9em;
                padding: 5px 7px;
                position: absolute;
                right: 0;
                top: 0;
                z-index: 100;

                a {
                    color: $white;
                }
            }


        }

        .post__link {
            font-size: 13px;
            margin: 2px 0 5px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            a {
                font-weight: bold;
            }
        }

        .post__embed-visibility {
            -moz-osx-font-smoothing: grayscale;
            -webkit-font-smoothing: antialiased;
            cursor: pointer;
            display: inline-block;
            font: normal normal normal 14px/1 FontAwesome;
            margin: 0;
            text-rendering: auto;
            position: relative;
            z-index: 2;

            &.pull-left {
                margin: 4px 5px 0 0;
            }

            &:hover {
                text-decoration: none;
            }

            &:before {
                content: '\f065';
                font-size: 14px;
    }

            &[data-expanded='true']:before {
                content: '\f066';
            }
        }

        .post__visibility {
            opacity: 0.6;
            font-size: .9em;
            padding: 8px;
        }

        .post__timestamp {
            float: right;
            margin: -10px 0 -5px 4px;

        }
        .post__time {
            opacity: 0.6;
            font-size: .9em;
        }

        .post__permalink {
            color: inherit;

            &:hover,
            &:focus {
                color: inherit;
            }

            &:focus {
                text-decoration: none;
            }

            &:hover {
                text-decoration: underline;
            }
        }

        .post-loading-gif {
            height: 10px;
            margin-top: 6px;
            width: 10px;
        }

        .post-fail {
            color: #d58a8a;
        }

        .post-waiting {
            color: #999999;
            position: relative;

            .spinner {
                width: 20px;
                position: absolute;
                right: 8px;
                top: 4px;
            }

            .post-message {
                padding-right: 24px;
            }
        }

        .permalink-icon {
            color: $primary-color;
            display: inline-block;
            visibility: hidden;
        }

        .post__reply {
            display: inline-block;
            margin-right: 6px;
            visibility: hidden;

            svg {
                fill: inherit;
                position: relative;
                top: 3px;
                width: 18px;
            }
        }

        .comment-icon__container {
            display: inline-block;
            fill: $primary-color;
            position: relative;
            text-decoration: none;
            top: 1px;
            visibility: hidden;

            &:focus {
                outline: none;
            }

            &.icon--visible {
                visibility: visible;
            }

            svg {
                height: 17px;
                width: 17px;
            }

            .comment-count {
                margin-left: 2px;
            }

            .comment-icon {
                display: inline-block;
                fill: inherit;
                margin: 0 1px 0 5px;
                position: relative;
                top: 1px;
                vertical-align: top;
            }

            path {
                fill: inherit;
            }
        }

        .reacticon__container {
            display: inline-block;
            fill: $primary-color;
            font-size: 16px;
            vertical-align: top;
            visibility: hidden;

            svg {
                height: 14px;
                position: relative;
                top: 1px;
                width: 14px;
            }
        }

        .flag-icon__container {
            display: inline-block;
            height: 15px;
            margin-left: 7px;
            position: relative;
            top: 2px;
            vertical-align: top;
            visibility: hidden;

            &.visible {
                visibility: visible;
            }

            svg {
                width: 12px;
            }

            path {
                fill: inherit;
            }

            .fa-star-o {
                opacity:.8;
            }

            &:focus {
                outline: none;
            }

            &.icon--visible {
                visibility: visible;
            }

        }

        .web-embed-data {
            border-radius:2px;
            @include alpha-property(background, $black, 0.05);
            height: 50px;
            overflow: hidden;
            padding: 2px 0 0 10px;
            text-overflow: ellipsis;

            .embed-title {
                color: #555555;
                font-weight: 600;
                margin: 3px 0 1px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            .embed-description {
                color: #888888;
                margin: 0;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            .embed-link {
                display: none;
            }
        }
    }

    .post__pinned-badge {
        border-radius: 2px;
        font-family: inherit;
        font-size: 10px;
        font-weight: 600;
        padding: 1px 4px;
    }

    .Badge {
        &.badge-autocomplete {
            margin: 0px 4px;
            padding: 0px 5px;
            display: inline;
        }
        &.badge-popoverlist {
            margin: 0px 4px;
            display: inline;
        }
        &.badge-admin {
            margin: 0px 4px;
            background: rgba(61, 60, 64, 0.15);
            display: inline;
        }
    }

    .post__pinned-badge {
        margin-left: 7px;
        position: relative;
        top: -1px;
    }

    .permalink-text {
        overflow: hidden;
    }

    .permalink-popover {
        margin-left: 50px !important;
        min-width: 320px;
    }

    .post-message {
        position: relative;
        overflow: hidden;
    }

    .post-message__status-badge {
        background: #f0f8ff;
        border-radius: 5px;
        padding: 5px;
        font-size: small;
    }

    .post-collapse {
        bottom: 0;
        pointer-events: none;
        position: absolute;
        width: 100%;

        .post-collapse__gradient, .post-attachment-collapse__gradient {
            position: relative;
        }

        .post-collapse__show-more, .post-attachment-collapse__show-more {
            pointer-events: auto;
        }
    }

    .post-collapse__show-more {
        padding-bottom: 10px;
    }

    .post-collapse__show-more, .post-attachment-collapse__show-more {
        display: flex;
        justify-content: center;
        width: 100%;
        z-index: 5;

        .post-collapse__show-more-button {
            //@include single-transition(all, .15s, ease);
            border-radius:2px;
            border: 1px solid transparent;
            display: inline-block;
            flex-shrink: 0;
            font-size: 13px;
            font-weight: bold;
            line-height: 24px;
            margin: 0 8px;
            padding: 0 8px;
            position: relative;

            .fa {
                font-size: 1.4em;
                font-weight: bold;
                margin-right: 5px;
                position: relative;
                top: 2px;

                &.fa-angle-up {
                    top: 1px;
                }
            }

            &:focus {
                outline: none;
            }
        }

        .post-collapse__show-more-line {
            display: inline-block;
            flex-basis: 200px;
            height: 1px;
            margin-top: 12px;
        }
    }

    .post-message--collapsed {
        .post-message__text-container {
            max-height: 600px;

        }

        .post-collapse__gradient, .post-attachment-collapse__gradient {
            height: 90px;
        }

        .post-collapse__show-more {
            bottom: 10px;
        }
    }

    .post-message--expanded {
        .post-collapse {
            position: relative;
        }

        .post-collapse__show-more, .post-attachment-collapse__show-more {
            position: relative;
            padding-top: 10px;
        }

        .post-collapse__gradient, .post-attachment-collapse__gradient {
            height: 0;
        }
    }

    .file-attachment-menu-item-input {
        cursor: pointer;
        direction: ltr;
        filter: alpha(opacity=0);
        font-size: 23px;
        height: 35px;
        margin: 0;
        opacity: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: 0;
    }

    .post--metadata {
       .post__content {
        -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
        float: right;
        background: white;
            &.deferred {
                background-color: #f1f1f1;
                -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
            }
        }

        .post-message__text {
            padding: 5px;
            font-weight: bold;
            margin-bottom: 5px;
            font-size: 8pt;
        }

        .post-message__buttons {
            padding: 5px 10px;
            font-weight: bold;
            margin-bottom: 10px;
        }
    }
    .embed-metadata {
        width: 100%;
        td {
            padding: 5px 10px;
        }

        .metadata-value {
            text-align: right;
        }
        .total-row {
            padding: 5px 10px;
            font-size: 1em;
            font-weight: bold;
            border-top: 1px solid #b2af4a;
        }

        .metadata-total-value {
            text-align: right;
        }
    }
    .metadata-desc {
        margin-bottom: 10px;
    }

    .metadata-desc__row {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border-bottom: 1px solid gainsboro;

        &.row_deferred {
            display: flex;
            justify-content: space-between;
            font-weight: bold;
            color: $orange;
        }
    }

    .metadata-text {
        padding: 0px 10px;
        .v{
            font-weight: 500;
            margin-right: 10px;
        }

        &:first-child {
            float: left;
        }

        &:last-child {
            float: right;
        }

        margin-bottom: 5px;
    }

    .metadata-cancel {
        padding: 10px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
}

@media screen and (max-width: 768px) {

    .sidebar-chat__item {
        border-bottom:0.5px solid gainsboro;
        font-size: small;
        font-weight: normal;


        &.item--active {
            background: #e8e8e8;
        }

        &.item--unread {
            font-weight: 600;
        }

        &.item--closed * {
            color: #9a9a9a;
        }

    }

    .MenuWrapper {
        .Menu {
            &.dropdown-menu {
                max-height: 100%;
                max-width: 100%;
            }
            &.mobile-channel-header-dropdown-enter {
                transform: translateY(100%);
            }

            &.mobile-channel-header-dropdown-enter-active {
                transform: translateY(0%);
                transition: transform .35s ease-in;
            }
            &.mobile-channel-header-dropdown-enter-done {
                transform: translateY(0%);
            }

            &.mobile-channel-header-dropdown-exit {
                transform: translateY(100%);
                transition: transform .35s ease-in;
            }
        }
    }

    .post__header {
        .dropdown-menu {
            >li {
                >button {
                    border-radius:0;
                    border-top: 1px solid;
                    padding: 15px 20px;
                    height: auto;
                }

                &:first-child {
                    button {
                        border: none;
                    }
                }
            }
        }
    }

    .app__body {
        .post {
            .Menu {
                border-radius:0;
                border: 0px;
                height: 100%;
                left: 0;
                position: fixed;
                top: 0;
                width: 100%;
                z-index: 9999;
                display: flex;
                margin: 0;
                padding: 0 2em;
                background: transparent;
                flex-direction: column;
                justify-content: center;

                &:after {
                    background: alpha-color($black, .3);
                    content: '';
                    height: 100%;
                    left: 0;
                    position: fixed;
                    top: 0;
                    width: 100%;
                }

                .MenuItem {
                    z-index: 9999;
                    background: $white;
                    &:last-child button {
                        border-bottom: 0px;
                    }
                }
            }
        }
    }

    .textarea-wrapper {
        .help__text {
            display: none;
        }
    }

    .integration-option {
        flex: 0 0 100%;
        margin: 0 0 30px;
    }

    .channel-header {
        .channel-header__icon {
            display: none;
        }
    }

    .member-role .member-menu,
    .member-drop .member-menu {
        right: 0;
        top: 30px;
    }

    .post-code {
        word-wrap: normal;
    }

    .table-responsive {
        border: none;
    }

    .multi-select__container {
        .btn {
            display: block;
            min-width: 50px;
        }

        .Select-value-label {
            max-width: 190px;
            text-overflow: ellipsis;
        }
    }

    .more-modal__list {
        .more-modal__actions--round {
            opacity: 0.5;
        }
    }

    .post-create__container {
        z-index: 2;



        .post-create-footer {
            padding: 0 10px;
        }

        form {
            padding: 0;
        }

        .post-create-body {
            padding: 0;

            .send-button {
                display: block;
            }

            .custom-textarea {
                max-height: 162px;
            }

            textarea {
                .app-content & {
                    border-left: none;
                    border-right: none;
                }
            }

            .post-body__actions {
                .Menu.dropdown-menu {
                    max-height: 80vh;
                }
            }
        }

        .msg-typing {
            display: none;
        }
    }

    .suggestion-list__content {
        max-height: 145px;
    }

    .filtered-user-list {
        .filter-button {
            .btn {
                width: 100%;
            }
        }
    }

    .settings-modal {
        .modal-body {
            overflow: auto;
        }
    }

    #header-popover {
        //@include single-transition(all, .35s, ease);
        transform: translate3d(0, 100%, 0);
        box-shadow:(none);
        background: alpha-color($black, .9);
        border: none;
        height: calc(100% - 50px);
        max-width: 100%;
        position: fixed;
        top: 40px !important;
        width: 100%;

        &.in {
            transform: translate3d(0, 0, 0);
        }

        a {
            color: #0091ff;
        }

        .arrow {
            display: none;
        }

        .popover-content {
            color: $white;
            font-size: 15px;
            padding: 15px 20px 100px;

            >div {
                &:first-child {
                    -webkit-overflow-scrolling: touch;
                    height: calc(100vh - 150px);
                    overflow: auto;
                }
            }
        }

        .close {
            border-radius:50%;
            border: 1px solid $white;
            bottom: 25px;
            color: $white;
            display: block;
            font-family: 'Open Sans', sans-serif;
            font-size: 23px;
            font-weight: 200;
            height: 30px;
            left: 50%;
            line-height: 0;
            margin-left: -15px;
            opacity: 1;
            padding-top: 13px;
            position: fixed;
            text-align: center;
            text-shadow: none;
            width: 30px;
        }
    }

    .app__body {
        .edit-modal-body {
            .custom-textarea {
                max-height: 30vh;
            }
        }
    }

    .video-div {
        &.embed-responsive-item {
            height: 0;
            padding-bottom: 75%;

            iframe {
                height: 100%;
                left: 0;
                position: absolute;
                top: 0;
                width: 100%;
            }
        }
    }


    .prompt {
        .prompt__heading {
            display: block;

            >div {
                &:first-child {
                    display: block;
                    margin: 0 0 1em;
                }
            }
        }
    }

    .post-code__language {
        opacity: 1;
        transition:(none);
        display: block;
        position: relative;
    }

    .backstage-form__footer {
        .has-error {
            float: none !important;
            max-width: 100%;
            margin-bottom: 16px;
        }
    }

    .backstage-filters {
        display: block;

        .backstage-filter__search {
            margin: 10px 0;
            width: 100%;
        }
    }

    .compliance-panel,
    .audit-panel {
        .row {
            >.form-group {
                padding-left: 15px;
            }
        }
    }

    .user-popover {
        cursor: pointer;
        display: inline-block;
    }

    .signup-team__container {
        font-size: 1em;
    }

    .sidebar--left .team__header .user__picture,
    .sidebar--menu .team__header .user__picture {
        display: none;
    }

    .date-separator.hovered--before:after,
    .date-separator.hovered--after:before,
    .new-separator.hovered--after:before,
    .new-separator.hovered--before:after {
        background: none !important;
    }

    .channel-intro {
        margin: 0 0 35px;
    }

    .post {
        &:not(.post--compact) {
            .Badge {
                left: 6px;
                top: 31px;
            }
        }

        &.post--system {
            .post__header {
                .col__reply {
                    min-width: 45px;
                }
            }
        }



        .browser--ie & {
            .post__header {
                .col__reply {
                    .dropdown+div {
                        display: none;
                    }
                }
            }
        }

        .reacticon__container {
            display: none;
        }

        .post__dropdown {
            display: inline-block;
            height: 28px;
            line-height: 21px;
            text-align: center;
            text-decoration: none;
            visibility: visible;
            width: 28px;

            &:after {
                content: '...';
                font-size: 20px;
                top: -3px;
            }
        }

        .post__remove {
            margin-right: 10px;
            visibility: visible;
        }

        &.post--compact {
            padding-left: 1em;

            .post__img {
                padding-top: 0;

                .post-right__container & {
                    padding-top: 2px;
                }
            }

            &.same--root {
                &.same--user {
                    padding-left: 1em;

                    .post__header {
                        height: auto;
                    }
                }
            }

            .post-message--overflow {
                @include clearfix;
            }

            .status-wrapper {
                &:after {
                    bottom: 3px;

                    .sidebar--right & {
                        bottom: 0;
                    }
                }
            }

            blockquote {
                margin-top: 0;
            }
        }

        &.same--root {
            &.same--user {
                .flag-icon__container {
                    left: auto;
                    position: relative;
                    top: 1px;
                }
            }
        }

        .post__content {
            padding: 5px;
        }

        &.current--user {
            &.post--comment {
                .post__header {
                    margin-bottom: 3px;
                }
            }
        }

        .post__header {
            margin-bottom: 0;
            padding-right: 70px;

            .col__reply {
                min-width: 45px;
                top: -6px;
                z-index: auto;

                >div {
                    width: auto;
                }

                .reacticon__container {
                    display: none;
                }
            }

            .col__name {
                .user-popover {
                    max-width: 130px;
                }
            }
        }

        .comment-icon__container {
            display: none;
            visibility: visible;

            .sidebar--right & {
                display: inline-block;
            }

            &.icon--show {
                display: inline-block;
            }
        }

        .post-list__content & {
            &:hover {
                background: transparent;
            }

            .comment-icon__container {
                visibility: visible;
            }
        }

        .dropdown,
        .post__reply {
            visibility: visible;
        }

        .post__body {
            width: 100%;
        }

        .post__reply {
            float: right;
            margin-right: 20px;

            svg {
                top: 1px;
            }
        }

        &.post--comment {
            .post__body {
                margin-top: 5px;
                padding: 0 0 0 7px;
            }
        }

        &.other--root {
            &.post--comment {
                .post__header {
                    .col__reply {
                        top: -6px;
                    }
                }
            }

            .post__reply {
                &.post__reply--hide {
                    visibility: hidden;
                }
            }
        }

        &.current--user {
            .post__link {
                margin: 0 0 8px;
            }
        }

        .star-icon__container {
            left: auto;
            position: relative;
            top: auto;

            &:not(.visible) {
                display: none;
            }
        }

        &.same--root {
            .star-icon__container {
                left: auto;
                position: relative;
                top: auto;
            }

            &.same--user {
                &.post--compact {
                    .status-wrapper {
                        &:after {
                            bottom: -2px;
                        }
                    }
                }

                .post__img {
                    img {
                        display: block;
                    }
                }

                .post__header {
                    height: auto;
                    margin-top: 5px;

                    .col__name {
                        display: inline-block;
                    }
                }
            }
        }

        .post__img {
            width: 40px;

            img {
                height: 32px;
                width: 32px;
            }
        }
    }

    .form-control {
        &.min-height {
            min-height: 100px;
        }
    }

    .img-div {
        max-width: 100%;
    }

    .tip-div {
        left: 15px;
        right: auto;
    }

    .tip-overlay {
        &.tip-overlay--chat {
            margin-left: 10px;

            .arrow {
                left: 32px;
            }
        }
    }

    .file-details__container {
        display: block;

        .file-details__preview {
            border-bottom: 1px solid #dddddd;
            border-right: none;
            display: block;
            height: 150px;
            width: 100%;

            img {
                height: 64px;
                width: 64px;
            }
        }

        .file-details {
            height: auto;
            width: 100%;
        }
    }

    .search-help-popover {
        left: 0;
        max-width: 100%;
    }

    .modal-direct-channels,
    .more-channel__modal {
        .member-count {
            display: block;
            float: none;
            margin-top: 10px;
        }
    }

    .file-overlay {
        font-size: em(18px);

        &.center-file-overlay {
            .overlay__indent {
                margin-left: 0;
            }
        }

        .overlay__circle {
            height: 300px;
            margin: -150px 0 0 -150px;
            width: 300px;
        }

        .overlay__files {
            margin: 60px auto 15px;
            width: 150px;
        }
    }

    .date-separator,
    .new-separator {
        &.hovered--after {
            &:before {
                background: none;
            }
        }

        &.hovered--before {
            &:after {
                background: none;
            }
        }
    }

    .post-list__timestamp {
        display: block;
    }

    .signup-team__container {
        font-size: .9em;
        margin-bottom: 30px;
        padding: 60px 10px 0;

        .signup-team__name {
            font-size: 2em;
        }

        .btn.btn-full {
            padding-left: 10px;
        }

        .btn {
            .icon,
            .fa {
                margin-right: 6px;
            }
        }
    }

    .app__body {
        .row--invite {
            .col-sm-6 {
                &:first-child {
                    padding-right: 15px;
                }
            }
        }

        .modal {
            .modal--scroll {
                .modal-body {
                    max-height: calc(100vh - 62px);
                    overflow: auto;
                }
            }

            .nav-pills {
                >li {
                    &.active {
                        button {
                            background: transparent;

                            &:before {
                                display: none;
                            }
                        }
                    }

                    button {
                        border-bottom: 1px solid;
                        padding: 15px;
                    }
                }
            }

            .info__label {
                padding-bottom: 5px;
                text-align: left;
            }

            .modal-header {
                .modal-action {
                    margin-top: 10px;
                }

                .close {
                    overflow: hidden;
                    font-size: 27px;
                    font-weight: normal;
                    margin-top: -2px;
                }

                .modal-title {
                    float: none;
                    max-width: 90%;
                }

                .btn {
                    &.btn-primary {
                        display: block;
                        float: none;
                        margin: 10px 0 6px;
                        width: 100%;
                    }
                }
            }

            .settings-modal {
                .modal-body {
                    min-height: 100%;
                }

                .nav-pills {
                    >li {
                        &:hover {
                            a {
                                background: transparent !important;
                            }
                        }
                    }
                }

                &.display--content {
                    .modal-header {
                        display: none;
                    }

                    .settings-table {
                        display: block;

                        .settings-content {
                            .section-title {
                                padding-right: 15px;
                            }

                            .section-edit {
                                position: relative;
                                right: 0;
                                text-align: left;
                                top: 0;
                            }

                            .appearance-section {
                                .theme-elements {
                                    .element {
                                        margin-right: 10px;

                                        &:nth-child(2n) {
                                            margin-right: 10px;
                                        }
                                    }
                                }
                            }

                            &.minimize-settings {
                                display: block;
                            }

                            .section-min:hover {
                                background: none;
                            }

                            .no-padding--left {
                                padding-left: 15px;
                            }
                        }

                        .settings-links {
                            display: none;
                        }

                        .modal-header {
                            display: block;
                            position: absolute;
                            top: 0;
                            width: 100%;
                            z-index: 5;

                            .modal-title {
                                max-width: 100%;
                                padding: 0 40px;
                                text-align: center;
                                width: 100%;

                                >span {
                                    display: block;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    white-space: nowrap;
                                }
                            }
                        }

                        .user-settings {
                            .tab-header {
                                display: none;
                            }

                            .divider-dark.first {
                                display: none;
                            }
                        }
                    }
                }

                .settings-table {
                    padding: 0;

                    .nav {
                        position: relative;
                        top: auto;
                        width: 100%;
                        margin: 0;

                        &.position--top {
                            top: auto;
                        }
                    }

                    .settings-content {
                        .section-edit {
                            text-align: left;

                            button {
                                text-align: left;
                                width: 100%;
                            }

                            .fa {
                                display: inline-block;
                            }
                        }

                        &.minimize-settings {
                            display: none;
                            padding: 0;

                            .user-settings {
                                padding: 70px 20px 30px;
                            }
                        }

                        .section-min:hover {
                            background: none !important;
                        }
                    }

                    .nav {
                        >li {
                            >a {
                                font-size: 1.1em;
                                line-height: 2.7;

                                .icon {
                                    margin: 0 7px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .file-preview__container {
        margin: 10px 0 5px;
    }

    .file-preview {
        margin-top: 0;
    }

    // Since system console is not responsive we're overriding bootstrap styles for it
    .admin-sidebar {
        .navbar-nav {
            margin-top: 0;

            >li {
                float: left;
            }

            .dropdown-menu {
                background: $white;
                left: auto;
                position: absolute;
                right: 0;
            }
        }
    }

    #navbar {
        .browser--ie & {
            .navbar-default {
                .dropdown-menu {
                    .close {
                        top: 70px;
                    }
                }
            }
        }

        .navbar-default {
            .navbar-header {
                float: none;
                margin: 0 -15px;

                .dropdown__icon {
                    background-size:(100% 100%);
                    display: inline-block;
                    height: 16px;
                    width: 4px;
                }
            }

            .navbar-toggle {
                display: block;

                &:not(.navbar-right__icon) {
                    &:hover {
                        background: transparent;
                    }
                }
            }

            .dropdown-menu {
                //@include single-transition(all, .35s, ease);
                background: alpha-color($black, .9);
                display: block;
                height: calc(100% - 26px);
                left: 0;
                overflow: auto;
                padding: 2.5em 0 0;
                position: fixed;
                top: 48px;
                width: 100%;
                padding-bottom: 50px;

                body.announcement-bar--fixed & {
                    height: calc(100% - 48px - #{$announcement-bar-height});
                    top: calc(48px + #{$announcement-bar-height});
                }

                .dropdown__divider {
                    &+.dropdown__divider {
                        display: none;
                    }
                }

                .close {
                    border-radius:50%;
                    border: 1px solid $white;
                    color: $white;
                    display: block;
                    font-family: 'Open Sans', sans-serif;
                    font-size: 23px;
                    font-weight: 200;
                    height: 30px;
                    line-height: 0;
                    margin-left: -15px;
                    opacity: 1;
                    padding-top: 13px;
                    position: fixed;
                    right: 20px;
                    text-align: center;
                    text-shadow: none;
                    top: 20px;
                    width: 30px;
                }

                >li {
                    >a,
                    >button {
                        color: $white;
                        font-weight: 600;
                        line-height: 32px;
                        margin: 0 auto;
                        text-align: center;
                        width: 70%;

                        &:hover {
                            background: transparent;
                        }
                    }

                    .navbar-right__icon {
                        margin: 10px;
                    }

                    hr {
                        border-bottom: 1px solid alpha-color($white, .3);
                        margin: 8px auto;
                        width: 70%;
                    }
                }
            }

            .status {
                margin: 0 8px 0 0px;
                top: 2px;
                width: 16px;

                svg {
                    max-height: 20px;
                    width: 16px;
                }
            }
        }
    }

    body {
        &.white {
            .inner-wrap {
                >.row.content {
                    margin-bottom: -185px;
                }
            }
        }
    }

    .footer,
    .footer-pane {
        height: 187px;
    }

    .footer-pane {
        .footer-link {
            display: block;
            line-height: 1.7;
            padding: 0;
            text-align: right;
            width: 100%;

            &.copyright {
                width: 100%;
            }
        }
    }

    .search-bar__container {
         flex:(0 0 50px);
        //background: $primary-color;
        color: $white;
        padding: 0;

        .search-form__container {
            color: $black;
            padding: 0 20px 0 0;
        }

        .search__form {
            //@include single-transition(all, .2s, linear);
            transform: translateX(0);
            background: $white;
            border: none;
            margin-top: 0px;
            padding-left: 40px;
            width: 100%;

            .fa-spin {
                font-size: 1.1em;
                top: 9px;
            }

            .search-bar {
                font-size: 14px;
                height: 32px;
            }

            .search__icon {
                left: 15px;
                top: 8px;

                svg {
                    stroke: $black;
                    stroke-opacity: .4;
                    width: 17px;
                }
            }

            .icon--refresh {
                opacity: 0.6;
                right: 16px;
                top: 9px;
            }

            .form-control {
                border-radius:3px;
                background: $white;
                border: none;
                color: $dark-gray;
                padding: 0 31px;
            }
        }

        .channel-header__links {
            display: none;
        }
    }

    .sidebar--menu {
        //@include single-transition(transform, .35s, ease);
        transform: translate3d(290px, 0, 0);
        border: none;
        visibility: hidden;
        width: 290px;

        &.visible {
            display: block;
            visibility: visible;
        }

        &.move--left {
            transform: translate3d(0, 0, 0);
            display: block;
            visibility: visible;
        }
        .mentions {
            font-size: 17px;
            font-weight: bold;
        }
    }

    .sidebar--left {
        //@include single-transition(transform, .35s, ease);
        transform: translate3d(-290px, 0, 0);
        border: none;
        width: 290px;
        background: white;

        &.sidebar--padded {
            padding-top: 0;
        }

        &.move--right {
            transform: translate3d(0, 0, 0);
        }

        .nav-pills__unread-indicator {
            width: 260px;
        }

        .team__header {
            @include clearfix;
            pointer-events: none;

            .user__name {
                display: none;
            }

            .team__name {
                margin: 10px 0;
            }

            .sidebar-header-dropdown {
                display: none;
            }

            .sidebar-header-dropdown__icon {
                display: none;
            }
        }

        .search__form {
            display: block;
        }

        .nav {
            li {
                &.dropdown.open {
                    padding-bottom: 25px;

                    ul {
                        background: $white;
                        border-radius: 3px;
                        clear: both;
                        position: relative;
                        top: 5px;

                        li {
                            a {
                                line-height: 30px;
                            }
                        }
                    }
                }

                .nav-more {
                    padding: 12px 0 12px 17px;
                }

                .sidebar-item {

                }

                h4 {
                    margin: 1.5 0 1em;
                }

                >a {
                    font-size: 15px;
                    //line-height: 2.5;
                    margin: 0;

                    &:hover,
                    &:focus {
                        background: transparent !important;
                    }
                }
            }
        }
    }
    .nav-view > .container-fluid {
        padding-right: 0px;
        padding-left: 0px;
        margin-right: auto;
        margin-left: auto;
    }
    .sidebar--right {
        transform: translate3d(100%, 0, 0);
        right: 0;
        width: 100%;

        .browser--ie & {
            -webkit-transform: none !important;
            -moz-transform: none !important;
            -ms-transform: none !important;
            -o-transform: none !important;
            transform: none !important;
            display: none;
        }

        .post-create__container {
            form {
                padding: .5em 1em;
            }


            .msg-typing:empty {
                display: none;
            }

            .post-create-footer {
                padding-right: 0;
                padding-top: 10px;

                .control-label {
                    margin: .5em 0;
                    top: 0;
                }

                .post-error {
                    left: 0;
                    top: 0;
                }
            }
        }

        .sidebar-collapse__container {
            display: table-cell;
            vertical-align: top;
        }

        .sidebar__search-clear {
            color: inherit;
            height: 32px;
            margin-right: 0;
            text-align: center;
            top: 0;
            width: 42px;
            display: flex;
            justify-content: center;
            align-items: center;

            &.visible {
                visibility: visible;
            }
        }

        .sidebar__search-clear-x {
            font-size: 21px;
            font-weight: 700;
            line-height: 0;
            position: relative;
        }

        .sidebar--right__close {
            display: none;
        }

        .sidebar-right__body {
            height: calc(100% - 56px);
        }
    }

    .search-items-container {
        height: calc(100% - 56px);
    }

    .inner-wrap {
        //@include single-transition(all, .35s, ease);

        .app__body & {
            &:before {
                //Some trickery in order for the z-index transition to happen immediately on move-in and delayed on move-out.
                background-color: transparent;
                content: '';
                height: 100%;
                left: -15px;
                position: absolute;
                top: 0;
                transition: background-color .35s ease, z-index 0s ease .35s;
                width: calc(100% + 30px);
                z-index: 0;
            }
        }

        &.move--right {
            transform: translate3d(0, 0, 0);

            &:before {
                //@include single-transition(all, .35s, ease);

                background-color: rgba(0, 0, 0, .4);
                z-index: 9999;
            }
        }

        &.move--left-small {
            transform: translate3d(-290px, 0, 0);

            &:before {
                //@include single-transition(all, .35s, ease);
                background-color: rgba(0, 0, 0, .4);
                z-index: 9999;
            }
        }

        &.move--left {
            margin: 0;
        transform: translate3d(-100%, 0, 0);
        }
    }

    .integration-option {
        height: auto;
        margin-left: 0;
        width: 100%;
    }

    .app__content {
        margin: 0 !important;
        padding-top: 50px;
        .channel__wrap & {
            padding-top: 50px;
        }

        #channel-header {
            display: none;
        }
    }

    .channel__wrap {

        .row {
            &.header {
                display: block;
            }
        }
    }

    .post-comments {
        padding: 9px 21px 10px 10px !important;
    }

    //.multi-teams {

        .app__content {
            margin-left: 0 !important;
        }

        .sidebar--left {
            left: 0;

            &.move--right {
                left: 65px;
            }

            .nav-pills__unread-indicator {
                left: 15px;
            }
        }

        .team-sidebar {
            display: none;

            &.move--right {
                display: flex;
                //width: 65px;
                transform: translate3d(0, 0, 0);
                .scrollbar--view {

                   // background: #000;
                }

            }
        }
        .nav-left {
            display: none;
            width: 65px;
            &.move--right {
                display: flex;
                width: 65px;
                transform: translate3d(0, 0, 0);
            }
        }
    //}

    .post {
        .attachment {
            .attachment-actions {
                .alert {
                    position: relative;
                    left: 0;
                    top: 0;
                    margin: 1rem 0 0.4rem;
                    display: inline-block;
                }
            }

            .attachment__body__wrap {
                .btn-close {
                    height: 30px;
                    left: -15px;
                    top: 7px;
                    visibility: visible;
                    width: 30px;
                }
            }
        }
    }

    .modal-body {
        .MenuWrapper {
            .Menu {
                &.dropdown-menu {
                    max-height: none;
                    max-width: none;
                    right: 0;
                    left: inherit;
                }
            }
        }
    }
}

@media screen and (max-width: 640px) {
    body {
        &.browser--ie {
            min-width: 600px;
        }
    }

    .multi-select__help {
        flex-direction: column;

        >span {
            &:first-child {
                margin-bottom: 1rem;
            }
        }
    }

    .filtered-user-list {
        height: calc(100vh - 80px);
    }

    .more-modal--action {
        .filtered-user-list {
            height: calc(100vh - 110px);
        }
    }

    .app__body {
        &.announcement-bar--fixed {
            .modal {
                padding-top: $announcement-bar-height;
            }
        }

        .modal {
            overflow: hidden;
            padding: 0;

            .modal-dialog {
                height: 100%;
                margin: 0;
                max-width: 100%;

                .modal-content {
                    height: 100%;
                }
            }

            .modal-footer {
                bottom: 0;
                position: fixed;
                width: 100%;
            }

            .about-modal {
                .modal-content {
                    display: flex;
                    flex-direction: column;
                }

                .modal-header {
                    flex: 0 0 70px;
                    padding: 0 25px;
                }

                .modal-footer {
                    position: relative;
                }

                .modal-body {
                    max-height: 100%;
                }
                .about-modal__content {
                    display: block;
                }

                .about-modal__hash {
                    p {
                        word-break: break-all;

                        &:first-child {
                            float: none;
                        }
                    }
                }

                .about-modal__logo {
                    float: none;
                    padding: 0;
                    text-align: center;
                    width: 100%;

                    svg {
                        height: 100px;
                        width: 100px;
                    }
                }

                .about-modal__logo+div {
                    padding: 2em 0 0;
                }
            }

            .more-modal {
                .modal-content {
                    display: flex;
                    flex-direction: column;
                }

                .modal-header {
                    flex: 0 0 56px;
                }

                .modal-body {
                    flex: 1 1 auto;
                    max-height: 100%;
                    display: flex;
                }

                .filtered-user-list {
                    flex: 1 1 auto;
                    height: auto;
                }
            }
        }

        .post {
            .open {
                >.dropdown-menu__content {
                    display: table;

                    >.dropdown-menu {
                        display: table-cell;
                    }
                }
            }
        }
    }

    .access-history__table {
        >div {
            display: block;
        }

        .access__report {
            margin: 0 0 15px 15px;
        }

        .access__date {
            div {
                margin-bottom: 15px;
            }
        }
    }

    .activity-log__table {
        >div {
            display: block;
        }

        .activity-log__report {
            width: 100%;
        }

        .activity-log__action {
            margin-top: 10px;
            text-align: left;
        }
    }
}

@media screen and (max-width: 550px) {
    .file-view--single {
        .file__image {
            .image-loaded {
                img {
                    max-width: 100%;
                }
            }
        }
    }

    .post {
        .img-div {
            max-width: 100%;
        }
    }

    .app__body {
        .more-modal {
            &.more-system-members {
                .filter-row {
                    min-height: 80px;
                    width: 100%;
                }
            }

            &.more-direct-channels {
                .member-show {
                    display: none;
                }

                select {
                    margin: 0;
                    width: 100%;
                }
            }
        }
    }

    .member-select__container {
        left: 15px;
        top: 60px;
        width: calc(100% - 30px);
    }
}

@media screen and (max-width: 480px) {

    #user-profile-popover {
        left: 50px !important;
    }

    .nav-tabs {
        margin-top: 1em;

        >li {
            margin-right: 0;

            a {
                font-size: .9em;
                padding: 6px 11px;
            }
        }
    }



    .sidebar--right {
        .post {
            &.post--compact {
                .post__header {
                    height: auto;
                }
            }
        }
    }

    .backstage-header {
        h1 {
            float: none;
            margin-bottom: 15px;
        }

        .add-integrations-link {
            float: none;
        }
    }

    .backstage-list__item {
        display: block;

        .item-actions,
        .actions {
            margin-top: 15px;
            padding: 0;
        }
    }

    .app__body {
        .modal {
            .settings-modal {
                &.display--content {
                    .modal-body {
                        max-height: 90%;
                    }
                }

                .modal-body {
                    max-height: 70%;
                    padding-bottom: 0;
                }

                .settings-table {
                    .security-links {
                        display: block;
                        margin-bottom: 10px;

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }

    .tip-overlay.tip-overlay--sidebar {
        min-height: 350px;
    }

    .member-div {
        padding: 8px 0;

        .member-drop,
        .member-role {
            margin: 0 0 0 44px;
            padding: 5px 0;
            position: relative;
            right: 0;
            top: 0;
        }

        .open>.dropdown-menu {
            left: 0;
            right: auto;
        }
    }

    .sidebar--left {
        transform: translate3d(-260px, 0, 0);
        width: 260px;

        .nav-pills__unread-indicator {
            width: 230px;
        }
    }

    .inner-wrap {
        &.move--right {
            transform: translate3d(0, 0, 0);
        }
    }


}

@media screen and (max-height: 640px) {
    .signup-team__container {
        font-size: .9em;
        margin-bottom: 30px;

        .signup-team__name {
            font-size: 2em;
        }
    }
}

@media screen and (max-width: 803px) {
    .signup-team__container {
        .terms-of-service-error__height--fill {
            min-height: calc(100vh - 121px - 4em);
            height: calc(100vh - 121px - 4em);
        }
    }
}

@media screen and (max-width: 449px) {
    .signup-team__container {
        .terms-of-service-error__height--fill {
            min-height: calc(100vh - 138px - 4em);
            height: calc(100vh - 138px - 4em);
        }
    }
}

@media screen and (max-width: 351px) {

    .signup-team__container {
        .terms-of-service-error__height--fill {
            min-height: calc(100vh - 145px - 4em);
            height: calc(100vh - 145px - 4em);
        }
    }
}

@media screen and (max-width: 263px) {
    .signup-team__container {
        .terms-of-service-error__height--fill {
            min-height: calc(100vh - 173px - 4em);
            height: calc(100vh - 173px - 4em);
        }

        .terms-of-service__height--fill {
            min-height: calc(100vh - 100px - 3em);
            height: calc(100vh - 100px - 3em);
        }
    }
}

@media screen and (max-width: 350px) {
    .post-attachment-dropdown {
        &:after {
            left: auto;
            right: 12px;
        }
    }
}

@media screen and (max-width: 320px) {
    .filter-row {
        .Select--multi {
            .Select-multi-value-wrapper {
                display: block;
                max-height: 50px;
                overflow: auto;
            }
        }

        .Select-input {
            >input {
                padding: 8px 0;
            }
        }
    }

    .post {
        &.post--system {
            .post__header {
                padding: 0;
            }
        }

    }

    .multi-teams {
        .sidebar--left {
            width: 220px;

            .nav-pills__unread-indicator {
                width: 190px;
            }
        }
    }

    .post {
        .post__header {
            .col__name {
                .user-popover {
                    max-width: 105px;
                }
            }
        }
    }



    .tip-overlay {
        &.tip-overlay--sidebar {
            min-height: 440px;
        }
    }
}

@media screen and (max-width: 380px) and (max-height: 580px) {
    #navbar {
        .navbar-default {
            .dropdown-menu {
                padding-top: 1em;

                >li {
                    >a {
                        border: none;
                        font-size: 13px;
                        line-height: 27px;
                    }
                }
            }
        }
    }
}



// on iOS, allow clicks within an input's label to actually propagate through to the input itself,
// but still allow clicks to a elements to go trough
// http://stackoverflow.com/a/34810294/6325807
label {
    span {
        pointer-events: none;
    }

    span a {
        pointer-events: all;
    }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
    select,
    textarea,
    input {
        font-size: 16px;
    }
}

.mobile-main-menu {
    .Menu {
        &.dropdown-menu {
            width: 100%;
            position: relative;
            background: transparent;
            padding-left: 0;
            list-style: none;
            margin-bottom: 0;
            max-height: inherit;
            max-width: inherit;
            border: 0px;
            padding-top: 0px;
            box-shadow: none;

            button,
            a,
            a:hover {
                color: $white;
                opacity: .8;
            }
            .MenuItem {
                >button,
                >a {
                    padding: 3px 16px;
                    font-size: 15px;
                    line-height: 32px;
                }
            }
            .MenuGroup {
                &.menu-divider {
                    background: $white;
                }
            }
        }
    }
}
