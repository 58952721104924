#ldap_groups {
    overflow: visible;
}
.groups-list {

    padding: .8em 1.5rem;

    .groups-list--global-actions {
        position: relative;
        display: flex;
        background: $white;
        padding: 8px 20px;
        .group-list-search {
            display: flex;
            flex-grow: 1;
            stroke: $dark-gray;
            position: relative;
            border: 1px solid rgba(0, 0, 0, .1);
            border-radius: 15px;
            height: 30px;
            input {
                border: 0px;
                margin-left: 25px;
                margin-right: 15px;
                font-size: .95em;
                width: 100%;
            }
            input:focus {
                outline: none;
            }
            margin-top: 2px;
            .group-filter-action {
                padding: 8px 10px 0px 0px;
                &.hidden {
                    visibility: hidden;
                }
            }
        }
        .group-list-link-unlink {
            display: flex;
            flex-grow: 1;
            justify-content: flex-end;
        }
        .group-search-filters {
            position: absolute;
            width: 303px;
            background-color: white;
            top: 40px;
            border: 1px solid rgba(0, 0, 0, .1);
            padding: 20px;
            .search-groups-btn {
                float: right;
                bottom: 20px;
                right: 20px;
            }
            .cancel-filters {
                float: right;
            }
            .filter-row {
                height: 30px;
                vertical-align: middle;
            }
            .filter-check {
                border-radius: 3px;
                border: 2px solid $dark-gray;
                height: 18px;
                width: 18px;
                display: inline-block;
                margin-right: 7px;
                vertical-align: middle;
                &.checked {
                    border: 0;
                    svg {
                        background: $white;
                        fill: $primary-color;
                    }
                }
            }
        }
    }
    .groups-list--header {
        border-bottom: solid 1px rgba(0,0,0,0.1);
        display: flex;
        flex-direction: row;
        font-size: 1.1em;
        font-weight: bold;
        padding: .5em 0;

        .group-name {
            margin-left: 16px;
        }

        .group-description {
            color: $black;
            display: block;
            width: 150px;
            opacity: 1;
        }

        .group-actions {
            width: 130px;
        }
    }

    .groups-list--body {
        background: $bg--gray;
        cursor: pointer;
        .groups-list-loading {
            text-align: center;
            padding: 40px;
        }
        .groups-list-empty {
            text-align: center;
            padding: 20px;
            font-size: 1.5em;
            background: alpha-color($white, .5);
            color: $gray;
        }
    }

    .groups-list--footer {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        background: $white;
        padding: 5px;
        height: auto;
        .btn-link {
            color: $dark-gray;
            &.disabled {
                opacity: .5;
            }
        }
        .counter{
            color: $dark-gray;
            font-size: 1.1em;
            margin-bottom: 2px;
        }
    }

    .group-check {
        align-items: center;
        border-radius: 3px;
        border: 2px solid $dark-gray;
        display: flex;
        justify-content: space-around;
        margin: 5px 5px 4px 14px;
        min-height: 18px;
        min-width: 18px;

        &.checked {
            border: 0;

            svg {
                background: $white;
                fill: $primary-color;
            }
        }
    }

    .group-name {
        display: inline-block;
        min-width: 300px;
        flex-grow: 3;
    }

    .group-description {
        align-items: center;
        display: flex;
        height: 100%;
        overflow: hidden;
        opacity: .65;
        width: 150px;
        i {
            margin-right: 5px;
        }
        > a {
            color: $black;
            text-decoration: none;
            padding: 3px 5px;
            &:hover {
                background-color: $light-gray;
                border-radius: 3px;
            }
            &.warning {
                color: $red;
            }
        }
    }

    .group-actions {
        width: 130px;
        text-align: right;
        padding-right: 20px;
    }

    .group {
        &.checked {
            background-color: lighten($primary-color, 40%);
            .group-description {
                opacity: 1;
            }
        }
        &:nth-child(even) {
            background: alpha-color($white, .5)
        }
        &.checked:nth-child(even) {
            background-color: lighten($primary-color, 40%);
        }
        .group-row {
            .group-name {
                min-width: 276px;
            }
        }
    }

    .group-row {
        align-items: center;
        border: 2px solid $transparent;
        display: flex;
        flex-direction: row;
        height: 34px;

        &:hover {
            background-color: lighten($primary-color, 45%);
            border-left: 2px solid $primary-color;
            .group-description {
                opacity: 1;
            }
        }

        &.selected {
            animation-duration: 3s;
            animation-name: rowhighlight;
        }
    }
}

.group-profile {
    padding: 15px;
    .group-profile-field {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        label {
            flex-grow: 1;
            width: 33.3%;
        }
        .form-control {
            flex-grow: 3;
            width: 100%;
        }
    }
}

.group-users {
    .group-users--header {
        background: #333;
        color: $white;
        padding: 10px 20px;
        a {
            color: $white;
            text-decoration: underline;
        }
    }
    .group-users--body {
        margin: 15px 15px 0 15px;
        position: relative;
        .group-users-loading {
            display: none;
            text-align: center;
            width: 100%;
            height: 100%;
            position: absolute;
            background: $light-gray;
            &.active {
                display: flex;
                align-items: center;
                justify-content: center;
                .fa-spinner {
                    max-width: 10%;
                }
            }
        }
        .group-users-empty {
            text-align: center;
            padding: 20px;
            font-size: 1.5em;
            background: alpha-color($white, .5);
            color: $gray;
        }
        .group-users-row {
            &:nth-child(even) {
                background: alpha-color($white, .5)
            }
            display: flex;
            align-items: center;
            padding: 10px 15px;
            .profile-picture {
                width: 32px;
                height: 32px;
                margin: 5px 10px;
                border-radius: 100%;
            }
            .user-data {
                .name-row {
                    display: flex;
                }
                .display-name, .email {
                    color: $gray;
                }
            }
            .email-label {
                margin-right: 5px;
            }
        }
    }
    .group-users--footer {
        margin: 0 15px 15px 15px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        background: $white;
        padding: 5px;
        height: auto;
        &.empty {
            border-top: 0;
            padding: 0;
            height: 0;
        }
        .btn-link {
            color: $dark-gray;
            &.disabled {
                opacity: .5;
            }
        }
        .counter{
            color: $dark-gray;
            font-size: 1.1em;
            margin-bottom: 2px;
        }
    }

}

.group-teams-and-channels {
    padding: 15px;
    .group-teams-and-channels-loading {
        text-align: center;
        padding: 40px;
    }
    .group-teams-and-channels-empty {
        text-align: center;
        padding: 20px;
        font-size: 1.5em;
        background: alpha-color($white, .5);
        color: $gray;
    }
    .group-teams-and-channels--header {
        font-size: 1.1em;
        font-weight: bold;
        padding: 5px 20px;
        border-bottom: solid 1px rgba(0,0,0,0.1);
    }
    .group-teams-and-channels--body {
        .group-teams-and-channels-row {
            display: flex;
            padding: 5px;
            align-items: center;
            &:nth-child(even) {
                background: alpha-color($white, .5)
            }
            .arrow-icon {
                margin-left: 10px;
                .fa-caret-right {
                    padding: 5px;
                    cursor: pointer;
                }
                .open {
                    transform: rotate(90deg);
                    transform-origin: 55% 50%;
                    transition: transform 300ms;
                }
            }
            .team-icon, .channel-icon {
                width: 16px;
                height: 16px;
                margin: 0 5px;
                color: inherit;
                opacity: .7;
            }
            .team-icon-private {
                font-size: .35em;
            }
            .actions {
                button {
                    padding: 5px;
                }
            }
            .name {
                width: 100%;
            }
            .remove {
                height: 30px;
            }
        }
        .teams-list {
            .teams-list--header {
                font-weight: bold;
                display: flex;
                font-size: 1.1em;
                border-bottom: 1px solid $dark-gray;
                justify-content: space-between;
            }
        }
    }
}

.group-profile-add-menu {
    position: relative;
    margin-left: 10px;
    margin-top: -36px;
    .fa-caret-down {
        margin-left: 5px;
    }
    .add-team-or-channel-menu {
        position: absolute;
        top: 33px;
        border: 1px solid $gray;
        background: $white;
        border-radius: 2px;
        width: 100%;
        padding: 5px 8px;
        a {
            display: block;
            color: $dark-gray;
            padding: 7px;
        }
    }
}
