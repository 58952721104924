@charset 'UTF-8';


.sidebar-chat__switcher {
    display: flex;
    background: white;
    border-bottom: 1px solid gainsboro;
    padding: 12.5px;
}


.sidebar-chat__item {
    border-bottom:0.5px solid gainsboro;
    font-size: small;
    font-weight: normal;

    &.item--active {
        background: #e8e8e8;
    }

    &.item--unread {
        font-weight: 600;
    }

    &.item--closed * {
        color: #9a9a9a;
    }

}

.sidebar-chat__item:hover {
    background: #efefef;
}

.sidebar-chat__name {
    display: flex;
    font-weight: bold;
    color: #333;
    justify-content: space-between;
}

.sidebar-chat__badge {
    text-align: center;
    background: #617281;
    color: #fff;
    border: 1px solid #fff;
    border-radius: 18px;
    font-size: 10pt;
    line-height: 10pt;
    padding: 5px;
    transition: 1s;
    bottom:50%;
    left:92%;
    position: absolute;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}

.sidebar-chat__last-item {
    display: flex;
    justify-content: space-between;
}

.sidebar-chat__last-item_message {
    color: #333;
    text-overflow: ellipsis;
    max-width: 70%;
    overflow: hidden;
    word-break: normal;
    font-size: 9pt;
}
