.SidebarHeaderDropdownButton {
    display: flex;
    position: relative;
    align-items: center;

    .header__info {
        @include clearfix;
        flex-grow:1;
        color: $white;
        padding: 3px 15px 0 8px;
        position: relative;
        width: 100%;
        .team__name {
            font-size: 14px;
            font-weight: 600;
            max-width: 100%;
            overflow: hidden;
            text-decoration: none;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        .user__name {
            font-weight: 400;
        }
    }

    .menu-icon {
        opacity:.8;
        fill: $white;
    }

    &:hover {
        .user__name,
        .menu-icon {
            opacity: 1;
        }
    }
}
