@charset 'UTF-8';

.product-picker {
    max-width: 1020px;
}
.product-picker_wrapper{
    display: flex;
    flex-direction: row;
    height: 500px;
    .catalog--tree {
        height: 100%;
        position: absolute;
        left: auto;
        display: flex;
        flex-direction: column;
        flex-basis: 100%;
        flex: 1;
    }
    .catalog--list {
        height: 100%;
        position: absolute;
        left: auto;
        display: flex;
        flex-direction: column;
        flex-basis: 100%;
        flex: 3;
        .catalog--list__list {
            height: 100%;
        }
    }
}

.product-picker__panel {
    &.panel {
        &_base {
            display: flex;

        }
    } 
}

.product-picker__tags {
    margin-bottom: 35px;
}

.product-picker__button {
    margin: 10px 0px 10px 0px;
}

.product-picker__panel {
    margin: 10px 0px 10px 0px;
}

.products-list {
    &.products-list {

        &_base {
            overflow: auto;
            height: 100%;
        }

    }
}
