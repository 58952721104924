@charset 'UTF-8';

.footer-pane {
    background: $bg--gray;
    padding-bottom: 1em;

    .footer-link {
        padding: 0 1.5em;

        &.copyright {
            color: $dark-gray;
            padding-right: 0;
        }
    }

    .footer-site-name {
        font-size: 14px;
        font-weight: bold;
        padding: 1.5em 0 1em;
        text-transform: uppercase;
    }
}

.footer,
.footer-pane, {
    height: 89px;
}


