@charset 'UTF-8';

b,
strong {
    font-weight: 600;
}

a {
    color: $primary-color;
    cursor: pointer;
    text-decoration: none;
    word-break: break-word;

    &:focus,
    &:hover {
        color: $primary-color--hover;
    }
}

.color--link {
    color: $link-color;
    cursor: pointer;

    &:hover,
    &:focus {
        text-decoration: underline;
    }
}
@mixin font-smoothing($value: antialiased) {
    @if $value == antialiased {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    @else {
        -webkit-font-smoothing: subpixel-antialiased;
        -moz-osx-font-smoothing: auto;
    }
}
body {
    @include font-smoothing;
    font-family: 'Inter', sans-serif;
}

.is-empty:empty {
    display: none;
}

label {
    &.has-error {
        font-weight: normal;
        color: $red;
    }
}

.small {
    font-size: 12px;
}

.light {
    opacity: 0.6;
}

.word-break--all {
    word-break: break-all;
}

.whitespace--nowrap {
    white-space: nowrap;
}

.whitespace--normal {
    white-space: normal;
}

.overflow--ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
}

ul,
ol {
    margin-bottom: .11em;
    margin-top: 3px;
    padding-left: 22px;
}

.fa {
    &.fa-margin--left {
        margin-left: 3px;
    }

    &.fa-margin--right {
        margin-right: 3px;
    }

    &.fa-1x {
        font-size: 1.6rem;
    }
}

.font-weight--normal {
    font-weight: normal;
}

.cursor--pointer {
    cursor: pointer;
}

@font-face {
    font-family: 'Roboto';
    font-style:  normal;
    font-weight: 100;
    src: url("../../fonts/Roboto.ttf") format("ttf"),
    url("../../fonts/Roboto.ttf") format("ttf");
}
@font-face {
    font-family: 'Inter';
    font-style:  normal;
    font-weight: 100;
    src: url("../../fonts/Inter-Thin-BETA.woff2") format("woff2"),
    url("../../fonts/Inter-Thin-BETA.woff") format("woff");
}
@font-face {
    font-family: 'Inter';
    font-style:  italic;
    font-weight: 100;
    src: url("../../fonts/Inter-ThinItalic-BETA.woff2") format("woff2"),
    url("../../fonts/Inter-ThinItalic-BETA.woff") format("woff");
}

@font-face {
    font-family: 'Inter';
    font-style:  normal;
    font-weight: 200;
    src: url("../../fonts/Inter-ExtraLight-BETA.woff2") format("woff2"),
    url("../../fonts/Inter-ExtraLight-BETA.woff") format("woff");
}
@font-face {
    font-family: 'Inter';
    font-style:  italic;
    font-weight: 200;
    src: url("../../fonts/Inter-ExtraLightItalic-BETA.woff2") format("woff2"),
    url("../../fonts/Inter-ExtraLightItalic-BETA.woff") format("woff");
}

@font-face {
    font-family: 'Inter';
    font-style:  normal;
    font-weight: 300;
    src: url("../../fonts/Inter-Light-BETA.woff2") format("woff2"),
    url("../../fonts/Inter-Light-BETA.woff") format("woff");
}
@font-face {
    font-family: 'Inter';
    font-style:  italic;
    font-weight: 300;
    src: url("../../fonts/Inter-LightItalic-BETA.woff2") format("woff2"),
    url("../../fonts/Inter-LightItalic-BETA.woff") format("woff");
}

@font-face {
    font-family: 'Inter';
    font-style:  normal;
    font-weight: 400;
    src: url("../../fonts/Inter-Regular.woff2") format("woff2"),
    url("../../fonts/Inter-Regular.woff") format("woff");
}
@font-face {
    font-family: 'Inter';
    font-style:  italic;
    font-weight: 400;
    src: url("../../fonts/Inter-Italic.woff2") format("woff2"),
    url("../../fonts/Inter-Italic.woff") format("woff");
}

@font-face {
    font-family: 'Inter';
    font-style:  normal;
    font-weight: 500;
    src: url("../../fonts/Inter-Medium.woff2") format("woff2"),
    url("../../fonts/Inter-Medium.woff") format("woff");
}
@font-face {
    font-family: 'Inter';
    font-style:  italic;
    font-weight: 500;
    src: url("../../fonts/Inter-MediumItalic.woff2") format("woff2"),
    url("../../fonts/Inter-MediumItalic.woff") format("woff");
}

@font-face {
    font-family: 'Inter';
    font-style:  normal;
    font-weight: 600;
    src: url("../../fonts/Inter-SemiBold.woff2") format("woff2"),
    url("../../fonts/Inter-SemiBold.woff") format("woff");
}
@font-face {
    font-family: 'Inter';
    font-style:  italic;
    font-weight: 600;
    src: url("../../fonts/Inter-SemiBoldItalic.woff2") format("woff2"),
    url("../../fonts/Inter-SemiBoldItalic.woff") format("woff");
}

@font-face {
    font-family: 'Inter';
    font-style:  normal;
    font-weight: 700;
    src: url("../../fonts/Inter-Bold.woff2") format("woff2"),
    url("../../fonts/Inter-Bold.woff") format("woff");
}
@font-face {
    font-family: 'Inter';
    font-style:  italic;
    font-weight: 700;
    src: url("../../fonts/Inter-BoldItalic.woff2") format("woff2"),
    url("../../fonts/Inter-BoldItalic.woff") format("woff");
}

@font-face {
    font-family: 'Inter';
    font-style:  normal;
    font-weight: 800;
    src: url("../../fonts/Inter-ExtraBold.woff2") format("woff2"),
    url("../../fonts/Inter-ExtraBold.woff") format("woff");
}
@font-face {
    font-family: 'Inter';
    font-style:  italic;
    font-weight: 800;
    src: url("../../fonts/Inter-ExtraBoldItalic.woff2") format("woff2"),
    url("../../fonts/Inter-ExtraBoldItalic.woff") format("woff");
}

@font-face {
    font-family: 'Inter';
    font-style:  normal;
    font-weight: 900;
    src: url("../../fonts/Inter-Black.woff2") format("woff2"),
    url("../../fonts/Inter-Black.woff") format("woff");
}
@font-face {
    font-family: 'Inter';
    font-style:  italic;
    font-weight: 900;
    src: url("../../fonts/Inter-BlackItalic.woff2") format("woff2"),
    url("../../fonts/Inter-BlackItalic.woff") format("woff");
}

/* -------------------------------------------------------
Variable font.
Usage:

  html { font-family: 'Inter', sans-serif; }
  @supports (font-variation-settings: normal) {
    html { font-family: 'Inter var', sans-serif; }
  }
*/
@font-face {
    font-family: 'Inter var';
    font-weight: 100 900;
    font-style: normal;
    font-named-instance: 'Regular';
    src: url("../../fonts/Inter-upright.var.woff2") format("woff2");
}
@font-face {
    font-family: 'Inter var';
    font-weight: 100 900;
    font-style: italic;
    font-named-instance: 'Italic';
    src: url("../../fonts/Inter-italic.var.woff2") format("woff2");
}


/* --------------------------------------------------------------------------
[EXPERIMENTAL] Multi-axis, single variable font.

Slant axis is not yet widely supported (as of February 2019) and thus this
multi-axis single variable font is opt-in rather than the default.

When using this, you will probably need to set font-variation-settings
explicitly, e.g.

  * { font-variation-settings: "slnt" 0deg }
  .italic { font-variation-settings: "slnt" 10deg }

*/
@font-face {
    font-family: 'Inter var experimental';
    font-weight: 100 900;
    font-style: oblique 0deg 10deg;
    src: url("../../fonts/Inter.var.woff2") format("woff2");
}

/* open-sans-300 - latin-ext_latin_cyrillic-ext_greek-ext_greek_cyrillic_vietnamese */
/*

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    src: url('../fonts/open-sans-v13-latin-ext_latin_cyrillic-ext_greek-ext_greek_cyrillic_vietnamese-300.eot');
    !* IE9 Compat Modes *!
    src: local('Open Sans Light'), local('OpenSans-Light'),
    url('../fonts/open-sans-v13-latin-ext_latin_cyrillic-ext_greek-ext_greek_cyrillic_vietnamese-300.eot?#iefix') format('embedded-opentype'), !* IE6-IE8 *!
    url('../fonts/open-sans-v13-latin-ext_latin_cyrillic-ext_greek-ext_greek_cyrillic_vietnamese-300.woff2') format('woff2'), !* Super Modern Browsers *!
    url('../fonts/open-sans-v13-latin-ext_latin_cyrillic-ext_greek-ext_greek_cyrillic_vietnamese-300.woff') format('woff'), !* Modern Browsers *!
    url('../fonts/open-sans-v13-latin-ext_latin_cyrillic-ext_greek-ext_greek_cyrillic_vietnamese-300.ttf') format('truetype'), !* Safari, Android, iOS *!
    url('../fonts/open-sans-v13-latin-ext_latin_cyrillic-ext_greek-ext_greek_cyrillic_vietnamese-300.svg#OpenSans') format('svg');
    !* Legacy iOS *!
}

!* open-sans-300italic - latin-ext_latin_cyrillic-ext_greek-ext_greek_cyrillic_vietnamese *!

@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 300;
    src: url('../fonts/open-sans-v13-latin-ext_latin_cyrillic-ext_greek-ext_greek_cyrillic_vietnamese-300italic.eot');
    !* IE9 Compat Modes *!
    src: local('Open Sans Light Italic'), local('OpenSansLight-Italic'),
    url('../fonts/open-sans-v13-latin-ext_latin_cyrillic-ext_greek-ext_greek_cyrillic_vietnamese-300italic.eot?#iefix') format('embedded-opentype'), !* IE6-IE8 *!
    url('../fonts/open-sans-v13-latin-ext_latin_cyrillic-ext_greek-ext_greek_cyrillic_vietnamese-300italic.woff2') format('woff2'), !* Super Modern Browsers *!
    url('../fonts/open-sans-v13-latin-ext_latin_cyrillic-ext_greek-ext_greek_cyrillic_vietnamese-300italic.woff') format('woff'), !* Modern Browsers *!
    url('../fonts/open-sans-v13-latin-ext_latin_cyrillic-ext_greek-ext_greek_cyrillic_vietnamese-300italic.ttf') format('truetype'), !* Safari, Android, iOS *!
    url('../fonts/open-sans-v13-latin-ext_latin_cyrillic-ext_greek-ext_greek_cyrillic_vietnamese-300italic.svg#OpenSans') format('svg');
    !* Legacy iOS *!
}

!* open-sans-regular - latin-ext_latin_cyrillic-ext_greek-ext_greek_cyrillic_vietnamese *!

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/open-sans-v13-latin-ext_latin_cyrillic-ext_greek-ext_greek_cyrillic_vietnamese-regular.eot');
    !* IE9 Compat Modes *!
    src: local('Open Sans'), local('OpenSans'),
    url('../fonts/open-sans-v13-latin-ext_latin_cyrillic-ext_greek-ext_greek_cyrillic_vietnamese-regular.eot?#iefix') format('embedded-opentype'), !* IE6-IE8 *!
    url('../fonts/open-sans-v13-latin-ext_latin_cyrillic-ext_greek-ext_greek_cyrillic_vietnamese-regular.woff2') format('woff2'), !* Super Modern Browsers *!
    url('../fonts/open-sans-v13-latin-ext_latin_cyrillic-ext_greek-ext_greek_cyrillic_vietnamese-regular.woff') format('woff'), !* Modern Browsers *!
    url('../fonts/open-sans-v13-latin-ext_latin_cyrillic-ext_greek-ext_greek_cyrillic_vietnamese-regular.ttf') format('truetype'), !* Safari, Android, iOS *!
    url('../fonts/open-sans-v13-latin-ext_latin_cyrillic-ext_greek-ext_greek_cyrillic_vietnamese-regular.svg#OpenSans') format('svg');
    !* Legacy iOS *!
}

!* open-sans-italic - latin-ext_latin_cyrillic-ext_greek-ext_greek_cyrillic_vietnamese *!

@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 400;
    src: url('../fonts/open-sans-v13-latin-ext_latin_cyrillic-ext_greek-ext_greek_cyrillic_vietnamese-italic.eot');
    !* IE9 Compat Modes *!
    src: local('Open Sans Italic'), local('OpenSans-Italic'),
    url('../fonts/open-sans-v13-latin-ext_latin_cyrillic-ext_greek-ext_greek_cyrillic_vietnamese-italic.eot?#iefix') format('embedded-opentype'), !* IE6-IE8 *!
    url('../fonts/open-sans-v13-latin-ext_latin_cyrillic-ext_greek-ext_greek_cyrillic_vietnamese-italic.woff2') format('woff2'), !* Super Modern Browsers *!
    url('../fonts/open-sans-v13-latin-ext_latin_cyrillic-ext_greek-ext_greek_cyrillic_vietnamese-italic.woff') format('woff'), !* Modern Browsers *!
    url('../fonts/open-sans-v13-latin-ext_latin_cyrillic-ext_greek-ext_greek_cyrillic_vietnamese-italic.ttf') format('truetype'), !* Safari, Android, iOS *!
    url('../fonts/open-sans-v13-latin-ext_latin_cyrillic-ext_greek-ext_greek_cyrillic_vietnamese-italic.svg#OpenSans') format('svg');
    !* Legacy iOS *!
}

!* open-sans-600 - latin-ext_latin_cyrillic-ext_greek-ext_greek_cyrillic_vietnamese *!

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    src: url('../fonts/open-sans-v13-latin-ext_latin_cyrillic-ext_greek-ext_greek_cyrillic_vietnamese-600.eot');
    !* IE9 Compat Modes *!
    src: local('Open Sans Semibold'), local('OpenSans-Semibold'),
    url('../fonts/open-sans-v13-latin-ext_latin_cyrillic-ext_greek-ext_greek_cyrillic_vietnamese-600.eot?#iefix') format('embedded-opentype'), !* IE6-IE8 *!
    url('../fonts/open-sans-v13-latin-ext_latin_cyrillic-ext_greek-ext_greek_cyrillic_vietnamese-600.woff2') format('woff2'), !* Super Modern Browsers *!
    url('../fonts/open-sans-v13-latin-ext_latin_cyrillic-ext_greek-ext_greek_cyrillic_vietnamese-600.woff') format('woff'), !* Modern Browsers *!
    url('../fonts/open-sans-v13-latin-ext_latin_cyrillic-ext_greek-ext_greek_cyrillic_vietnamese-600.ttf') format('truetype'), !* Safari, Android, iOS *!
    url('../fonts/open-sans-v13-latin-ext_latin_cyrillic-ext_greek-ext_greek_cyrillic_vietnamese-600.svg#OpenSans') format('svg');
    !* Legacy iOS *!
}

!* open-sans-600italic - latin-ext_latin_cyrillic-ext_greek-ext_greek_cyrillic_vietnamese *!

@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 600;
    src: url('../fonts/open-sans-v13-latin-ext_latin_cyrillic-ext_greek-ext_greek_cyrillic_vietnamese-600italic.eot');
    !* IE9 Compat Modes *!
    src: local('Open Sans Semibold Italic'), local('OpenSans-SemiboldItalic'),
    url('../fonts/open-sans-v13-latin-ext_latin_cyrillic-ext_greek-ext_greek_cyrillic_vietnamese-600italic.eot?#iefix') format('embedded-opentype'), !* IE6-IE8 *!
    url('../fonts/open-sans-v13-latin-ext_latin_cyrillic-ext_greek-ext_greek_cyrillic_vietnamese-600italic.woff2') format('woff2'), !* Super Modern Browsers *!
    url('../fonts/open-sans-v13-latin-ext_latin_cyrillic-ext_greek-ext_greek_cyrillic_vietnamese-600italic.woff') format('woff'), !* Modern Browsers *!
    url('../fonts/open-sans-v13-latin-ext_latin_cyrillic-ext_greek-ext_greek_cyrillic_vietnamese-600italic.ttf') format('truetype'), !* Safari, Android, iOS *!
    url('../fonts/open-sans-v13-latin-ext_latin_cyrillic-ext_greek-ext_greek_cyrillic_vietnamese-600italic.svg#OpenSans') format('svg');
    !* Legacy iOS *!
}
*/

.app__body.font--open_sans {
    //font-family: 'Roboto', sans-serif;
}

