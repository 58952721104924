@charset 'UTF-8';

.file-preview__container {
    display: flex;
    flex-shrink: 0;
    flex-wrap: nowrap;
    height: 115px;
    margin: 1px 0 5px;
    overflow-x: auto;
    overflow-y: hidden;
    overflow: auto;
    position: relative;
    white-space: nowrap;
    width: 100%;
}

.file-preview {
    @include clearfix;
    flex-shrink: 0;
    flex-wrap: nowrap;
    border: 1px solid $light-gray;
    display: inline-block;
    height: 100px;
    margin-right: 10px;
    position: relative;
    width: 120px;

    .spinner {
        height: 32px;
        left: 50%;
        margin-left: -16px;
        margin-top: -16px;
        position: absolute;
        top: 50%;
        width: 32px;
    }
}

.file-preview__remove {
    background: alpha-color($black, .5);
    border-radius: 0 0 0 2px;
    height: 28px;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    width: 28px;

    i {
        color: $white;
        cursor: pointer;
        font-size: 16px;
        line-height: 29px;
        position: relative;
        right: auto;
        text-shadow: 0 0 3px alpha-color($black, .7);
        top: auto;
        z-index: 5;
    }
}

.image-comment {
    background-position: left top;
    background-repeat: no-repeat;
    height: 300px;
    width: 300px;
}


.file-icon {
    height: 100%;
    width: 100%;

    &.audio {
        @include file-icon('../../images/icons/audio.svg');
    }

    &.video {
        @include file-icon('../../images/icons/video.svg');
    }

    &.ppt {
        @include file-icon('../../images/icons/ppt.svg');
    }

    &.generic {
        @include file-icon('../../images/icons/generic.svg');
    }

    &.code {
        @include file-icon('../../images/icons/code.svg');
    }

    &.excel {
        @include file-icon('../../images/icons/excel.svg');
    }

    &.word {
        @include file-icon('../../images/icons/word.svg');
    }

    &.pdf {
        @include file-icon('../../images/icons/pdf.svg');
    }

    &.patch {
        @include file-icon('../../images/icons/patch.png');
    }

    &.image {
        @include file-icon('../../images/icons/image.svg');
    }
}

.pdf-preview-spacer {
    height: 5px;
}

.pdf-max-pages {
    background: $white;
    bottom: 0;
    display: block;
    left: 0;
    position: relative;
    width: 100%;

    span {
        border-radius:3px;
        background: alpha-color($black, .8);
        bottom: 15px;
        color: $white;
        display: inline-block;
        height: 3em;
        line-height: 3em;
        padding: 0 1.5em;
        position: relative;
    }
}

.file-view--single {
    margin: 5px 0;
    position: relative;

    .file__image {
        @include legacy-pie-clearfix;

        .image-name {
            display: inline-block;
            font-size: 14px;
            font-weight: bold;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100%;

            div {
                display: inline;
                margin-left: 5px;
            }
        }

        .image-container {
            @include legacy-pie-clearfix;
            position: relative;
            max-width: 1024px;
            width: 100%;
            height: 100%;
        }

        .image-preload {
            position: absolute;
            z-index: 1;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            display: flex;
            justify-content: center;
            align-items: center;

            .fa {
                font-size: 20px;
                opacity: .6;
            }
        }

        .image-loaded {

            border-radius:4px;
            bottom: 0;
            min-height: 40px;
            min-width: 40px;
            max-height: 350px;
            max-width: 100%;

            img {
                border-radius:4px;

                align-self: center;
                cursor: pointer;
                max-height: 350px;
                overflow: hidden;
                position: relative;
                z-index: 2;
                &:hover {
                    box-shadow:(0 2px 5px 0 rgba($black, 0.1), 0 2px 10px 0 rgba($black, 0.1));
                }
                &.min-preview {
                    max-height: 50px;
                    max-width: 100%;
                }

                &.min-preview--portrait {
                    max-height: initial;
                    max-width: 50px;
                }
            }
            &.svg {
                  width: 100%;
                  height: inherit;
                  display: block;
                img {
                    height: inherit;
                    &:hover {
                        box-shadow:0 2px 5px 0 rgba($black, 0.1), 0 2px 10px 0 rgba($black, 0.1);
                    }
               }
            }
        }

        .image-fade-in {
            opacity: 1;
        }
    }

    .file-details__name {
        display: block;
        font-size: 14px;
        font-weight: bold;
    }

    .file-details__name:hover {
        cursor: pointer;
        text-decoration: underline;
    }
}

.post-image__columns {
    display: flex;
    flex-flow: wrap;
    position: relative;
}

.post-image__column {
    border-radius:4px;
    align-items: center;
    border: 1px solid alpha-color($black, .1);
    display: flex;
    height: 100px;
    margin: 5px 10px 5px 0;
    max-width: 100%;
    overflow: hidden;
    position: relative;
    width: 320px;

    .post__content & {
        cursor: pointer;
    }

    &--placeholder {
        visibility: hidden;
    }

    &.file-preview {
        flex-shrink: 0;
        flex-wrap: nowrap;
        margin: 0 10px 0 0;

        .post-image__details {
            .post-image__name {
                white-space: normal;
            }
        }
    }
}

.post-image__load {
    background-position: center;
    background-repeat: no-repeat;
    background-size: 20px 20px;
    height: 100%;
    width: 100%;
}

.post-image {
  background-size:cover;
    background-color: $white;
    background-repeat: no-repeat;
    height: 100%;
    overflow: hidden;
    position: relative;
    text-align: center;
    width: 100%;

    &.small {
        background-position: center;
    }

    &.normal {
        background-position: top left;
    }

    .spinner {
        .file__loading {
            left: 50%;
            margin-left: -16px;
            margin-top: -16px;
            position: absolute;
            top: 50%;
        }
    }

    .file__loaded {
        max-width: initial;

        &.landscape,
        &.quadrat {
            height: 100px;
        }

        &.portrait {
            width: 120px;
        }
    }
}

.post-image__thumbnail {
    cursor:zoom-in;
    flex: none;
    height: 100%;
    width: 100px;
}

.post-image__details {
    @include clearfix;
    position: relative;
    align-items: center;
    border-left: 1px solid $light-gray;
    color: alpha-color($black, .8);
    display: flex;
    font-size: 13px;
    height: 100%;
    padding-right: 50px;
    width: 100%;

    .post-image__detail_wrapper {
        padding-left: 15px;
        width: 100%;

        .post--compact & {
            padding-left: 8px;
        }

        .post-image__detail {
            height: 100%;
            line-height: normal;
            width: 100%;

            .post-image__name {
                display: block;
                font-weight: 600;
                margin-bottom: 3px;
                outline: none;
                text-decoration: none;
            }

            .post-image__type {
                opacity: 0.6;
            }

            .post-image__size {
                opacity: 0.6;
                margin-left: 4px;
            }
        }
    }

    .post-image__download {
        align-items: center;
        cursor: pointer;
        display: flex;
        height: 100%;
        justify-content: center;
        line-height: 0;
        padding: 0 5px;
        position: absolute;
        right: 0;
        text-align: center;
        top: 0;
        width: 50px;

        svg {
            opacity: 0.7;;
            border-radius:50%;
            border: 1px solid;
            display: inline-block;
            height: 30px;
            width: 30px;

            &:hover {
                opacity: 1;
            }
        }
    }
    .post-image__progressBar {
        margin-bottom: 0px;
        height: 7px;
        position: absolute;
        bottom: 0px;
        width: 100%;
        margin-left: -15px;
        border-radius: 0px;
    }

    .progress-bar {
        background-color: rgb(40, 90, 185);
    }
    .post-image__uploadingTxt {
        opacity: 0.6;
    }
}

.file-details__container {
    display:flex;
    background: $white;

    .file-details {
        height: 270px;
        padding: 14px;
        text-align: left;
        vertical-align: top;
        width: 320px;

        .file-details__name {
            color: alpha-color($black, .9);
            font-size: 16px;
            margin: 5px 0;
            word-break: break-word;
        }

        .file-details__info {
            color: alpha-color($black, .5);
        }
    }

    .file-details__preview {
        border-right: 1px solid $light-gray;
        height: 270px;
        vertical-align: center;
        width: 320px;

        img {
            width: 80px;
        }

        // helper to center the image icon in the preview window
        .file-details__preview-helper {
            display: inline-block;
            height: 100%;
            vertical-align: middle;
        }
    }
}

.file-playback__controls {

    bottom: 0;
    cursor: pointer;
    font-size: 22px;
    position: absolute;
    right: 5px;
    transition: opacity .6s;
    z-index: 2;

    &.stop {
        opacity: 0;
    }
}

.view-image__loading {
    background: $black;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 140px;
    flex-direction: column;
    padding-top: 28px;

    .fa {
        color: $white;
        font-size: 20px;
        opacity: .6;
    }
}

.image-loading__container {
    max-height: inherit;
    overflow: hidden;
}




.file-preview__container {
    &.product-file-preview__container {
        display: flex;
        flex-shrink: 0;
        flex-wrap: wrap;

        margin: 1px 0 5px;
        overflow-x: auto;
        overflow-y: hidden;
        overflow: auto;
        position: relative;
        white-space: normal;
        width: 100%;
        height: auto;
    }
    &.any-file-preview__container {
        display: flex;
        flex-shrink: 0;
        flex-wrap: wrap;

        margin: 1px 0 5px;
        overflow-x: auto;
        overflow-y: hidden;
        overflow: auto;
        position: relative;
        white-space: normal;
        width: 100%;
        height: 130px;
    }
}
.product-image__columns {
    display: flex;
    flex-flow: wrap;
    position: relative;
}

.product-image__column {

   border-radius:6px;
    align-items: center;
    border: none;
    //display: flex;
    //height: 100px;
    margin: 5px 10px 5px 0;
    max-width: 100%;
    overflow: hidden;
    position: relative;
    padding: 10px;
    flex-shrink: 0;
    flex-wrap: nowrap;

    display: block;
    flex: none;

    &.fixed {
        display: fixed;
    }

    .file-preview__remove {
        background: none;
        border-radius: 0 0 0 2px;
        height: 28px;
        position: absolute;
        right: 0;
        text-align: center;
        top: 0;
        width: 28px;
        i {
            color: #63AEF4;
            cursor: pointer;
            font-size: 16px;
            line-height: 29px;
            position: relative;
            right: auto;
            text-shadow:  none;
            top: auto;
            z-index: 5;
        }
    }

    &.file-upload-button {
        flex-shrink: 0;
        flex-wrap: nowrap;
        margin: 5px 10px 5px 0;
        width: 120px;
        height: 120px;
        padding: 0;
        display: flex;
        border: 1px solid gainsboro;
        border-radius: 12px;
        overflow: hidden;
        align-items: center;
        cursor: pointer;

        &.fixed {
            position: absolute;
        }

        &:hover {
            transition: 2;
            box-shadow: inset 0px 2px 2px 0px gainsboro;
        }

        & > span {
            height: 100%;
            width: 100%;
            flex: 1 auto;
            align-items: center;
            vertical-align: middle;
            display: inherit;
        }
        .upload-text {
            text-align: center;
            font-size: 12px;
            color: #63AEF4;
            font-weight: bold;
        }
        label {
            display: block;
            text-align: center;
            cursor: pointer;
        }
        input[type="file"] {
            display: none;
        }

    }
    .product__content & {
        cursor: pointer;
    }

    &--placeholder {
        visibility: hidden;
    }

    &.file-preview {
        flex-shrink: 0;
        flex-wrap: nowrap;
        margin: 5px 10px 5px 0;
        border: none;

        .product-image__details {
            .product-image__name {
                white-space: normal;
            }
        }
    }
}

.product-image__load {
    background-position: center;
    background-repeat: no-repeat;
    background-size: 20px 20px;
    height: 100%;
    width: 100%;
}

.product-image {
    background-size:cover;
    background-color: $white;
    background-repeat: no-repeat;
    height: 100%;
    overflow: hidden;
    position: relative;
    text-align: center;
    width: 100%;

    &.small {
        background-position: center;
    }

    &.normal {
        background-position: center;
    }

    .spinner {
        .file__loading {
            left: 50%;
            margin-left: -16px;
            margin-top: -16px;
            position: absolute;
            top: 50%;
        }
    }

    .file__loaded {
        max-width: initial;

        &.landscape,
        &.quadrat {
            height: 100px;
        }

        &.portrait {
            width: 120px;
        }
    }
}
/*.product-file-preview__container {
    .product-image__thumbnail {
        @include cursor(zoom-in);

        flex: none;
        height: 200px;
        width: 200px;
        margin-bottom: 10px;
    }
}*/
.product-image__thumbnail {
  cursor:zoom-in;

    flex: none;
    height: 100px;
    width: 100px;
    margin-bottom: 10px;
}

.product-image__details {
    @include clearfix;
    position: relative;
    align-items: center;
    border-left: none;
    color: alpha-color($black, .8);
    display: block;
    text-align: center;
    font-size: 13px;
    height: auto;
    padding-right: 0;
    width: 100%;

    .product-image__detail_wrapper {
        padding-left: 0;
        width: 100%;

        .product--compact & {
            padding-left: 8px;
        }

        .product-image__detail {
            height: 100%;
            line-height: normal;
            width: 100%;

            .product-image__name {
                display: block;
                font-weight: 600;
                margin-bottom: 3px;
                outline: none;
                text-decoration: none;
            }

            .product-image__type {
                opacity: 0.6;
            }

            .product-image__size {
                opacity: 0.6;
                margin-left: 0px;
            }
        }
    }

    .product-image__download {
        align-items: center;
        cursor: pointer;
        display: flex;
        height: 100%;
        justify-content: center;
        line-height: 0;
        padding: 0 5px;
        position: absolute;
        right: 0;
        text-align: center;
        top: 0;
        width: 50px;

        svg {
            opacity: 0.7;;

            border-radius:50%;
            border: 1px solid;
            display: inline-block;
            height: 30px;
            width: 30px;

            &:hover {
                opacity: 1;
            }
        }
    }
    .product-image__progressBar {
        margin-bottom: 0px;
        height: 7px;
        position: absolute;
        bottom: 0px;
        width: 100%;
        margin-left: -15px;
        border-radius: 0px;
    }

    .progress-bar {
        background-color: rgb(40, 90, 185);
    }
    .product-image__uploadingTxt {
        opacity: 0.6;
    }
}

.product-file__details {
    @include clearfix;


    .product-file__detail_wrapper {
        padding-left: 0;
        width: 100%;

        .product--compact & {
            padding-left: 8px;
        }

        .product-file__detail {
            height: 100%;
            line-height: normal;
            width: 100%;

            .product-file__name {
                display: block;
                font-weight: 600;
                margin-bottom: 3px;
                outline: none;
                text-decoration: none;
            }

            .product-file__type {
                opacity: 0.6;
            }

            .product-file__size {
                opacity: 0.6;
                margin-left: 0px;
            }
        }
    }

    .product-file__download {
        align-items: center;
        cursor: pointer;
        display: flex;
        height: 100%;
        justify-content: center;
        line-height: 0;
        padding: 0 5px;
        position: absolute;
        right: 0;
        text-align: center;
        top: 0;
        width: 50px;

        svg {
            opacity: 0.7;;

            border-radius:50%;
            border: 1px solid;
            display: inline-block;
            height: 30px;
            width: 30px;

            &:hover {
                opacity: 1;
            }
        }
    }
    .product-file__progressBar {
        margin-bottom: 0px;
        height: 7px;
        position: absolute;
        bottom: 0px;
        width: 100%;
        margin-left: -15px;
        border-radius: 0px;
    }

    .progress-bar {
        background-color: rgb(40, 90, 185);
    }
    .product-file__uploadingTxt {
        opacity: 0.6;
    }
}
