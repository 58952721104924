.form-control__status {
    color: #f75454;
    font-weight: bold;
    margin-top: 5px;
}

.form-control__input {
    max-width: 600px;

    &.input_w-160 {
        width: 160px;
    }
    &.input_w-200 {
        width: 200px;
    }
    &.input_w-300 {
        width: 300px;
    }
    &.input_w-400 {
        width: 400px;
    }
    &.input_w-500 {
        width: 500px;
    }
}
