@charset 'UTF-8';
@import "../base/colors";

@media screen and (min-width: 1024px) {

    .post-message__header-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: baseline;
    }

.custom-textarea {
    background: transparent;
    border: 1px solid #cccccc;
    height: 100%;
    line-height: 20px;
    min-height: 36px;
    overflow-x: hidden;
    resize: none;
    white-space: pre-wrap;
    word-wrap: break-word;

    &:focus {
        border-color: #cccccc;
        box-shadow: none;
    }

    &.bad-connection {
        background: #ffffac !important;
        color: #d04444 !important;
    }
}

.textarea-wrapper {
    min-height: 37px;
    position: relative;

    .textbox-preview-area {
        white-space: normal;
        box-shadow: none;
        left: 0;
        position: absolute;
        top: 0;
        z-index: 2;

        p {
            margin: 0;
        }

        ul + p,
        ol + p {
            margin-top: .6em;
        }

        p + ul,
        p + ol {
            margin-top: .6em;
        }
    }

    .help__text {
        bottom: -23px;
        font-size: 13px;
        position: absolute;
        right: 0;
        text-align: right;
        z-index: 3;

        .modal & {
            bottom: initial;
            margin-top: -20px;
            position: relative;
            top: 30px;
        }

        a {
            margin-left: 10px;
        }
    }

    .textbox-preview-link {
        margin-right: 8px;
    }
}

.help__format-text {
    opacity: 0;
    //@include single-transition(all, .5s, ease, .5s);
    display: inline-block;
    font-size: .85em;
    vertical-align: bottom;
    white-space: nowrap;

    .modal & {
        white-space: normal;
    }

    b,
    i,
    span {
        margin: 0 2px;
        position: relative;
        top: -1px;
    }

    b {
        opacity:(.9);
    }

    code {
        background: transparent;
        padding: 0;
    }

    .textbox-preview-link {
        cursor: pointer;
        font-size: 13px;
        margin-left: 15px;
    }
}

.date-separator,
.new-separator {
    height: 2em;
    margin: 0;
    position: relative;
    text-align: center;
    z-index: 0;

    &:before,
    &:after {
        content: '';
        display: none;
        height: 1em;
        left: 0;
        position: absolute;
        width: 100%;
    }

    &:before {
        bottom: 0;
    }

    &:after {
        top: 0;
    }

    &.hovered--after {
        &:before {
            background: #f5f5f5;
            display: block;
        }
    }

    &.hovered--before {
        &:after {
            background: #f5f5f5;
            display: block;
        }
    }

    .separator__hr {
        border-color: #cccccc;
        margin: 0;
        position: relative;
        top: 1em;
        z-index: 5;
    }

    .separator__text {
        border-radius:50px;
        background: #ffffff;
        color: #555555;
        display: inline-block;
        font-size: 13px;
        font-weight: 700;
        line-height: 2em;
        padding: 0 1em;
        position: relative;
        z-index: 5;
    }
}

.new-separator {
    .separator__hr {
        border-color: #ffaf53;
    }

    .separator__text {
        color: #ff8800;
        font-weight: normal;
    }
}

.file-overlay {
    color: $white;
    font-size: em(20px);
    font-weight: 600;
    height: 100%;
    left: 0;
    pointer-events: none;
    position: absolute;
    text-align: center;
    top: 0;
    width: 100%;
    z-index: 11;

    .overlay__indent {
        @include clearfix;
        @include alpha-property(background-color, $black, .6);
        height: 100%;
        position: relative;
    }

    &.center-file-overlay {
    }

    &.right-file-overlay {
        font-size: em(18px);

        .overlay__circle {
            height: 300px;
            margin: -150px 0 0 -150px;
            width: 300px;
        }

        .overlay__files {
            margin: 60px auto 15px;
            width: 150px;
        }
    }

    .overlay__circle {
        border-radius:500px;
        @include alpha-property(background, $black, .7);
        height: 370px;
        left: 50%;
        margin: -185px 0 0 -185px;
        pointer-events: none;
        position: absolute;
        top: 50%;
        width: 370px;
    }

    .overlay__files {
        display: block;
        margin: 75px auto 20px;
    }

    .overlay__logo {
        opacity: 0.3;
        bottom: 30px;
        left: 50%;
        margin-left: -50px;
        position: absolute;
    }

    .fa {
        display: inline-block;
        font-size: 1.1em;
        margin-right: 8px;
    }
}

#post-list {
     flex:(1 1 auto);
    height: 100%;
    overflow-y: hidden;
    position: relative;

    .inactive {
        display: none;
    }

    .post-list__loading {
        opacity: 0.5;
        font-size: .9em;
        font-style: italic;
        padding: 1em 0;
        text-align: center;

        i {
            margin-right: 2px;
        }

        &.post-list__loading-search {
            cursor: pointer;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .post-list-holder-by-time {
        height: 100%;
        width: 100%;
        position: absolute;
        &.active {
            display: inline;
        }
    }

    .more-messages-text {
        border: none;
        display: block;
        font-size: 13px;
        margin: 5px 0 10px;
        outline: none;
        text-align: center;
        width: 100%;
    }

    .new-messages__button {
        opacity: 0;
        transform: translateX(-50%);
        bottom: 0;
        font-size: 13.5px;
        left: 50%;
        margin: 5px auto;
        position: absolute;
        text-align: center;
        visibility: hidden;
        z-index: 3;

        .fa {
            font-size: 1.2em;
            font-weight: bold;
            margin-right: .5rem;
            position: relative;
            top: 1px;
        }

        .icon {
            margin-left: 5px;
        }

        div {
            border-radius:50px;
            cursor: pointer;
            display: inline-block;
            padding: 0 20px;
            height: 28px;
            display: flex;
            align-items: center;
        }

        &.visible {
            opacity: 1;
            visibility: visible;
        }

        &.disabled {
            display: none;
        }
    }
}

.post-list__timestamp {
    opacity: 0;
    //@include single-transition(all, .6s, ease);
    transform: translateY(-45px);
    display: none;
    left: 0;
    position: absolute;
    text-align: center;
    top: 8px;
    width: 100%;
    z-index: 50;

    &.scrolling {
        transform: translateY(0);
        opacity:(.9);
    }

    &.rhs {
        top: 98px;
    }

    > div {
        border-radius:3px;
        @include font-smoothing(initial);
        background: $primary-color;
        border: 1px solid;
        color: $white;
        display: inline-block;
        font-size: 12px;
        line-height: 25px;
        padding: 0 8px;
        text-align: center;
    }
}

.post-list__arrows {
    opacity: 0;
    //@include single-transition(all, .6s);
    background-repeat: no-repeat;
    bottom: 0;
    display: none;
    fill: #444444;
    height: 40px;
    left: 9px;
    position: absolute;
    text-align: center;
    width: 40px;
    z-index: 50;

    svg {
        color: inherit;
        height: 28px;
        width: 28px;
    }

    &.scrolling {
        opacity: 1;
        display: block;
    }
}

.post-create-message {
    padding: 15px;
}

.post-create__container {
     flex:(0 0 auto);
    width: 100%;
    z-index: 10;
    padding: 20px 0;
    background-color: #efefef;
    label {
        font-weight: normal;
    }

    form {
        margin: 0 auto;
        padding: 0 15px 0;
        width: 100%;
    }

    .center {
        max-width: 1028px;
    }

    .custom-textarea {
        bottom: 0;
        max-height: calc(50vh - 40px);
        overflow: hidden;
        padding: 12px 0 12px 15px;
        resize: none;
        background: #fff;

        &.textbox-preview-area {
            overflow-y: auto;
        }

        @include input-placeholder {
            color: inherit;
        }
    }


    .scroll {
        .post-body__actions {
            right: 10px;
        }

        .custom-textarea {
            -ms-overflow-style: auto;
            -webkit-overflow-scrolling: touch;
            overflow: auto;

                padding-right: 50px;

        }
    }

    .post-body__actions {
        display: flex;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;

        > span {
            border-left: 1px solid transparent;
            width: 42px;

            &:first-child {
                border: none;
            }

            > span,
            div {
                display: block;
                height: 49px;
                line-height: 49px;
                text-align: center;
                width: 100%;
            }
        }

        &:hover,
        &:active {
            box-shadow: none;
        }

        &.btn-file__disabled {
            opacity: 0.1;

            &:hover,
            &:active {
                opacity: 0.1;
            }
        }

        .icon--attachment {
            opacity:(.4);
            //@include single-transition(all, .15s);
            cursor: pointer;
            overflow: hidden;
            position: relative;
            vertical-align: top;

            &:hover {
                opacity: 0.6;
            }

            input {
                cursor: pointer;
                direction: ltr;
                filter: alpha(opacity=0);
                font-size: 23px;
                height: 100%;
                margin: 0;
                opacity: 0;
                position: absolute;
                right: 0;
                top: 0;
                width: 100%;
            }

            svg {
                position: relative;
                top: 2px;
            }
        }
    }

    .post-create-body {
        padding: 0 0 2px;
        position: relative;

        .post-body__cell {
            position: relative;
            vertical-align: top;
        }

        .send-button {
            //@include single-transition(all, .15s);
            border-left: 1px solid transparent;
            cursor: pointer;
            //display: none;
            font-size: 17px;
            height: 100%;
            line-height: 49px;
            padding-right: 4px;
            text-align: center;
            vertical-align: bottom;
            width: 45px;

            &:active {
                opacity:(.75);
            }

            &.disabled {
                i {
                    color: inherit;
                }
            }

            .android &,
            .ios & {
                display: block;
            }
        }



        textarea {
            box-shadow: none;
        }
    }

    .post-create-footer {
        @include clearfix;
        font-size: 13px;
        padding: 3px 0 0;
        position: relative;

        .post-error {
            opacity:(.55);
            display: inline-block;
            font-size: .85em;
            font-weight: normal;
            margin-bottom: 0;
            position: absolute;
            top: 4px;
        }
    }

    .msg-typing {
        opacity: 0.7;;
        display: block;
        font-size: .95em;
        height: 20px;
        margin-bottom: 5px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .channel-archived__message {
        padding: 25px;
        border-top-width: 1px;
        border-top-style: solid;
        text-align: center;
    }

    .channel-archived__close-btn {
        margin: 10px;
    }
}

.post-list__table {
    @include clearfix;
    height: 100%;
    width: 100%;

    .post-list__content {
        height: 100%;
        overflow: hidden;

        .dropdown-menu {
            &.bottom {
                bottom: 19px;
                top: auto;
            }
        }
    }
    .loading-screen {
        position: relative;
        height: 40px;
        padding: 0px;
        .loading__content {
            height: 40px;
        }
    }
}
.post-item {
    margin-bottom: 12px;
}
.post {
    @include legacy-pie-clearfix;
    //max-width: 100%;
    padding:  0 2.5em 0 2.5em;
    position: relative;
    word-wrap: break-word;


    .browser--ie & {
        .post__header {
            .col__reply {
                .comment-icon__container {
                    flex: 0 1 auto;
                    align-items: center;
                }

                > .open, > div {
                    flex: 0 1 30px;
                    align-items: center;

                    &:first-child {
                        flex: 0 1 25px;
                    }
                }
            }
        }
    }

    &.post--system {
        .post__header {
            .col__reply {
                min-width: 0;
            }
        }
    }

    &:hover {
        .dropdown,
        .comment-icon__container,
        .reacticon,
        .reacticon__container,
        .flag-icon__container,
        .post__reply,
        .post__dropdown,
        .post__remove {
            visibility: visible;
        }

        .permalink-icon {
            visibility: visible;
        }

    }

    &.post--hovered {
        .dropdown,
        .comment-icon__container,
        .post__reply,
        .post__remove,
        .post__dropdown,
        .reacticon__container,
        .permalink-icon {
            visibility: visible;
        }

        .post__header {
            .col__reply {
                z-index: 7;
            }
        }

        .post__body {
            background: transparent !important;
        }
    }

    &.post--highlight {
        background-color: beige;
    }

    &.post--hide-controls {
        .post__header {
            .col__reply {
                display: none;
            }
        }
    }

    &.post--thread {
        &.same--user {
            padding: 0 .5em 0 1em;

            .post__header {
                margin-bottom: 0;

                .post__header--info {
                    position: absolute;
                    left: -5px;

                    .post__time {
                        font-size: 0.7em;
                        display: inline-block;
                        left: -14px;
                        line-height: 20px;
                        position: absolute;
                        text-align: right;
                        text-rendering: auto;
                        top: 4px;
                        width: 51px;

                    }

                    .flag-icon__container {
                        left: 32px;
                        margin-left: 7px;
                        position: absolute;
                        top: 6px;
                    }
                }
            }
        }
    }

    &.post--compact {
        &.post--thread {
            .post__header {
                padding-top: 3px;
            }
        }

        &.post--system {
            .status {
                visibility: hidden;
            }

            > div {
                margin-bottom: 0;
            }
        }

        &.same--root {
            &.same--user {
                .post__header {
                    height: 0;
                }
            }
        }

        .post-message {
            overflow: inherit;
        }



        .post-message--collapsed + .post-image__columns {
            &:before {
                content: '';
                height: 100%;
                left: 0;
                position: absolute;
                top: 0;
                width: 100%;
            }
        }

        .post__img {
            padding-top: 0;
        }

        .attachment {
            clear: both;
            padding-top: 1px;

            &.attachment--pretext {
                clear: none;
                padding: 0;
            }

            .attachment__body__wrap {
                .btn-close {
                    left: -2px;
                }
            }
        }

        &.post--comment {
            .attachment {
                .attachment__body__wrap {
                    .btn-close {
                        left: -13px;
                    }
                }
            }
        }

        .auto-responder {
            background: alpha-color($white, .7);
        }

        .status-wrapper {
            cursor: auto;
            height: 14px;
            pointer-events: none;

            .status {
                bottom: auto;
                height: 13px;
                left: -2px;
                position: relative;
                right: auto;
                top: auto;
                width: 13px;
            }

            svg {
                top: 1px;
            }

            &:after {
                bottom: 0;
            }
        }

        blockquote {
            display: flex;
            padding: 3px 0px 3px 20px;
            flex-direction: column;

            &:before {
                font-size: 15px;
                left: 0;
                top: 0;
            }
        }

        .search-item-snippet {
            blockquote {
                margin-top: 0;
            }
        }

        .markdown__heading {
            clear: both;
            font-size: 1em;
            margin: 0;
        }

        .post__header {
            display: block;
            height: 22px;
            margin-bottom: 0;
            padding: 0;
            width: auto;
        }

        .post__body {
            background: transparent !important;
            line-height: 1.5;
            padding: 2px 0 0;

            .post--edited {
                position: relative;
                top: -1px;
            }

            .img-div {
                max-height: 150px;
                max-width: 150px;
            }

            p {
                line-height: inherit;
            }

            ol,
            ul {
                clear: both;
                padding-left: 20px;
            }

            div {
                margin-bottom: 0;
            }

            .post-image__column {
                margin: 3px 10px 3px 0;
                padding: 0;
            }
        }

        .post-image__column {
            border-radius:2px;
            font-size: .9em;
            height: 26px;
            line-height: 25px;
            max-width: 250px;
            min-width: 150px;
            padding: 0 7px;

            .post-image__thumbnail {
                display: none;
            }

            .post-image__details {
                background: transparent;
                border: none;
                padding: 0;
                width: 100%;

                span {
                    display: none;
                }

                svg {
                    position: relative;
                    top: 2px;
                }

                .icon {
                    display: block;
                    float: left;
                    margin-right: 5px;
                    opacity: .6;
                }
            }

            .post-image__download {
                opacity: 1;
                padding: 0;
                position: relative;
                width: auto;
            }

            .post-image__name {
                @include clearfix;
                display: block;
                margin: 0;
                padding: 0 5px;
                text-overflow: ellipsis;
                white-space: nowrap;
                width: 100%;

                i {
                    font-size: .9em;
                    margin-right: 5px;
                    opacity: .5;
                }
            }

            a {
                &:hover {
                    text-decoration: none;
                }
            }
        }

        .post__img {
            padding-top: 1px;
            width: 16px;

            img {
                display: none;
            }
        }
    }

    .post__img:hover {
        cursor: pointer;
    }

    .post--fail {
        position: relative;
    }

    .post-edited__indicator {
        opacity: 0.6;
        font-size: .87em;
    }


    p {
        font-size: 13.5px;
        line-height: 1.6em;
        margin: 0;
        white-space: pre-wrap;
        width: 100%;
        word-break: break-word;
    }

    .post__header--info {
        padding: 0;
    }

    &.post--root {
        .comment-icon__container {
            visibility: visible;
        }
    }

    &.post--comment {
        .post__body {
            border-left: 4px solid $gray;
            padding-left: 7px;

            &.mention-comment {
                border-left: 4px solid $yellow;
                border-color: $yellow;
            }
        }

        .attachment {
            .attachment__body__wrap {
                .btn-close {
                    left: -11px;
                }
            }
        }
    }

    &.same--root {
        &.same--user {
            padding: 0 .5em 0 1.5em;

            &:hover,
            &.post--hovered {
                .post__time {
                    opacity: 0.5;
                }
            }

            .flag-icon__container {
                left: 32px;
                margin-left: 7px;
                position: absolute;
                top: 6px;
            }

            .post__img {
                .status-wrapper {
                    display: none;
                }

                img {
                    display: none;
                }
            }

            .post__header {
                height: 0;
                margin: 0;

                .col__name {
                    display: none;
                }

                .col__reply {
                    top: -1px;
                }
            }
        }

        &.post--comment {
            .post__link {
                display: none;
            }

            &.same--user {
                .post__img {
                    img {
                        display: none;
                    }
                }
            }
        }
    }

    &.other--root {
        .comment-icon__container {
            &.icon--show {
                visibility: visible;
            }
        }

        &.post--comment {

            .popover {
                margin-top: 84px;

                > .arrow {
                    top: 21px !important;
                }
            }

            .post__header {
                .col__reply {
                    top: -4px;
                }
            }
        }
    }

    .post__content {
        max-width: 35%;
        margin: 0 auto;
        padding: 6px 7px 8px 9px;
        position: relative;
        width: 100%;
        font-size: 10pt;
        > div {
            //display: table-cell;
            //vertical-align: top;
        }
    }

    .post__tail {
        content: "";
        position: absolute;
        top: 0;
        width: 12px;
        height: 19px;

        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-size: contain;
    }
    .post__content {
        @include alpha-property(background-color, #63AEF4, .1);
        box-shadow: 0 1px 0.5px rgba(0,0,0,.13);
        border-radius: 7.5px;
        border-top-right-radius: 0;
        float: left;

    }

    .post__tail {
        left: -12px;
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAmCAMAAADp2asXAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAACQUExURUxpccPDw9ra2m9vbwAAAAAAADExMf///wAAABoaGk9PT7q6uqurqwsLCycnJz4+PtDQ0JycnIyMjPf3915eXvz8/E9PT/39/RMTE4CAgAAAAJqamv////////r6+u/v7yUlJeXl5f///5ycnOXl5XNzc/Hx8f///xUVFf///+zs7P///+bm5gAAAM7Ozv///2fVensAAAAvdFJOUwCow1cBCCnqAhNAnY0WIDW2f2/hSeo99g1lBYT87vDXG8/6d8oL4sgM5szrkgl660OiZwAAAHRJREFUKM/ty7cSggAABNFVUQFzwizmjPz/39k4YuFWtm55bw7eHR6ny63+alnswT3/rIDzUSC7CrAziPYCJCsB+gbVkgDtVIDh+DsE9OTBpCtAbSBAZSEQNgWIygJ0RgJMDWYNAdYbAeKtAHODlkHIv997AkLqIVOXVU84AAAAAElFTkSuQmCC)
    }

    .post__text {
        width: 100%;

    }

    &.current--user {

        .post__content {
            background-color: #dcf8c6;
            float: right;
        }
        .post__tail {
            left: auto;
            right: -12px;
            background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAmCAMAAADp2asXAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAADAUExURUxpcXmHa4maet/4yA0aDRMTE8fhsgAAAAAAAMDXrCsxJeX/z1xzXIiYetPsvGBsVUdPPuH8zOH8zNDrvMvmtrrOpwAAAAAAABUVFRoaGtnyxLTMozQ+MMfftFBeSR8nH5aoh6q/mW9+ZN/4yMjhtRwlHAAAAIOWd+r/06C1kkNLOwsLC9z4xur/0+n/0t76x9v4xeL9y+b/z+j/0d/7yeH8yuX/zeD8ytz5xt76yOP/zeH+y+b/zuD8yd35xuf/0MY9jkkAAAAsdFJOUwBvd/ATDZIBAsMp/At/11c9yPbizHoICQwT4bY1ykkgjahl6s8bBYT6nUAWOLbtFAAAAIhJREFUKM/tzbUWwlAURNFBE9zdg0NecLf//yvKUJyUdDnl7HXXletXqmXl9wPbQ9JCcC+VJsOj2mDwovzj3osjHGNFEVxNRAj7UR1hlx+I4FbuC8HkZBE8OwnRxamdFsEmUxCCGdoI51RLBK9xVwTvjyMEbzlDMJMp7lqseNc8YNc6CGyF/a0vcmwhZbCG+kEAAAAASUVORK5CYII=)
        }



    }

    .center {
        max-width: 1000px;
    }

    &:hover {
        .post__header {
            padding: 0 55px 0 0;
        }
    }

    .post__header {
        display: flex;
        margin-bottom: 2px;
        padding: 0 40px 0 0;
        white-space: nowrap;
        width: 100%;

        > div {
            display: inline-block;
            vertical-align: top;
        }

        .col__name {
            font-weight: 600;
            margin-right: 7px;
            overflow: hidden;
            text-overflow: ellipsis;

            .user-popover {
                @include clearfix;
                text-overflow: ellipsis;
                vertical-align: top;
                white-space: nowrap;
            }

            .colon {
                display: none;
                font-weight: 900;
                margin-left: 2px;
            }
        }

        .col__reply {
            border-radius:4px;
            border: 1px solid transparent;
            display: flex;
            justify-content: flex-end;
            padding: 4px;
            position: absolute;
            right: 0;
            top: -8px;
            white-space: normal;
            z-index: 6;

            > div,
            > button {
                border-radius:4px;
                display: flex;
                flex: 1;
                height: 24px;
                top: 0;
                width: 24px;
            }

            .comment-icon__container {
                flex: 0;
                justify-content: flex-end;
                padding: 0 4px 0 0;
                width: auto;
                align-items: center;
            }

            .dropdown {
                > div {
                    display: flex;
                    flex: 1;
                    justify-content: center;
                    align-items: center;
                }
            }

            .reacticon__container,
            .icon {
                display: flex;
                flex: 1;
                margin: 0;
                top: 0;
                align-items: center;
            }

            .reacticon__container {
                width: 28px;
            }


            > .open, > div {
                display: flex;
                flex: 1;
                justify-content: center;
            }

            span {
                svg {
                    flex: 1;
                    height: 16px;
                    top: 0;
                    width: 16px;
                }
            }

            svg {
                flex: 1;
                height: 16px;
                top: 0;
                width: 16px;
            }

            &.dot_small {
                min-width: auto !important;
            }
        }

        .col__remove {
            position: absolute;
            right: 10px;
        }

        .permalink-popover {
            min-width: 0;

            .popover-content {
                padding: 5px;
            }

            .form-control,
            .btn {
                font-size: 13px;
                height: 30px;
                line-height: 30px;
                padding: 0 8px;
            }
        }
    }

    .post__img {
        padding-right: 10px;
        text-align: right;
        width: 53px;

        .icon {
            svg {
                height: 32px;
                width: 32px;
            }
        }

        path {
            fill: inherit;
        }

        img {
            border-radius:50px;
            height: 32px;
            max-width: none;
            vertical-align: inherit;
            width: 32px;
        }
    }

    .post__embed-container {
        //@include single-transition(max-height, .5, ease);
        display: block;
        padding-top: 5px;
        max-height: 1000px;
        overflow: hidden;

        &[hidden] {
            max-height: 0;
        }
    }

    .dropdown {
        display: inline-block;
        top: -1px;
        vertical-align: middle;
        visibility: hidden;

        &:last-child {
            margin-right: 0;
        }

        .dropdown-menu {
            left: auto;
            min-width: 130px;
            padding: 2px 0;
            right: 0;
            top: 20px;

            li {
                display: block;
            }

            a {
                padding: 5px 15px;
            }
        }
    }

    .post__dropdown {
        display: flex;
        justify-content: center;
        text-decoration: none;
        vertical-align: middle;
        width: 100%;
        visibility: hidden;

        &:after {
            content: '...';
            font-family: 'Open Sans', sans-serif;
            position: relative;
            top: -1px;
            width: 100%;
        }
    }

    .post__remove {
        opacity: 0.5;
        color: inherit;
        display: inline-block;
        float: right;
        font-family: 'Open Sans', sans-serif;
        font-size: 20px;
        font-weight: 600;
        height: 20px;
        line-height: 20px;
        position: relative;
        right: -10px;
        text-decoration: none;
        vertical-align: top;
        visibility: hidden;
        width: 20px;

        &:hover {
            opacity:.8;
        }
    }


    .post__body {
        @include legacy-pie-clearfix;
        padding: 0;
        width: 100%;
        word-wrap: break-word;

        &.post--ephemeral {
            padding-right: 20px !important;
        }

        div {
        }

        p + p {
            margin: 0.5em 0 0;
        }

        ul,
        ol {
            font-size: 13.5px;

            p {
                margin-bottom: 0;
            }

            li {
                ul,
                ol {
                    font-size: 1em;
                    margin: 0;
                    padding: 0 0 0 20px;
                }
            }

            li.list-item--task-list ul,
            li.list-item--task-list ol {
                margin-left: 20px;
            }

            li.list-item--task-list ul {
                padding-left: 0;
            }

            li:not(.list-item--task-list) li.list-item--task-list,
            li:not(.list-item--task-list) li.list-item--task-list ~ li {
                margin-left: -20px;
            }

            li input[type='checkbox']:disabled {
                cursor: default;
                vertical-align: top;
            }
        }

        ul li.list-item--task-list,
        ul li.list-item--task-list ~ li {
            list-style-type: none;
        }

        ul li.list-item--task-list ~ li:not(.list-item--task-list) {
            text-indent: 3px;

            &:before {
                content: '\2022';
                margin-right: 8px;
            }
        }

        ul + p,
        ol + p {
            margin-top: .6em;
        }

        p + ul,
        p + ol {
            margin-top: .6em;
        }

        .pending-post-actions {
            background: alpha-color($black, .7);
            color: $white;
            font-size: .9em;
            padding: 5px 7px;
            position: absolute;
            right: 0;
            top: 0;
            z-index: 100;

            a {
                color: $white;
            }
        }


    }

    .post__link {
        font-size: 13px;
        margin: 2px 0 5px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        a {
            font-weight: bold;
        }
    }

    .post__embed-visibility {
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        cursor: pointer;
        display: inline-block;
        font: normal normal normal 14px/1 FontAwesome;
        margin: 0;
        text-rendering: auto;
        position: relative;
        z-index: 2;

        &.pull-left {
            margin: 4px 5px 0 0;
        }

        &:hover {
            text-decoration: none;
        }

        &:before {
            content: '\f065';
            font-size: 14px;
}

        &[data-expanded='true']:before {
            content: '\f066';
        }
    }

    .post__visibility {
        opacity: 0.6;
        font-size: .9em;
        padding: 8px;
    }

    .post__timestamp {
        float: right;
        margin: -10px 0 -5px 4px;

    }
    .post__time {
        opacity: 0.6;
        font-size: .9em;
    }

    .post__permalink {
        color: inherit;

        &:hover,
        &:focus {
            color: inherit;
        }

        &:focus {
            text-decoration: none;
        }

        &:hover {
            text-decoration: underline;
        }
    }

    .post-loading-gif {
        height: 10px;
        margin-top: 6px;
        width: 10px;
    }

    .post-fail {
        color: #d58a8a;
    }

    .post-waiting {
        color: #999999;
        position: relative;

        .spinner {
            width: 20px;
            position: absolute;
            right: 8px;
            top: 4px;
        }

        .post-message {
            padding-right: 24px;
        }
    }

    .permalink-icon {
        color: $primary-color;
        display: inline-block;
        visibility: hidden;
    }

    .post__reply {
        display: inline-block;
        margin-right: 6px;
        visibility: hidden;

        svg {
            fill: inherit;
            position: relative;
            top: 3px;
            width: 18px;
        }
    }

    .comment-icon__container {
        display: inline-block;
        fill: $primary-color;
        position: relative;
        text-decoration: none;
        top: 1px;
        visibility: hidden;

        &:focus {
            outline: none;
        }

        &.icon--visible {
            visibility: visible;
        }

        svg {
            height: 17px;
            width: 17px;
        }

        .comment-count {
            margin-left: 2px;
        }

        .comment-icon {
            display: inline-block;
            fill: inherit;
            margin: 0 1px 0 5px;
            position: relative;
            top: 1px;
            vertical-align: top;
        }

        path {
            fill: inherit;
        }
    }

    .reacticon__container {
        display: inline-block;
        fill: $primary-color;
        font-size: 16px;
        vertical-align: top;
        visibility: hidden;

        svg {
            height: 14px;
            position: relative;
            top: 1px;
            width: 14px;
        }
    }

    .flag-icon__container {
        display: inline-block;
        height: 15px;
        margin-left: 7px;
        position: relative;
        top: 2px;
        vertical-align: top;
        visibility: hidden;

        &.visible {
            visibility: visible;
        }

        svg {
            width: 12px;
        }

        path {
            fill: inherit;
        }

        .fa-star-o {
            opacity:.8;
        }

        &:focus {
            outline: none;
        }

        &.icon--visible {
            visibility: visible;
        }

    }

    .web-embed-data {
        border-radius:2px;
        @include alpha-property(background, $black, 0.05);
        height: 50px;
        overflow: hidden;
        padding: 2px 0 0 10px;
        text-overflow: ellipsis;

        .embed-title {
            color: #555555;
            font-weight: 600;
            margin: 3px 0 1px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .embed-description {
            color: #888888;
            margin: 0;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .embed-link {
            display: none;
        }
    }
}

.post__pinned-badge {
    border-radius: 2px;
    font-family: inherit;
    font-size: 10px;
    font-weight: 600;
    padding: 1px 4px;
}

.Badge {
    &.badge-autocomplete {
        margin: 0px 4px;
        padding: 0px 5px;
        display: inline;
    }
    &.badge-popoverlist {
        margin: 0px 4px;
        display: inline;
    }
    &.badge-admin {
        margin: 0px 4px;
        background: rgba(61, 60, 64, 0.15);
        display: inline;
    }
}

.post__pinned-badge {
    margin-left: 7px;
    position: relative;
    top: -1px;
}

.permalink-text {
    overflow: hidden;
}

.permalink-popover {
    margin-left: 50px !important;
    min-width: 320px;
}

.post-message {
    position: relative;
    overflow: hidden;
}

.post-message__status-badge {
    background: #f0f8ff;
    padding: 0.5rem;
    border-radius: 5px;
    font-size: small;
}

.post-collapse {
    bottom: 0;
    pointer-events: none;
    position: absolute;
    width: 100%;

    .post-collapse__gradient, .post-attachment-collapse__gradient {
        position: relative;
    }

    .post-collapse__show-more, .post-attachment-collapse__show-more {
        pointer-events: auto;
    }
}

.post-collapse__show-more {
    padding-bottom: 10px;
}

.post-collapse__show-more, .post-attachment-collapse__show-more {
    display: flex;
    justify-content: center;
    width: 100%;
    z-index: 5;

    .post-collapse__show-more-button {
        //@include single-transition(all, .15s, ease);
        border-radius:2px;
        border: 1px solid transparent;
        display: inline-block;
        flex-shrink: 0;
        font-size: 13px;
        font-weight: bold;
        line-height: 24px;
        margin: 0 8px;
        padding: 0 8px;
        position: relative;

        .fa {
            font-size: 1.4em;
            font-weight: bold;
            margin-right: 5px;
            position: relative;
            top: 2px;

            &.fa-angle-up {
                top: 1px;
            }
        }

        &:focus {
            outline: none;
        }
    }

    .post-collapse__show-more-line {
        display: inline-block;
        flex-basis: 200px;
        height: 1px;
        margin-top: 12px;
    }
}

.post-message--collapsed {
    .post-message__text-container {
        max-height: 600px;

    }

    .post-collapse__gradient, .post-attachment-collapse__gradient {
        height: 90px;
    }

    .post-collapse__show-more {
        bottom: 10px;
    }
}

.post-message--expanded {
    .post-collapse {
        position: relative;
    }

    .post-collapse__show-more, .post-attachment-collapse__show-more {
        position: relative;
        padding-top: 10px;
    }

    .post-collapse__gradient, .post-attachment-collapse__gradient {
        height: 0;
    }
}

.file-attachment-menu-item-input {
    cursor: pointer;
    direction: ltr;
    filter: alpha(opacity=0);
    font-size: 23px;
    height: 35px;
    margin: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 0;
}

.post--metadata {
   .post__content {
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    float: right;
    background: white;
        &.deferred {
            background-color: #f1f1f1;
            -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
        }
    }

    .post-message__text {
        padding: 5px 10px;
        font-weight: bold;
        margin-bottom: 10px;
        font-size: 10pt;
    }

    .post-message__buttons {
        padding: 5px 10px;
        font-weight: bold;
        margin-bottom: 10px;
    }
}
.embed-metadata {
    width: 100%;
    td {
        padding: 5px 10px;
    }

    .metadata-value {
        text-align: right;
    }
    .total-row {
        padding: 5px 10px;
        font-size: 1em;
        font-weight: bold;
        border-top: 1px solid #b2af4a;
    }

    .metadata-total-value {
        text-align: right;
    }
}
.metadata-desc {
    margin-bottom: 10px;
}

.metadata-desc__row {
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    word-wrap: break-word;
    flex-wrap: nowrap;

    &.row_deferred {
        display: flex;
        justify-content: space-between;
        font-weight: bold;
        color: $orange;
    }
}

.metadata-text {
    padding: 0px 10px;
    .v{
        font-weight: 500;
        margin-right: 10px;
    }

    &:first-child {
        float: left;
    }

    &:last-child {
        float: right;
    }

    margin-bottom: 5px;
}

.metadata-cancel {
    display: flex;
    justify-content: flex-end;
    margin: 5px 5px 15px 0px;
    flex-wrap: wrap;
}
}
