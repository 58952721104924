@charset 'UTF-8';

.inner-wrap {
    &.move--left {
        .search-bar__container {
            display: none;
        }

        .search-btns {
            display: none;
        }

        .header-list__right {
            // the negative margin-right is used
            // to prevent the icons in the header from
            // moving to the left when the RHS is open
            //
            // the below z-index is used to ensure the icons
            // stays on the top and don't get hidden by the
            // search's input block
            float: right;
            padding-right: 0px !important;
            position: relative;
            z-index: 6;
        }
    }

    &.move--right {
        .search-bar__container {
            display: none;
        }

        .search-btns {
            display: none;
        }

        .header-list__right {
            // the negative margin-right is used
            // to prevent the icons in the header from
            // moving to the left when the RHS is open
            //
            // the below z-index is used to ensure the icons
            // stays on the top and don't get hidden by the
            // search's input block
            float: right;
            margin-right: -18px;
            padding-right: 0px !important;
            position: relative;
            z-index: 6;
        }
    }
}

.app__content {
    display: flex;
    flex-direction: (column);
    background: $white;
    height: 100%;
    margin-left: 220px;
    padding-top: 50px;
    position: relative;

    .channel__wrap & {
        padding-top: 0;
        margin-left: 520px;
    }
}

#archive-link-home {
flex:(0 0 auto);
    cursor: pointer;
    font-size: 13px;

    .fa {
        opacity: 0.7;;
        font-size: 11px;
    }

    a {
        color: inherit;
        display: block;
        padding: 10px 20px;
        width: 100%;
    }
}

.delete-message-text {
    margin-top: 10px;
}

.col-sm-auto {
    padding-left: 15px;
    padding-right: 15px;
}

.nav-view {
    overflow: hidden;
    zoom: 1;
    height: 100%;
    position: relative;
}



.nav-left {
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    border-right: $border-gray;
    height: 100%;
    left: 0;
    position: fixed;
    width: 100px;
    z-index: 10;
    background: #63AEF4;


    .SidebarHeader .MenuWrapper {
        text-align: center;
    }

    .nav-left-wrapper {
        position: relative;
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        overflow: hidden;
    }



    &.sidebar--padded {
        padding-top: 44px;
    }

    .sidebar--left__icons {
        border-bottom: 1px solid transparent;
    }

    .sidebar-item {


        .icon {
            display: inline-block;
            margin: 0 7px 0 1px;
            position: relative;
            text-align: left;
            vertical-align: top;

        }

        .icon__lock {
            top: 2px;
        }

        .icon__globe {
            top: 1px;
        }

        .icon__archive {
            top: 2px;
        }

        .icon__draft {
            top: 2px;
        }

        .icon__bot {
            top: 1px;
            left: -1px;

            svg {
                width: 16px;
                height: 14px;
            }
        }

        &.active {
            //background: #57a2d8;

            transition: top 1s ease-out 0.5s;
            svg * {
                fill: #487db1;
            }

            span:not(.chat_section__badge)  {
                color: #487db1;
            }
        }

        &:hover {

        }
    }

    .sidebar-item__name {
        display: flex;
        flex: 1;
        /* padding-right: 10px; */
        overflow: hidden;
        /* vertical-align: middle; */
        align-items: center;

        > span {
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100%;
            color: #Fff;
            font-weight: normal;
            font-size: 12px;
        }
    }

    .sidebar__divider {
        color: $white;
        font-size: .9em;
        height: 20px;
        line-height: 20px;
        margin: 5px 0 6px;
        position: relative;
        z-index: 0;

        &:before {
            background: $light-gray;
            content: '';
            height: 1px;
            position: absolute;
            top: 10px;
            width: 100%;
        }

        .sidebar__divider__text {
            background: $primary-color;
            float: right;
            padding: 0 10px;
            position: relative;
            z-index: 1;
        }
    }

    .sidebar__switcher {
        flex: 0 0 45px;
        bottom: 0;
        display: block;
        padding-bottom: 0;
        width: 100%;

        button {
            background: transparent;
            display: block;
            font-weight: 600;
            height: 45px;
            line-height: 40px;
            padding: 0;
            text-decoration: none;
            width: 100%;

            > span {
                display: inline-block;
                position: relative;
                vertical-align: middle;
                z-index: 5;
            }

            &:hover {
                span {
                    opacity: 1;
                }
            }
        }

        .switch__shortcut {
            margin-left: 4px;
        }
    }



    .nav-pills {
        > li {
            .badge {
                margin: 0 10px 0 0;
            }
        }
    }

    .sidebar--left__list {
        position: relative;
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        overflow: hidden;
    }

    .nav-pills__container {
        -webkit-overflow-scrolling: touch;
        overflow: auto;
        position: relative;
        width: 100%;
        padding-bottom: 10px;
    }

    .nav-pills__unread-indicator {
         border-radius:(50px);
        font-size: 13.5px;
        cursor: pointer;
        left: 15px;
        margin: 0 auto;
        padding: 4px 0 3px;
        position: absolute;
        text-align: center;
        width: 190px;
        z-index: 1;

        .icon {
            margin-left: 5px;
        }
    }

    .nav-pills__unread-indicator-top {
        top: 15px;

        .icon {
            svg {
                transform:(rotate(180deg));
            }
        }
    }

    .nav-pills__unread-indicator-bottom {
        bottom: 15px;
    }

    .nav {
        &.nav-stacked {
            margin-top: 0 !important;
            > li + li {
                margin: 0;
            }
        }

        .public_direct_name {
            flex: 1;
            cursor: pointer;
        }

        li {
            > h4 {
                align-items: end;
                display: flex;
                font-size: 1em;
                font-weight: 600;
                margin: 1.5em 0 .7em;
                padding: 0 12px 0 15px;
                text-transform: uppercase;

                span {
                    flex: 1;
                }
            }

            .divider {
                & + .divider {
                    display: none;
                }
            }

            > .nav-more {
                border-radius: 0;
                display: block;
                line-height: 1.5;
                outline: none;
                overflow: hidden;
                padding: 5px 0 5px 15px;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            .sidebar-item {
                ////@include single-transition(none);

                align-items: center;
                border-radius: 0;
                display: flex;
                line-height: 1.5;
                outline: none;
                overflow: hidden;
                text-decoration: none;
                text-align: center;
                text-overflow: ellipsis;
                white-space: nowrap;
                width: 100%;
                flex-direction: column;
                height: 100px;
                align-content: baseline;
                padding: 20px 0;

                .status {
                    flex: 0 0 auto;
                }


                &.unread-title {
                    font-weight: 600;
                }

                &.muted {
                    opacity: 0.5;
                }

            }

            &.active {
                .muted {
                    opacity: 1;
                }

                button,a {
                    &:before {
                        background: $black;
                        content: '';
                        height: 100%;
                        left: 0;
                        position: absolute;
                        top: 0;
                        width: 3px;
                    }
                }

                .icon {
                    &.icon__archive,
                    &.icon__globe,
                    &.icon__draft,
                    &.icon__lock,
                    &.icon__bot {
                        opacity: 0.7;;
                    }
                }

                button,a,
                button:hover,a:hover,
                button:focus,a:focus {
                    @include alpha-property(background-color, $black, .1);
                    border-radius: 0;
                    font-weight: 400;

                    &.unread-title {
                        font-weight: 600;
                    }
                }
            }
        }
    }
    .SidebarHeader .status-selector {
        margin: 3px 0px 0 0px;
    }

    .status-wrapper {
        height: 36px;
        width: 36px;

        .status {
            bottom: -4px;
            height: 18px;
            right: -5px;
            width: 18px;

            &.status-edit {
                .fa {
                    top: 4px;
                }
            }

            .fa {
                position: relative;
                top: 2px;
            }

            .icon__container {
                &:after {
                    border-radius: 20px;
                    content: '';
                    height: 10px;
                    left: 4px;
                    position: absolute;
                    top: 4px;
                    width: 10px;
                }
            }

            svg {
                height: 14px;
                left: 0px;
                max-height: initial;
                position: relative;
                top: 2px;
                width: 14px;
                z-index: 1;
            }
        }
    }
}

.admin-form-content {
    padding: 20px;
}


.select-wrapper {
    width: 250px;
}





.color-block {
    width: 15px;
    height: 15px;
    padding: 10px;
    border-radius: 25px;
    margin: 2px;
    cursor: pointer;

    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;

    &:hover {
        -webkit-transform: scale(1.2);
        -ms-transform: scale(1.2);
        transform: scale(1.2);
    }
}

.select-wrapper {
    margin-bottom: 1.5em;
    margin-top: 1.5em;

    font-size: small;
    label {
        display: flex;
        flex-direction: column;
    }

    select {
        cursor: pointer;
        width: 100%;
        border:1px solid gainsboro;
        font-size: small;
        padding: 5px;
        outline: none;
        box-sizing: content-box;

        option {
            cursor: pointer;
        }
    }


}


.moderation-client-row {
    display: flex;
    justify-content: space-between;

    &__counters{
        display: flex;
        justify-content: space-around;

        .notify-counter {
            color: #fff;
            width: 20px;
            height: 20px;
            display: flex;
            border-radius: 10px;
            justify-content: center;
            align-items: center;
        }
    }
}




.plank-color-picker {
    display: flex;
    margin: 15px 10px 15px 0px;
    flex-direction: column;
    position: relative;

    span {
        font-size: small;
    }

    input {
        background: #FFFFFF;
        border: 1px solid #E2E2E2;
        border-radius: 4px;
        padding-left: 15px;
        text-transform: uppercase;
        font-weight: bold;
    }

    .color-button-wrapper {
        cursor: pointer;
        margin-left: 10px;
        background-color: white;
    }

}


.phone-preview {
    border: 1px solid greenyellow;
}

.offices-tab-content {
    width: 60%;
    padding: 20px;
}
.zones-tab-content {
    width: 60%;
    padding: 20px;
}
.color {
    &.red {
        color:red;
    }

    &.green {
        color:green;
    }

    &.gray {
        color:darkgray;
    }

    &.blue {
        color:#63AEF4;
    }
}

/* level list */




.list__counter_large {
    width: 130px;
}


.w-10 {
    width: 10%;
}

.w-20 {
    width: 20%;
}

.w-80 {
    width: 80%;
}

.w-50 {
    width: 50%;
}

.w-60 {
    width: 60%;
}

.w-40 {
    width: 40%
}

.w-20 {
    width: 20%;
}

.w-30 {
    width: 30%;
}

.w-70 {
    width: 70%;
}





