.MenuWrapper {
    .MenuItem {
        font-size: 14px;
        display: flex;
        align-items: center;

        > a {
            text-decoration: none;

            &:focus,
            &:hover {
                color: inherit;
            }
        }

        > button,
        > a {
            align-items: center;
            clear: both;
            color: inherit;
            cursor: pointer;
            display: flex;
            font-weight: normal;
            padding: 0 24px;
            text-align: left;
            white-space: nowrap;
            width: 100%;
            height: 28px;
            line-height: normal;

            &.MenuItem__help {
                height: auto;
                padding: 8px 24px;
                flex-direction: column;
                align-items: flex-start;
            }

            &:hover {
                background: alpha-color($black, .08);
            }
        }

        span.icon {
            width: 30px;
            padding-right: 15px;
            display: inline-block;
            text-align: center;
        }

        span.extra-text {
            opacity: 0.7;;
            display: block;
            font-size: 10px;
        }
    }
}
