.rui-Input-input {
    width: 100%;
    cursor: auto;
    widows: 2;
    margin: 0;
    border: 0 solid;
    hyphens: none;
    padding: 0;
    display: block;
    outline: 0;
    tab-size: 8;
    direction: ltr;
    font-size: medium;
    list-style: disc outside none;
    text-align: left;
    visibility: visible;
    box-sizing: border-box;
    background: #fff;
    box-shadow: none;
    transition: all 200ms ease;
    empty-cells: show;
    font-weight: 400;
    line-height: normal;
    text-indent: 0;
    text-shadow: none;
    white-space: normal;
    caption-side: top;
    font-variant: normal;
    font-stretch: normal;
    word-spacing: normal;

    border-radius: 0;
    border-spacing: 0;
    vertical-align: baseline;
    letter-spacing: 0;
    text-transform: none;
    border-collapse: separate;
    text-align-last: auto;
    -webkit-appearance: none;
}

@media (max-device-width: 767px) {
    .rui-Input-input {
        font-weight: 400;
        letter-spacing: 0;
    }
}

.rui-Input-textareaRoot .rui-Input-input {
    resize: vertical;
    height: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
}

.rui-Input-input {
    border-color: #dde0e8;
    &::-ms-reveal, &::-ms-clear {
        display: none;
    }

    &:enabled:hover {
        border-color: rgb(198, 201, 208);
    }

    &:disabled {
        color: #dde0e8;
        cursor: not-allowed;
        background: #fff;
        border-color: rgba(221, 224, 232, 0.6);
    }

    &.rui-Input-filled[type="password"] {
        font-family: monospace;
    }

    &:focus + .rui-Input-activeBorder + .rui-Input-placeholder {
        display: none;
    }

    &::-webkit-input-placeholder, &::-moz-placeholder, &:-moz-placeholder, &:-ms-input-placeholder, &::placeholder {
        color: #a4a9b8;
        opacity: 1;
        transition: opacity 140ms linear;
    }

    &:disabled {
        &::-webkit-input-placeholder, &::-moz-placeholder, &:-moz-placeholder, &:-ms-input-placeholder, &::placeholder {
            color: #dde0e8;
            opacity: 1;
        }
    }

    &:focus {
        &::-webkit-input-placeholder, &::-moz-placeholder, &:-moz-placeholder, &:-ms-input-placeholder, &::placeholder {
            opacity: 0.54;
        }
    }
}

.rui-Input-isEnabled.rui-Input-isFocused .rui-Input-input {
    &::-webkit-input-placeholder, &::-moz-placeholder, &:-moz-placeholder, &:-ms-input-placeholder, &::placeholder {
        opacity: 0.54;
    }
}

.rui-Input-input {
    &[type="month"] {
        &::-webkit-clear-button, &::-webkit-inner-spin-button {
            display: none;
        }
    }

    &[type="date"] {
        &::-webkit-clear-button, &::-webkit-inner-spin-button {
            display: none;
        }
    }

    &[type="time"] {
        &::-webkit-clear-button, &::-webkit-inner-spin-button {
            display: none;
        }
    }

    &[type="month"]::-webkit-date-and-time-value, &[type="date"]::-webkit-date-and-time-value, &[type="time"]::-webkit-date-and-time-value {
        margin: 0;
    }
}

@media (max-device-width: 767px) {
    .rui-Input-textareaRoot .rui-Input-input {
        padding-top: 20px;
        padding-bottom: 20px;
    }
}

.rui-Input-withStatusLine .rui-Input-activeBorder {
    transform: scaleX(0.6) scaleY(0);
    border-width: 0 0 2px;
    transform-origin: center bottom;

}

.rui-Input-withOutline .rui-Input-input, .rui-Input-regular .rui-Input-input, .rui-Input-regular .rui-Input-activeBorder {
    border-width: 1px;
    border-radius: 1px;

}

.rui-Input-awesome .rui-Input-activeBorder {
    border-radius: 1px;
}

.rui-Input-promo .rui-Input-input {
    border-width: 1px 0;
    border-top-color: transparent !important;
    background: none;

    &:disabled {
        background: none;
    }
}

.rui-Input-medium {
    .rui-Input-input {
        font-size: 13px;
    }

    input.rui-Input-input {
        height: 45px;
    }

    .rui-Input-icon, .rui-Input-eye {
        width: 15px;
        height: 15px;
        line-height: 15px;
    }

    .rui-Input-placeholder {
        font-size: 13px;
        pointer-events: none;
    }

    &.rui-Input-withOutline, &.rui-Input-regular{
        .rui-Input-placeholder {
            padding-left: 15px;
        }

        .rui-Input-input {
            padding-left: 15px;
            padding-right: 15px;
        }
    }

    &.rui-Input-withLeftIcon {
        &.rui-Input-regular .rui-Input-input {
            padding-left: 44px;
        }

        &.rui-Input-awesome .rui-Input-input {
            padding-left: 45px;
        }

        &.rui-Input-promo .rui-Input-input {
            padding-left: 30px;
        }
    }

    &.rui-Input-withRightIcon.rui-Input-regular .rui-Input-input {
        padding-right: 44px;
    }

    &.rui-Input-withEye {
        &.rui-Input-regular .rui-Input-input {
            padding-right: 44px;
        }

        &.rui-Input-withRightIcon.rui-Input-regular .rui-Input-input {
            padding-right: 74px;
        }
    }

    &.rui-Input-withRightIcon.rui-Input-awesome .rui-Input-input {
        padding-right: 45px;
    }

    &.rui-Input-withEye {
        &.rui-Input-awesome .rui-Input-input {
            padding-right: 45px;
        }

        &.rui-Input-withRightIcon.rui-Input-awesome .rui-Input-input {
            padding-right: 75px;
        }
    }

    &.rui-Input-withRightIcon.rui-Input-promo .rui-Input-input {
        padding-right: 30px;
    }

    &.rui-Input-withEye {
        &.rui-Input-promo .rui-Input-input {
            padding-right: 30px;
        }

        &.rui-Input-withRightIcon.rui-Input-promo .rui-Input-input {
            padding-right: 60px;
        }

        &.rui-Input-regular .rui-Input-iconRight, &.rui-Input-awesome .rui-Input-iconRight {
            right: 45px;
        }

        &.rui-Input-promo .rui-Input-iconRight {
            right: 30px;
        }
    }

    &.rui-Input-regular .rui-Input-iconLeft, &.rui-Input-awesome .rui-Input-iconLeft {
        left: 15px;
    }

    &.rui-Input-promo .rui-Input-iconLeft {
        left: 0;
    }

    &.rui-Input-regular .rui-Input-iconRight, &.rui-Input-awesome .rui-Input-iconRight {
        right: 15px;
    }

    &.rui-Input-promo .rui-Input-iconRight {
        right: 0;
    }

    &.rui-Input-inGroup {
        &.rui-Input-promo {
            &:not(.rui-Input-endPosition) {
                &.rui-Input-withRightIcon .rui-Input-input {
                    padding-right: 45px;
                }

                &.rui-Input-withEye {
                    .rui-Input-input {
                        padding-right: 45px;
                    }

                    &.rui-Input-withRightIcon .rui-Input-input {
                        padding-right: 74px;
                    }

                    .rui-Input-iconRight {
                        right: 45px;
                    }
                }

                .rui-Input-iconRight {
                    right: 15px;
                }
            }

            &:not(.rui-Input-startPosition) {
                &.rui-Input-withLeftIcon .rui-Input-input {
                    padding-left: 45px;
                }

                .rui-Input-iconLeft {
                    left: 15px;
                }
            }
        }

        &.rui-Input-regular:not(.rui-Input-endPosition) {
            .rui-Input-input, .rui-Input-activeBorder {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
        }

        &.rui-Input-awesome:not(.rui-Input-endPosition) {
            .rui-Input-input, .rui-Input-activeBorder {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
        }

        &.rui-Input-regular:not(.rui-Input-startPosition) {
            .rui-Input-input, .rui-Input-activeBorder {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
        }

        &.rui-Input-awesome:not(.rui-Input-startPosition) {
            .rui-Input-input, .rui-Input-activeBorder {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
        }
    }

    .rui-Input-eyeWrapper:after {
        top: -15px;
        left: -10px;
        right: -10px;
        bottom: -15px;
        display: block;
        content: " ";
        position: absolute;
    }
}

@media (max-device-width: 767px) {
    .rui-Input-medium.rui-Input-inGroup.rui-Input-promo:not(.rui-Input-endPosition) .rui-Input-iconRight {
        right: 20px;
    }
}

@media (max-device-width: 767px) {
    .rui-Input-medium.rui-Input-inGroup.rui-Input-promo:not(.rui-Input-endPosition).rui-Input-withEye .rui-Input-iconRight {
        right: 60px;
    }
}

@media (max-device-width: 767px) {
    .rui-Input-medium.rui-Input-inGroup.rui-Input-promo:not(.rui-Input-endPosition).rui-Input-withEye.rui-Input-withRightIcon .rui-Input-input {
        padding-right: 99px;
    }
}

@media (max-device-width: 767px) {
    .rui-Input-medium.rui-Input-inGroup.rui-Input-promo:not(.rui-Input-endPosition) {
        &.rui-Input-withRightIcon .rui-Input-input, &.rui-Input-withEye .rui-Input-input {
            padding-right: 60px;
        }
    }
}

@media (max-device-width: 767px) {
    .rui-Input-medium.rui-Input-inGroup.rui-Input-promo:not(.rui-Input-startPosition) .rui-Input-iconLeft {
        left: 20px;
    }
}

@media (max-device-width: 767px) {
    .rui-Input-medium.rui-Input-inGroup.rui-Input-promo:not(.rui-Input-startPosition).rui-Input-withLeftIcon .rui-Input-input {
        padding-left: 60px;
    }
}

@media (max-device-width: 767px) {
    .rui-Input-medium {
        &.rui-Input-regular .rui-Input-iconRight, &.rui-Input-awesome .rui-Input-iconRight {
            right: 20px;
        }
    }
}

@media (max-device-width: 767px) {
    .rui-Input-medium {
        &.rui-Input-regular .rui-Input-iconLeft, &.rui-Input-awesome .rui-Input-iconLeft {
            left: 20px;
        }
    }
}

@media (max-device-width: 767px) {
    .rui-Input-medium.rui-Input-withEye.rui-Input-promo .rui-Input-iconRight {
        right: 40px;
    }
}

@media (max-device-width: 767px) {
    .rui-Input-medium.rui-Input-withEye {
        &.rui-Input-regular .rui-Input-iconRight, &.rui-Input-awesome .rui-Input-iconRight {
            right: 60px;
        }
    }
}

@media (max-device-width: 767px) {
    .rui-Input-medium.rui-Input-withEye.rui-Input-withRightIcon.rui-Input-promo .rui-Input-input {
        padding-right: 80px;
    }
}

@media (max-device-width: 767px) {
    .rui-Input-medium {
        &.rui-Input-withRightIcon.rui-Input-promo .rui-Input-input, &.rui-Input-withEye.rui-Input-promo .rui-Input-input {
            padding-right: 40px;
        }
    }
}

@media (max-device-width: 767px) {
    .rui-Input-medium.rui-Input-withEye.rui-Input-withRightIcon.rui-Input-awesome .rui-Input-input {
        padding-right: 100px;
    }
}

@media (max-device-width: 767px) {
    .rui-Input-medium {
        &.rui-Input-withRightIcon.rui-Input-awesome .rui-Input-input, &.rui-Input-withEye.rui-Input-awesome .rui-Input-input {
            padding-right: 60px;
        }
    }
}

@media (max-device-width: 767px) {
    .rui-Input-medium.rui-Input-withEye.rui-Input-withRightIcon.rui-Input-regular .rui-Input-input {
        padding-right: 99px;
    }
}

@media (max-device-width: 767px) {
    .rui-Input-medium {
        &.rui-Input-withRightIcon.rui-Input-regular .rui-Input-input, &.rui-Input-withEye.rui-Input-regular .rui-Input-input {
            padding-right: 59px;
        }
    }
}

@media (max-device-width: 767px) {
    .rui-Input-medium.rui-Input-withLeftIcon.rui-Input-promo .rui-Input-input {
        padding-left: 40px;
    }
}

@media (max-device-width: 767px) {
    .rui-Input-medium.rui-Input-withLeftIcon.rui-Input-awesome .rui-Input-input {
        padding-left: 60px;
    }
}

@media (max-device-width: 767px) {
    .rui-Input-medium.rui-Input-withLeftIcon.rui-Input-regular .rui-Input-input {
        padding-left: 59px;
    }
}

@media (max-device-width: 767px) {
    .rui-Input-medium.rui-Input-withOutline .rui-Input-input,  .rui-Input-medium.rui-Input-regular .rui-Input-input {
        padding-left: 20px;
        padding-right: 20px;
    }
}

@media (max-device-width: 767px) {
    .rui-Input-medium.rui-Input-withOutline .rui-Input-placeholder,.rui-Input-medium.rui-Input-regular .rui-Input-placeholder {
        padding-left: 20px;
    }
}

@media (max-device-width: 767px) {
    .rui-Input-medium .rui-Input-placeholder {
        font-size: 16px;
    }
}

@media (max-device-width: 767px) {
    .rui-Input-medium .rui-Input-eyeWrapper:after {
        top: -13px;
        bottom: -13px;
    }
}

@media (max-device-width: 767px) {
    .rui-Input-medium .rui-Input-eye {
        width: 19px;
        height: 19px;
        line-height: 19px;
    }
}

@media (max-device-width: 767px) {
    .rui-Input-medium .rui-Input-icon {
        width: 19px;
        height: 19px;
        line-height: 19px;
    }
}

@media (max-device-width: 767px) {
    .rui-Input-medium input.rui-Input-input {
        height: 55px;
    }
}

@media (max-device-width: 767px) {
    .rui-Input-medium input.rui-Input-input {
        &[type="month"], &[type="date"], &[type="time"] {
            line-height: 45px;
        }
    }
}

@media (max-device-width: 767px) {
    .rui-Input-medium .rui-Input-input {
        font-size: 16px;
    }
}

.rui-Input-small {
    .rui-Input-input {
        font-size: 13px;
    }

    input.rui-Input-input {
        height: 35px;
    }

    .rui-Input-icon, .rui-Input-eye {
        width: 15px;
        height: 15px;
        line-height: 15px;
    }

    .rui-Input-placeholder {
        font-size: 13px;
        pointer-events: none;
    }

    &.rui-Input-withOutline, &.rui-Input-regular {
        .rui-Input-placeholder {
            padding-left: 15px;
        }

        .rui-Input-input {
            padding-left: 15px;
            padding-right: 15px;
        }
    }

    &.rui-Input-withLeftIcon {
        &.rui-Input-regular .rui-Input-input {
            padding-left: 44px;
        }

        &.rui-Input-awesome .rui-Input-input {
            padding-left: 45px;
        }

        &.rui-Input-promo .rui-Input-input {
            padding-left: 30px;
        }
    }

    &.rui-Input-withRightIcon.rui-Input-regular .rui-Input-input {
        padding-right: 44px;
    }

    &.rui-Input-withEye {
        &.rui-Input-regular .rui-Input-input {
            padding-right: 44px;
        }

        &.rui-Input-withRightIcon.rui-Input-regular .rui-Input-input {
            padding-right: 74px;
        }
    }

    &.rui-Input-withRightIcon.rui-Input-awesome .rui-Input-input {
        padding-right: 45px;
    }

    &.rui-Input-withEye {
        &.rui-Input-awesome .rui-Input-input {
            padding-right: 45px;
        }

        &.rui-Input-withRightIcon.rui-Input-awesome .rui-Input-input {
            padding-right: 75px;
        }
    }

    &.rui-Input-withRightIcon.rui-Input-promo .rui-Input-input {
        padding-right: 30px;
    }

    &.rui-Input-withEye {
        &.rui-Input-promo .rui-Input-input {
            padding-right: 30px;
        }

        &.rui-Input-withRightIcon.rui-Input-promo .rui-Input-input {
            padding-right: 60px;
        }

        &.rui-Input-regular .rui-Input-iconRight, &.rui-Input-awesome .rui-Input-iconRight {
            right: 45px;
        }

        &.rui-Input-promo .rui-Input-iconRight {
            right: 30px;
        }
    }

    &.rui-Input-regular .rui-Input-iconLeft, &.rui-Input-awesome .rui-Input-iconLeft {
        left: 15px;
    }

    &.rui-Input-promo .rui-Input-iconLeft {
        left: 0;
    }

    &.rui-Input-regular .rui-Input-iconRight, &.rui-Input-awesome .rui-Input-iconRight {
        right: 15px;
    }

    &.rui-Input-promo .rui-Input-iconRight {
        right: 0;
    }

    &.rui-Input-inGroup {
        &.rui-Input-promo {
            &:not(.rui-Input-endPosition) {
                &.rui-Input-withRightIcon .rui-Input-input {
                    padding-right: 45px;
                }

                &.rui-Input-withEye {
                    .rui-Input-input {
                        padding-right: 45px;
                    }

                    &.rui-Input-withRightIcon .rui-Input-input {
                        padding-right: 74px;
                    }

                    .rui-Input-iconRight {
                        right: 45px;
                    }
                }

                .rui-Input-iconRight {
                    right: 15px;
                }
            }

            &:not(.rui-Input-startPosition) {
                &.rui-Input-withLeftIcon .rui-Input-input {
                    padding-left: 45px;
                }

                .rui-Input-iconLeft {
                    left: 15px;
                }
            }
        }

        &.rui-Input-regular:not(.rui-Input-endPosition) {
            .rui-Input-input, .rui-Input-activeBorder {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
        }

        &.rui-Input-awesome:not(.rui-Input-endPosition) {
            .rui-Input-input, .rui-Input-activeBorder {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
        }

        &.rui-Input-regular:not(.rui-Input-startPosition) {
            .rui-Input-input, .rui-Input-activeBorder {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
        }

        &.rui-Input-awesome:not(.rui-Input-startPosition) {
            .rui-Input-input, .rui-Input-activeBorder {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
        }
    }

    .rui-Input-eyeWrapper:after {
        top: -10px;
        left: -10px;
        right: -10px;
        bottom: -10px;
        display: block;
        content: " ";
        position: absolute;
    }
}

@media (max-device-width: 767px) {
    .rui-Input-small.rui-Input-inGroup.rui-Input-promo:not(.rui-Input-endPosition) .rui-Input-iconRight {
        right: 15px;
    }
}

@media (max-device-width: 767px) {
    .rui-Input-small.rui-Input-inGroup.rui-Input-promo:not(.rui-Input-endPosition).rui-Input-withEye .rui-Input-iconRight {
        right: 50px;
    }
}

@media (max-device-width: 767px) {
    .rui-Input-small.rui-Input-inGroup.rui-Input-promo:not(.rui-Input-endPosition).rui-Input-withEye.rui-Input-withRightIcon .rui-Input-input {
        padding-right: 84px;
    }
}

@media (max-device-width: 767px) {
    .rui-Input-small.rui-Input-inGroup.rui-Input-promo:not(.rui-Input-endPosition) {
        &.rui-Input-withRightIcon .rui-Input-input, &.rui-Input-withEye .rui-Input-input {
            padding-right: 50px;
        }
    }
}

@media (max-device-width: 767px) {
    .rui-Input-small.rui-Input-inGroup.rui-Input-promo:not(.rui-Input-startPosition) .rui-Input-iconLeft {
        left: 15px;
    }
}

@media (max-device-width: 767px) {
    .rui-Input-small.rui-Input-inGroup.rui-Input-promo:not(.rui-Input-startPosition).rui-Input-withLeftIcon .rui-Input-input {
        padding-left: 50px;
    }
}

@media (max-device-width: 767px) {
    .rui-Input-small {
        &.rui-Input-regular .rui-Input-iconRight, &.rui-Input-awesome .rui-Input-iconRight {
            right: 15px;
        }
    }
}

@media (max-device-width: 767px) {
    .rui-Input-small {
        &.rui-Input-regular .rui-Input-iconLeft, &.rui-Input-awesome .rui-Input-iconLeft {
            left: 15px;
        }
    }
}

@media (max-device-width: 767px) {
    .rui-Input-small.rui-Input-withEye.rui-Input-promo .rui-Input-iconRight {
        right: 35px;
    }
}

@media (max-device-width: 767px) {
    .rui-Input-small.rui-Input-withEye {
        &.rui-Input-regular .rui-Input-iconRight, &.rui-Input-awesome .rui-Input-iconRight {
            right: 50px;
        }
    }
}

@media (max-device-width: 767px) {
    .rui-Input-small.rui-Input-withEye.rui-Input-withRightIcon.rui-Input-promo .rui-Input-input {
        padding-right: 70px;
    }
}

@media (max-device-width: 767px) {
    .rui-Input-small {
        &.rui-Input-withRightIcon.rui-Input-promo .rui-Input-input, &.rui-Input-withEye.rui-Input-promo .rui-Input-input {
            padding-right: 35px;
        }
    }
}

@media (max-device-width: 767px) {
    .rui-Input-small.rui-Input-withEye.rui-Input-withRightIcon.rui-Input-awesome .rui-Input-input {
        padding-right: 85px;
    }
}

@media (max-device-width: 767px) {
    .rui-Input-small {
        &.rui-Input-withRightIcon.rui-Input-awesome .rui-Input-input, &.rui-Input-withEye.rui-Input-awesome .rui-Input-input {
            padding-right: 50px;
        }
    }
}

@media (max-device-width: 767px) {
    .rui-Input-small.rui-Input-withEye.rui-Input-withRightIcon.rui-Input-regular .rui-Input-input {
        padding-right: 84px;
    }
}

@media (max-device-width: 767px) {
    .rui-Input-small {
        &.rui-Input-withRightIcon.rui-Input-regular .rui-Input-input, &.rui-Input-withEye.rui-Input-regular .rui-Input-input {
            padding-right: 49px;
        }
    }
}

@media (max-device-width: 767px) {
    .rui-Input-small.rui-Input-withLeftIcon.rui-Input-promo .rui-Input-input {
        padding-left: 35px;
    }
}

@media (max-device-width: 767px) {
    .rui-Input-small.rui-Input-withLeftIcon.rui-Input-awesome .rui-Input-input {
        padding-left: 50px;
    }
}

@media (max-device-width: 767px) {
    .rui-Input-small.rui-Input-withLeftIcon.rui-Input-regular .rui-Input-input {
        padding-left: 49px;
    }
}

@media (max-device-width: 767px) {
    .rui-Input-small.rui-Input-withOutline .rui-Input-input,  .rui-Input-small.rui-Input-regular .rui-Input-input {
        padding-left: 15px;
        padding-right: 15px;
    }
}

@media (max-device-width: 767px) {
    .rui-Input-small.rui-Input-withOutline .rui-Input-placeholder, .rui-Input-small.rui-Input-regular .rui-Input-placeholder {
        padding-left: 15px;
    }
}

@media (max-device-width: 767px) {
    .rui-Input-small .rui-Input-placeholder {
        font-size: 16px;
    }
}

@media (max-device-width: 767px) {
    .rui-Input-small .rui-Input-eyeWrapper:after {
        top: -8px;
        bottom: -8px;
    }
}

@media (max-device-width: 767px) {
    .rui-Input-small .rui-Input-eye {
        width: 19px;
        height: 19px;
        line-height: 19px;
    }
}

@media (max-device-width: 767px) {
    .rui-Input-small .rui-Input-icon {
        width: 19px;
        height: 19px;
        line-height: 19px;
    }
}

@media (max-device-width: 767px) {
    .rui-Input-small input.rui-Input-input {
        height: 45px;
    }
}

@media (max-device-width: 767px) {
    .rui-Input-small input.rui-Input-input {
        &[type="month"], &[type="date"], &[type="time"] {
            line-height: 35px;
        }
    }
}

@media (max-device-width: 767px) {
    .rui-Input-small .rui-Input-input {
        font-size: 16px;
    }
}

.rui-Input-root {
    cursor: auto;
    widows: 2;
    margin: 0;
    hyphens: none;
    padding: 0;
    tab-size: 8;
    position: relative;
    direction: ltr;
    font-size: medium;
    font-style: normal;
    list-style: disc outside none;
    text-align: left;
    visibility: visible;
    box-sizing: border-box;
    empty-cells: show;
    font-weight: normal;
    line-height: normal;
    text-indent: 0;
    text-shadow: none;
    white-space: normal;
    caption-side: top;
    font-variant: normal;
    font-stretch: normal;
    word-spacing: normal;
    border-radius: 0;
    border-spacing: 0;
    vertical-align: baseline;
    letter-spacing: normal;
    text-transform: none;
    border-collapse: separate;
    text-align-last: auto;
}

.rui-Input-activeBorder {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 0 solid transparent;
    position: absolute;
    transition: all 140ms linear;
    pointer-events: none;
}

.rui-Input-input:focus + .rui-Input-activeBorder, .rui-Input-isEnabled.rui-Input-isFocused .rui-Input-activeBorder {
    transform: none;
    border-color: #409EF8;
}

.rui-Input-success.rui-Input-isEnabled .rui-Input-activeBorder {
    transform: none;
    border-color: #28bc00;
}

.rui-Input-error.rui-Input-isEnabled .rui-Input-activeBorder {
    transform: none;
    border-color: #ff564e;
}

.rui-Input-warning.rui-Input-isEnabled .rui-Input-activeBorder {
    transform: none;
    border-color: #ffc000;
}

.rui-Input-icon {
    top: 0;
    color: #b0b4c2;
    bottom: 0;
    margin: auto;
    position: absolute;
    font-size: 0;
}

.rui-Input-textareaRoot .rui-Input-icon {
    margin-top: 15px;
}

@media (max-device-width: 767px) {
    .rui-Input-textareaRoot .rui-Input-icon {
        margin-top: 20px;
    }
}

.rui-Input-eye {
    border: 0;
    cursor: pointer;
    outline: 0;
    pointer-events: auto;

    &:hover {
        color: #409EF8;
    }
}

.rui-Input-regular .rui-Input-eye, .rui-Input-awesome .rui-Input-eye {
    right: 15px;
}

.rui-Input-promo .rui-Input-eye {
    right: 0;
}

@media (max-device-width: 767px) {
    .rui-Input-regular .rui-Input-eye, .rui-Input-awesome .rui-Input-eye {
        right: 20px;
    }
}

.rui-Input-iconLeft, .rui-Input-iconRight {
    pointer-events: none;
}

.rui-Input-placeholder {
    top: 2px;
    left: 1px;
    color: #a4a9b8;
    height: calc(100% - 5px);
    display: flex;
    opacity: 1;
    position: absolute;
    background: #fff;
    transition: opacity 140ms linear;
    align-items: center;
}


.rui-Button-button {
    cursor: pointer;
    widows: 2;
    margin: 0;
    border: none;
    hyphens: none;
    padding: 0;
    outline: none !important;
    display: inline-block;
    tab-size: 8;
    position: relative;
    direction: ltr;
    font-size: medium;
    list-style: disc outside none;
    text-align: center;
    visibility: visible;
    box-sizing: border-box;
    empty-cells: show;
    font-weight: 500;
    line-height: normal;
    text-indent: 0;
    text-shadow: none;
    white-space: normal;
    user-select: none;
    caption-side: top;
    font-variant: normal;
    font-stretch: normal;
    word-spacing: normal;
    border-radius: 4px;
    border-spacing: 0;
    vertical-align: baseline;
    letter-spacing: 1.3px;
    text-transform: uppercase;
    border-collapse: separate;
    text-align-last: auto;
    text-decoration: none !important;
    transition: background-color .2s, border .2s, box-shadow .2s;

    * {
        transition: background-color .2s, border .2s, box-shadow .2s;
    }

    &:before, &:after {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border: 1px solid transparent;
        content: "";
        display: block;
        position: absolute;
        transition: all .2s;
        border-radius: 1px;
        pointer-events: none;
    }

    &[disabled] {
        pointer-events: none;
    }

    &::-moz-focus-inner {
        border: none !important;
        outline: none !important;
    }
}

@media (max-device-width: 767px) {
    .rui-Button-button {
        font-weight: 500;
        letter-spacing: 1.4px;
    }
}

.rui-Button-isRounded {
    &.rui-Button-button, &:before, &:after {
        border-radius: 9999px !important;
    }
}

.rui-Button-isLoading {
    opacity: 0;
    pointer-events: none;
}

.rui-Button-loader {
    font-size: 3px;
}

@media (max-device-width: 767px) {
    .rui-Button-loader {
        font-size: 4px;
    }
}

.rui-Button-content {
    display: block;
    padding: 0 20px;
    overflow: hidden;
    position: relative;
    white-space: nowrap;
    text-overflow: ellipsis;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    &:before {
        width: 0;
        height: 100%;
        display: inline-block;
        content: "";
        vertical-align: middle;
    }

    > * {
        vertical-align: middle;
    }
}

@media (max-device-width: 767px) {
    .rui-Button-content {
        padding: 0 25px;
    }
}

.rui-Button-block {
    width: 100%;
    display: block;
}

.rui-Button-size-big {
    font-size: 24px;

    .rui-Button-content {
        height: 56px;
        line-height: 56px;
    }
}

@media (max-device-width: 767px) {
    .rui-Button-size-big {
        font-size: 16px;
    }
}

@media (max-device-width: 767px) {
    .rui-Button-size-big .rui-Button-content {
        height: 42px;
        line-height: 42px;
    }
}

.rui-Button-size-medium {
    font-size: 11px;

    .rui-Button-content {
        height: 45px;
        line-height: 45px;
    }
}

@media (max-device-width: 767px) {
    .rui-Button-size-medium {
        font-size: 13px;
    }
}

@media (max-device-width: 767px) {
    .rui-Button-size-medium .rui-Button-content {
        height: 55px;
        line-height: 55px;
    }
}

.rui-Button-size-small {
    font-size: 11px;

    .rui-Button-content {
        height: 35px;
        line-height: 35px;
    }
}

@media (max-device-width: 767px) {
    .rui-Button-size-small {
        font-size: 13px;
    }
}

@media (max-device-width: 767px) {
    .rui-Button-size-small .rui-Button-content {
        height: 45px;
        line-height: 45px;
    }
}

.rui-Button-overlay {
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    bottom: 0;
    height: 100%;
    cursor: pointer;
    z-index: 1;
    opacity: 0;
    position: absolute;
}

.rui-Button-icon {
    display: inline-block;
    margin-top: -2px;
    transition: fill .2s;
}

.rui-Button-iconPosition-right .rui-Button-icon {
    margin-left: 5px;
}

@media (max-device-width: 767px) {
    .rui-Button-iconPosition-right .rui-Button-icon {
        margin-left: 10px;
    }
}

.rui-Button-iconPosition-left .rui-Button-icon {
    margin-right: 5px;
}

@media (max-device-width: 767px) {
    .rui-Button-iconPosition-left .rui-Button-icon {
        margin-right: 10px;
    }
}

.rui-Button-type-primary {
    background: #409EF8;

    &:active:active {
        background: rgb(51, 126, 198);
    }

    &:hover {
        background: rgb(57, 142, 223);
    }

    &[disabled] {
        background: #f5f7f8;
    }

    color: #fff;

    * {
        color: #fff;
    }

    .rui-Button-icon {
        fill: #fff;
    }

    .rui-Button-loader {
        color: #fff;
    }
}



html[data-focus-source="other"] .rui-Button-type-primary:focus:after {
    top: -3px;
    left: -3px;
    right: -3px;
    bottom: -3px;
    border-color: #409EF8;
    border-radius: 3px;
}

.rui-Button-type-primary[disabled] {
    color: #afb5c9;

    * {
        color: #afb5c9;
    }

    .rui-Button-icon {
        fill: rgb(199, 202, 212);
    }
}

.rui-Button-type-secondary {
    background: #f5f7f8;

    &:active:active, &:hover {
        background: rgb(239, 240, 242);
    }

    &[disabled] {
        background: #f5f7f8;
    }

    color: #262626;

    * {
        color: #262626;
    }

    .rui-Button-icon {
        fill: #409EF8;
    }

    .rui-Button-loader {
        color: #409EF8;
    }
}

html[data-focus-source="other"] .rui-Button-type-secondary:focus:after {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-color: #409EF8;
    border-radius: 1px;
}

.rui-Button-type-secondary {
    &[disabled] {
        color: #afb5c9;

        * {
            color: #afb5c9;
        }

        .rui-Button-icon {
            fill: rgb(199, 202, 212);
        }
    }

    &:hover {
        color: #409EF8;

        * {
            color: #409EF8;
        }
    }

    &:active:active {
        color: rgb(51, 126, 198);

        * {
            color: rgb(51, 126, 198);
        }

        .rui-Button-icon {
            fill: rgb(51, 126, 198);
        }
    }
}

.rui-Button-type-outline {
    background: transparent;

    &:active:active {
        background: #f5f7f8;
    }

    color: #262626;

    * {
        color: #262626;
    }

    &:before {
        border-color: rgb(231, 232, 236);
    }

    .rui-Button-icon {
        fill: #409EF8;
    }

    .rui-Button-loader {
        color: #409EF8;
    }
}

html[data-focus-source="other"] .rui-Button-type-outline:focus:after {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-color: #409EF8;
    border-radius: 1px;
}

.rui-Button-type-outline {
    &[disabled] {
        color: #afb5c9;

        * {
            color: #afb5c9;
        }

        &:before {
            border-color: rgb(239, 240, 242);
        }

        .rui-Button-icon {
            fill: rgb(199, 202, 212);
        }
    }

    &:hover {
        color: #409EF8;

        * {
            color: #409EF8;
        }

        &:before {
            border-color: #409EF8;
        }
    }

    &:active:active {
        color: rgb(51, 126, 198);

        * {
            color: rgb(51, 126, 198);
        }

        &:before {
            border-color: rgb(51, 126, 198);
        }

        .rui-Button-icon {
            fill: rgb(51, 126, 198);
        }
    }
}

.rui-Button-type-flat {
    background: transparent;

    &:active:active, &:hover {
        background: #f5f7f8;
    }

    color: #262626;

    * {
        color: #262626;
    }

    .rui-Button-icon {
        fill: #409EF8;
    }

    .rui-Button-loader {
        color: #409EF8;
    }
}

html[data-focus-source="other"] .rui-Button-type-flat:focus:after {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-color: #409EF8;
    border-radius: 1px;
}

.rui-Button-type-flat[disabled] {
    color: #afb5c9;

    * {
        color: #afb5c9;
    }

    .rui-Button-icon {
        fill: rgb(199, 202, 212);
    }
}

.rui-Button-type-danger {
    background: #ff564e;

    &:active:active {
        background: rgb(204, 68, 62);
    }

    &:hover {
        background: rgb(229, 77, 70);
    }

    &[disabled] {
        background: #f5f7f8;
    }

    color: #fff;

    * {
        color: #fff;
    }

    .rui-Button-icon {
        fill: #fff;
    }

    .rui-Button-loader {
        color: #fff;
    }
}

html[data-focus-source="other"] .rui-Button-type-danger:focus:after {
    top: -3px;
    left: -3px;
    right: -3px;
    bottom: -3px;
    border-color: #ff564e;
    border-radius: 3px;
}

.rui-Button-type-danger[disabled] {
    color: #afb5c9;

    * {
        color: #afb5c9;
    }

    .rui-Button-icon {
        fill: rgb(199, 202, 212);
    }
}

.rui-Switcher-root {
    color: #262626;
    cursor: pointer;
    widows: 2;
    margin: 0;
    hyphens: none;
    padding: 0;
    display: inline-flex;
    tab-size: 8;
    position: relative;
    direction: ltr;
    font-size: 13px;
    font-style: normal;
    list-style: disc outside none;
    text-align: left;
    visibility: visible;
    empty-cells: show;
    font-weight: normal;
    line-height: 15px;
    text-indent: 0;
    text-shadow: none;
    white-space: normal;
    align-items: flex-start;
    caption-side: top;
    font-variant: normal;
    font-stretch: normal;
    word-spacing: normal;
    border-radius: 0;
    border-spacing: 0;
    vertical-align: top;
    letter-spacing: normal;
    text-transform: none;
    border-collapse: separate;
    text-align-last: auto;
    justify-content: space-between;

    .rui-Switcher-switcher {
        background-color: #ccc;
    }

    .rui-Switcher-track {
        background-color: #fff;
    }

    &.rui-Switcher-disabled {
        color: #dde0e8;

        .rui-Switcher-switcher {
            background-color: rgb(238, 239, 243);
        }

        .rui-Switcher-track {
            background-color: #fff;
        }
    }

    &:active .rui-Switcher-track {
        background-color: rgba(255, 255, 255, 0.9);
    }

    &:hover .rui-Switcher-switcher {
        background-color: #b0b4c2;
    }
}

.rui-Switcher-checked {
    color: #262626;

    .rui-Switcher-switcher {
        background-color: #409EF8;
    }

    .rui-Switcher-track {
        left: 16px;
    }

    &.rui-Switcher-disabled {
        color: #dde0e8;

        .rui-Switcher-switcher {
            background-color: #dde0e8;
        }

        .rui-Switcher-track {
            background-color: #fff;
        }
    }

    &:active .rui-Switcher-track {
        background-color: rgba(255, 255, 255, 0.9);
    }

    &:hover .rui-Switcher-switcher {
        background-color: rgb(51, 126, 198);
    }
}

.rui-Switcher-disabled {
    pointer-events: none;
}

.rui-Switcher-left {
    flex-direction: row;

    .rui-Switcher-label {
        margin-left: 10px;
    }
}

.rui-Switcher-right {
    flex-direction: row-reverse;

    .rui-Switcher-label {
        margin-right: 10px;
    }
}

.rui-Switcher-checkbox {
    opacity: 0;
    position: absolute;
    pointer-events: none;
    -webkit-appearance: none;
}

.rui-Switcher-switcher {
    width: 30px;
    height: 15px;
    position: relative;
    margin-top: 1px;
    flex-shrink: 0;
    border-radius: 9999px;
    transition-property: background;
    transition-duration: 200ms;
}

.rui-Switcher-track {
    top: 1px;
    left: 1px;
    width: 13px;
    height: 13px;
    position: absolute;
    border-radius: 9999px;
    transition-property: left, background;
    transition-duration: 200ms;
}

.rui-Switcher-label {
    cursor: pointer;
    font-weight: normal;
    line-height: 1.43;
}

@-webkit-keyframes ruiAnimateDot1 {
    0% {
        transform: scale(1) translate3d(0, 0, 0);
    }

    20% {
        transform: scale(1) translate3d(0, 100%, 0);
    }

    80% {
        transform: scale(1) translate3d(0, 0, 0);
    }
}

@-webkit-keyframes ruiAnimateDot2 {
    13.333333333333334% {
        transform: scale(1) translate3d(0, 0, 0);
    }

    33.333333333333336% {
        transform: scale(1) translate3d(0, 100%, 0);
    }

    93.33333333333333% {
        transform: scale(1) translate3d(0, 0, 0);
    }
}

@-webkit-keyframes ruiAnimateDot3 {
    26.666666666666668% {
        transform: scale(1) translate3d(0, 0, 0);
    }

    46.66666666666667% {
        transform: scale(1) translate3d(0, 100%, 0);
    }

    106.66666666666667% {
        transform: scale(1) translate3d(0, 0, 0);
    }
}

.rui-Spinner-spinner {
    color: #315efb;
    cursor: auto;
    widows: 2;
    margin: 0;
    hyphens: none;
    padding: 0;
    display: inline-block;
    tab-size: 8;
    direction: ltr;
    font-size: 5px;
    font-style: normal;
    list-style: disc outside none;
    text-align: left;
    visibility: visible;
    empty-cells: show;
    font-family: inherit;
    font-weight: normal;
    line-height: normal;
    text-indent: 0;
    text-shadow: none;
    white-space: nowrap;
    caption-side: top;
    font-variant: normal;
    font-stretch: normal;
    word-spacing: normal;
    border-radius: 0;
    border-spacing: 0;
    vertical-align: middle;
    letter-spacing: normal;
    text-transform: none;
    pointer-events: none;
    border-collapse: separate;
    text-align-last: auto;

    &:before {
        width: 0;
        height: 100%;
        display: inline-block;
        content: "";
        vertical-align: middle;
    }

    > * {
        vertical-align: middle;
    }
}

.rui-Spinner-position {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    text-align: center;
}

.rui-Spinner-dot {
    top: -0.4em;
    width: 1em;
    color: inherit !important;
    height: 1em;
    display: inline-block;
    position: relative;
    transform: translate3d(0, 0, 0);
    animation: .6s ease-out;
    background: currentColor;
    transition: transform .6s ease-out;
    border-radius: 50%;
    vertical-align: middle;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;

    &:nth-child(1) {
        animation-name: ruiAnimateDot1;
    }

    &:nth-child(2) {
        margin: 0 1em;
        animation-name: ruiAnimateDot2;
    }

    &:nth-child(3) {
        animation-name: ruiAnimateDot3;
    }
}

.rui-Popup-backdrop {
    top: 0;
    left: 0;
    right: 0;
    cursor: auto;
    widows: 2;
    margin: 0;
    bottom: 0;
    hyphens: none;
    padding: 0;
    opacity: 0;
    tab-size: 8;
    position: fixed;
    direction: ltr;
    font-size: medium;
    font-style: normal;
    list-style: disc outside none;
    text-align: center;
    visibility: visible;
    overflow-y: auto;
    overflow-x: hidden;
    margin-top: -10px;
    empty-cells: show;
    font-family: Inter, sans-serif;
    font-weight: normal;
    line-height: normal;
    text-indent: 0;
    text-shadow: none;
    white-space: normal;
    padding-top: 20px;
    caption-side: top;
    font-variant: normal;
    font-stretch: normal;
    word-spacing: normal;
    border-radius: 0;
    border-spacing: 0;
    vertical-align: baseline;
    letter-spacing: normal;
    text-transform: none;
    padding-bottom: 20px;
    border-collapse: separate;
    text-align-last: auto;
    transition-duration: 200ms;
    transition-property: margin-top, opacity;

    &:before {
        width: 0;
        height: 100%;
        display: inline-block;
        content: "";
        vertical-align: middle;
    }

    > * {
        vertical-align: middle;
    }
}

.rui-Popup-backdrop-black {
    background-color: rgba(0, 0, 0, 0.8);
}

.rui-Popup-backdrop-blue {
    background-color: rgba(52, 59, 76, 0.95);
}

.rui-Popup-isVisible {
    opacity: 1;
    margin-top: 0;
}

.rui-Popup-popup {
    color: #262626;
    display: inline-block;
    padding: 20px 25px 25px;
    outline: none;
    position: relative;
    min-width: 300px;
    max-width: calc(100% - 20px);
    font-size: 13px;
    box-sizing: border-box;
    box-shadow: -2px 2px 10px 0 rgba(102, 116, 166, 0.2);
    text-align: left;
    margin-left: 10px;
    line-height: 20px;
    margin-right: 10px;
    border-radius: 1px;
    background-color: #fff;
}

@media (min-device-width: 768px) {
    .rui-Popup-popup {
        min-width: 350px;
        max-width: auto;
    }
}

.rui-Popup-title {
    font-size: 20px;
    line-height: 25px;
    font-weight: 500;
    margin-bottom: 20px;
    padding-right: 25px;
}

.rui-Popup-close {
    top: 25px;
    right: 25px;
    width: 15px;
    color: #262626;
    border: 0;
    margin: 0;
    height: 15px;
    cursor: pointer;
    padding: 0;
    outline: 0;
    position: absolute;
    background: transparent;

    &:hover {
        color: #315efb;
    }
}

html[data-focus-source="other"] .rui-Popup-close:focus {
    color: #315efb;
}

.rui-Popup-buttons {
    display: flex;
    margin-top: 30px;
    justify-content: space-between;

    > * {
        width: 50%;
        flex-grow: 1;

        + * {
            margin-left: 10px;
        }
    }
}

@media (min-device-width: 768px) {
    .rui-Popup-buttons > * + * {
        margin-left: 20px;
    }
}
.rui-Dropdown-dropdown {
    cursor: auto;
    widows: 2;
    margin: 0;
    hyphens: none;
    padding: 0;
    opacity: 0.01;
    outline: none;
    tab-size: 8;
    position: relative;
    direction: ltr;
    font-size: medium;
    font-style: normal;
    list-style: disc outside none;
    text-align: left;
    visibility: visible;
    box-sizing: border-box;
    background: #fff;
    box-shadow: -2px 2px 10px 0 rgba(102, 116, 166, 0.2);
    empty-cells: show;
    font-family: Inter, sans-serif;
    font-weight: normal;
    line-height: normal;
    text-indent: 0;
    text-shadow: none;
    white-space: normal;
    caption-side: top;
    font-variant: normal;
    font-stretch: normal;
    word-spacing: normal;
    border-radius: 1px;
    border-spacing: 0;
    vertical-align: baseline;
    letter-spacing: normal;
    text-transform: none;
    pointer-events: none;
    border-collapse: separate;
    text-align-last: auto;
    transition-duration: 200ms;
    transition-property: opacity, top;
}

.rui-Dropdown-pointY-bottom {
    top: -10px;
}

.rui-Dropdown-pointY-top {
    top: 10px;
}

.rui-Dropdown-isVisible {
    top: 0;
    opacity: 1;
    pointer-events: auto;
}

.rui-Dropdown-withArrow {
    &:before {
        border: 5px solid transparent;
        position: absolute;
        pointer-events: none;
    }

    &.rui-Dropdown-pointY-top:before {
        top: -5px;
        content: "";
        border-top-width: 0;
        border-bottom-color: #fff;
    }

    &.rui-Dropdown-pointY-bottom:before {
        bottom: -5px;
        content: "";
        border-top-color: #fff;
        border-bottom-width: 0;
    }

    &.rui-Dropdown-pointX-left:before {
        left: 15px;
    }

    &.rui-Dropdown-pointX-center:before {
        left: calc(50% - 5px);
    }

    &.rui-Dropdown-pointX-right:before {
        right: 15px;
    }
}
.rui-RelativeOverlay-container {
    display: inline-block;
    position: relative;
}
.rui-RelativeOverlay-content {
    z-index: 10;
    position: absolute;
}

.rui-FormGroup-root {
    cursor: auto;
    widows: 2;
    margin: 0;
    hyphens: none;
    padding: 0;
    tab-size: 8;
    direction: ltr;
    font-size: 13px;
    font-style: normal;
    list-style: disc outside none;
    text-align: left;
    visibility: visible;
    empty-cells: show;
    font-weight: normal;
    line-height: 15px;
    text-indent: 0;
    text-shadow: none;
    white-space: normal;
    caption-side: top;
    font-variant: normal;
    font-stretch: normal;
    word-spacing: normal;
    border-radius: 0;
    border-spacing: 0;
    vertical-align: baseline;
    letter-spacing: normal;
    text-transform: none;
    border-collapse: separate;
    text-align-last: auto;
}

@media (max-device-width: 767px) {
    .rui-FormGroup-root {
        font-size: 14px;
        line-height: 20px;
    }
}

.rui-FormGroup-inline {
    margin-bottom: 15px;
}

.rui-FormGroup-label {
    width: 100%;
    display: inline-block;
    margin-bottom: 10px;
}

.rui-FormGroup-note {
    color: #9D9D9D;
    display: inline-block;
    margin-left: 10px;
}

.rui-FormGroup-inner {
    position: relative;
}

@media (min-width: 768px) {
    .rui-FormGroup-normal {
        margin-bottom: 30px;

        .rui-FormGroup-label {
            width: 100%;
        }
    }

    .rui-FormGroup-inline {
        display: flex;
        align-items: flex-start;
        margin-bottom: 30px;

        .rui-FormGroup-label {
            margin-left: 0;
            line-height: 1;
            margin-bottom: 0;
        }

        .rui-FormGroup-inner {
            flex: 1;
            width: auto;
        }
    }

    .rui-FormGroup-inner {
        width: 100%;
        display: inline-block;
        margin-left: 0;
        vertical-align: top;
    }

    .rui-FormGroup-small.rui-FormGroup-inline .rui-FormGroup-label {
        padding-top: 12px;
    }

    .rui-FormGroup-medium.rui-FormGroup-inline .rui-FormGroup-label {
        padding-top: 15px;
    }

    .rui-FormGroup-center {
        margin-bottom: 30px;

        .rui-FormGroup-label {
            width: 100%;
            text-align: center;
        }
    }
}

.rui-InputStatus-success .rui-InputStatus-message {
    color: #28bc00;
}

.rui-InputStatus-warning .rui-InputStatus-message {
    color: #ffc000;
}

.rui-InputStatus-error .rui-InputStatus-message {
    color: #ff564e;
}

.rui-InputStatus-message {
    cursor: auto;
    widows: 2;
    margin: 0;
    hyphens: none;
    padding: 0;
    tab-size: 8;
    direction: ltr;
    font-size: 13px;
    font-style: normal;
    list-style: disc outside none;
    text-align: left;
    visibility: visible;
    margin-top: 10px;
    empty-cells: show;
    font-weight: normal;
    line-height: 15px;
    text-indent: 0;
    text-shadow: none;
    white-space: normal;
    caption-side: top;
    font-variant: normal;
    font-stretch: normal;
    word-spacing: normal;
    border-radius: 0;
    border-spacing: 0;
    vertical-align: baseline;
    letter-spacing: normal;
    text-transform: none;
    border-collapse: separate;
    text-align-last: auto;
}

@media (max-device-width: 767px) {
    .rui-InputStatus-message {
        font-size: 14px;
        margin-top: 5px;
        line-height: 20px;
    }
}
.rui-MenuItem-root {
    cursor: auto;
    widows: 2;
    margin: 0;
    hyphens: none;
    padding: 0;
    display: flex;
    outline: 0;
    tab-size: 8;
    direction: ltr;
    font-size: 13px;
    flex-wrap: wrap;
    font-style: normal;
    list-style: disc outside none;
    text-align: left;
    visibility: visible;
    box-sizing: border-box;
    empty-cells: show;
    font-family: Inter, sans-serif;
    font-weight: normal;
    line-height: 19px;
    text-indent: 0;
    text-shadow: none;
    white-space: normal;
    align-items: center;
    caption-side: top;
    font-variant: normal;
    font-stretch: normal;
    word-spacing: normal;
    padding-left: 15px;
    border-radius: 0;
    padding-right: 15px;
    border-spacing: 0;
    vertical-align: baseline;
    letter-spacing: normal;
    text-transform: none;
    border-collapse: separate;
    text-align-last: auto;
}

.rui-MenuItem-medium {
    min-height: 45px;
    padding-top: 13px;
    padding-bottom: 13px;
}

.rui-MenuItem-small {
    min-height: 35px;
    padding-top: 8px;
    padding-bottom: 8px;
}

.rui-MenuItem-isEnabled {
    color: #262626;
    cursor: pointer;
    background-color: #fff;

    &:hover {
        color: #315efb;
        background-color: rgb(251, 251, 251);
    }

    &:focus {
        color: #262626;
        background: rgb(251, 251, 251);
    }

    &:active {
        color: rgb(39, 75, 200);
        background: rgb(247, 247, 248);
    }
}

.rui-MenuItem-isSelected {
    color: rgb(215, 217, 224);
}

.rui-MenuItem-isDisabled {
    color: rgb(215, 217, 224);
    cursor: not-allowed;
}
.rui-Menu-menu {
    cursor: auto;
    widows: 2;
    margin: 0;
    hyphens: none;
    padding: 0;
    tab-size: 8;
    direction: ltr;
    font-size: medium;
    font-style: normal;
    list-style: disc outside none;
    text-align: left;
    visibility: visible;
    box-sizing: border-box;
    overflow-y: auto;
    empty-cells: show;
    font-family: Inter, sans-serif;
    font-weight: normal;
    line-height: normal;
    text-indent: 0;
    text-shadow: none;
    white-space: normal;
    caption-side: top;
    font-variant: normal;
    font-stretch: normal;
    word-spacing: normal;
    border-radius: 0;
    border-spacing: 0;
    vertical-align: baseline;
    letter-spacing: normal;
    text-transform: none;
    border-collapse: separate;
    text-align-last: auto;

    &::-webkit-scrollbar {
        width: 4px;
        background-color: transparent;
    }

    &::-webkit-scrollbar-track {
        -webkit-box-shadow: none;
    }

    &::-webkit-scrollbar-thumb {
        background: #dcdfe7;
        border-radius: 0;
    }
}

.rui-Select-root {
    cursor: auto;
    widows: 2;
    margin: 0;
    hyphens: none;
    padding: 0;
    tab-size: 8;
    position: relative;
    direction: ltr;
    font-size: medium;
    font-style: normal;
    list-style: disc outside none;
    text-align: left;
    visibility: visible;
    empty-cells: show;
    font-family: inherit;
    font-weight: normal;
    line-height: normal;
    text-indent: 0;
    text-shadow: none;
    white-space: normal;
    caption-side: top;
    font-variant: normal;
    font-stretch: normal;
    word-spacing: normal;
    border-radius: 0;
    border-spacing: 0;
    vertical-align: baseline;
    letter-spacing: normal;
    text-transform: none;
    border-collapse: separate;
    text-align-last: auto;

    &.rui-Select-isDisabled {
        cursor: not-allowed;
    }

    &.rui-Select-isReadonly:not(.rui-Select-lightPlaceholder):not(.rui-Select-isDisabled) .rui-Select-input input {
        &::-webkit-input-placeholder, &::-moz-placeholder, &:-moz-placeholder, &:-ms-input-placeholder, &::placeholder {
            color: #262626;
            opacity: 1;
        }
    }

    &:not(.rui-Select-isFocused):not(.rui-Select-lightPlaceholder):not(.rui-Select-isDisabled) .rui-Select-input input {
        &::-webkit-input-placeholder, &::-moz-placeholder, &:-moz-placeholder, &:-ms-input-placeholder, &::placeholder {
            color: #262626;
            opacity: 1;
        }
    }

    &.rui-Select-isDisabled {
        .rui-Select-input {
            pointer-events: none;
        }

        .rui-Select-arrow {
            color: rgb(215, 217, 224) !important;
            pointer-events: none;
        }
    }

    &:hover .rui-Select-arrow, &.rui-Select-isFocused .rui-Select-arrow {
        color: #315efb;
    }
}

.rui-Select-dropdownContainer.rui-Select-dropdownContainer {
    display: block;
}

.rui-Select-isMultipleWithoutSearch {
    .rui-Select-dropdownContainer {
        top: 0;
        left: 0;
        right: 0;
        position: absolute;
    }

    .rui-Select-icon {
        top: auto;
        bottom: auto;
        margin: 0;
        transform: translateY(-50%);
    }
}

.rui-Select-arrow {
    color: #262626;
    cursor: pointer;
    text-align: center;
    line-height: 0;
    pointer-events: auto;

    svg {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        position: absolute;
        max-width: 100%;
        max-height: 100%;
    }

    &:empty:after {
        width: 8px;
        height: 8px;
        content: "";
        position: absolute;
        transform: rotate(-45deg) translateY(50%);
        border-style: solid;
        border-width: 0 0 1px 1px;
    }
}

.rui-Select-withCustom .rui-Select-input {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
}

.rui-Select-isReadonly .rui-Select-field {
    cursor: pointer;
    user-select: none;
}

.rui-Select-withSearch .rui-Select-field {
    cursor: text;
}

.rui-Select-withCustom {
    .rui-Select-field.rui-Select-field {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: 100%;
        position: absolute;
    }

    position: relative;
}

.rui-Select-isOpened .rui-Select-withCustom {
    z-index: 1;
}

.rui-Select-custom {
    position: relative;
    pointer-events: none;
}

.rui-Select-options-regular .rui-Select-multipleValueItem {
    color: #262626;
}

.rui-Select-dropdown.rui-Select-dropdown {
    border: 1px solid #dde0e8;
    box-shadow: none;
    border-bottom: 0;

    &.rui-Select-isMultipleDropdown {
        top: 0 !important;
        transition-property: opacity;
    }
}

.rui-Select-selected {
    cursor: default;
    border-bottom: 1px solid #dde0e8;
}

.rui-Select-menu {
    border-bottom: 1px solid #dde0e8;
}

.rui-Select-menuSize-small {
    max-height: 247px;

    &.rui-Select-reducedHeight {
        max-height: 212px;
    }
}

.rui-Select-menuSize-medium {
    max-height: 317px;

    &.rui-Select-reducedHeight {
        max-height: 272px;
    }
}

.rui-Select-clear {
    flex: none;
    fill: currentColor;
    color: #b0b4c2;
    cursor: pointer;
    align-self: center;
    margin-top: 1px;
    margin-left: 1px;
    pointer-events: auto;

    &:hover, &:active {
        color: #315efb;
    }
}

.rui-Select-medium {
    &.rui-Select-isMultipleWithoutSearch {
        height: 45px;

        .rui-Select-icon {
            top: 22.5px;
        }
    }

    .rui-Select-withCustom {
        min-height: 45px;
    }

    &.rui-Select-isOpened .rui-Select-arrow:empty:after {
        top: 9px;
        left: 1px;
        transform: rotate(45deg) translateY(-50%) scaleY(-1);
    }

    .rui-Select-custom {
        padding-left: 16px;
        padding-right: 16px;
    }

    &.rui-Select-withLeftIcon .rui-Select-custom {
        padding-left: 46px;
    }

    &.rui-Select-withRightIcon .rui-Select-custom {
        padding-right: 46px;
    }

    .rui-Select-options.rui-Select-options-regular {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .rui-Select-selected.rui-Select-options-regular {
        padding: 9px 14px;
    }

    .rui-Select-options.rui-Select-options-background {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .rui-Select-selected.rui-Select-options-background {
        padding: 9px 14px;
    }

    .rui-Select-arrow {

        &:before {
            top: -10px;
            left: -10px;
            right: -10px;
            bottom: -10px;
            display: block;
            content: " ";
            position: absolute;
        }

        &:empty:after {
            top: -2px;
            left: 1px;
        }
    }
}

@media (max-device-width: 767px) {
    .rui-Select-medium .rui-Select-arrow:before {
        top: -13px;
        bottom: -13px;
    }
}

.rui-Select-small {
    &.rui-Select-isMultipleWithoutSearch {
        height: 35px;

        .rui-Select-icon {
            top: 17.5px;
        }
    }

    .rui-Select-withCustom {
        min-height: 35px;
    }

    &.rui-Select-isOpened .rui-Select-arrow:empty:after {
        top: 9px;
        left: 1px;
        transform: rotate(45deg) translateY(-50%) scaleY(-1);
    }

    .rui-Select-custom {
        padding-left: 16px;
        padding-right: 16px;
    }

    &.rui-Select-withLeftIcon .rui-Select-custom {
        padding-left: 46px;
    }

    &.rui-Select-withRightIcon .rui-Select-custom {
        padding-right: 46px;
    }

    .rui-Select-options.rui-Select-options-regular {
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .rui-Select-selected.rui-Select-options-regular {
        padding: 4px 14px;
    }

    .rui-Select-options.rui-Select-options-background {
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .rui-Select-selected.rui-Select-options-background {
        padding: 4px 14px;
    }

    .rui-Select-arrow {
        &:before {
            top: -10px;
            left: -10px;
            right: -10px;
            bottom: -10px;
            display: block;
            content: " ";
            position: absolute;
        }

        &:empty:after {
            top: -2px;
            left: 1px;
        }
    }
}

@media (max-device-width: 767px) {
    .rui-Select-small .rui-Select-arrow:before {
        top: -8px;
        bottom: -8px;
    }
}

.rui-Select-isNative .rui-Select-icon {
    pointer-events: none;
}

.rui-Select-nativeSelect {
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    cursor: auto;
    widows: 2;
    margin: 0;
    bottom: 0;
    height: 100%;
    hyphens: none;
    padding: 0;
    opacity: 0;
    outline: 0;
    tab-size: 8;
    position: absolute;
    overflow: hidden;
    direction: ltr;
    font-size: medium;
    font-style: normal;
    list-style: disc outside none;
    text-align: left;
    visibility: visible;
    empty-cells: show;
    font-family: inherit;
    font-weight: normal;
    line-height: normal;
    text-indent: 0;
    text-shadow: none;
    white-space: normal;
    caption-side: top;
    font-variant: normal;
    font-stretch: normal;
    word-spacing: normal;
    border-radius: 0;
    border-spacing: 0;
    vertical-align: baseline;
    letter-spacing: normal;
    text-transform: none;
    border-collapse: separate;
    text-align-last: auto;
}

.rui-RadioButton-root {
    width: 100%;
    color: #262626;
    cursor: pointer;
    widows: 2;
    margin: 0;
    hyphens: none;
    padding: 0;
    display: flex;
    tab-size: 8;
    position: relative;
    direction: ltr;
    font-size: 13px;
    font-style: normal;
    list-style: disc outside none;
    text-align: left;
    visibility: visible;
    empty-cells: show;
    font-family: Inter, sans-serif;
    font-weight: normal;
    line-height: normal;
    text-indent: 0;
    text-shadow: none;
    white-space: normal;
    user-select: none;
    caption-side: top;
    font-variant: normal;
    font-stretch: normal;
    word-spacing: normal;
    border-radius: 0;
    border-spacing: 0;
    vertical-align: baseline;
    letter-spacing: normal;
    text-transform: none;
    border-collapse: separate;
    text-align-last: auto;

    &:not(:last-child) {
        margin-bottom: 15px;
    }
}

.rui-RadioButton-isDisabled {
    color: rgba(176, 180, 194, 0.5);
    cursor: not-allowed;
    pointer-events: none;
}

.rui-RadioButton-real {
    opacity: 0;
    position: absolute;
    font-family: Inter, sans-serif;
    pointer-events: none;
    -webkit-appearance: none;
}

.rui-RadioButton-fake {
    flex: none;
    width: 15px;
    height: 15px;
    border: 1px solid;
    display: inline-block;
    position: relative;
    box-sizing: border-box;
    background: #fff;
    margin-top: 3px;
    border-color: rgb(231, 232, 236);
    border-radius: 50%;
    vertical-align: middle;
    transition-duration: 200ms;
    transition-property: border-color, background-color, color;

    &:after {
        top: 0;
        left: 0;
        right: 0;
        width: 5px;
        bottom: 0;
        margin: auto;
        height: 5px;
        content: "";
        opacity: 0;
        position: absolute;
        transform: scale(0.5, 0.5);
        box-sizing: border-box;
        background: currentColor;
        border-radius: 50%;
        transition-duration: inherit;
        transition-property: opacity, transform, background-color;
    }
}

.rui-RadioButton-isChecked .rui-RadioButton-fake:after {
    opacity: 1;
    transform: scale(1, 1);
}

.rui-RadioButton-isEnabled:hover .rui-RadioButton-fake {
    color: #315efb;
    border-color: #315efb;
}

html[data-focus-source="other"] .rui-RadioButton-real:focus + .rui-RadioButton-fake {
    border-color: #315efb;
}

.rui-RadioButton-isEnabled:active .rui-RadioButton-fake {
    color: rgb(39, 75, 200);
    background: rgba(39, 75, 200, 0.1);
    border-color: rgb(39, 75, 200);
}

.rui-RadioButton-isDisabled .rui-RadioButton-fake {
    color: rgba(176, 180, 194, 0.5);
    border-color: rgba(176, 180, 194, 0.2);
}

.rui-RadioButton-labelright .rui-RadioButton-fake {
    margin-right: 10px;
}

.rui-RadioButton-labelleft .rui-RadioButton-fake {
    margin-left: 10px;
}

.rui-RadioButton-label {
    display: inline-block;
    line-height: 20px;
    vertical-align: middle;
}

.rui-RadioButton-labelleft .rui-RadioButton-label {
    order: -1;
    margin-right: auto;
}

.rui-RadioButtonGroup-radioButtonGroup {
    cursor: auto;
    widows: 2;
    margin: 0;
    hyphens: none;
    padding: 0;
    tab-size: 8;
    direction: ltr;
    font-size: medium;
    font-style: normal;
    list-style: disc outside none;
    text-align: left;
    visibility: visible;
    empty-cells: show;
    font-family: Inter, sans-serif;
    font-weight: normal;
    line-height: normal;
    text-indent: 0;
    text-shadow: none;
    white-space: normal;
    caption-side: top;
    font-variant: normal;
    font-stretch: normal;
    word-spacing: normal;
    border-radius: 0;
    border-spacing: 0;
    vertical-align: baseline;
    letter-spacing: normal;
    text-transform: none;
    border-collapse: separate;
    text-align-last: auto;
}

.rui-TagsInput-root {
    cursor: auto;
    widows: 2;
    margin: 0;
    hyphens: none;
    padding: 0;
    tab-size: 8;
    overflow: hidden;
    direction: ltr;
    font-size: 13px;
    font-style: normal;
    list-style: disc outside none;
    text-align: left;
    visibility: visible;
    empty-cells: show;
    font-family: Roboto, sans-serif;
    font-weight: 400;
    line-height: normal;
    text-indent: 0;
    text-shadow: none;
    white-space: normal;
    user-select: none;
    caption-side: top;
    font-variant: normal;
    font-stretch: normal;
    word-spacing: normal;
    border-radius: 0;
    border-spacing: 0;
    vertical-align: baseline;
    letter-spacing: normal;
    text-transform: none;
    border-collapse: separate;
    text-align-last: auto;
}

.rui-TagsInput-items {
    display: flex;
}

.rui-TagsInput-isDisabled .rui-TagsInput-items {
    pointer-events: none;
}

.rui-TagsInput-isExpanded .rui-TagsInput-items {
    flex-wrap: wrap;
}

.rui-TagsInput-item.rui-TagsInput-item {
    flex: none;
    align-self: flex-start;
    white-space: nowrap;
}

.rui-TagsInput-more {
    color: #b0b4c2;
    transition: color .2s;
}

.rui-TagsInput-isDisabled .rui-TagsInput-more {
    color: #afb5c9;
}

.rui-TagsInput-isClickable {
    pointer-events: auto;
}

.rui-TagsInput-isEnabled .rui-TagsInput-isClickable {
    cursor: pointer;
}

.rui-TagsInput-isDisabled .rui-TagsInput-isClickable {
    cursor: not-allowed;
}

.rui-TagsInput-isEnabled .rui-TagsInput-isClickable {
    &:hover {
        color: #315efb;
    }

    &:active {
        color: #274bc8;
    }
}

.rui-TagsInput-regular {
    .rui-TagsInput-items {
        margin-top: 0;
        min-height: 25px;
        margin-left: -15px;
    }

    .rui-TagsInput-item {
        max-width: calc(100% - 15px);
        margin-top: 0;
        margin-left: 15px;
        line-height: 25px;
    }
}

.rui-TagsInput-background {
    .rui-TagsInput-items {
        margin-top: -10px;
        min-height: 35px;
        margin-left: -5px;
    }

    .rui-TagsInput-item {
        max-width: calc(100% - 5px);
        margin-top: 10px;
        margin-left: 5px;
        line-height: 25px;
    }
}

.rui-TagsInput-isHidden.rui-TagsInput-isHidden {
    order: 1;
    visibility: hidden;
}
.rui-TagsInputItem-root {
    cursor: auto;
    widows: 2;
    margin: 0;
    hyphens: none;
    padding: 0;
    display: inline-flex;
    tab-size: 8;
    direction: ltr;
    font-size: 13px;
    font-style: normal;
    list-style: disc outside none;
    text-align: left;
    visibility: visible;
    transition: background-color .2s;
    empty-cells: show;
    font-family: inherit;
    font-weight: normal;
    line-height: normal;
    text-indent: 0;
    text-shadow: none;
    white-space: nowrap;
    caption-side: top;
    font-variant: normal;
    font-stretch: normal;
    word-spacing: normal;
    border-radius: 0;
    border-spacing: 0;
    vertical-align: baseline;
    letter-spacing: normal;
    text-transform: none;
    pointer-events: none;
    border-collapse: separate;
    text-align-last: auto;
}

.rui-TagsInputItem-icon {
    fill: currentColor;
    align-self: center;
    transition: fill .2s, color .2s;
}

.rui-TagsInputItem-text {
    flex: 0 1 auto;
    overflow: hidden;
    transition: color .2s;
    text-overflow: ellipsis;
}

.rui-TagsInputItem-remove {
    flex: none;
    order: 1;
    width: 1em;
    height: 1em;
    font-size: 15px;
    align-self: center;
    transition: fill .2s;
    pointer-events: auto;
}

.rui-TagsInputItem-isClickable {
    pointer-events: auto;
}

.rui-TagsInputItem-isEnabled {
    &.rui-TagsInputItem-isClickable, .rui-TagsInputItem-remove {
        cursor: pointer;
    }
}

.rui-TagsInputItem-isDisabled {
    &.rui-TagsInputItem-isClickable, .rui-TagsInputItem-remove {
        cursor: not-allowed;
    }
}

.rui-TagsInputItem-regular {
    line-height: 25px;

    .rui-TagsInputItem-icon {
        margin-right: 5px;

        + .rui-TagsInputItem-text {
            margin-left: 0;
        }
    }

    .rui-TagsInputItem-remove ~ .rui-TagsInputItem-text {
        margin-right: 0;
    }

    &.rui-TagsInputItem-isEnabled {
        color: #b0b4c2;
    }

    &.rui-TagsInputItem-isDisabled {
        color: #afb5c9;

        .rui-TagsInputItem-remove {
            fill: #afb5c9;
        }
    }

    &.rui-TagsInputItem-isEnabled {
        .rui-TagsInputItem-remove {
            fill: #b0b4c2;

            &:hover {
                fill: #315efb;
            }

            &:active {
                fill: #274bc8;
            }
        }

        &.rui-TagsInputItem-isClickable {
            &:active {
                .rui-TagsInputItem-remove:not(:active) ~ .rui-TagsInputItem-text.rui-TagsInputItem-text, .rui-TagsInputItem-text.rui-TagsInputItem-text:only-child, .rui-TagsInputItem-icon:first-child + .rui-TagsInputItem-text.rui-TagsInputItem-text {
                    color: #274bc8;
                }

                .rui-TagsInputItem-remove:not(:active) + .rui-TagsInputItem-icon.rui-TagsInputItem-icon, .rui-TagsInputItem-icon.rui-TagsInputItem-icon:first-child {
                    fill: currentColor !important;
                    color: #274bc8;
                }
            }

            &:hover {
                .rui-TagsInputItem-remove:not(:hover) ~ .rui-TagsInputItem-text, .rui-TagsInputItem-text:only-child, .rui-TagsInputItem-icon:first-child + .rui-TagsInputItem-text {
                    color: #315efb;
                }

                .rui-TagsInputItem-remove:not(:hover) + .rui-TagsInputItem-icon, .rui-TagsInputItem-icon:first-child {
                    fill: currentColor !important;
                    color: #315efb;
                }
            }
        }
    }
}

.rui-TagsInputItem-background {
    line-height: 25px;
    border-radius: 12.5px;

    .rui-TagsInputItem-icon {
        margin-left: 12px;
        margin-right: 5px;
    }

    .rui-TagsInputItem-remove {
        margin-left: 5px;
        margin-right: 10px;
    }

    .rui-TagsInputItem-text {
        margin-left: 15px;
        margin-right: 15px;
    }

    .rui-TagsInputItem-icon + .rui-TagsInputItem-text {
        margin-left: 0;
    }

    .rui-TagsInputItem-remove ~ .rui-TagsInputItem-text {
        margin-right: 0;
    }

    &.rui-TagsInputItem-isEnabled {
        color: #b0b4c2;
        background: #f5f7f8;
    }

    &.rui-TagsInputItem-isDisabled {
        color: #afb5c9;
        background: #f5f7f8;

        .rui-TagsInputItem-remove {
            fill: rgb(199, 202, 212);
        }
    }

    &.rui-TagsInputItem-isEnabled {
        .rui-TagsInputItem-remove {
            fill: #b0b4c2;

            &:hover {
                fill: #315efb;
            }

            &:active {
                fill: #274bc8;
            }
        }

        &.rui-TagsInputItem-isClickable {
            &:hover {
                background: rgb(239, 240, 242);
            }

            &:active {
                background: rgb(239, 240, 242);

                .rui-TagsInputItem-remove:not(:active) ~ .rui-TagsInputItem-text.rui-TagsInputItem-text, .rui-TagsInputItem-text.rui-TagsInputItem-text:only-child, .rui-TagsInputItem-icon:first-child + .rui-TagsInputItem-text.rui-TagsInputItem-text {
                    color: #274bc8;
                }

                .rui-TagsInputItem-remove:not(:active) + .rui-TagsInputItem-icon.rui-TagsInputItem-icon, .rui-TagsInputItem-icon.rui-TagsInputItem-icon:first-child {
                    fill: currentColor !important;
                    color: #274bc8;
                }
            }

            &:hover {
                .rui-TagsInputItem-remove:not(:hover) ~ .rui-TagsInputItem-text, .rui-TagsInputItem-text:only-child, .rui-TagsInputItem-icon:first-child + .rui-TagsInputItem-text {
                    color: #315efb;
                }

                .rui-TagsInputItem-remove:not(:hover) + .rui-TagsInputItem-icon, .rui-TagsInputItem-icon:first-child {
                    fill: currentColor !important;
                    color: #315efb;
                }
            }
        }
    }
}

.rui-Checkbox-checkbox {
    cursor: pointer;
    widows: 2;
    margin: 0;
    hyphens: none;
    padding: 0;
    display: inline-block;
    tab-size: 8;
    position: relative;
    direction: ltr;
    font-size: 13px;
    font-style: normal;
    list-style: disc outside none;
    text-align: left;
    visibility: visible;
    transition: color 200ms;
    empty-cells: show;
    font-family: Roboto, sans-serif;
    font-weight: normal;
    line-height: normal;
    text-indent: 0;
    text-shadow: none;
    white-space: normal;
    user-select: none;
    caption-side: top;
    font-variant: normal;
    font-stretch: normal;
    word-spacing: normal;
    border-radius: 0;
    border-spacing: 0;
    vertical-align: top;
    letter-spacing: normal;
    text-transform: none;
    border-collapse: separate;
    text-align-last: auto;
}

.rui-Checkbox-isDisabled {
    cursor: not-allowed;
    pointer-events: none;
}

.rui-Checkbox-regular {
    color: #262626;

    &.rui-Checkbox-isDisabled {
        color: rgba(176, 180, 194, 0.5);
    }

    .rui-Checkbox-fake {
        color: #262626;
        background: #fff;
        border-color: rgb(231, 232, 236);
    }

    &.rui-Checkbox-isEnabled {
        &:hover .rui-Checkbox-fake {
            color: #315efb;
            border-color: #315efb;
        }

        &:active .rui-Checkbox-fake {
            color: rgb(39, 75, 200);
            background: rgba(39, 75, 200, 0.1);
            border-color: rgb(39, 75, 200);
        }
    }

    &.rui-Checkbox-isDisabled .rui-Checkbox-fake {
        color: rgba(176, 180, 194, 0.5);
        background: rgba(176, 180, 194, 0.2);
        border-color: rgba(0, 0, 0, 0);
    }
}

html[data-focus-source="other"] .rui-Checkbox-regular .rui-Checkbox-real:focus ~ .rui-Checkbox-fake {
    border-color: #315efb;
}

.rui-Checkbox-awesome {
    color: #262626;

    &.rui-Checkbox-isDisabled {
        color: rgba(176, 180, 194, 0.5);
    }

    .rui-Checkbox-fake {
        color: #fff;
        background: #fff;
        border-color: rgb(231, 232, 236);
    }

    &.rui-Checkbox-isEnabled {
        &:hover .rui-Checkbox-fake {
            border-color: rgba(176, 180, 194, 0.7);
        }

        &.rui-Checkbox-isChecked .rui-Checkbox-fake {
            background: #315efb;
            border-color: transparent;
        }
    }

    &.rui-Checkbox-awesome.rui-Checkbox-indeterminate .rui-Checkbox-fake {
        background: #315efb;
        border-color: transparent;
    }

    &.rui-Checkbox-isEnabled {
        &.rui-Checkbox-isChecked:hover .rui-Checkbox-fake, &.rui-Checkbox-indeterminate:hover .rui-Checkbox-fake {
            background: rgb(39, 75, 200);
        }
    }

    &.rui-Checkbox-isDisabled .rui-Checkbox-fake {
        color: rgba(176, 180, 194, 0.5);
        background: rgba(176, 180, 194, 0.2);
        border-color: transparent;
    }
}

.rui-Checkbox-fake {
    display: block;
    position: absolute;
    box-sizing: border-box;
    line-height: 0;
    border-style: solid;
    border-width: 1px;
    border-radius: 1px;
    transition-duration: 200ms;
    transition-property: border-color, background-color, color;

    &:before {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0.5px;
        height: 2px;
        margin: auto 2px;
        content: "";
        opacity: 0;
        position: absolute;
        transform: scaleX(0.4);
        background: currentColor;
        border-radius: 1px;
        transition-duration: 200ms;
        transition-property: transform, opacity;
    }
}

.rui-Checkbox-indeterminate .rui-Checkbox-fake:before {
    opacity: 1;
    transform: scaleX(1);
}

.rui-Checkbox-iconright .rui-Checkbox-fake {
    right: 0;
}

.rui-Checkbox-iconleft .rui-Checkbox-fake {
    left: 0;
}

.rui-Checkbox-real {
    opacity: 0;
    position: absolute;
    pointer-events: none;
    -webkit-appearance: none;
}

.rui-Checkbox-label {
    display: inline-block;
    font-size: 13px;
    font-weight: normal;
}

.rui-Checkbox-tick {
    fill: currentColor;
    opacity: 0;
    position: absolute;
    transition-duration: 200ms;
    transition-property: transform, opacity;
}

.rui-Checkbox-isChecked .rui-Checkbox-tick {
    opacity: 1;
}

.rui-Checkbox-medium {
    &.rui-Checkbox-checkbox {
        line-height: 15px;
    }

    .rui-Checkbox-fake {
        top: 2px;
        width: 15px;
        height: 15px;
    }

    .rui-Checkbox-tick {
        top: 2px;
        left: 2px;
        width: 9px;
        height: 9px;
        transform: translateY(-4.5px);
    }

    .rui-Checkbox-label {
        line-height: 20px;
    }
}

.rui-Checkbox-iconright.rui-Checkbox-medium .rui-Checkbox-label {
    padding-right: 25px;
}

.rui-Checkbox-iconleft.rui-Checkbox-medium .rui-Checkbox-label {
    padding-left: 25px;
}

.rui-Checkbox-isChecked.rui-Checkbox-medium .rui-Checkbox-tick {
    transform: translateY(0);
}

.rui-Checkbox-small {
    &.rui-Checkbox-checkbox {
        line-height: 13px;
    }

    .rui-Checkbox-fake {
        top: 1px;
        width: 13px;
        height: 13px;
    }

    .rui-Checkbox-tick {
        top: -1px;
        left: -1px;
        width: 13px;
        height: 13px;
        transform: translateY(-3.9px);
    }

    .rui-Checkbox-label {
        line-height: 15px;
    }
}

.rui-Checkbox-iconright.rui-Checkbox-small .rui-Checkbox-label {
    padding-right: 18px;
}

.rui-Checkbox-iconleft.rui-Checkbox-small .rui-Checkbox-label {
    padding-left: 18px;
}

.rui-Checkbox-isChecked.rui-Checkbox-small .rui-Checkbox-tick {
    transform: translateY(0);
}
.crop-popup {
    width: 600px;
    height: 400px;
    position: relative;
}
.crop-container  {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    touch-action: none;
    cursor: move;

}
.crop-image {
    max-width: 100%;
    max-height: 100%;
    margin: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    will-change: transform;
}
.crop-cropper {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    box-shadow: 0px 0px 0px 9999em;
    color: rgba(0, 0, 0, 0.5);
    border-width: 1px;
    border-style: solid;
    border-color: rgba(255, 255, 255, 0.5);
    border-image: initial;
    overflow: hidden;
    &::before {
        content: " ";
        box-sizing: border-box;
        position: absolute;
        top: 0px;
        bottom: 0px;
        left: 33.33%;
        right: 33.33%;
        border-width: 0px 1px;
        border-style: solid solid;
        border-color: rgba(255, 255, 255, 0.5) rgba(255, 255, 255, 0.5);
        border-image: initial;
        border-top: 0px;
        border-bottom: 0px;
    }
    &::after {
        content: " ";
        box-sizing: border-box;
        position: absolute;
        top: 33.33%;
        bottom: 33.33%;
        left: 0px;
        right: 0px;
        border-width: 1px 0px;
        border-style: solid solid;
        border-color: rgba(255, 255, 255, 0.5) rgba(255, 255, 255, 0.5);
        border-image: initial;
        border-left: 0px;
        border-right: 0px;
    }
}

.rui-Toggle-toggle {
    cursor: auto;
    widows: 2;
    margin: 0;
    hyphens: none;
    padding: 0;
    display: inline-block;
    tab-size: 8;
    direction: ltr;
    font-size: medium;
    font-style: normal;
    list-style: disc outside none;
    text-align: left;
    visibility: visible;
    empty-cells: show;
    font-family: Roboto, sans-serif;
    font-weight: normal;
    line-height: normal;
    text-indent: 0;
    text-shadow: none;
    white-space: normal;
    caption-side: top;
    font-variant: normal;
    font-stretch: normal;
    word-spacing: normal;
    border-radius: 0;
    border-spacing: 0;
    vertical-align: baseline;
    letter-spacing: normal;
    text-transform: none;
    border-collapse: separate;
    text-align-last: auto;

    &.rui-Toggle-block {
        display: flex;
    }

    transition-duration: 200ms;
    transition-property: background, opacity, border, box-shadow;

    * {
        transition-duration: 200ms;
        transition-property: background, opacity, border, box-shadow;
    }
}

.rui-Toggle-option {
    &.rui-Toggle-option {
        color: #262626;
        cursor: pointer;
        display: inline-block;
        position: relative;
        text-align: center;
        background: #fff;
        font-family: Roboto, sans-serif;
        user-select: none;
        margin-left: -1px;
        border-style: solid;
        border-width: 1px;
        border-color: rgb(231, 232, 236);
    }

    &:first-child {
        margin-left: 0;
        border-top-left-radius: 1px;
        border-bottom-left-radius: 1px;
    }

    &:last-child {
        border-width: 1px;
        border-top-right-radius: 1px;
        border-bottom-right-radius: 1px;
    }

    &:enabled:hover, &:active {
        z-index: 1;
    }

    &:disabled {
        color: #dde0e8;
        cursor: not-allowed;
    }
}

.rui-Toggle-isSelected {
    z-index: 2;

    &:enabled:hover {
        z-index: 3;
    }
}

.rui-Toggle-regular .rui-Toggle-option {
    &:disabled {
        border-color: rgba(176, 180, 194, 0.2);
    }

    &:enabled:hover {
        color: #315efb;
        border-color: rgb(215, 217, 224);
    }
}

html[data-focus-source="other"] .rui-Toggle-regular .rui-Toggle-option:focus {
    color: #315efb;
}

.rui-Toggle-regular {
    .rui-Toggle-option:active {
        background: rgba(49, 94, 251, 0.1);
    }

    .rui-Toggle-isSelected {
        &:enabled {
            color: #315efb;
            border-color: #315efb;

            &:hover {
                color: rgb(39, 75, 200);
                border-color: rgb(39, 75, 200);
            }
        }

        &:disabled {
            background: rgb(247, 247, 248);
        }
    }
}

.rui-Toggle-transparent {
    .rui-Toggle-isSelected {
        background: rgba(176, 180, 194, 0.1);
    }

    .rui-Toggle-option:enabled:hover {
        background: rgba(176, 180, 194, 0.05);
    }
}

html[data-focus-source="other"] .rui-Toggle-transparent .rui-Toggle-option:focus {
    color: #315efb;
}

.rui-Toggle-transparent .rui-Toggle-option:disabled {
    border-color: rgb(231, 232, 236);
}

.rui-Toggle-block {
     flex-wrap: nowrap;
     flex-direction: row;
 }

.rui-Toggle-equalWidth .rui-Toggle-option {
    flex: 1;
    flex-basis: 0;
}

.rui-Toggle-behavior-radio .rui-Toggle-isSelected:enabled {
    cursor: default;
}
.rui-ToggleOption-toggleOption {
    color: inherit;
    cursor: pointer;
    widows: 2;
    margin: 0;
    hyphens: none;
    padding: 0;
    display: inline-block;
    outline: none !important;
    tab-size: 8;
    direction: ltr;
    font-size: medium;
    font-style: normal;
    list-style: disc outside none;
    text-align: center;
    visibility: visible;
    background: transparent;
    empty-cells: show;
    font-family: Roboto, sans-serif;
    font-weight: normal;
    line-height: normal;
    text-indent: 0;
    text-shadow: none;
    white-space: nowrap;
    user-select: none;
    caption-side: top;
    font-variant: normal;
    font-stretch: normal;
    word-spacing: normal;
    border-radius: 0;
    border-spacing: 0;
    vertical-align: baseline;
    letter-spacing: normal;
    text-transform: none;
    border-collapse: separate;
    text-align-last: auto;

    &:before {
        width: 0;
        height: 100%;
        display: inline-block;
        content: "";
        vertical-align: middle;
    }

    > * {
        vertical-align: middle;
    }

    &::-moz-focus-inner {
        border: none !important;
        outline: none !important;
    }
}

.rui-ToggleOption-size-small {
    height: 35px;
    padding: 0 15px;
    font-size: 13px;
    line-height: 33px;
}

.rui-ToggleOption-size-medium {
    height: 45px;
    padding: 0 25px;
    font-size: 13px;
    line-height: 43px;
}

.rui-ToggleOption-icon {
    display: inline-block;
    margin-right: 10px;
}
.rui-Toggle-link {
    flex-wrap: wrap;
    flex-direction: row;
    text-align: center;
    .rui-Toggle-option {

        &.rui-Toggle-option {
            color: #262626;
            cursor: pointer;
            display: inline-block;
            position: relative;
            text-align: center;
            background: #fff;
            font-family: Roboto, sans-serif;
            user-select: none;
            margin-left: -1px;
            border-style: solid;
            border-width: 0;
            border-color: #e7e8ec;
            span {
                border-bottom: 1px dashed #262626;
            }
            &.rui-Toggle-isSelected {
                span {
                    color: #63AEF4;
                    border-color: #63AEF4;
                }
            }
        }
    }
    .rui-ToggleOption-size-small {
        height: auto;
        padding: 0 10px;
        margin-bottom: 5px;
        font-size: 13px;
        line-height: 16px;
    }
}

.rui-Tabs-tabs {
    cursor: auto;
    widows: 2;
    margin: 0;
    hyphens: none;
    padding: 0;
    display: inline-flex;
    tab-size: 8;
    direction: ltr;
    font-size: medium;
    font-style: normal;
    list-style: disc outside none;
    text-align: left;
    visibility: visible;
    box-shadow: inset 0 -1px 0px rgb(239, 240, 242);
    empty-cells: show;
    font-family: Roboto, sans-serif;
    font-weight: normal;
    line-height: normal;
    text-indent: 0;
    text-shadow: none;
    white-space: normal;
    caption-side: top;
    font-variant: normal;
    font-stretch: normal;
    word-spacing: normal;
    padding-left: 10px;
    border-radius: 0;
    padding-right: 10px;
    border-spacing: 0;
    vertical-align: baseline;
    letter-spacing: normal;
    text-transform: none;
    border-collapse: separate;
    text-align-last: auto;
}

.rui-Tabs-item {
    &.rui-Tabs-item {
        flex: none;
    }

    &:nth-child(1n+2) {
        margin-left: 40px;
    }
}

.rui-Tabs-isDisabled {
    cursor: not-allowed;
}

.rui-Tabs-isBottom {
    box-shadow: inset 0 1px 0px rgb(239, 240, 242);
}

.rui-TabsItem-tab {
    cursor: pointer;
    widows: 2;
    margin: 0;
    hyphens: none;
    padding: 0;
    display: inline-block;
    outline: none !important;
    tab-size: 8;
    direction: ltr;
    font-size: medium;
    font-style: normal;
    list-style: disc outside none;
    text-align: left;
    visibility: visible;
    background: none;
    empty-cells: show;
    font-family: inherit;
    font-weight: 500;
    line-height: normal;
    text-indent: 0;
    text-shadow: none;
    white-space: nowrap;
    user-select: none;
    caption-side: top;
    font-variant: normal;
    font-stretch: normal;
    word-spacing: normal;
    border-style: solid;
    border-width: 0 0 2px;
    border-radius: 0;
    border-spacing: 0;
    vertical-align: baseline;
    letter-spacing: normal;
    text-transform: none;
    border-collapse: separate;
    text-align-last: auto;
    transition-duration: 200ms;
    transition-property: color, border-color;

    &::-moz-focus-inner {
        border: none !important;
        outline: none !important;
    }

    &.rui-TabsItem-tab {
        color: #262626;
        border-color: transparent;
    }

    &.rui-TabsItem-isEnabled {
        &.rui-TabsItem-isSelected {
            color: #315efb;
            border-color: currentColor;
        }

        &:hover, &:focus {
            color: #315efb;
        }

        &:active {
            color: rgb(39, 75, 200);
        }
    }

    &.rui-TabsItem-isDisabled {
        color: rgb(215, 217, 224);

        &.rui-TabsItem-isSelected {
            border-color: rgb(239, 240, 242);
        }
    }
}

.rui-TabsItem-tabBottom {
    border-width: 2px 0 0;
}

.rui-TabsItem-size-small {
    font-size: 11px;
    line-height: 1.36;
    padding-bottom: 9px;
    letter-spacing: 1.3px;
    text-transform: uppercase;
}

.rui-TabsItem-size-medium {
    font-size: 14px;
    line-height: 1.43;
    padding-bottom: 11px;
}

.rui-TabsItem-size-small-bottom {
    font-size: 11px;
    padding-top: 9px;
    line-height: 1.36;
    letter-spacing: 1.3px;
    text-transform: uppercase;
}

.rui-TabsItem-size-medium-bottom {
    font-size: 14px;
    padding-top: 11px;
    line-height: 1.43;
}

.rui-TabsItem-isDisabled {
    cursor: not-allowed;
    pointer-events: none;
}

.value-list {

    margin-bottom: .5em ;
    .v {
        display: flex;
        flex-direction: row;
        .label-value {
            margin-right: 20px;
            width: 500px;
            /* white-space: nowrap; */
            text-overflow: ellipsis;
            overflow: hidden;
        }
        .value {
            font-weight: bold;
        }
    }
}
.color-number {
    &.positive {
        color: #00c100;
    }
    &.negative {
        color: #a94442;
    }
}

.react-grid-cell-wrapper {
    padding: 10px 0;
}



.delimiter-line {
    height: 1px;
    width: 100%;
    margin-bottom: 20px;
    border-bottom: 1px solid ;
}

.background {
    &.green {
        background: #008000bd;
        color:white;
    }
}


.rounded {
    border-radius: 0.2em !important;
}
