@charset 'UTF-8';

$blue: #337ab7;;
$red: #DF5454;;
$green: #74C467;
$purple: #a790e0;
$orange: #ff885c;
$ocean: #f9fcff;
$lgray: #bdbdbd;

.metrics {
    max-width: 1200px;
    padding: 5px 0px 10px 20px;
}

.metrics__date-selectors {
    display: flex;
    margin: 10px 0px 0px 5px;
}

.metrics__value-tick {
    //fill: $blue;
    opacity: 0.65;

    &:hover {
        fill: $red;
        cursor: pointer;
        opacity: 1;
    }

}

.metrics__value_color-a {
        fill: $blue;
        opacity: 0.55;

        &:hover {
            fill: $purple;
            cursor: pointer;
            opacity: 0.55;
        }
}

.metrics__value_color-b {
        fill: $green;
        opacity: 0.50;

        &:hover {
            fill: $purple;
            cursor: pointer;
            opacity: 0.55;
        }
}



.metrics-card-wrapper {
    display: flex;
    flex-wrap: wrap;
}

.metrics__diagramm {
    margin-top: 20px;
}


.metrics__bar-text {
    font-weight: bold;;
}

.metrics-card {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    padding: 10px;
    margin: 5px 10px 0px 5px;
    border-radius: 10px;
    width: 250px;
    height: 80px;

    &__value {
        font-size: 12pt;
        font-weight: bold;
    }

    &__title{
        font-size: 10pt;
    }
}

.metrics__y-line {
    stroke: #ccc;;
}


.metrics-legend {
    width: 250px;
    border: 1px solid gainsboro;
    padding: 5px;
}

.metrics_flex-end {
    display: flex;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

}

.metrics-legend_flex {
    margin: auto;
    margin-top: 2%;
    margin-left:5%;
}

.metrics-legend__row_flex {
    display: flex;
    justify-content: space-between;
    margin: 5px;
}


.metrics__color {
    width: 25px;
}

.metrics-legend__row {

}

.metrics__color {
    &.metrics__color_a {
        background: $blue;
        opacity: 0.50;
    }
    &.metrics__color_b {
        background: $green;
    }
}

.metrics-legend__row_flex {
    display: flex;
}

.metrics__loader {
    &.loader_large {
        display: flex;
        font-size: 10pt;
        margin: 0px 10px 0px 5px;
    }

}

.my-node-enter {
    opacity: 0;
  }
  .my-node-enter-active {
    opacity: 1;
    transition: opacity 200ms;
  }
  .my-node-exit {
    opacity: 1;
  }
  .my-node-exit-active {
    opacity: 0;
    transition: opacity 200ms;
  }