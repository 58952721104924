
.medias {
    border:1px solid gainsboro;
    padding: 10px;
    width: 27%;
    display: flex;
    justify-content: space-between;
    border-radius: 5px;
    flex-wrap: wrap;
}

.medias__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: column;
    border: 1px solid #dcdcdc;
    margin-top: 10px;
    max-width: 535px;
    padding: 5px;
    border-radius: 5px;
    font-size: 8pt;
}

.medias__list-item {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    color: #808080;

}

.medias__delete-button {
    outline: none;
    background: none;
    border: 0;
    color: #a09191;
    position: absolute;
    bottom: -5px;
    left: 22px;
    background: #fff;
    border: 1px solid #c3c3c3;
    border-radius: 15px;
    font-size: 8pt;

    &:hover {
        cursor: pointer;
        box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
        transition: all 0.3s cubic-bezier(.25,.8,.25,1);
        border-radius: 20px;
        background: red;
        color: white;
        border:1px solid white;
    }

}

.medias__icon-row {
    padding: 5px;
    margin: 0.25em;
    border:1px solid gainsboro;
    border-radius: 10px;
    width: 6;
    position: relative;

    &.icon-row_filled {
        background: #cae4fd;
    }

    &:hover {
        cursor: pointer;
        box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
        transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    }
}

.medias__menu-wrapper {
    width: 300px;
}

.medias__buttons {
    display: flex;
    justify-content:flex-end
}