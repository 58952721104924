@charset 'UTF-8';

.integration-product-picker {
    max-width: 1020px;
}
.integration-product-picker__wrapper{
    display: flex;


    .catalog--tree {
        display: flex;
        flex-direction: column;
        border-right: 1px solid #ddd;
        height: 100%;
        left: auto;
        position: relative;
        width: 420px;
        z-index: 1;

    }
    .catalog--list {
        height: 100%;
        position: absolute;
        left: auto;
        display: flex;
        flex-direction: column;
        flex-basis: 100%;
        flex: 3;
        .catalog--list__list {
            height: 100%;
        }
    }
}
.integration-inner-wrap {
    height: 100%;
    /* flex: 1 auto; */
    height: 600px;
    position: relative;
    width: 100%;
    display: block;
}
.integration-row {
    height: 100%;
    position: relative;
    width: 100%;
    flex: 1 1;
}
.integration-catalog__content {
    /* display: -webkit-flex; */
    /* display: flex; */
    /* -webkit-flex-direction: column; */
    /* flex-direction: column; */
    /* background: white; */
    /* height: 100%; */
    /* margin-left: 420px; */
    /* padding-top: 0px; */
    position: relative;
    min-height: 100%;
    position: relative;
    width: 100%;
    display: block;
}
.integration-catalog__content {

}
.integration-catalog-list {
    position: relative;
    /* flex: 1 1 auto; */
    /* display: flex; */
    /* flex-direction: row; */
    /* overflow: hidden; */
    height: 600px;
}
.item-integration-content-wrapper {
    .item-name {
        white-space: normal;
    }
}
