@charset 'UTF-8';

.suggestion-list {
    @extend %popover-box-shadow;
    width: 100%;
    z-index: 100;

    .suggestion-list__item {
        &:hover {
            background: alpha-color($black, .1);
        }
    }

    .badge {
        position: absolute;
        top: 8px;
        right: 10px;
        color: rgb(61, 60, 64);
        background: #CECECF;
        font-size: 10px;
    }
    .LoadingSpinner {
        margin: 6px 11px;
    }
}

.suggestion-list--top {
    bottom: 100%;
    position: absolute;
}

.suggestion-list--bottom {
    height: 0;
    position: relative;
}

.suggestion-list__content {
    -webkit-overflow-scrolling: touch;
    background-color: $white;
    border: $border-gray;
    max-height: 292px;
    overflow-x: auto;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
    overflow-y: scroll;
    padding-bottom: 5px;
    width: 100%;

    .command {
        border-bottom: 1px solid $light-gray;
        font-size: .95em;
        line-height: 24px;
        padding: 5px 10px 8px;
        position: relative;
        width: 100%;
        z-index: 101;

        .command__desc {
            opacity: 0.5;
            line-height: normal;
            margin-left: 5px;
        }
    }
}

.suggestion-list__content--top {
    bottom: 0;
    position: absolute;
}

.suggestion-list--bottom {
    position: relative;
}

.suggestion-list__divider {
    line-height: 21px;
    margin: 5px 0 5px 5px;
    position: relative;

    &:first-child {
        margin-top: 5px;
    }

    > span {
        display: inline-block;
        font-size: .9em;
        padding: 0 10px 0 5px;
        position: relative;
        z-index: 5;
    }

    &:before {
        opacity: 0.2;
        background: $dark-gray;
        content: '';
        height: 1px;
        left: 0;
        position: absolute;
        top: 11px;
        width: 100%;
    }
}

.suggestion-list__no-results {
    line-height: 21px;
    margin: 7px 10px 2px;
    position: relative;

    > span {
        display: inline-block;
        font-size: .9em;
        padding: 0 10px 0 5px;
        position: relative;
        z-index: 5;
    }
}
