.aquiring-form-content {
    width: 950px;
    padding: 5px 0px 0px 20px;

    h1 h2 h3 h4 {
        padding: 0 !important;
        margin: 0 !important;
    }

    ul {
        li {
            padding: 10px 10px 10px 0px;
        }
    }

    .aquiring-row-wrapper {
        border: 1px solid gainsboro;
        border-radius: 8px;
    }

    .aquiring-switcher {
        justify-content: space-around;
        border: 1px solid #dcdcdc;
        padding: 20px 5px 10px 5px;
        border-radius: 6px;

        label {
            margin-left: 20px;
        }
    }


    .aquiring-row {
        display: flex;
        align-items: baseline;
        justify-content: center;
        justify-content: space-around;
        padding: 20px 5px 10px 5px;

        &__buttons {
            margin-top: 20px;
        }
    }

    .switch-image-wrapper {
        display: flex;
        width: 3%;
    }

    .bank-image-wrapper {
        width: 20%;

        img {
            border-radius: 8px;
        }
    }

    .aquiring-info-cell {
        width: 70%;
        align-self: start;
    }

    .aquiring-data-cell {
        display: flex;
        flex-direction: column;

        /* Первый параграф */
        button:nth-of-type(2) {
            margin-left:10px;
        }
    }

    .aquiring-data-cell__button {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .aquiring-data-cell__status {
        
    }

    .aquiring-data-cell__status {
        font-weight: bold;
        font-size: 12pt;
        &--ok {
            color: $green;
        }

        &--fail {
            color: $red;
        }
    }
}