@charset 'UTF-8';

.sidebar--right {
transform:translateX(400px);
    background: $white;
    height: 100%;
    padding: 0;
    position: fixed;
    right: 0;
    width: 400px;
    z-index: 8;

    body.announcement-bar--fixed & {
        height: calc(100% - #{$announcement-bar-height});
    }

    &.sidebar--right--expanded {
        z-index: 15;
    }

    .sidebar--right__bg {
        //@include single-transition(background-color, .5s, ease);
        background-color: alpha-color($black, 0);
        height: 100%;
        position: absolute;
        top: 0;
        visibility: hidden;
        width: 300%;
    }

    &.move--left {
        .sidebar--right__bg {
            left: -100%;
        }
    }

    .sidebar-right__table {
        display: table;

        > div:not(.sidebar-collapse__container) {
            display: table-cell;
            vertical-align: top;

            &:last-child {
                .channel-header__icon {
                    margin-right: 16px;
                }
            }
        }

        .search-form__container {
            width: 100%;
        }
    }

    .post-body {
        img {
            max-height: 200px;
        }
    }

    .post {
        &.post--compact {
            .post__pinned-badge {
                margin: 0 0 0 5px;
            }
        }

        &.post--comment {
            .post__body {
                border-left: none;
            }
        }

        .post__content {
            padding: 0 10px 0 0;
        }

        .post__header {
            .col__name {
                .user-popover {
                    width: 100%;
                }

                + .col {
                    flex: auto;
                }

                &.Badge {
                    flex: none;
                }
            }

            .flag-icon__container {
                z-index: 5;
            }
        }
    }

    .post-create__container {
        form {
            padding: .5em 15px 0;
        }

        .post-create-footer {
            @include clearfix;
            clear: both;
            font-size: 13px;
            overflow: visible;
            position: relative;

            .post-error {
                opacity:(.55);
                display: inline-block;
                font-size: .85em;
                font-weight: normal;
                margin-bottom: 0;
                position: absolute;
                top: -25px;
            }
        }
    }

    .channel-archived-warning {
        padding: 20px;
    }

    .help__format-text {
        display: none;
    }

    .sidebar--right__content {
        display:flex;
        flex-direction:(column);
        height: 100%;
    }

    .sidebar--right__back {
        //@include single-transition(all, .2s, ease-in);
        opacity: 0.5;
        color: inherit;
        display: inline-block;
        font-size: 26px;
        margin-left: -14px;
        text-align: center;
        vertical-align: top;
        width: 30px;

        &:hover,
        &:active {
            color: inherit;
            opacity: .8;
        }

        i {
            position: relative;
            top: -2px;
        }
    }

    .sidebar-right__body {
        display:flex;
         flex:(1 1 auto);
        flex-direction:(column);
        border-left: $border-gray;
        height: calc(100% - 56px);

        .loading-screen {
            position: relative;
            height: 40px;
            padding: 0px;
            .loading__content {
                height: 40px;
            }
        }
    }

    .sidebar__overlay {
        opacity: 0.1;
        background-color: $yellow;
        height: 100%;
        pointer-events: none;
        position: absolute;
        width: 100%;
        z-index: 5;
    }

    .input-group {
        word-break: break-word;
    }

    .sidebar--right__buttons {
        float: right;
    }

    .sidebar--right__close {
        //@include single-transition(all, .2s, ease-in);
        background: none;
        border: none;
        font-size: 20px;
        height: 22px;
        line-height: 0;
        margin: 11px 0 0;
        opacity: .5;
        outline: none;
        padding: 0;
        width: 30px;

        &:hover,
        &:active {
            opacity: .8;
        }

        i {
            position: relative;
        }
    }

    .sidebar--right__expand {
        @extend .sidebar--right__close;
        font-size: 17px;

        i {
            top: -2px;
        }

        .fa-compress {
            display: none;
        }
    }

    .sidebar--right__title {
        @include clearfix;
        padding-left: 15px;
    }

    .sidebar--right__header {
         flex:(0 0 44px);
        border-bottom: 1px solid;
        color: inherit;
        font-size: 1em;
        height: 44px;
        line-height: 44px;
        overflow: hidden;
        padding: 0 5px 0 0;
        text-transform: uppercase;
    }

    .sidebar--right__loading {
        opacity: 0.7;;
        text-align: center;

        .fa {
            margin-right: 5px;
        }
    }

    .sidebar--right__subheader {
        font-size: 1em;
        padding: .5em 1em 0;

        h4 {
            font-size: 1em;
        }

        ul {
            opacity: 0.7;;
            padding: 10px 0 0 30px;
        }

        li {
            font-size: .95em;
            padding-bottom: 10px;
        }

        .usage__icon {
            opacity:(.9);
            margin: 0 3px;
            position: relative;
            top: 3px;
        }
    }

    .suggestion-list__content {
        max-height: 120px;
    }
}

.sidebar-right-container {
    height: 100%;
}

.sidebar-collapse__container {
    display: none;
}

.sidebar-collapse {
    //@include single-transition(all, .2s, linear);
    transform: translateX(0);
    cursor: pointer;
    height: 48px;
    text-align: center;
    width: 45px;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;

    .fa {
        position: relative;
        top: 1px;
    }
}


.sidebar--left__search {

    height: 100%;


    body.announcement-bar--fixed & {
        height: calc(100% - #{$announcement-bar-height});
    }

    &.sidebar--right--expanded {
        z-index: 15;
    }

    .sidebar--right__bg {
        //@include single-transition(background-color, .5s, ease);
        background-color: alpha-color($black, 0);
        height: 100%;
        position: absolute;
        top: 0;
        visibility: hidden;
        width: 300%;
    }

    &.move--left {
        .sidebar--right__bg {
            left: -100%;
        }
    }

    .sidebar-right__table {
        display: table;

        > div:not(.sidebar-collapse__container) {
            display: table-cell;
            vertical-align: top;

            &:last-child {
                .channel-header__icon {
                    margin-right: 16px;
                }
            }
        }

        .search-form__container {
            width: 100%;
        }
    }

    .post-body {
        img {
            max-height: 200px;
        }
    }

    .post {
        &.post--compact {
            .post__pinned-badge {
                margin: 0 0 0 5px;
            }
        }

        &.post--comment {
            .post__body {
                border-left: none;
            }
        }

        .post__content {
            padding: 0 10px 0 0;
        }

        .post__header {
            .col__name {
                .user-popover {
                    width: 100%;
                }

                + .col {
                    flex: auto;
                }

                &.Badge {
                    flex: none;
                }
            }

            .flag-icon__container {
                z-index: 5;
            }
        }
    }

    .post-create__container {
        form {
            padding: .5em 15px 0;
        }

        .post-create-footer {
            @include clearfix;
            clear: both;
            font-size: 13px;
            overflow: visible;
            position: relative;

            .post-error {
                opacity:(.55);
                display: inline-block;
                font-size: .85em;
                font-weight: normal;
                margin-bottom: 0;
                position: absolute;
                top: -25px;
            }
        }
    }

    .channel-archived-warning {
        padding: 20px;
    }

    .help__format-text {
        display: none;
    }

    .sidebar--right__content {
        display:flex;
        flex-direction:(column);
        height: 100%;
    }

    .sidebar--right__back {
        //@include single-transition(all, .2s, ease-in);
        opacity: 0.5;
        color: inherit;
        display: inline-block;
        font-size: 26px;
        margin-left: -14px;
        text-align: center;
        vertical-align: top;
        width: 30px;

        &:hover,
        &:active {
            color: inherit;
            opacity: .8;
        }

        i {
            position: relative;
            top: -2px;
        }
    }

    .sidebar-right__body {
        display:flex;
         flex:(1 1 auto);
        flex-direction:(column);
        border-left: $border-gray;
        height: calc(100% - 56px);

        .loading-screen {
            position: relative;
            height: 40px;
            padding: 0px;
            .loading__content {
                height: 40px;
            }
        }
    }

    .sidebar__overlay {
        opacity: 0.1;
        background-color: $yellow;
        height: 100%;
        pointer-events: none;
        position: absolute;
        width: 100%;
        z-index: 5;
    }

    .input-group {
        word-break: break-word;
    }

    .sidebar--right__buttons {
        float: right;
    }

    .sidebar--right__close {
        //@include single-transition(all, .2s, ease-in);
        background: none;
        border: none;
        font-size: 20px;
        height: 22px;
        line-height: 0;
        margin: 11px 0 0;
        opacity: .5;
        outline: none;
        padding: 0;
        width: 30px;

        &:hover,
        &:active {
            opacity: .8;
        }

        i {
            position: relative;
        }
    }

    .sidebar--right__expand {
        @extend .sidebar--right__close;
        font-size: 17px;

        i {
            top: -2px;
        }

        .fa-compress {
            display: none;
        }
    }

    .sidebar--right__title {
        @include clearfix;
        padding-left: 15px;
    }

    .sidebar--right__header {
         flex:(0 0 44px);
        border-bottom: 1px solid #cccccc;
        //color: inherit;
        font-size: 1em;
        height: 44px;
        line-height: 44px;
        overflow: hidden;
        padding: 0 5px 0 0;
        text-transform: uppercase;
        font-weight: bold;
        color: #959595;
    }

    .sidebar--right__loading {
        opacity: 0.7;;
        text-align: center;

        .fa {
            margin-right: 5px;
        }
    }

    .sidebar--right__subheader {
        font-size: 1em;
        padding: .5em 1em 0;

        h4 {
            font-size: 1em;
        }

        ul {
            opacity: 0.7;;
            padding: 10px 0 0 30px;
        }

        li {
            font-size: .95em;
            padding-bottom: 10px;
        }

        .usage__icon {
            opacity:(.9);
            margin: 0 3px;
            position: relative;
            top: 3px;
        }
    }

    .suggestion-list__content {
        max-height: 120px;
    }
}
