@charset 'UTF-8';

h1 {
    font-size: 28px;
}

h2 {
    font-size: 25px;
}

h3 {
    font-size: 22px;
}

h4 {
    font-size: 19px;
}

h5 {
    font-size: 15px;
}

h6 {
    font-size: 1em;
}

.markdown__link {
}

.markdown__heading {
    font-weight: 700;
    line-height: 1.5;
    margin: 10px 0;

    &:first-child {
        margin: 5px 0 10px;
    }

}

.markdown__paragraph-inline {
    display: inline;

    + .markdown__paragraph-inline {
        margin-left: 4px;
    }
}

#post-list {
    .markdown-inline-img {
        -moz-force-broken-image-icon: 1;
        margin: 5px 0;
        max-height: 500px;
    }
}

.post-code {
    overflow-x: auto;
    overflow-y: hidden;
    position: relative;

    code {
        border: 1px solid rgba(221,221,221,1);
        border-radius: .25em;
        display: block;
        font-size: 13px;
        margin: 5px 0;
        overflow-x: auto;
        padding: 6.5px;
        text-align: left;
        white-space: pre;
    }

    td {
        padding: 0 .5em;
        vertical-align: top;
    }

    &:hover .post-code__language {
        opacity: 1;
    }

    &--wrap code {
        white-space: pre-wrap;
    }

    .hljs {
        position: relative;
        visibility: visible;
    }
}

.post-code__language {
    opacity: 0;
     transition:(opacity, .6s);
    user-select: none;
    background: #21586d;
    border-radius: 0 0 0 2px;
    color: $white;
    padding: 4px 10px 5px;
    position: absolute;
    right: 0;
    top: 5px;
    z-index: 5;
}

.post-code__lineno {
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    -webkit-user-select: none;
    border-right: 1px solid #aaa;
    color: #aaa;
    margin-right: .5em;
    text-align: right;
    user-select: none;
}

.post-code__search-highlighting {
    color: transparent;
    right: 6.5px;
    left: 6.5px;
    pointer-events: none;
    position: absolute;
    user-select:(none);
}

.post__body {
    hr {
        opacity: 0.2;
        background-color: #e7e7e7;
        border: 0 none;
        height: 4px;
        margin: 15px 0 16px;
        padding: 0;
    }

    .codespan__pre-wrap {
        code {
            white-space: pre-wrap;
        }
    }
}

.markdown__table {
    background: $white;
    margin: 5px 0 10px;

    th,
    td {
        border: 1px solid #ddd;
        padding: 6px 13px;
    }

    tbody tr {
        background: $white;

        &:nth-child(2n) {
            background-color: #f8f8f8;
        }
    }
}

blockquote {
    border: none;
    font-size: inherit;
    margin-bottom: 0;
    padding: 10px 10px 10px 38px;
    position: relative;

    &:before {
        opacity: 0.6;
        content: '\f10d';
        display: inline-block;
        font-family: FontAwesome;
        font-size: 20px;
        font-style: normal;
        font-weight: normal;
        left: 8px;
        position: absolute;
        text-decoration: inherit;
        top: 5px;
    }
}

pre {
    color: inherit;
    margin: 5px 0;
    padding: 0;
}

code {
    background: $white;
    color: inherit;
}

.help {
    code {
        background: rgba(51,51,51,0.1);
    }

    .hljs {
        background: #f8f8f8;
        color: #333;
        display: block;
        overflow-x: auto;
        padding: 0.5em;
    }

    .container {
        margin-bottom: 40px;
    }

    .links {
        font-size: 1.2em;
        margin-top: 30px;
    }
}
