@charset 'UTF-8';

@media screen and (max-width: 1020px) {
    .sidebar--right {
        //@include single-transition(all, .35s, ease);
        transform: translateX(100%);
        z-index: 10;

        &.move--left {
            -webkit-transform: translateX(0) !important;
            -moz-transform: translateX(0) !important;
            -ms-transform: translateX(0) !important;
            -o-transform: translateX(0) !important;
            transform: translateX(0) !important;

            .browser--ie & {
                -webkit-transform: none   !important;
                -moz-transform: none !important;
                -ms-transform: none !important;
                -o-transform: none !important;
                transform: none !important;
                display: block;
            }

            .search-bar__container {
                z-index: 5;
            }
        }
    }

    .inner-wrap {
        &:not(.move--left) {
            margin-right: 0 !important;
        }

        &.move--left {
            margin-right: 0 !important;

            .channel-header__links {
                position: relative;
                right: auto;
                top: auto;
            }
        }

        &.move--left,
        &.move--right {
            .header-list__right {
                // hide it behind the RHS
                z-index: -1;
            }
        }
    }
}

@media screen and (max-width: 960px) {
    .system-users__filter-row {
        flex-direction: column;

        .system-users__filter {
            &:first-child {
                flex: 1 1 auto;
                padding-bottom: 12px;
            }
        }

        > label {
            margin-bottom: 12px;

            span {
                flex: 0 0 auto;
                padding-left: 0;
            }
        }
    }

    .app__body {
        .modal {
            .modal-image {
                .modal-image__wrapper {
                    img {
                        max-height: calc(100vh - 100px);
                    }

                    >div {
                        font-size: 12px;
                        min-width: 250px;
                    }

                    .modal-close {
                        opacity: 1;
                    }
                }

                .modal-image__content {
                    max-height: 93vh;
                }

                .modal-button-bar {
                    line-height: 30px;
                    padding: 5px 10px 5px 5px;
                    bottom: 20px;
                }
            }
        }
    }

    .post-create__container {
        .channel-archived__close-btn {
            display: block;
            margin: 10px auto 0;
        }
    }

    .help__format-text {
        display: none;
    }


    .signup-team__container {
        &.branded {
            display: block;
            margin: 0 auto;
            max-width: 380px;

            .signup__markdown {
                display: none;
            }
        }
    }

    .backstage-content {
        margin: 30px;
        max-width: 100%;
        padding: 0;
    }

    .backstage-sidebar {
        height: auto;
        padding: 30px 15px 0;
        position: relative;
        width: 100%;
    }

    .post {
        .attachment {
            .attachment__image {
                &.attachment__image--opengraph {
                    max-height: 70px;
                    max-width: 100%;

                    &.loading {
                        height: 70px;
                    }
                }
            }
        }
    }
}

@media (min-width: 880px) {
    .post-collapse__show-more {
        padding-right: 60px;
    }

    .post-attachment-collapse__show-more {
        padding-right: 0;
    }

    .sidebar--right {
        .post-collapse__show-more, .post-attachment-collapse__show-more {
            padding-right: 0;
        }
    }
}

// Tablet and desktop
@media screen and (min-width: 768px) {
    .channel-header__popover {
        left: 236px !important;
        top: 44px !important;
    }

    .col-sm-auto {
        float: left;
    }

    .second-bar {
        display: none;
    }

    .channel-header {
        .search-bar__container {
            .search__form {
                &.focused {
                    width: 300px;
                }
            }
        }
    }

    .sidebar--right {
        &.move--left {
            transform: translateX(0);
        }

        &.sidebar--right--expanded {
            width: calc(100% - 280px);

            .sidebar--right__bg {
                background-color: alpha-color($black, .4);
                visibility: visible;
            }

            .search-bar__container {
                padding-left: 10px;
            }

            .sidebar-right-container {
                position: relative;
                z-index: 5;
            }

            .sidebar--right__expand {
                .fa-expand {
                    display: none;
                }

                .fa-compress {
                    display: inline-block;
                }
            }
        }

        .sidebar--right__title {
            display: inline-block;
            max-width: 300px;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    .inner-wrap {
        &.move--left {
            margin-right: 400px;
        }
    }

    .post {
        .sidebar--right & {
            &.same--root {
                &.post--comment {
                    &:not(.post--compact) {
                        .post__pinned-badge {
                            margin-left: 7px;
                        }
                    }
                }
            }
        }

        &.post--compact {
            .post-message {
                &.post-message--overflow {
                    @include clearfix;
                    margin-top: 22px;
                }
            }

            &.same--root {
                &.same--user {
                    .post-message {
                        &.post-message--overflow {
                            margin-top: 0
                        }
                    }
                }
            }

            &.post--comment {
                &.other--root {
                    .post-message {
                        &.post-message--overflow {
                            margin-top: 0
                        }
                    }
                }
            }

            &.post--thread {
                .post-message {
                    &.post-message--overflow {
                        margin-top: 0
                    }
                }
            }

            .channel__wrap & {
                .post__time {
                    font-size: .85em;
                    left: -79px;
                    position: absolute;
                    text-align: right;
                    top: 3px;
                    width: 60px;
                }

                .star-icon__container {
                    left: -65px;
                    position: absolute;
                    text-align: right;
                    top: 6px;
                    width: 60px;
                }
            }

            .post__pinned-badge {
                margin-left: 0;
                margin-right: 5px;
            }

            &:not(.post--thread) {
                padding: 0 .5em 0 77px;

                .post__link {
                    margin: 3px 0 7px;
                    vertical-align: bottom;
                }

                span {
                    p {
                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }

                .post__header {
                    float: left;
                    height: 21px;
                    padding-right: 0;
                    padding-top: 2px;

                    .col__name {
                        font-weight: bold;
                        margin-right: 2px;
                        padding-right: 5px;
                        position: relative;
                        z-index: 1;
                    }

                    .colon {
                        display: inline;
                    }

                    .col__reply {
                        top: -6px;
                    }
                }

                &.other--root {
                    .post__link+.post__body {
                        clear: both;
                    }

                    &.post--comment {
                        .post__header {
                            .col__reply {
                                top: -6px;
                            }
                        }
                    }
                }

                .post-code {
                    clear: both;
                }

                .post__body {
                    width: 100%;
                }

                .post__content {
                    padding-right: 45px;
                }
            }

            .flag-icon__container {
                left: -21px;
                position: absolute;
                top: 4px;
            }

            .sidebar--right & .flag-icon__container {
                left: auto;
                position: relative;
                top: 1px;
            }

            &.same--root {
                &.same--user {
                    padding-left: 77px;
                    padding-top: 0;

                    .flag-icon__container {
                        left: -21px;
                        position: absolute;
                        top: 4px;
                    }

                    .post__header {
                        .col__reply {
                            top: -6px;
                        }
                    }

                    .post__img {
                        img {
                            display: none;
                        }
                    }

                    &.post--root {
                        .post__img {
                            img {
                                display: block;
                            }
                        }

                        .post__time {
                            opacity: 0.6;
                        }
                    }
                }

                &.post--comment {
                    &.same--user {
                        .post__img {
                            img {
                                display: none;
                            }
                        }
                    }

                    .post__header {
                        margin-left: 12px;
                    }
                }
            }
        }

        &.same--root {
            &.same--user {
                .post__time {
                    opacity: 0;
                }

                .post__header {
                    float: left;

                    .col__reply {
                        top: -21px;
                    }

                    .post__pinned-badge {
                        margin-right: 5px;
                    }
                }

                &.post--pinned {
                    .post__body {
                        &:before {
                            content: '';
                            display: block;
                            height: 21px;
                            width: 1px;
                        }
                    }
                }

                &:not(.post--compact) {
                    .post__header {
                        .post__pinned-badge {
                            top: 3px;
                        }
                    }

                    .flag-icon__container {
                        .sidebar--right & {
                            left: 30px;
                        }
                    }

                    .post__time {
                        display: inline-block;
                        font-size: 11px;
                        left: -18px;
                        line-height: 20px;
                        position: absolute;
                        text-align: right;
                        text-rendering: auto;
                        top: 4px;
                        width: 51px;

                        .sidebar--right & {
                            left: -20px;
                        }

                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }

            &.post--comment:not(.post--compact) {
                .post__pinned-badge {
                    margin-left: 10px;
                }
            }
        }
    }
}
