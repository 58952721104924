@charset 'UTF-8';

@keyframes spin {
    from {
        transform: scale(1) rotate(0deg);
    }

    to {
        transform: scale(1) rotate(360deg);
    }
}

@keyframes highlight {
    from {
        @include alpha-property(background, $yellow, .5);
    }

    to {
        background: none;
    }
}
