@charset 'UTF-8';

.radio,
.checkbox {
    &.margin-bottom--none {
        margin-bottom: 0;
    }
}

.form-control {
    @include input-placeholder {
        color: inherit;
    }

    &.form-sm {
        font-size: 13px;
        height: 32px;
    }
}

.form-horizontal {
    .modal-intro {
        margin: -10px 0 30px;
    }

    .form__label {
        font-size: 1.1em;
        font-weight: 600;
        padding-right: 3px;
        text-align: left;

        &.light {
            opacity: 0.6;
            font-size: 1.05em;
            font-style: italic;
            font-weight: normal;
            padding-top: 2px;
        }
    }

    .input__help {
        opacity:.8;
        color: inherit;
        margin: 10px 0 0 0;
        word-break: break-word;

        &.dark {
            opacity: 1;
        }

        &.error {
            color: darken($red, 20%);
        }
    }

    .form-control {
        font-weight: normal;
    }

    .form-group {
        margin-bottom: 25px;

        &.less {
            margin-bottom: 10px;
        }
    }
}

.help-block {
    color: $dark-gray;
    font-size: .95em;
    margin: 10px 0 0;
}

.form-group {
    &.form-group--small {
        margin-bottom: 10px;
    }
}
.form-group-title {
    margin-bottom: 40px;
    font-size: 16px;
}
.has-error {
    .help-block,
    .radio,
    .checkbox,
    .radio-inline,
    .checkbox-inline {
        color: $red;
    }

    .form__label {
        &.control-label {
            color: inherit !important;
        }
    }

    &.radio,
    &.checkbox,
    &.radio-inline,
    &.checkbox-inline {
        label {
            color: $red;
        }
    }
}

.multi-select__radio {
    label {
        display: flex;
        align-items: center;
    }

    input[type='radio'] {
        margin-top: 0;
        width: 13px;
    }

    .flex-parent {
        display: flex;
        align-items: center;
    }

    .icon {
        display: flex;
        height: 21px;
        align-items: center;
        padding-right: 1px;
        margin-right: 6px;
        width: 14px;
        justify-content: center;

        & + span {
            font-weight: 600;
            margin-right: 4px;
        }
    }

    span {
        &:last-child {
            opacity:.8;
        }
    }
}

.form-control {
    &.inline {
        display: inline-block;
        width: auto;
    }

    &::-moz-placeholder,
    &:-moz-placeholder {
        color: inherit !important;
        opacity: .6;
    }
}

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
    background-color: #f9f9f9;
}

.fieldset {
    margin-bottom: 10px;

    &__buttons {
        margin-top: 10px;
        display: flex;
        justify-content: space-between;

        &--small {
            width: 540px;
        }
    }

    &__password {
        margin-top: 10px;
        margin-bottom: 20px;
    }

    &__push-heading {
        display: flex;
        justify-content: space-between;
    }

    &.border_shadow {
        -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
        -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
        -ms-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
        -o-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
        -webkit-transition: all 0.25s ease-in-out;
        -moz-transition: all 0.25s ease-in-out;
        -ms-transition: all 0.25s ease-in-out;
        -o-transition: all 0.25s ease-in-out;
        transition: all 0.25s ease-in-out;
        padding: 20px;
    }
    &.fieldset_middle {
        max-width: 600px;
        margin-bottom: 20px;
        padding: 20px;
    }
    &.fieldset_small {
        max-width: 350px;
        margin-bottom: 20px;
        padding: 20px;
    }
    &.fieldset_gray {
        //background: $gray;
    }

    &--base {
        padding: 5px 20px 10px 20px;
    }
    &--small {
        width: 495px;
    }
    &--mid {
        width: 695px;
    }
    &--shadow {
        -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
        -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
        -ms-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
        -o-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
        -webkit-transition: all 0.25s ease-in-out;
        -moz-transition: all 0.25s ease-in-out;
        -ms-transition: all 0.25s ease-in-out;
        -o-transition: all 0.25s ease-in-out;
        transition: all 0.25s ease-in-out;
    }
}


.form-content {
    width: 80%;
    padding: 20px;
    overflow: auto;
    height: 85vh;
}

.form-wrapper {

}

.form-wrapper {
    &--scrollable {
        overflow: auto;
    }
}


.bot-section{
    margin: 20px;
    border: 1px solid #E2E2E2;
    padding: 20px;
    background: #fbfbfb;
    /* border-bottom: 1px solid #E2E2E2; */
    border-radius: 5px;
    font-size: 16px;
    font-weight: 600;
    margin-left: 0;
}
.bot-section .bot-code {
    padding: 10px 0;
    font-weight: 200;
    font-size: 30px;
    letter-spacing: 2px;
}
