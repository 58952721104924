.app-preview__builder {
    margin: auto;
    .overlay {
        position: relative;
    }

    .app-layout {
        position: absolute;
        width: 320px;
        height: 555px;
        left: 40px;
        top: 121px;
        font-size: 12px;
        font-weight: bold;
        overflow: hidden;

        &__menu {
            display: flex;
            justify-content: space-evenly;
            height: 40px;
            align-items: center;


            .active {
                border-radius: 16px;
                width: 74px;
                height: 26px;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        &__list {
            list-style-type: none;
            padding: 10px;
            font-size: 16px;
            height: 480px;
            li {
                border-bottom:1px solid gainsboro;
                padding: 10px;
            }
        }

        .products {
            max-height: 425px;
            overflow: hidden;

            &.mosaic {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-around;
                flex-direction: inherit;
            }

            &.list {
                display: flex;
                justify-content:baseline;
                flex-direction: column;

                .content {
                    display: flex;
                    flex-direction: column;
                    margin-left: 15px;
                    justify-content: center;
                }
            }

            &.large-list {
                .product {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                }

                .content {
                    padding: 10px;
                    width: 100%;
                }
            }

            .product {
                padding: 2px;
            }
        }

        .main-button {
            color: white;
            background: #F84F6E;
            border-radius: 12px;
            /* height: 30px; */
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 7px;

            &.large {
                width: 95%;
                height: 35px;
            }
        }
    }

    .icon-mask {
        width: 48px;
        height: 48px;
        border-radius: 10px;
        position: absolute;
        left: 71.5%;
        top: 455px;
        background: #ff3636;
        border: 3px solid #fff;
    }

    .ls-logo-mask {
        width: 150px;
        height: 150px;
        position: relative;
        top: 25%;
        left: 25%;
    }

    .icon-text {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        position: absolute;
        left: -15px;
        top: 45px;
        font-size: 8pt;
        font-weight: bold;
        color: #fff;
        text-align: center;
        width: 75px;
    }

    .top-plank {
        width: 320px;
        height: 40px;
        top: 116px;
        left: 40px;
        display: flex;
        justify-content: space-between;

        &__region-select {
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            width: 30%;
            font-size: 12pt;
            color: white;
        }

        &__promo-value {
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            width: 23%;
            font-size: 12pt;
            color: white;
            justify-content: space-evenly;
        }
    }

    .bottom-plank {
        width: 320px;
        height: 40px;
        position: absolute;
        left: -1px;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        &__menu-item {

            width: 20%;
            height: 100%;
            align-items: center;
            display: flex;
            justify-content: center;

            &.active {
                background: rgba(0, 0, 0, 0.15);
            }
        }
    }

    .product-mockup {
        width: 320px;
        height: 80px;
        top: 116px;
        left: 40px;
    }

    .promo {
        background: rgb(248, 79, 110);
        height: 185px;
        background: rgb(248, 79, 110);
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 10px;
        color: white;
        font-weight: 14px;

        .slider {
            display: flex;
            justify-content: space-between;
            width: 20%;
        }

        .rect {
            width: 6px;
            height: 6px;
            background: white;
            border-radius: 2rem;

            &.active {
                background:rgba(0, 0, 0, 0.35);
            }
        }
    }

}

.app-preview__canvas {

}


.fixed-submit-bar {
    position: absolute;
    bottom: 1em;
    width: 100%;
    height: 5%;
    left: 1.5em;

    .button-wrapper {
        width: 250px;
        display: flex;
        justify-content: space-between;
    }
}

.constructor {
    display: flex;
    overflow: auto;
}

.preview-wrapper {
    position: fixed;
    left: 60%;
    top: 10%;
}


.products-extra__tags-input {
    background: $red;
}

.build-mashine-wrapper {
    width: 700px;
}

.build_mashine__pre-verobose-log {
    padding: 20px;
    height: 200px;
    overflow: auto;
}

.build_mashine__buttons {
    margin-top: 20px;
    button {
        margin-right: 5px;
    }
}

.build_mashine__pre-status {
    padding: 20px;
}