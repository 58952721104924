@charset 'UTF-8';

.compliance-panel__table,
.audit-panel__table,
.cluster-panel__table {
    background-color: $white;
    border: 1px solid $border-gray;
    margin-top: 10px;
    max-height: 70vh;
    min-height: 100px;
    overflow: auto;
    padding: 5px;
    width: 100%;
}

.compliance-panel,
.audit-panel {
    .row {
        > .form-group {
            padding-left: 0;

            &:first-child {
                padding-left: 15px;
            }
        }

        label {
            font-weight: 600;
        }
    }

    .fa-refresh {
        margin-right: 5px;
    }
}

.compliance-panel {
    margin-bottom: 3em;
}
