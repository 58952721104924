.levels-form-content {

}

.levels-list {
    list-style-type: none;
    margin-bottom: 20px;
}

.levels-list__formgroup {
    margin-bottom: 5px;
}


.levels-list__item {
    display: flex;
    align-items: center;
    padding: 5px;
}

.levels-list__item-wrapper {
    width: 450px;
    display:flex
}

.levels-list__menu {
    min-width:500px;
    display: flex;
    justify-content: space-between;
}


.levels-form-content__input {
    //height: 35px !important;
    text-align: right !important;
}

.levels-container__header {
    //color: $blue;
}

.levels-form-content__input_full {
    text-align: right !important;
    width: 100%;
}

.levels-form-content__container {

    margin-right: 10px !important;
}

.levels-list__label {

    &--number {
        align-self: center;
        margin-right: 10px;
        font-weight: bold !important;
        //color: $blue;
        width: 10px;
    }
    &--text {
        align-self: center;
        margin-right: 10px;
        text-overflow: ellipsis;
        overflow: hidden;
        word-break: normal;
        font-weight: bold !important;
    }
}

.levels-list__icon {
    &--base {
        margin:0px 2.5px 0px 2.5px !important;
    }
}



.levels-list__input-container {
    width: 100%;
}
