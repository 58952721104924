.MenuGroup {
    &.menu-divider {
        opacity:(.15);
        background: $black;
        height: 1px;
        margin: 8px 0;

        & + .menu-divider,
        &:first-child,
        &:last-child,
        &:nth-last-of-type(3) {
            display: none;
        }
    }

    &.mobile-menu-divider {
        & + .mobile-menu-divider,
        &:first-child,
        &:nth-last-child(2),
        &:nth-last-of-type(3) {
            display: none;
        }
    }
}
