
.sidebar--left {
    display: flex;
    flex-direction: column;
    border-right: $border-gray;
    height: 100%;
    left: 0;
    position: fixed;
    width: 420px;
    z-index: 9;


    .search-bar__container {
        padding: 0 10px;
    }

    body.announcement-bar--fixed & {
        height: calc(100% - #{$announcement-bar-height});
    }

    &.sidebar--padded {
        padding-top: 44px;
    }

    .sidebar--left__icons {
        border-bottom: 1px solid transparent;
    }

    .slidebar--left__tab-wrapepr {
        display: flex;
        /* padding: 10px; */
        justify-content: center;
        background: #fff;
        padding: 17px;
        border-bottom: 1px solid gainsboro;
    }

    .sidebar-item {
        height: 4em;
        border-bottom: 1px solid #cccccc;

        .icon {
            display: inline-block;
            //margin: 0 7px 0 1px;
            position: relative;
            text-align: left;
            vertical-align: top;
            width: 15px;
        }

        .icon__lock {
            top: 2px;
        }

        .icon__globe {
            top: 1px;
        }

        .icon__archive {
            top: 2px;
        }

        .icon__draft {
            top: 2px;
        }

        .icon__bot {
            top: 1px;
            left: -1px;

            svg {
                width: 16px;
                height: 14px;
            }
        }
    }

    .sidebar-item__name {
        display: flex;
        flex: 1;
/*         padding-right: 10px;
        overflow: hidden; */
        vertical-align: top;

        > span {
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100%;
            font-weight: bold;
        }
    }

    .sidebar-item__icon {
        display: flex;
        width: 100%;
        justify-content: center;
    }

    .sidebar-item__last {
        .message {
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }
    .sidebar__divider {
        color: $white;
        font-size: .9em;
        height: 20px;
        line-height: 20px;
        margin: 5px 0 6px;
        position: relative;
        z-index: 0;

        &:before {
            background: $light-gray;
            content: '';
            height: 1px;
            position: absolute;
            top: 10px;
            width: 100%;
        }

        .sidebar__divider__text {
            background: $primary-color;
            float: right;
            padding: 0 10px;
            position: relative;
            z-index: 1;
        }
    }

    .sidebar__switcher {
        flex: 0 0 45px;
        bottom: 0;
        display: block;
        padding-bottom: 0;
        width: 100%;

        button {
            background: transparent;
            display: block;
            font-weight: 600;
            height: 45px;
            line-height: 40px;
            padding: 0;
            text-decoration: none;
            width: 100%;

            > span {
                display: inline-block;
                position: relative;
                vertical-align: middle;
                z-index: 5;
            }

            &:hover {
                span {
                    opacity: 1;
                }
            }
        }

        .switch__shortcut {
            margin-left: 4px;
        }
    }
.sidebar--search {
    -webkit-flex: 0 0 65px;
    flex: 0 0 65px;
    border-bottom: 1px solid #cccccc;
    font-size: 14px;
    position: relative;
    width: 100%;
    z-index: 9;
    padding-top: 14px;
}
    .search__form {

        margin: 0;
        padding: 0em 10px;

        position: relative;


        .sidebar__search-clear {
            height: 35px;
            right: 15px;
            .sidebar__search-clear-x {
                height: 35px;
                display: block;
                line-height: 35px;
            }
        }

        .search-bar {

            box-shadow: none;
            font-size: 14px;

            outline: none;
            padding-bottom: 2px;
            width: 100%;

            border-radius: 1px;
            border: 1px #dde0e8  solid ;
            height: 35px;
            padding-left: 15px;
            padding-right: 15px;
            background: #fff;
            &:focus {
                border: 1px #63AEF4 solid;

            }
        }
    }
    .nav {
        margin: 0;
    }
    .nav-pills {
        > li {
            .badge {
                margin: 0 10px 0 0;
            }
        }
    }

    .sidebar--left__list {
        position: relative;
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        overflow: hidden;
    }

    .nav-pills__container {
        -webkit-overflow-scrolling: touch;
        overflow: auto;
        position: relative;
        width: 100%;
        padding-bottom: 10px;
    }

    .nav-pills__unread-indicator {
        border-radius:50px;
        font-size: 13.5px;
        cursor: pointer;
        left: 15px;
        margin: 0 auto;
        padding: 4px 0 3px;
        position: absolute;
        text-align: center;
        width: 190px;
        z-index: 1;

        .icon {
            margin-left: 5px;
        }
    }

    .nav-pills__unread-indicator-top {
        top: 15px;
        background: gray;
        color: #fff;
        left: 25%;

        .icon {
            svg {
                fill: white;
                transform:(rotate(180deg));
            }
        }
    }

    .nav-pills__unread-indicator-bottom {
        bottom: 15px;
    }

    .nav {
        &.nav-stacked {
            //margin-top: 3px !important;
            > li + li {
                margin: 0;
            }
        }

        .public_direct_name {
            flex: 1;
            cursor: pointer;
        }

        li {
            > h4 {
                align-items: end;
                display: flex;
                font-size: 1em;
                font-weight: 600;
                margin: 1.5em 0 .7em;
                padding: 0 12px 0 15px;
                text-transform: uppercase;

                span {
                    flex: 1;
                }
            }

            .divider {
                & + .divider {
                    display: none;
                }
            }

            > .nav-more {
                border-radius: 0;
                display: block;
                line-height: 1.5;
                outline: none;
                overflow: hidden;
                padding: 5px 0 5px 15px;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            .sidebar-item {
                //@include single-transition(none);
                align-items: center;
                border-radius: 0;

                line-height: 1.5;
                outline: none;
                overflow: hidden;
                padding: 5px 5px 5px 15px;
                text-decoration: none;
                text-align: left;
                text-overflow: ellipsis;
                white-space: nowrap;
                width: 100%;

                .status {
                    flex: 0 0 auto;
                }

                .icon {
                    &.icon__archive,
                    &.icon__globe,
                    &.icon__draft,
                    &.icon__lock,
                    &.icon__bot {
                        opacity: 0.5;
                    }
                }

                &.has-close {
                    &:hover {
                        .btn-close {
                            opacity:.8;
                            display: block;
                        }
                    }

                    .btn-close {
                        opacity: 0;
                        display: none;
                        font-family: 'Open Sans', sans-serif;
                        font-size: 21px;
                        font-weight: 600;
                        position: relative;
                        width: 24px;
                        height: 20px;
                        line-height: 20px;
                        margin-right: 7px;
                        text-align: center;

                        &:hover {
                            opacity: 1;
                        }
                    }
                }

                &.unread-title {
                    font-weight: 600;
                }

                &.muted {
                    opacity: 0.5;
                }

            }

            &.active {

                .muted {
                    opacity: 1;
                }

                button,a {
                    &:before {

                    }
                }

                .icon {
                    &.icon__archive,
                    &.icon__globe,
                    &.icon__draft,
                    &.icon__lock,
                    &.icon__bot {
                        opacity: 0.7;;
                    }
                }

                button,a,
                button:hover,a:hover,
                button:focus,a:focus {
                    @include alpha-property(background-color, #63AEF4, .1);
                    border-radius: 0;
                    font-weight: 400;

                    color: #000;
                    &.unread-title {
                        font-weight: 600;
                    }
                }
            }
        }
    }

    .modal-title {
        line-height: 2em;
    }

    .add-channel-btn {
        float: right;
        font-size: 1.9em;
        font-weight: 700;
        line-height: 18px;
        margin: -2px 0 0 0;
        outline: none;
        padding: 0 5px;
        text-decoration: none;

        &:hover {
            color: #666666;
        }
    }

    .status-wrapper {
        height: 36px;
        width: 36px;

        .status {
            bottom: -4px;
            height: 18px;
            right: -5px;
            width: 18px;

            &.status-edit {
                .fa {
                    top: 4px;
                }
            }

            .fa {
                position: relative;
                top: 2px;
            }

            .icon__container {
                &:after {
                    border-radius: 20px;
                    content: '';
                    height: 10px;
                    left: 4px;
                    position: absolute;
                    top: 4px;
                    width: 10px;
                }
            }

            svg {
                height: 13px;
                left: 3px;
                max-height: initial;
                position: relative;
                top: 3px;
                width: 13px;
                z-index: 1;
            }
        }
    }
}


    .sidebar--left {
        left: 100px;
    }

    .app__content {
        margin-left: 305px;
    }

.channel-loading-gif {
    height: 15px;
    margin-top: 2px;
    width: 15px;
}

.join-channel-loading-gif {
    height: 25px;
    margin-left: 10px;
    margin-top: 5px;
    width: 25px;
}

/* context menu shown when right-clicking on a channel in the LHS when using the desktop app */
.react-contextmenu--visible {
    background: #f0f0f0;
    border: 1px solid #C6C6C6;
    border-radius: 5px;
    color: black;
    cursor: pointer;
    padding: 2px 0;
    z-index: 100;
}

.react-contextmenu-item {
    margin: 1px 0;
    padding: 0 22px;

    &:hover {
        background: #489DFE;
        color: $white;
    }

    &:focus,
    span {
        outline: none;
    }
}

.flex-child{
    &.last {
        padding-top: 8px;
    }
}

.menu {
    &__info {
        p {
            font-weight: bold;
        }

        ul {
            list-style: none;
            margin-left:0;
        }

        .fields {
            padding: 2px 24px
        }

    }

    &__logout {
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        padding: 12px;
        color:gray;
    }
}
