.req-form-content {
    padding: 20px;

    &__fixed-submit-bar {
        position: absolute;
        bottom: 1em;
        width: 100%;
        height: 5%;
        left: 1.5em;
    }
}

.req-form-wrapper {
    width: 1000px;
}