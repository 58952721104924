.channel-view {
    .sidebar-item__name {
        position: relative;
    }

    .team-sidebar {
        .badge {
            border: 1px solid transparent;
            position: absolute;
            right: -8px;
            top: -6px;
            z-index: 5;
        }
    }

    .navbar-toggle {
        .badge {
            position: absolute;
            top: 10px;
            left: 8px;
        }
    }
}
