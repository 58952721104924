
button {
    &.style--none {
        background: transparent;
        border: none;
        padding: 0;

        &:focus {
            outline: 0;
        }

        &.btn {
            padding: 6px 12px;
        }

        &.btn--block {
            text-align: left;
            width: 100%;
        }

        &:hover,
        &:active {
            text-decoration: none;
        }
    }
}

.btn {

    border-radius:$border-rad;

    &.btn-primary {
        background: $primary-color;
        border-color: transparent;

        &:hover,
        &:focus,
        &:active {
            background: $primary-color--hover;
        }
    }

    &.btn-danger {
        color: $white;

        .app__body & {
            color: $white;

            &:hover,
            &:focus,
            &:active {
                color: $white;
            }
        }

        &:hover,
        &:focus,
        &:active {
            color: $white;
        }
    }

    &.btn-transparent {
        background: transparent;
        border: none;
        padding: 7px 12px;
    }

    &.btn-inactive {
        background: $light-gray;
        border-color: transparent;
        color: $white;
    }

    .fa {
        margin-right: 3px;

        &.margin-right {
            margin-right: 6px;
        }

        &.margin-left {
            margin-left: 6px;
        }
    }

    &.btn-link {
        &:active,
        &:focus {
            outline: none;
        }
    }
}


.link-style-button {
    background: none;
    border:none;
    outline: none;
    padding: 1px;
    margin-right: 1em;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover:not(.disabled) {
        cursor: pointer;
        margin-right: 1em;
        font-weight: bold;
    }

    &.gray {
        color: #C1C1C1;
    }

    &.green {
        color:#74C467;
    }

    &.red {
        color: #DF5454;
    }

    &.blue {
        color:#63AEF4;
    }

    &.orange {
        color: #F38D2E;
    }

    &.disabled {
        cursor:not-allowed;
        color: #C1C1C1;
    }

    text-align: left;
}

