.builder-form-content {
    width: 950px;
    padding: 20px;
    //overflow: auto;
    height: 85vh;

    h1, h2, h3, h4 {
        margin: 0px 10px 20px 0px !important;
    }

    .color-panel {
        max-width: 500px;
    }

    &__buttons {
        margin-top: 10px;
        display: flex;
        button {
            margin-right:5px;
        }
    }
}