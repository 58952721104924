.get-app {
    -webkit-overflow-scrolling: touch;
    height: 100%;
    left: 0;
    overflow: auto;
    padding: 20px 30px 30px;
    position: absolute;
    top: 0;
    width: 100%;

    hr {
        border-top: 1px solid #ddd;
    }

    .get-app__header {
        color: #666;
        font-size: 20px;
        font-weight: bold;
        text-align: center
    }

    .get-app__screenshot {
        border-bottom: 1px solid #ddd;
        display: block;
        margin: auto;
    }

    .get-app__continue-with-browser {
        display: block;
        margin-top: 40px;
        padding-bottom: 30px;
        text-align: center;
    }

    .btn-primary {
        display: block;
        font-size: 18px;
        margin: 2rem 0 3rem;
        font-weight: 600;
        border-radius: 4px;
        line-height: 40px;
    }
}

.get-android-app {
    .get-app__header {
        text-align: left;
    }

    .get-android-app__icon {
        width: 60px;
    }

    .get-android-app__app-info {
        display: inline-block;
        margin-left: 8px;
        vertical-align: middle;

        .get-android-app__app-name {
            color: #666;
            display: block;
            font-size: 13px;
            font-weight: bold;
        }

        .get-android-app__app-creator {
            color: #aaa;
            display: block;
            font-size: 10px
        }
    }

    .get-app__screenshot {
        width: 240px;
    }
}

.get-ios-app {
    .get-app__screenshot {
        width: 180px;
    }

    .get-ios-app__app-store-link {
        display: block;
        margin: auto;
        margin-bottom: 30px;
        width: 180px;
    }

    .get-ios-app__already-have-it {
        font-size: 18px;
        margin-bottom: 20px;
        text-align: center;
    }
}