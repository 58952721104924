@charset 'UTF-8';
@import "../base/colors";

.status-text {
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
}

.status-badge {
    border-radius: 4px;
    padding: 4px;
    display: flex;
    flex-wrap: wrap;
    max-width: 150px;
    justify-content: center;
    color:white;
}

.status-badge {
    &_purple {
        background: $purple;
    }
    &_red {
        background: $red;
    }
    &_green {
        background: $green;
    }
    &_orange {
        background: $orange;
    }
    &_ocean {
        background: $ocean;
    }
    &_lgray {
        background: $lgray;
    }
}

.status-badge {
    &_shadow {
        box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    }
}

.status-text_important {
    font-weight: bold;
}