@charset 'UTF-8';


$blue: #409ef8;
$red: #DF5454;;
$green: #74C467;
$purple: #a790e0;
$orange: #ff885c;
$ocean: #f9fcff;
$lgray: #bdbdbd;

.color {
    &.green {
        color:$green;
    }
    &.red {
        color:$red;
    }
    &.blue {
        color:$blue;
    }
}

.background {
    &.green {
        background:$green;
    }
    &.red {
        background:$red;
    }
    &.blue {
        background:$blue;
    }
    &.purple {
        background: $purple;
    }
    &.orange {
        background: $orange;
    }
}

.fg-color {
    &--green {
        color:$green;
    }
    &--red {
        color:$red;
    }
    &--blue {
        color:$blue;
    }
    &--purple {
        color: $purple;
    }
    &--orange {
        color: $orange;
    }
}
