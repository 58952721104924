@keyframes rowhighlight {
    0%  {
      background-color: $transparent;
    }

    20% {
      background-color: beige;
    }

    80% {
      background-color: beige;
    }

    100% {
      background-color: $transparent;
    }
}

.permissions-block {
    .more-schemes {
        width: 100%;
        text-align: center;
        padding: 10px;
        text-decoration: none;
    }

    &.closed {
        .permissions-tree {
            max-height: 0;
            overflow-y: hidden;
            padding: 0 1.5rem;
        }
    }
    .no-team-schemes {
        margin: 20px;
        padding: 20px;
        background: rgba(255, 255, 255, .5);
        font-size: 16px;
        color: $gray;
        text-align: center;
    }
    .team-override-unavailable {
        padding: 20px;
        color: #3333337d;
        opacity: 50%;
        .team-override-unavailable__inner {
            padding: 20px;
            background-color: $white;
        }
    }
    .teams-list {
        padding: 20px;
        .no-team-schemes {
            margin: 0;
        }
        .team {
            display: flex;
            padding: 10px;
            &:nth-child(even) {
                background: rgba(255, 255, 255, .5);
            }
            .remove {
                flex-grow: 0;
            }
        }
    }
}

.permissions-tree {

    max-height: 2000px;
    padding: .8em 1.5rem;

    .permissions-tree--header {
        border-bottom: 1px solid #cccccc;
        display: flex;
        flex-direction: row;
        font-size: 1.1em;
        font-weight: bold;
        padding: .5em 0;

        .permission-name {
            margin-left: 16px;
            min-width: 300px;
        }

        .permission-description {
            color: $black;
            display: block;
            width: 100%;
            opacity: 1;
        }
    }

    .permissions-tree--body {
        background-color: $bg--gray;
        background-image: linear-gradient(transparent 50%, alpha-color($white, .5) 50%);
        background-size: 68px 68px;
        cursor: pointer;
    }

    .permission-arrow {
        align-items: center;
        display: flex;
        justify-content: space-around;
        margin: 5px 7px 4px 6px;
        min-height: 10px;
        min-width: 10px;
        transition: transform 300ms;

        &.open {
            transform: rotate(90deg);
            transform-origin: 55% 50%;
            transition: transform 300ms;
            margin-top: 6px
        }
    }

    .permission-check {
        align-items: center;
        border-radius: 3px;
        border: 2px solid $dark-gray;
        display: flex;
        justify-content: space-around;
        margin-right: 5px;
        min-height: 18px;
        min-width: 18px;

        &.checked,
        &.intermediate {
            border: 0;

            svg {
                background: $white;
                fill: $primary-color;
            }
        }
    }

    .permission-name {
        display: inline-block;
        min-width: 300px;
    }

    .permission-description {
        align-items: center;
        display: flex;
        height: 100%;
        overflow: hidden;
        width: 100%;
        opacity: .65;

        span {
            display: inline-block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 100%;
        }
    }

    .permission-group {
        .permission-group-row {
            .permission-name {
                min-width: 268px;
            }
        }

        .permission-group-permissions {
            max-height: 0;
            overflow: hidden;
            transition: max-height 300ms;

            &.open {
                max-height: 400px;
                transition: max-height 300ms;
            }

            .permission-row {
                padding: 0 0 0 48px;

                .permission-name {
                    min-width: 242px;
                }
            }

            .permission-group-row {
                padding: 0 0 0 25px;

                &.combined {
                    padding: 0 0 0 48px;
                }

                .permission-name {
                    min-width: 244px;
                }
            }

            .permission-group-permissions {
                .permission-row {
                    padding: 0 0 0 78px;

                    .permission-name {
                        min-width: 213px;
                    }
                }

                .permission-group-row {
                    padding: 0 0 0 60px;

                    &.combined {
                        padding: 0 0 0 78px;
                    }

                    .permission-name {
                        min-width: 192px;
                    }
                }
            }
        }
    }

    .permission-row,
    .permission-group-row {
        align-items: center;
        border: 2px solid $transparent;
        display: flex;
        flex-direction: row;
        height: 34px;

        &:hover {
            background-color: lighten($primary-color, 40%);
            border-left: 2px solid $primary-color;
            .permission-description {
                opacity: 1;
            }
        }

        &.selected {
            animation-duration: 3s;
            animation-name: rowhighlight;
        }

        &.read-only {
            .permission-check {
                background-color: $gray;
                border: none;

                &.checked,
                &.intermediate {
                    svg {
                        background: $white;
                        fill: $gray;
                    }
                }
            }
        }
    }
}

.permissions-scheme-summary {
    margin: 0 20px;
    padding: 10px 20px 0 20px;
    border-left: 2px solid $transparent;
    cursor: pointer;
    &:nth-child(2) {
        margin-top: 20px;
    }
    &:last-child {
        margin-bottom: 20px;
    }
    &:nth-child(odd) {
        background: rgba(255, 255, 255, .5);
    }
    &:hover {
        background-color: lighten($primary-color, 40%);
        border-left: 2px solid $primary-color;
    }
    .permissions-scheme-summary--header {
        display: flex;
        flex-direction: row;
        font-size: 1.1em;
        .title {
            flex-grow: 1;
            font-weight: bold;
            font-size: 14px;
        }
        .actions {
            flex-grow: 0;
            a {
                display: inline-block;
                padding: 0 3px;
            }
        }
    }
    .permissions-scheme-summary--teams {
        padding: 1.5em 0 0.5em;

        .team {
            margin-bottom: 10px;
            height: 25px;
            display: inline-flex;
            background: rgba(0, 0, 0, 0.05);
            border-radius: 13px;
            padding: 0 8px;
            margin-right: 10px;
            align-items: center;
            justify-content: center;
            font-size: 11px;
            color: alpha-color($black, .6);
        }
    }
}

.team-scheme-extra-teams-overlay {
    .tooltip-inner {
        display: flex;
        flex-direction: column;
    }
}

.permission-scheme-summary-error-message {
    color: $red;
}

.team-scheme-details {
    padding: 20px 20px 5px 20px;
    .form-group {
        display: flex;
        label {
            width: 240px;
        }
    }
}

.team-info-block {
    display: flex;
    flex-grow: 1;
    align-items: center;

    .icon {
        border-radius:4px;
        background-color: $primary-color;
        color: $white;
        font-weight: bold;
        height: 44px;
        letter-spacing: -.5px;
        line-height: 42px;
        position: relative;
        text-align: center;
        text-transform: capitalize;
        vertical-align: top;
        width: 44px;

        .team-btn__image {
            border-radius: 4px;
            overflow: hidden;
            background-color: white;
            background-repeat: round;
            background-size: contain;
            height: 100%;
            width: 100%;
        }

        .team-btn__content {
            bottom: 4px;
            font-size: 8.5px;
            font-weight: normal;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 31px;
        }
    }
    .team-data {
        flex-grow: 1;
        padding: 0 0 0 10px;
        .title {
            font-weight: bold;
        }
    }
}
