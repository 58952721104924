.hint-wrapper {
    display: flex;
    align-items: center;
    padding: 5px 0px 5px 0px;
    justify-content: space-between;
    max-width: 250px;
}

.hint-wrapper__header {
    font-size: small;
}

.hint-wrapper__hint {
    width: 250px;
    margin-left:5px;
}