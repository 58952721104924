@charset 'UTF-8';

.padding-top {
    padding-top: 7px;

    &.half {
        padding-top: 3px;
    }

    &.x2 {
        padding-top: 12px;
    }

    &.x3 {
        padding-top: 21px;
    }
}

.padding-bottom {
    padding-bottom: 7px;

    &.half {
        padding-bottom: 3px;
    }

    &.x1 {
        padding-bottom: 4px;
    }

    &.x2 {
        padding-bottom: 12px;
    }

    &.x3 {
        padding-bottom: 21px;
    }

    .control-label {
        font-weight: 600;

        &.text-left {
            text-align: left;
        }
    }
}

.padding-left {
    padding-left: 7px;

    &.x2 {
        padding-left: 12px;
    }

    &.x3 {
        padding-left: 21px;
    }
}

.padding-right {
    padding-right: 7px;

    &.x2 {
        padding-right: 12px;
    }

    &.x3 {
        padding-right: 21px;
    }
}

.margin-right {
    margin-right: 7px;

    &.margin-right--half {
        margin-right: 3px;
    }

    &.x2 {
        margin-right: 12px;
    }

    &.x3 {
        margin-right: 21px;
    }
}

.margin-left {
    margin-left: 7px;

    &.x2 {
        margin-left: 12px;
    }

    &.x3 {
        margin-left: 21px;
    }
}

.margin-top {
    margin-top: 7px;

    &.margin-top--half {
        margin-top: 4px;
    }

    &.x2 {
        margin-top: 12px;
    }

    &.x3 {
        margin-top: 21px;
    }
}

.margin-bottom {
    margin-bottom: 7px;

    &.margin-bottom--half {
        margin-top: 4px;
    }

    &.x2 {
        margin-bottom: 12px;
    }

    &.x3 {
        margin-bottom: 21px;
    }
}

.d-block {
    display: block;
}


.text-bold {
    font-weight: bold;
}

.text-right {
    text-align: end !important;
}

.p-20 {
    padding: 20px;
}

.p-4em {
    padding: 4em;
}

.text-light {

}