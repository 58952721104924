@charset 'UTF-8';

@media screen and (max-width: 1800px) {
    .inner-wrap {
        &.move--left {
            .date-separator,
            .new-separator {
                &.hovered--comment {
                    &:before,
                    &:after {
                        background: none;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1440px) {
    .inner-wrap {
        &.move--left {
            .help__format-text {
                display: none;
            }
        }
    }

    .date-separator,
    .new-separator {
        &.hovered--comment {
            &:before,
            &:after {
                background: none !important;
            }
        }
    }

    .backstage-content {
        margin: 46px 46px 46px 150px;
    }
}

@media screen and (max-width: 1280px) {
    .inner-wrap.move--left {
        .post-collapse__show-more, .post-attachment-collapse__show-more {
            margin-left: 0;
        }
    }

    .inner-wrap {
        &.move--left {
            .post-create__container {
                .channel-archived__close-btn {
                    display: block;
                    margin: 10px auto 0;
                }
            }
        }
    }

    .integration-option {
        flex: 0 0 45%;
    }
}

@media screen and (max-width: 1140px) {
    .tip-overlay {
        &.tip-overlay--chat {
            margin: -10px 0 0 -10px;

            .arrow {
                left: auto;
                right: 15px;
            }
        }
    }

    .inner-wrap {
        &.move--left {
            .file-overlay {
                font-size: em(18px);

                .overlay__circle {
                    height: 300px;
                    margin: -150px 0 0 -150px;
                    width: 300px;
                }

                .overlay__files {
                    margin: 60px auto 15px;
                    width: 150px;
                }
            }
        }
    }
}

@media (max-width: 1024px) {
    .channel-header {
        .search-bar__container {
            .search__form {
                width: 150px;
            }
        }
    }
}

@media (min-width: 992px) {
    .modal-lg {
        width: 700px;
    }
}
