

.p-row {
    display:flex;
    flex-wrap: nowrap;
}

.p-column {

    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 1;

    text-align: left;



}

.p-col-1 {
    max-width: calc(100% / 6);
}
.p-col-2 {
    max-width: calc(100% / 6 * 2);
}
.p-col-3 {
    max-width: calc(100% / 6 * 3);
}
.p-col-4 {
    max-width: calc(100% / 6 * 4);
}
.p-col-5 {
    max-width: calc(100% / 6 * 5);
}
.p-col-6 {
    max-width: calc(100% / 2);
}



@media screen and (max-width: 768px) {
    .p-container {
        overflow: auto;
    }
    .p-column {
        min-width: 100%;
        width: 100%;
        margin: 1em 0;
    }
}


.preview-image {
    display: block;
    height: 100%;
    width: 100%;
}

.iphone-preview {
    width: 22.85em;
    height: 45.714285em;
    position: relative;
    margin-bottom: 1em;
}
.bg {
    width: 22.85em;
    height: 45.714285em;
    z-index: 1;
    position: absolute;
}


.preview-content {
    width: 20em;
    height: 43em;
    position: absolute;
    top: 1.385em;
    left: 1.585em;
    overflow: hidden;
}
.iphone-preview {
    font-family: Roboto;
}

.p-menu {
    margin-bottom: 1em;

}

.p-catalog-menu__wrapper {
    overflow: hidden;
}

.p-catalog-menu {
    white-space: nowrap;
    overflow: hidden;
    overflow-x: hidden;
    font-size: 1em;

    li {
        display: inline-block;
        margin: 0 1em;

        border-radius: 3em;
        padding: 0.5em 1em;
        &.active {
            font-weight: 500;
            background: #F84F6E;
            color: #fff
        }
    }
}


.p-header {
    width: 19.825em;
    height: 3.285em;
    position: fixed;
    background-color: #F84F6E;
}
.p-content {
    width: 19.825em;
    padding-top: 5em;
    position: relative
}
.p-product__image {
    position: relative;
    background-size: cover;
    background-position: 50% 50%;
    width: 100%;
    height: 8.5em;
    border-radius: 0.85em;
    background-repeat: no-repeat;
}
.p-product__title {

    font-size: 1em;
    font-weight: 500;
    margin-bottom: .85em;
    color: #333333;
}
.p-product__desc {
    font-size: 0.75em;
    margin-bottom: .85em;
    color: #333333;
}
.p-product__attr {
    font-size: 0.75em;
    margin-bottom: .85em;
}
.p-product__button {
    font-size: 1em;
    font-weight: 500;
    margin-bottom: .85em;
}
.p-product__wr{
    padding: 0.5em;
}
.b-btn {
    &.b-btn__round {
        display: inline-block;
        background: #F84F6E;
        color: #fff;
        width: 2em;
        height: 2em;
        text-align: center;
        line-height: 2em;
        font-size: 1.14285em;
        font-weight: 500;
        border-radius: 3em;

    }
    &.b-btn__long {
        display: none;
        width: auto;
        padding: 0 1em;
    }
    margin-right: 0.5em;
}
.p-product__attr_l {
    display: none;
}

.p-catalog {

    &.p-catalog__card{
        display:flex;
        flex-wrap: wrap;
        .p-product {
            flex-grow: 1;
            flex-shrink: 1;
            flex-basis: 1;


            text-align: left;


            max-width: 50%;
            .p-row {
                display: block;

                .p-col-6 {
                    display: block;
                    max-width: 100%;
                }
            }

        }
    }
}
.p-catalog {

    &.p-catalog__block{
        display:block;
        flex-wrap: wrap;
        .p-product {
            flex-grow: 1;
            flex-shrink: 1;
            flex-basis: 1;


            text-align: left;

            padding: 0;

            flex: none;
            width: 100%;
            display: block;
            .p-row {
                display: block;

                .p-col-6 {
                    display: block;
                    max-width: 100%;
                }
            }

        }

        .b-btn__round {
            display: none;
            &.b-btn__long {
                display: inline-block;
                width: 100%;
            }
        }
        .p-product__image {
            height: 200px;
        }
        .b-price {
            display: none;
        }

        .p-colum__image {

            margin: 0;
        }
        .p-colum__text {
            padding: 6px;
            margin: 6px;
        }
        .p-product__attr {
            display: none;
        }
        .p-product__attr_l {
            display: inline-block;
        }
    }
}

.p-product__mockup {
    .p-product__image {
        color: #efefef !important;
        background: #efefef !important;
    }
    .mockup-text {
        color: #efefef !important;
        background: #efefef !important;
    }
    .b-btn {
        color: #efefef !important;
        background: #efefef !important;
    }
}

.preview-view {
    width: 22.85em;
    height: 45.714285em;
    position: relative;
    text-align: center;
}
