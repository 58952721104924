.promo-form-content {
    padding: 20px;
    font-size: 13px;
    width:950px;
}

.promo-form {
    margin-right: 350px;
    padding: 0px;
}
.promo-preview {
    position: fixed;
    height: 100%;
    right: 0;
    top: 120px;
    width: 350px;
}