@charset 'UTF-8';


.products-search__container{
    margin-left: 1rem;
    margin-right: 2rem;
    border: 1px solid $blue;
    padding: 1rem;
    display: flex;
}

.search-bar__container {
    .channel-header__links {
        .sidebar--right & {
            line-height: 25px;
        }
    }
}

.icon--rotate {
   // @include animation(spin .7s infinite linear);
}

.search-item-snippet {
    @include clearfix;
    text-overflow: ellipsis;

    ul,
    ol {
        padding-left: 20px;
    }
}

.search__icon {
    left: 9px;
    position: absolute;
    top: 6px;

    svg {
       // //@include single-transition(all, .3s, ease-out);
    }
}

.sidebar__search-clear {
    ////@include single-transition(all, .3s, ease-out);
    opacity: 0.5;
    cursor: pointer;
    margin-right: 20px;
    position: absolute;
    right: 0;
    top: 0;
    visibility: hidden;
    width: 22px;
    margin: 0;
    font-size: 20px;

    &:hover {
        opacity: 1;
    }

    &.visible {
        visibility: visible;
    }
}

.search-bar__container {
    padding-left: 8px;
}

.search__form {

    /* todo: отрубил т.к. едет поиск
    padding: 0 30px; */
    position: relative;

    .search-bar__container & {

        ////@include single-transition(all, .3s, ease-out);

        margin: 0;
        width: 100%;

       /* background: #FFFFFF;
        border: 1px solid #E2E2E2;
        box-sizing: border-box;
        box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.08);
        border-radius: 4px;*/
    }

    .sidebar--right & {
        width: 100%;
    }

    .search-bar {
        background: transparent;
        border: none;
        box-shadow: none;
        font-size: 14px;
        height: 28px;
        outline: none;
        padding-bottom: 2px;
        width: 100%;
    }

    .LoadingSpinner {
        opacity: 0.5;
        font-size: 1em;
        position: absolute;
        right: 8px;
        top: 5px;
    }
}

.search-items-container {
    flex:1 1 auto;
    -webkit-overflow-scrolling: touch;
    height: calc(100% - 56px);
    padding-top: 10px;
    position: relative;
}

.search-items-container div.loading {
    text-align: center;
}

.search-items-container img {
    display: inline-block;
}

.search-results-header {
    border-bottom: $border-gray;
    color: #999999;
    font-size: 1em;
    font-weight: 400;
    height: 44px;
    line-height: 44px;
    padding: 0 10px;
    text-transform: uppercase;
}

.search-item__container {
    .search-post {
        min-height: 4em;
        border-bottom: 1px solid #cccccc;
        transition: none 1s;
        align-items: center;
        border-radius: 0;
        line-height: 1.5;
        outline: none;
        overflow: hidden;
        padding: 5px 15px 5px 15px;
        text-decoration: none;
        text-align: left;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
        position: relative;
        .search-post__wrapper {
            position: relative;
        }
        .search-post__content {
            position: relative;
        }

        .search-post__messageWrapper {

        }
        .search-post__username {
            padding-right: 10px;
            color: #63AEF4;
            font-weight: 500;
        }
        .search-post__message {
            display: inline;
            vertical-align: top;
            white-space: normal;
            .post-message {
                position: relative;
                overflow: hidden;
                display: inline;
                white-space: normal;
            }
            .post-message--collapsed .post-message__text-container {
                max-height: 600px;
                white-space: normal;
                display: inline;
            }
            .post-message__text {
                white-space: normal;
                display: inline;
                p{
                    white-space: normal;
                    display: inline;
                }
            }
        }
        .search-post__jamper {
            position: relative;
            color: inherit;
            display: block;
            &:hover{
                text-decoration: none;
            }
        }
        .search-channel__name {
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100%;
            font-weight: bold;
        }
        .search-post-time {
            position: absolute;
            right: 0;
            top: 0
        }
        .search-channel__archived {
            opacity: 0.5;
            float: right;
        }
    }
}

.col__controls {
    font-size: 13px;
    position: absolute;
    right: 0;
    top: 0;

    a {
        vertical-align: top;
    }


    .search-item__jump {
        border-radius:4px;
        font-size: 13px;
        position: relative;
        padding: 2px 7px 0;
        text-decoration: none;
    }
}

.search-item-time {
    opacity: 0.7;;
    font-size: .9em;
}

.search-results-none {
    padding: 10px;
}

.search-highlight {
    background-color: $yellow;
}
