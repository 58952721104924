@charset 'UTF-8';

.animation--highlight {
    &:before {
        animation: highlight 1.5s ease;
        content: '';
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        display: inline-block;
    }
}

.activity-log__table {
    border-top: 1px solid $light-gray;
    display: table;
    line-height: 1.8;
    padding: 15px 0;
    width: 100%;

    &:first-child {
        border: none;
        padding-top: 0;
    }

    > div {
        display: table-cell;
        vertical-align: top;
    }

    .activity-log__report {
        width: 80%;
    }

    .activity-log__action {
        text-align: right;
    }

    .report__platform {
        font-size: 15px;
        font-weight: 600;

        .fa {
            margin-right: 6px;
        }
    }

    .report__info {
        opacity:.8;
    }
}

.session-help-text {
    padding: 0 0 20px;
}
