.browser--ie {
    .modal {
        .modal-dialog {
            transform: translateY(0);
        }
    }
}

.modal-body {
    max-height: calc(90vh - 62px);
    overflow: auto;
    padding: 20px 15px;
}

.edit-modal-body {
    overflow: visible;

    .custom-textarea {
        height: auto;
        max-height: 60vh;
        min-height: 8em;

        &.custom-textarea--preview {
            display: none;
        }

        &.textbox-preview-area {
            position: relative;
        }
    }

    .suggestion-list__content {
        max-height: 150px;
    }

    .edit-post__actions {
        display: inline-block;
        height: 30px;
        line-height: 34px;
        position: absolute;
        right: 18px;
        text-align: center;
        top: 24px;
        width: 30px;

        .icon {
            opacity: 0.5;
            cursor: pointer;
        }

        &:hover {
            .icon {
                opacity: 0.7;;
            }
        }
    }

    .edit-post-footer {
        display: inline-block;
        font-size: 13px;
        position: relative;

        .post-error {
            opacity:.55;
            font-size: .85em;
            font-weight: normal;
            margin-bottom: 0;
            position: relative;
            top: 3px;
        }
    }
}

.row--invite {
    .col-sm-6 {
        &:first-child {
            padding-right: 0;
        }
    }
}

.modal__hint {
     opacity:.8;
    display: block;
    font-size: .9em;
    margin: 0 0 10px;
}

.modal__error {
    color: $red;
    float: left;
    font-size: .95em;
    font-weight: normal;
    margin-top: 6px;
}

.more-table {
    margin: 0;
    table-layout: fixed;
}

.modal {
    color: alpha-color($black, .9);
    width: 100%;

    .channel-switch-modal {
        .modal-header {
            background: transparent;
            border: none;
            min-height: 0;
            padding: 0;

            .close {
                top: 10px;
            }
        }
    }

    .modal--overflow {
        .modal-body {
            overflow: visible;
        }
    }

    textarea {
        overflow-x: hidden;
    }

    &.modal-image {
        overflow: hidden;

        .modal-backdrop {
            &.in {
                opacity: 0.7;;
            }
        }
    }

    .custom-textarea {
        border-color: $light-gray;
        color: inherit;
        padding: 12px 30px 12px 15px;

        &:focus {
            border-color: $light-gray;
            box-shadow: none;
        }
    }

    .btn {
        font-size: 13px;
    }

    .info__label {
        opacity: 0.5;
        font-size: .9em;
        font-weight: 600;
        margin-bottom: 3px;
    }

    .info__value {
        padding-left: 10px;
        word-break: break-word;
    }

    .team-member-list {
        width: 100%;
    }

    .remove__member {
        color: inherit;
        float: right;
        font-size: 20px;
        line-height: 0;
        padding: 6px;

        &:hover {
            color: $red;
        }
    }

    .modal-dialog {
        margin-bottom: 0;
        margin-left: auto;
        margin-right: auto;
        max-width: 95%;

        &.modal--overflow-visible {
            .modal-body {
                position: static;

                .dropdown {
                    position: static;
                }

                .dropdown-menu {
                    left: auto;
                    right: 20px;
                }
            }
        }

        &.modal-xl {
            width: 800px;
        }
    }

    .modal-push-down {
        margin-top: 60px;
    }

    .modal-next-bar {
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
    }

    .modal-header {
        border-radius:0;
        @include clearfix;
        background: alpha-color($black, .85);
        border: 1px solid $light-gray;
        color: $white;
        min-height: 56px;
        padding: 14px 15px 11px;

        .modal-title {
            color: $bg--gray;
            float: left;
            font-size: 17px;
            line-height: 27px;
            max-width: calc(100% - 80px);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            .name {
                color: $white;
                font-weight: 600;
            }
        }

        .modal-action {
            margin: 0;
            padding: 0;
        }

        .close {
            opacity: 1;
            //@include single-transition(all, .25s, ease-in);
            color: $white;
            height: 30px;
            line-height: 30px;
            position: absolute;
            right: 10px;
            text-shadow: none;
            width: 30px;
            z-index: 5;

            &:hover {
                @include alpha-property(background, $black, .1);
            }

            .sr-only {
                display: none;
            }

            span {
                font-family: 'Open Sans', sans-serif;
                line-height: 10px;
            }
        }

        .btn {
            &.btn-primary {
                float: right;
                margin: -4px 33px 0 0;
                position: relative;

                i {
                    margin-right: 5px;
                }
            }
        }
    }

    .no-channel-message {
        padding: 2em 1em;
        text-align: center;

        .primary-message {
            font-size: 1.25em;
        }

        .secondary-message {
            opacity:.8;
            margin: 1em 0 0;
        }
    }

    .modal-content {
        box-shadow:0 0 10px rgba($black, .5);
        border-radius: $border-rad;
    }

    .modal-chevron-icon {
        font-size: 120%;
        top: 50%;
    }

    .modal-prev-bar {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
    }

    .modal-image {
        height: 100%;
        margin: 0 auto;
        max-width: 100%;
        position: relative;
        width: 100%;

        .modal-body {
            @include clearfix;
            background: transparent;
            display: table;
            height: 100%;
            max-height: 100%;
            table-layout: fixed;
            width: 100%;
        }

        .modal-image__content {
            background: #F3F3F3;
            max-height: 91.5vh;
            max-width: 96vw;
            overflow-x: hidden;
            overflow-y: visible;
        }

        .post-code__container {
            overflow: auto;
        }

        .modal-image__wrapper {
            border-radius:(3px);
            display: table-cell;
            max-width: 90%;
            position: relative;
            text-align: center;
            vertical-align: middle;
            width: 100%;

            &:hover {
               border-radius:(3px 3px 0 0);
            }

            &.default {
                height: 80%;
                width: 100%;
            }

            .post-code {
                background: $white;
            }

            audio,
            canvas,
            progress,
            video {
                display: block;
                height: auto;
                max-height: 85vh;
                width: auto;
                margin: 0 auto;
            }

            .modal-close {

                background-size:(100% 100%);
                opacity: 0;
                background-image: url('../../images/close.png');
                cursor: pointer;
                height: 37px;
                position: absolute;
                right: -13px;
                top: -13px;
                transition: opacity .6s;
                width: 37px;
                z-index: 9999;

                &.modal-close--show {
                    opacity: 1;
                }
            }

            >div {
                display: inline-block;
                min-height: 100px;
                min-width: 300px;
                position: relative;
                max-width: 100%;
            }

            code {
                min-height: 130px;
                min-width: 330px;
            }

            pre,
            code {
                display: inline-block;
            }

            .post-body--code {
                max-height: calc(100vh - 80px);
                max-width: calc(100vw - 80px);
                overflow: auto;
            }

            img {
                max-height: 91vh;
                max-width: 100%;
            }

            .spinner {
                .file__loading {
                    left: 50%;
                    margin-left: -16px;
                    margin-top: -16px;
                    position: absolute;
                    top: 50%;
                    z-index: 2;
                }
            }
        }

        .modal-content {
            background: alpha-color($black, 0);
            border: none;
            box-shadow: none;
            height: 100%;
            padding: 0;
            width: 100%;
        }

        .image-control {
            background: url('../../images/prev.png') left no-repeat;
            float: left;
            height: 45px;
            margin-top: -23px;
            position: relative;
            top: 50%;
            width: 50px;

            &.image-next {
                background: url('../../images/next.png') left no-repeat;
                float: right;
            }
        }

        .loader-image {
            bottom: 0;
            left: 0;
            margin: auto;
            position: absolute;
            right: 0;
            top: 0;
        }

        .loader-percent {
            color: alpha-color($white, 0.8);
            display: block;
            margin-top: 12px;
            height: 20px;
        }

        .modal-button-bar {
            overflow: hidden;
            background-color: $black;
            line-height: 40px;
            max-width: 100%;
            padding: 0 10px;

            .image-links {
                a,
                span {
                    float: right;
                }
            }

            .text {
                bottom: 0;
                color: $white;
                margin-left: 5px;
                vertical-align: middle;
            }

            .public-link {
                margin-right: 5px;
            }
        }
    }

    &.more-channel__modal {
        .modal-body {
            overflow-x: hidden;
            padding: 10px 0 15px;
        }

        .channel-count {
            opacity:.8;
            float: right;
            margin-top: 5px;
        }
    }
}

.more-modal {
    .Select-input {
        >input {
            padding: 8px 0;
        }
    }

    .status-wrapper {
        flex: 32px 0 0;
    }

    .more-modal__gm-icon {
        align-items: center;
        display: flex;
        flex: 32px 0 0;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        justify-content: center;
    }

    .user-list {
        margin-top: 10px;
        overflow-x: hidden;
        overflow-y: auto;
        position: relative;
    }

    .modal-body {
        overflow-x: hidden;
        padding: 10px 0 15px;
    }

    .filter-row {
        margin: 5px 0 10px;
        width: 300px;

        &.filter-row--full {
            width: 100%;
        }
    }

    .member-count {
        opacity:.8;
        float: right;
        margin-top: 5px;
    }

    .member-show {
        opacity:.8;
        float: right;
        margin-right: 3px;
        margin-top: 8px;
    }

    .member-select__container {
        .member-count {
            margin: 12px 0 0 3px;
        }

        select {
            opacity:.8;
            float: right;
            margin: 1px 5px 0 2px;
            width: auto;
        }
    }

    .more-purpose {
        opacity: 0.7;;
    }
}

.modal-tabs {
    width: 100%;
    display: flex;
    flex-direction: column;

    .tab-content {
        flex: 1 1 auto;
        height: auto;
        flex-direction: column;
        display: flex;
    }

    .tab-pane {
        flex: 1 1 auto;
        height: auto;
        display: none;

        &.active {
            display: flex;
        }
    }

    .nav-tabs {
        margin: 0 0 16px;

        >li {
            margin-right: 0px;
            width: 50%;
            text-align: center;
            border: none;
            border-bottom: 2px $light-gray solid;

            &.active {
                border-bottom: 2px $primary-color solid;

                a {
                    opacity: 1;
                }
            }

            >a {
                opacity: .5;
                color: inherit;
                height: 40px;
                padding-bottom: 12px;
                outline: none;
                border: none;
                display: flex;
                font-weight: 900;
                justify-content: center;
                align-items: center;

                &:active {
                    opacity: 1;
                }
            }
        }
    }
}

.more-modal__button {
    .btn {
        font-size: .9em;
        font-weight: 600;
        height: 39px;
        width: 100%;
    }
}

.more-modal__list {
    display: flex;
    flex-direction: column;

    .browser--ie & {
        flex: 20;
    }

    >div {
        min-height: 100%;
        overflow: auto;
    }

    .popover & {
        font-size: .95em;

        .more-modal__row {
            padding: 5px 10px;
        }
    }

    .more-modal__image {
        border-radius:60px;
        flex-grow: 0;
        flex-shrink: 0;
        margin-top: 2px;
        max-width: none;
    }

    .more-modal__details {
        align-self: center;
        flex-grow: 1;
        flex-shrink: 1;
        overflow: hidden;
        padding-left: 10px;
        text-overflow: ellipsis;
    }

    .more-modal__actions {
        flex-grow: 0;
        flex-shrink: 0;
        margin: 5px 0;
        padding-left: 8px;
    }

    .more-modal__name {
        font-weight: 600;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .more-modal__description {
        opacity: 0.6;
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        &:empty {
            display: none;
        }
    }

    .more-modal__row {
        border-bottom: 1px solid $light-gray;
        display: flex;
        padding: 10px 15px;
        align-items: center;

        &.clickable {
            cursor: pointer;
        }

        &:hover {
            .more-modal__actions--round {
                opacity: .5;
            }
        }
    }

    .more-modal__placeholder-row {
        padding: 10px 15px;
    }

    .more-modal__actions--round {
        height: 32px;
        line-height: 32px;
        opacity: 0;
        text-align: center;
        width: 32px;
    }

    .more-modal__right {
        display: flex;
        flex-direction: column;
        width: 100%;

        .control-label {
            font-weight: normal;
            padding: 5px 0 0 5px;
        }
    }

    .more-modal__top {
        display: flex;
    }

    .more-modal__bottom>.has-error {
        padding-left: 5px;
    }

    p {
        opacity:.8;
        font-size: .9em;
        margin: 5px 0;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.member-select__container {
    position: absolute;
    right: 15px;
    top: 15px;
}

.filtered-user-list {
    display: flex;
    flex-direction: column;
    height: calc(90vh - 120px);
    width: 100%;

    >div {
        flex: 1 1 auto;
    }

    .more-modal__list {
        flex-grow: 500;
        height: 1px;
    }

    .filter-button {
        .btn {
            height: 34px;
        }
    }

    .filter-controls {
        padding: 1em 1.5em 0;

        .filter-control__next {
            float: right;
        }
    }

    .filter-button {
        margin-left: 0;
        padding-left: 0;
    }
}

.team-selector-modal, .channel-selector-modal {
    .more-modal__row {
        border: 0px;
        &:nth-child(odd) {
            background: rgba(0, 0, 0, .03);
        }
        &:hover {
            background: rgba(0, 0, 0, .05);
        }
        .team-name {
            padding-left: 5px;
            color: $gray;
        }
        .icon__lock, .icon__globe {
            margin-right: 5px;
        }
    }
}

.modal-invite-member {
    .btn:hover {
        text-decoration: underline;
    }
}

.team-selector-modal, .channel-selector-modal {
    .modal-header {
        background: $black;
        color: $white;
        .close {
            color: $white;
            opacity: 1;
            &:hover {
                opacity: 1;
            }
        }
    }
    .modal-body {
        padding: 15px 0;
        .filter-row {
            padding: 0 15px;
        }
        .more-modal__row--selected {
            background-color: lighten($primary-color, 40%);
            .more-modal__actions--round {
                opacity: .5;
            }
        }
        .no-channel-message {
            width: 100%;
            text-align: center;
            font-size: 1.25em;
            color: $gray;
            margin-top: 40px;
        }
        .filter-row--full {
            flex-grow: 0;
        }
    }
    .filter-control {
        color: $primary-color;
        background: none;
        border: none;
        &:hover,
        &:focus,
        &:active {
            color: $primary-color;
            background: none;
            border: none;
            box-shadow: none;
        }
    }
}

.more-public-direct-channels {
    .filtered-user-list {
        height: calc(90vh - 180px);
    }
}

.modal-body {
    .manage-teams__teams {
        .MenuWrapper {
            position: static;
        }

        .Menu {
            top: inherit;
            margin-right: 20px;
        }
    }
}
